import axios from 'axios';
import { IMG_API_BASE_URL } from 'common/data/Api';
import { decodeImage, encodeParams } from 'common/utils';

export type FontType = 'font' | 'fontVersion';

export interface FontImageParameters {
  text?: string;
  fontSize?: number;
  minFontSize?: number;
  maxFontSize?: number;
  maxWidth?: number;
  maxHeight?: number;
  lineHeight?: number;
  maxLines?: number;
  wordWrap?: boolean;
  breakWord?: boolean;
  ellipsis?: boolean;
  hAlign?: 'left' | 'center' | 'right';
  vAlign?: 'top' | 'center' | 'bottom';
  hMargin?: number;
  vMargin?: number;
  hTrim?: boolean;
  vTrim?: boolean;
  pixelTrim?: boolean;
  backgroundColor?: string;
  fillColor?: string;
  strokeColor?: string;
  strokeWidth?: number;
  otFeatures?: Record<string, any>;
}

const baseUrl = `${IMG_API_BASE_URL}/image`;

export const generateFontImage = async (
  fontType: FontType,
  fontId: number,
  parameters?: FontImageParameters
): Promise<API.FontImage.Image> => {
  const cryptedParams = await encodeParams(fontId, parameters);
  return axios.get(`${baseUrl}/${fontType}/${fontId}?${encodeURIComponent(cryptedParams)}`).then(async ({ data }) => {
    const decryptedSvg = await decodeImage(fontId, data.svg);
    data.svg = decryptedSvg;
    return data;
  });
};
