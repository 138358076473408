import { useState } from 'react';

export interface SearchContextData {
  openSearch: boolean;
  setOpenSearch: (value: boolean) => void;
}

export const useSearchState = (data: SearchContextData): SearchContextData => {
  const [openSearch, setOpenSearch] = useState<boolean>(data.openSearch);

  return {
    openSearch,
    setOpenSearch,
  };
};
