import styled from '@emotion/styled';
import { GREY_6 } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 16px;
`;

export const Text = styled.p`
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: ${GREY_6};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @supports (-webkit-line-clamp: 2) {
    white-space: initial;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  /* TABLET */
  ${mq['tablet']} {
    width: 135px;
  }
`;
