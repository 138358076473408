import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GREY_2, GREY_6, NINJA_GREEN, WHITE } from 'design-system/global/colors';
import { easeInOutExpo } from 'common/easing/Easing';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
  open?: boolean;
}

export const NotificationStyled = styled.div`
  position: fixed;
  z-index: 2;
  display: flex;
  align-items: center;
  background-color: ${NINJA_GREEN};
  transition: transform 0.3s ${easeInOutExpo};
  /* MOBILE */
  top: 0;
  left: 0;
  width: 100%;
  height: 95px;
  padding: 0 18px;
  transform: translate(0, -96px);
  ${(props: Props) =>
    props.open &&
    css`
      transform: translate(0px, 0px);
    `}
  /* TABLET */
  ${mq['tablet']} {
    top: 24px;
    left: auto;
    right: 24px;
    width: 243px;
    height: 79px;
    padding: 0 30px 0 24px;
    border-radius: 8px;
    border: 1px solid ${(props: Props) => (props.darkMode ? 'transparent' : GREY_6)};
    box-shadow: ${(props: Props) => (props.darkMode ? 'none' : `4px 4px 0px ${GREY_6}`)};
    transform: translate(270px, 0);
    ${(props: Props) =>
      props.open &&
      css`
        transform: translate(0px, 0px);
      `}
  }

  ${(props: Props) =>
    props.open &&
    css`
      transform: translate(0px, 0px);
    `}
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
`;
