import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  burgerMenuOpen?: boolean;
  darkMode?: boolean;
  displayNavPageAppearanceTooltip?: boolean;
}

export const NavSearchPageAppearanceWrapper = styled.div`
  display: flex;
  /* MOBILE */
  margin-right: -12px;
  /* DESKTOP */
  ${mq['desktop']} {
    margin: -9px -4px 0 -6px;
  }
  ${(props: Props) =>
    props.burgerMenuOpen &&
    css`
      pointer-events: none;
    `}
`;

export const NavSearchPageAppearanceItem = styled.div`
  position: relative;
  /* MOBILE */
  margin: 0;
  /* DESKTOP */
  ${mq['desktop']} {
    margin: 0 -4px;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)} !important;
  }

  @media (hover: hover) {
    &.nav-search:hover {
      .tooltip-search {
        ${(props: Props) =>
          !props.displayNavPageAppearanceTooltip &&
          css`
            display: block;
          `}
      }
    }

    &.nav-page-appearance:hover {
      .tooltip-page-appearance {
        ${(props: Props) =>
          !props.displayNavPageAppearanceTooltip &&
          css`
            display: block;
          `}
      }
    }
  }
`;

export const Tooltip = styled.div`
  display: none;
  position: absolute;
  min-width: 99px;
  top: 40px;
  left: 14px;
  border-radius: 8px;
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  box-shadow: ${(props: Props) => (props.darkMode ? 'none' : `4px 4px 0px ${GREY_6}`)};
  padding: 11px 16px;
  /* MOBILE */
  transform: translate(-80%, 0);
  /* TABLET */
  ${mq['tablet']} {
    transform: translate(-75%, 0);
  }
  /* DESKTOP */
  ${mq['desktop']} {
    transform: translate(-50%, 0);
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% + 32px);
    top: -32px;
    left: 0;
    pointer-events: none;
  }
`;

export const TooltipArrow = styled.div`
  position: absolute;
  height: 16px;
  top: 0;
  line-height: normal;
  transform: translate(-25%, -12px);
  @-moz-document url-prefix() {
    transform: translate(-25%, -12.5px);
  }
  /* MOBILE */
  left: 80%;
  transform: translate(-25%, -12px);
  @-moz-document url-prefix() {
    transform: translate(-25%, -12.5px);
  }
  /* TABLET */
  ${mq['tablet']} {
    left: 75%;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    left: 50%;
  }
`;

export const TooltipLabel = styled.p`
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
`;
