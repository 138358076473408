import { isServerSideRendering } from 'common/utils';

const DEV_API_BASE_URL = 'http://localhost:8888';
const PREPROD_CLIENT_API_BASE_URL = 'https://api-v2-preprod.fonts.ninja';
const PREPROD_SERVER_API_BASE_URL = 'http://fontsninja-api-v2-preprod-lb-1009248345.eu-west-3.elb.amazonaws.com';
const PROD_CLIENT_API_BASE_URL = 'https://api-v2.fonts.ninja';
const PROD_SERVER_API_BASE_URL = 'http://fontsninja-api-v2-prod-lb-223376177.eu-west-3.elb.amazonaws.com';

const PREPROD_IMG_API_BASE_URL = 'https://rdm342bhva.execute-api.eu-west-3.amazonaws.com';
const PROD_IMG_API_BASE_URL = 'https://el8c9u8ej7.execute-api.eu-west-3.amazonaws.com';

const getApiBaseUrl = () => {
  if (process.env.API_ENV === 'preprod') {
    if (isServerSideRendering()) {
      return PREPROD_SERVER_API_BASE_URL;
    } else {
      return PREPROD_CLIENT_API_BASE_URL;
    }
  } else if (process.env.API_ENV === 'production') {
    if (isServerSideRendering()) {
      return PROD_SERVER_API_BASE_URL;
    } else {
      return PROD_CLIENT_API_BASE_URL;
    }
  } else {
    return DEV_API_BASE_URL;
  }
};

const getImgApiBaseUrl = () => {
  if (process.env.API_ENV === 'production') {
    return PROD_IMG_API_BASE_URL;
  } else {
    return PREPROD_IMG_API_BASE_URL;
  }
};

export const API_BASE_URL = getApiBaseUrl();
export const IMG_API_BASE_URL = getImgApiBaseUrl();
