import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DANGER_RED } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
}

interface InputTextWrapperProps {
  isPrivate?: boolean;
}

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 5px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 32px 16px;
  /* TABLET */
  ${mq['tablet']} {
    width: 395px;
    padding: 32px 30px 23px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 32px;
`;

export const FormWrapper = styled.form``;

export const InputTextWrapper = styled.div`
  position: relative;
  margin-bottom: 26px;
  ${(props: InputTextWrapperProps) =>
    props.isPrivate &&
    css`
      [class^='icon-'],
      [class*=' icon-'] {
        position: absolute;
        right: 12px;
        /* MOBILE */
        top: 14px;
        /* TABLET */
        ${mq['tablet']} {
          top: 10px;
        }
      }
      label > input {
        padding-right: 36px;
      }
    `}
`;

export const SwitchWrapper = styled.div`
  /* MOBILE */
  margin-bottom: 40px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 45px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonSaveWrapper = styled.div``;

export const ButtonDeleteWrapper = styled.div``;

export const GlobalError = styled.p`
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${DANGER_RED};
`;
