import { CryptographyKey, SodiumPlus } from 'sodium-plus';

let sodium: SodiumPlus;
let key: CryptographyKey;

const initSodium = async () => {
  if (sodium) return sodium;

  sodium = await SodiumPlus.auto();
  const keyBuf = Buffer.from('shared-front+back-length-32bytes');
  key = new CryptographyKey(keyBuf);

  return sodium;
};

export const encodeParams = async (idFont: number, params: any) => {
  const sodium = await initSodium();
  const nonce = Buffer.alloc(24, 'other-string-len-24bytes');
  nonce.writeDoubleBE(idFont);
  const json = JSON.stringify(params);
  const encrypted = await sodium.crypto_secretbox(unescape(encodeURIComponent(json)), nonce, key);
  return encrypted.toString('base64');
};

export const decodeImage = async (idFont: number, encrypted: string) => {
  const sodium = await initSodium();
  const buffer = Buffer.from(encrypted, 'base64');
  const nonce = Buffer.alloc(24, 'other-string-len-24bytes');
  nonce.writeDoubleBE(idFont);
  const decrypted = await sodium.crypto_secretbox_open(buffer, nonce, key);
  return decrypted.toString();
};
