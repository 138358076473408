import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Slider } from 'design-system/atoms/slider';
import { usePrevious } from 'hooks';
import BookmarkButton from 'containers/BookmarkButton/BookmarkButton';
import { BookmarkButtonWithSliderWrapper, SliderContainer, SliderWrapper } from './BookmarkButtonWithSlider.styled';

interface FontItem {
  value: number;
  idFont: number;
  label: string;
  icon: string;
}
type FontItems = Array<FontItem>;

interface Props {
  familyId: string;
  fontId: number;
  fonts: API.Fonts;
  darkMode: boolean;
  onClickBookmark: (Event: SyntheticEvent<HTMLButtonElement>) => void;
  onChangeFontId?: (fontId: number) => void;
  onAfterChangeFontId?: (fontId: number) => void;
}

const BookmarkButtonWithSlider = ({
  familyId,
  fontId,
  fonts,
  darkMode,
  onClickBookmark,
  onChangeFontId,
  onAfterChangeFontId,
}: Props) => {
  const previousFontId = usePrevious(fontId);

  const orderedFonts = useMemo(() => fonts.sort((a, b) => (a.weightNum > b.weightNum ? 1 : -1)), [fonts]);

  const getGoodIcon = useCallback((weightNum: number): string => {
    if (weightNum <= 100) {
      return 'weight_100';
    } else if (weightNum > 100 && weightNum <= 200) {
      return 'weight_200';
    } else if (weightNum > 200 && weightNum <= 300) {
      return 'weight_300';
    } else if (weightNum > 300 && weightNum <= 400) {
      return 'weight_400';
    } else if (weightNum > 400 && weightNum <= 500) {
      return 'weight_500';
    } else if (weightNum > 500 && weightNum <= 600) {
      return 'weight_600';
    } else if (weightNum > 600 && weightNum <= 700) {
      return 'weight_700';
    } else if (weightNum > 700 && weightNum <= 800) {
      return 'weight_800';
    } else {
      return 'weight_900';
    }
  }, []);

  const fontItems: FontItems = useMemo(
    () =>
      orderedFonts.map((font, index) => {
        return {
          value: index + 1,
          idFont: font.idFont,
          label: font.weight.toUpperCase(),
          icon: getGoodIcon(font.weightNum),
        };
      }),
    [getGoodIcon, orderedFonts]
  );

  const [fontValue, setFontValue] = useState<number>(fontItems.find((font) => font.idFont === fontId)?.value ?? 1);
  const [fontIcon, setFontIcon] = useState<string>(
    fontItems.find((font) => font.value === fontValue)?.icon ?? 'weight_400'
  );
  const [fontLabel, setFontLabel] = useState<string>(fontItems.find((font) => font.value === fontValue)?.label ?? '');
  const marksSlider = useMemo(() => fontItems.map((item) => item.value), [fontItems]);

  useEffect(() => {
    if (previousFontId && previousFontId !== fontId) {
      let currentFontValue = fontItems.find((font) => font.idFont === fontId)?.value ?? 1;
      setFontValue(currentFontValue);
      setFontIcon(fontItems.find((font) => font.value === currentFontValue)?.icon ?? 'weight_400');
      setFontLabel(fontItems.find((font) => font.value === currentFontValue)?.label ?? '');
    }
  }, [fontId, fontItems, previousFontId]);

  const handleChangeFont = useCallback(
    (value: number | readonly number[], index: number) => {
      const currentValue = Array.isArray(value) ? value[index] : value;
      if (onChangeFontId) onChangeFontId(fontItems.find((font) => font.value === currentValue)!.idFont);
    },
    [fontItems, onChangeFontId]
  );

  const handleAfterChangeFont = useCallback(
    (value: number | readonly number[], index: number) => {
      const currentValue = Array.isArray(value) ? value[index] : value;
      if (onAfterChangeFontId) onAfterChangeFontId(fontItems.find((font) => font.value === currentValue)!.idFont);
    },
    [fontItems, onAfterChangeFontId]
  );

  return (
    <BookmarkButtonWithSliderWrapper nbFonts={fonts.length}>
      {fonts.length > 1 && (
        <SliderWrapper darkMode={darkMode}>
          <SliderContainer>
            <Slider
              value={fontValue}
              defaultIcon={fontIcon}
              defaultLabel={fontLabel}
              withTooltip
              min={1}
              max={fontItems.length}
              marks={marksSlider}
              step={1}
              darkMode={darkMode}
              onChange={handleChangeFont}
              onAfterChange={handleAfterChangeFont}
            />
          </SliderContainer>
        </SliderWrapper>
      )}
      <BookmarkButton
        familyId={familyId}
        darkMode={darkMode}
        onClickBookmark={onClickBookmark}
      />
    </BookmarkButtonWithSliderWrapper>
  );
};

export default BookmarkButtonWithSlider;
