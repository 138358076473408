import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import { useNotificationContext, useSettingsContext } from 'context';
import Notification from 'components/Notification';
import Icon from './Icon';
import { ContentWrapper, IconWrapper, Text } from './BoardInvitationEmailSend.styled';

const BoardInvitationEmailSend = () => {
  const { t } = useTranslation('common');
  const { darkMode } = useSettingsContext();
  const { openNotificationEmailInvitationSend, setOpenNotificationEmailInvitationSend } = useNotificationContext();

  const handleCloseComplete = useCallback(() => {
    setOpenNotificationEmailInvitationSend(null);
  }, [setOpenNotificationEmailInvitationSend]);

  if (openNotificationEmailInvitationSend) {
    return (
      <Notification
        darkMode={darkMode}
        onCloseComplete={handleCloseComplete}
      >
        <ContentWrapper>
          <IconWrapper>
            <Icon />
          </IconWrapper>
          <Text>
            {t('notification-board-email-invitation-send.label', { email: openNotificationEmailInvitationSend })}
          </Text>
        </ContentWrapper>
      </Notification>
    );
  }
  return null;
};

export default BoardInvitationEmailSend;
