import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface NavProfileContainerProps {
  displayNavPageAppearanceTooltip: boolean;
}

interface TooltipProps {
  darkMode: boolean;
}

interface TooltipItemProps {
  active?: boolean;
  darkMode?: boolean;
}

export const NavProfileContainer = styled.div`
  position: relative;
  &:hover {
    .tooltip-profile {
      ${(props: NavProfileContainerProps) =>
        !props.displayNavPageAppearanceTooltip &&
        css`
          display: block;
        `}
    }
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
  z-index: 1;
  cursor: pointer;
`;

export const Tooltip = styled.div`
  display: none;
  position: absolute;
  min-width: 99px;
  top: 38px;
  left: -54px;
  border-radius: 8px;
  background-color: ${(props: TooltipProps) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: TooltipProps) => (props.darkMode ? GREY_2 : GREY_6)};
  box-shadow: ${(props: TooltipProps) => (props.darkMode ? 'none' : `4px 4px 0px ${GREY_6}`)};
  padding: 4px;
  &:before {
    content: '';
    position: absolute;
    width: 70px;
    height: calc(100% + 38px);
    top: -38px;
    right: 0;
  }
`;

export const TooltipArrow = styled.div`
  position: absolute;
  height: 16px;
  top: 0;
  left: 53px;
  line-height: normal;
  transform: translate(0, -12px);
  // Firefox
  @-moz-document url-prefix() {
    transform: translate(0, -12.5px);
  }
  // Safari Mobile
  @supports (-webkit-touch-callout: none) {
    transform: translate(0, -13px);
  }
`;

export const TooltipItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 2px;
  background-color: ${(props: TooltipItemProps) => (props.darkMode ? GREY_6 : WHITE)};
  border-radius: 4px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover {
    background-color: ${(props: TooltipItemProps) => (props.darkMode ? WHITE : GREY_6)};
  }
  a {
    padding: 0 8px;
    color: ${(props: TooltipItemProps) => (props.darkMode ? WHITE : GREY_6)} !important;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
    cursor: ${(props: TooltipItemProps) => (props.active ? 'default' : 'pointer')};
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
  [class^='icon-'],
  [class*=' icon-'] {
    color: ${(props: TooltipItemProps) => (props.darkMode ? WHITE : GREY_6)} !important;
    margin-right: 8px;
  }

  &:hover {
    a {
      color: ${(props: TooltipItemProps) => (props.darkMode ? GREY_6 : WHITE)} !important;
    }
    [class^='icon-'],
    [class*=' icon-'] {
      color: ${(props: TooltipItemProps) => (props.darkMode ? GREY_6 : WHITE)} !important;
    }
  }

  ${(props: TooltipItemProps) =>
    props.active &&
    css`
      background-color: ${props.darkMode ? WHITE : GREY_6};
      a {
        color: ${props.darkMode ? GREY_6 : WHITE} !important;
      }
      [class^='icon-'],
      [class*=' icon-'] {
        color: ${props.darkMode ? GREY_6 : WHITE} !important;
      }
    `}
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`;
