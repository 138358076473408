import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useAuthContext, useBreakpointContext, useModalContext, useSettingsContext } from 'context';
import { useSocial } from 'hooks/queries/useSocial';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Avatar from 'components/Avatar';
import { transformStringToUrl } from 'common/utils';
import { PAGE_SIZE_FOLLOW, QUERY_KEYS } from 'common/data/Constants';
import {
  CloseButtonWrapper,
  ContentWrapper,
  FollowButtonWrapper,
  FollowerAvatarName,
  FollowerItem,
  FollowerLoading,
  FollowerName,
  FollowersLoadingWrapper,
  FollowersWrapper,
  ModalWrapper,
  Separator,
  TitleWrapper,
} from './FollowingFoundry.styled';

const FollowingFoundry = () => {
  const { t } = useTranslation('common');
  const { userId } = useAuthContext();
  const { darkMode } = useSettingsContext();
  const { isMobile } = useBreakpointContext();
  const { openModalFollowingFoundry, setOpenModalFollowingFoundry } = useModalContext();

  const queryClient = useQueryClient();

  const { useListFollowersByFoundry, useFollowUser, useUnfollowUser } = useSocial();

  const {
    data: followingData,
    isLoading: isLoadingFollowing,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useListFollowersByFoundry(openModalFollowingFoundry?.idFoundry ?? '', PAGE_SIZE_FOLLOW, {
    enabled: !!openModalFollowingFoundry,
  });

  const followUserMutation = useFollowUser({
    onMutate: (variables) => {
      if (followingData) {
        // Snapshot the previous value
        const previousData = queryClient.getQueryData<InfiniteData<API.Social.ListFollowingFoundry>>([
          QUERY_KEYS.LIST_FOLLOWERS_BY_FOUNDRY,
          { idFoundry: openModalFollowingFoundry!.idFoundry },
        ]);
        const cloneFollowingData: InfiniteData<API.Social.ListFollowingFoundry> = JSON.parse(
          JSON.stringify(previousData)
        );
        for (let i = 0; i < cloneFollowingData.pages.length; i++) {
          const currentUserIndex = cloneFollowingData.pages[i].users.findIndex((user) => user.id === variables.idUser);
          if (currentUserIndex !== -1) {
            cloneFollowingData.pages[i].users[currentUserIndex].followed = true;
            queryClient.setQueryData(
              [QUERY_KEYS.LIST_FOLLOWERS_BY_FOUNDRY, { idFoundry: openModalFollowingFoundry!.idFoundry }],
              cloneFollowingData
            );
          }
        }
        return { previousData };
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_SOCIAL_SUMMARY] });
    },
    onError: (err, variables, context) => {
      if (context?.previousData) {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData(
          [QUERY_KEYS.LIST_FOLLOWERS_BY_FOUNDRY, { idFoundry: openModalFollowingFoundry!.idFoundry }],
          context.previousData
        );
      }
    },
  });

  const unfollowUserMutation = useUnfollowUser({
    onMutate: (variables) => {
      if (followingData) {
        // Snapshot the previous value
        const previousData = queryClient.getQueryData<InfiniteData<API.Social.ListFollowingFoundry>>([
          QUERY_KEYS.LIST_FOLLOWERS_BY_FOUNDRY,
          { idFoundry: openModalFollowingFoundry!.idFoundry },
        ]);
        const cloneFollowingData: InfiniteData<API.Social.ListFollowingFoundry> = JSON.parse(
          JSON.stringify(previousData)
        );
        for (let i = 0; i < cloneFollowingData.pages.length; i++) {
          const currentUserIndex = cloneFollowingData.pages[i].users.findIndex((user) => user.id === variables.idUser);
          if (currentUserIndex !== -1) {
            cloneFollowingData.pages[i].users[currentUserIndex].followed = false;
            queryClient.setQueryData(
              [QUERY_KEYS.LIST_FOLLOWERS_BY_FOUNDRY, { idFoundry: openModalFollowingFoundry!.idFoundry }],
              cloneFollowingData
            );
          }
        }
        return { previousData };
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_SOCIAL_SUMMARY] });
    },
    onError: (err, variables, context) => {
      if (context?.previousData) {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData(
          [QUERY_KEYS.LIST_FOLLOWERS_BY_FOUNDRY, { idFoundry: openModalFollowingFoundry!.idFoundry }],
          context.previousData
        );
      }
    },
  });

  const modalWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const [startClose, setStartClose] = useState<boolean>(false);
  const [modalWrapperHeight, setModalWrapperHeight] = useState<string | undefined>(undefined);

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalFollowingFoundry(null);
  }, [setOpenModalFollowingFoundry]);

  const displaySeparator = useCallback(() => {
    if (isMobile) {
      if (followingData && followingData?.pages[0].totalUsers >= 9) {
        return true;
      }
    } else {
      if (followingData && followingData?.pages[0].totalUsers >= 12) {
        return true;
      }
    }
    return false;
  }, [followingData, isMobile]);

  const handleFollow = useCallback(
    (userId: number) => {
      if (followingData) {
        followUserMutation.mutate({ idUser: userId });
      }
    },
    [followUserMutation, followingData]
  );

  const handleUnfollow = useCallback(
    (userId: number) => {
      if (followingData) {
        unfollowUserMutation.mutate({ idUser: userId });
      }
    },
    [followingData, unfollowUserMutation]
  );

  useEffect(() => {
    // transition resize ModalWrapper
    setTimeout(() => {
      if (modalWrapperRef.current && contentWrapperRef.current && followingData) {
        const contentWrapperHeight = contentWrapperRef.current.getBoundingClientRect().height;
        modalWrapperRef.current!.style.height = `${contentWrapperHeight}px`;
        modalWrapperRef.current!.addEventListener('transitionend', () => {
          setModalWrapperHeight('auto');
          modalWrapperRef.current!.removeAttribute('style');
        });
      }
    }, 10);
    // transition resize ModalWrapper: Security for Safari when `transitionend` not fired
    setTimeout(() => {
      if (modalWrapperRef.current && modalWrapperRef.current.style.height !== '') {
        setModalWrapperHeight('auto');
        modalWrapperRef.current.removeAttribute('style');
      }
    }, 400);
  }, [followingData]);

  useEffect(() => {
    if (!openModalFollowingFoundry) {
      setModalWrapperHeight(undefined);
    }
  }, [openModalFollowingFoundry]);

  if (openModalFollowingFoundry !== null) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper
          ref={modalWrapperRef}
          modalWrapperHeight={modalWrapperHeight}
        >
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper ref={contentWrapperRef}>
            <TitleWrapper displaySeparator={displaySeparator()}>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {`${openModalFollowingFoundry.count} ${t('following-foundry.following')}`}
              </H3>
            </TitleWrapper>
            <Separator
              displaySeparator={displaySeparator()}
              darkMode={darkMode}
            />
            <FollowersWrapper
              id="followersWrapper"
              displaySeparator={displaySeparator()}
            >
              {isLoadingFollowing && (
                <FollowersLoadingWrapper>
                  <Spinner darkMode={darkMode} />
                </FollowersLoadingWrapper>
              )}
              {!isLoadingFollowing && followingData && (
                <InfiniteScroll
                  dataLength={followingData.pages.reduce((acc, page) => acc + page.users.length, 0)}
                  next={() => {
                    if (!isFetchingNextPage) {
                      fetchNextPage();
                    }
                  }}
                  hasMore={!!hasNextPage}
                  loader={
                    <FollowerLoading>
                      <Spinner darkMode={darkMode} />
                    </FollowerLoading>
                  }
                  scrollableTarget="followersWrapper"
                >
                  {followingData.pages.map((page, pageIndex) => (
                    <Fragment key={pageIndex}>
                      {page.users.map((user, index) => (
                        <FollowerItem key={`${pageIndex}_${index}`}>
                          <FollowerAvatarName>
                            <Link
                              href={`/${transformStringToUrl(user.name)}-${user.id}`}
                              onClick={handleClose}
                            >
                              <Avatar
                                width={24}
                                height={24}
                                border={1}
                                darkMode={darkMode}
                                src={user.avatarUrl}
                                userId={user.id}
                                userName={user.name}
                                withShadow={false}
                              />
                            </Link>
                            <Link
                              href={`/${transformStringToUrl(user.name)}-${user.id}`}
                              onClick={handleClose}
                            >
                              <FollowerName>{user.name}</FollowerName>
                            </Link>
                          </FollowerAvatarName>
                          {user.id !== userId && (
                            <FollowButtonWrapper>
                              {user.followed ? (
                                <Button
                                  size="small"
                                  render="contrast"
                                  darkMode={darkMode}
                                  onClick={() => handleUnfollow(user.id)}
                                >
                                  {t('following-foundry.following')}
                                </Button>
                              ) : (
                                <Button
                                  size="small"
                                  darkMode={darkMode}
                                  onClick={() => handleFollow(user.id)}
                                >
                                  {t('following-foundry.follow')}
                                </Button>
                              )}
                            </FollowButtonWrapper>
                          )}
                        </FollowerItem>
                      ))}
                    </Fragment>
                  ))}
                </InfiniteScroll>
              )}
            </FollowersWrapper>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default FollowingFoundry;
