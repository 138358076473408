export const displayModeOptions: Options = [
  { value: 'typeface name', label: 'list-appearance.display-mode-labels.typeface-name' },
  { value: 'pangram', label: 'list-appearance.display-mode-labels.pangram' },
  // DISABLE FOR BETA
  // { value: 'font in use', label: 'list-appearance.display-mode-labels.font-in-use' },
  { value: 'poster', label: 'list-appearance.display-mode-labels.poster' },
  { value: 'colored poster', label: 'list-appearance.display-mode-labels.colored-poster' },
];

export const weightIcons = [
  { value: 0, label: 'ALL', icon: 'all' },
  { value: 100, label: 'THIN', icon: 'weight_100' },
  { value: 200, label: 'EXTRA LIGHT', icon: 'weight_200' },
  { value: 300, label: 'LIGHT', icon: 'weight_300' },
  { value: 400, label: 'REGULAR', icon: 'weight_400' },
  { value: 500, label: 'MEDIUM', icon: 'weight_500' },
  { value: 600, label: 'SEMI BOLD', icon: 'weight_600' },
  { value: 700, label: 'BOLD', icon: 'weight_700' },
  { value: 800, label: 'EXTRA BOLD', icon: 'weight_800' },
  { value: 900, label: 'BLACK', icon: 'weight_900' },
  { value: 1000, label: 'EXTRA BLACK', icon: 'weight_900' },
];

export const widthIcons = [
  { value: -50, label: 'ALL', icon: 'all' },
  { value: 0, label: 'COMPRESSED', icon: 'width_0' },
  { value: 50, label: 'CONDENSED', icon: 'width_50' },
  { value: 100, label: 'NORMAL', icon: 'width_100' },
  { value: 150, label: 'WIDE', icon: 'width_150' },
  { value: 200, label: 'EXTENDED', icon: 'width_200' },
];

export const contrastIcons = [
  { value: -25, label: 'ALL', icon: 'all' },
  { value: 0, label: 'NONE', icon: 'contrast_0' },
  { value: 25, label: 'LOW', icon: 'contrast_25' },
  { value: 50, label: 'MEDIUM', icon: 'contrast_50' },
  { value: 75, label: 'HIGH', icon: 'contrast_75' },
  { value: 100, label: 'EXTREME', icon: 'contrast_100' },
];

export const endingIcons = [
  { value: 1, label: 'ALL', icon: 'all' },
  { value: 2, label: 'SQUARE', icon: 'ending_square' },
  { value: 3, label: 'ROUNDED', icon: 'ending_rounded' },
];

export const priceRangeOptions: { [key: string]: Array<{ value: number; label: string }> } = {
  USD: [
    { value: 0, label: '0' },
    { value: 25, label: '$25' },
    { value: 50, label: '$50' },
    { value: 75, label: '$75' },
    { value: 100, label: '$100' },
    { value: 125, label: '$100+' },
  ],
  EUR: [
    { value: 0, label: '0' },
    { value: 25, label: '€25' },
    { value: 50, label: '€50' },
    { value: 75, label: '€75' },
    { value: 100, label: '€100' },
    { value: 125, label: '€100+' },
  ],
  GBP: [
    { value: 0, label: '0' },
    { value: 25, label: '£25' },
    { value: 50, label: '£50' },
    { value: 75, label: '£75' },
    { value: 100, label: '£100' },
    { value: 125, label: '£100+' },
  ],
  CHF: [
    { value: 0, label: '0' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 75, label: '75' },
    { value: 100, label: '100' },
    { value: 125, label: '100+' },
  ],
};

export const currencyIcons = [
  { value: 'USD', label: '$' },
  { value: 'EUR', label: '€' },
  { value: 'GBP', label: '£' },
  { value: 'CHF', label: 'CHF' },
];

export const singleFontAppearanceOptions = [
  { value: 'pangram', label: 'filters.pangram' },
  { value: 'alphabet', label: 'filters.alphabet' },
];

export const singleFontUpperLowerCaseOptions = [
  { value: 'uppercase', label: 'filters.uppercase' },
  { value: 'lowercase', label: 'filters.lowercase' },
];

export const singleFontItalicOptions = [{ value: 'italic', label: 'filters.italics', icon: 'italic' }];

export const singleFontCharacterSetOptions = [{ value: 'default', label: 'filters.characters-dropdown.default' }];

export const singleFontViewerOptions = [
  { value: 'pangram', label: 'the quick brown fox jumps over a lazy dog.' },
  { value: 'alphabet', label: 'abcdefghijklmnopqrstuvwxyz\n0123456789' },
];

export const familyCategoryOptions = [
  { value: 'category:serif:normal', label: 'Serif' },
  { value: 'category:serif:triangle', label: 'Triangle Serif' },
  { value: 'category:serif:squared', label: 'Slab Serif' },
  { value: 'category:sans:normal', label: 'Sans Serif' },
  { value: 'category:sans:hexagon', label: 'Hexagonal Sans' },
  { value: 'category:other', label: 'Other' },
];
