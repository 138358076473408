export const NINJA_GREEN = '#9FE5C8';
export const DANGER_RED = '#EC143B';
export const WARNING_YELLOW = '#FFCB00';

export const GREY_6 = '#121212';
export const GREY_5 = '#2C2C2E';
export const GREY_4 = '#3A3A3C';
export const GREY_3 = '#48484A';
export const GREY_2 = '#636366';
export const GREY_1 = '#8E8E93';
export const GREY_0 = '#D7D7DB';
export const LIGHT_GREY = '#B1B1B9';

export const BLACK = '#000000';

export const WHITE_ALMOST = '#FEFEFE';
export const WHITE = '#FFFFFF';

export const YELLOW = '#F9F59F';
export const ORANGE = '#FCC342';
export const RED = '#E6404E';
export const PINK = '#EB99B0';
export const SALMON = '#FDBB9F';
export const PURPLE = '#8E6DE2';
export const LAVENDER = '#DDCDFE';
export const GREEN_DARK = '#0F9491';
export const BLUE_DARK = '#356AB6';
export const BLUE_LIGHT = '#CADEEB';
export const BLUE_GREY = '#99B7CB';

export const OLD_NINJA_GREEN_LIGHT = '#00CA99';
