import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_DESKTOP_1200,
  BREAKPOINT_DESKTOP_1440,
  BREAKPOINT_TABLET,
} from 'common/data/Constants';

const BREAKPOINTS = {
  mobile: 1,
  tablet: BREAKPOINT_TABLET,
  desktop: BREAKPOINT_DESKTOP,
  desktop_1200: BREAKPOINT_DESKTOP_1200,
  desktop_1440: BREAKPOINT_DESKTOP_1440,
};

type Mq = keyof typeof BREAKPOINTS;
export const mq = Object.keys(BREAKPOINTS)
  .map((key) => [key, BREAKPOINTS[key as Mq]] as [Mq, number])
  .reduce((prev, [key, breakpoint]) => {
    prev[key] = `@media (min-width: ${breakpoint}px)`;
    return prev;
  }, {} as Record<Mq, string>);
