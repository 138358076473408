import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface SelectBoardProps {
  active: boolean;
  width?: string;
  darkMode?: boolean;
}

interface LabelProps {
  active: boolean;
  darkMode?: boolean;
}

interface IconBookmarkProps {
  show: boolean;
}

const BREAKPOINT_TABLET = 744;

export const SelectBoardStyled = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${(props: SelectBoardProps) => props.width ?? '100%'};
  border-radius: 8px;
  border: 1px solid ${(props: SelectBoardProps) => (props.darkMode ? GREY_2 : GREY_6)};
  cursor: pointer;
  /* MOBILE */
  height: 44px;
  padding: 7px 10px 8px 14px;
  /* TABLET */
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    height: 36px;
    padding: 3px 10px 4px 14px;
  }
  /* NOT ACTIVE */
  ${(props: SelectBoardProps) =>
    !props.active &&
    css`
      background-color: ${props.darkMode ? GREY_6 : WHITE};
      .bookmark-status {
        display: none;
      }
    `}
  /* ACTIVE */
  ${(props: SelectBoardProps) =>
    props.active &&
    css`
      background-color: ${props.darkMode ? WHITE : GREY_6};
      border-color: ${props.darkMode ? WHITE : GREY_6};
      .bookmark-status {
        display: inline;
      }
    `}
  [class^='icon-'], [class*=' icon-'] {
    margin-left: 8px;
    vertical-align: top;
  }
`;

export const Label = styled.p`
  width: 100%;
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
  /* MOBILE */
  font-size: 16px;
  /* TABLET */
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    font-size: 14px;
  }
  /* NOT ACTIVE */
  ${(props: LabelProps) =>
    !props.active &&
    css`
      color: ${props.darkMode ? WHITE : GREY_6};
    `}
  /* ACTIVE */
  ${(props: LabelProps) =>
    props.active &&
    css`
      color: ${props.darkMode ? GREY_6 : WHITE};
    `}
`;

export const IconBookmark = styled.span`
  display: ${(props: IconBookmarkProps) => (props.show ? 'inline' : 'none')};
  height: 16px;
`;
