import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface Props {
  darkMode: boolean;
}

const NavProfileDesktopArrow = ({ darkMode }: Props) => {
  return (
    <svg
      width="24"
      height="6"
      viewBox="0 0 24 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="background"
        d="M0 6V4.5L6 4L10 1H13.5L18 4H24V6H0Z"
        fill={darkMode ? GREY_6 : WHITE}
      />
      <path
        className="border"
        d="M0 4.5H4C5.29822 4.5 6.56142 4.07893 7.6 3.3L10.2 1.35C11.2667 0.55 12.7333 0.55 13.8 1.35L16.4 3.3C17.4386 4.07893 18.7018 4.5 20 4.5H24"
        stroke={darkMode ? GREY_2 : GREY_6}
      />
    </svg>
  );
};

export default NavProfileDesktopArrow;
