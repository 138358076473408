import styled from '@emotion/styled';
import { DANGER_RED } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 5px;
  }
`;

// STEP 1
export const Step1Wrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 32px 16px;
  /* TABLET */
  ${mq['tablet']} {
    width: 352px;
    padding: 32px 40px;
  }
`;

export const Step1TitleWrapper = styled.div`
  /* MOBILE */
  margin-bottom: 16px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 24px;
  }
`;

export const Step1ContentWrapper = styled.div`
  /* MOBILE */
  margin-bottom: 24px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 16px;
  }
`;

export const Step1Description = styled.p`
  /* MOBILE */
  font-size: 16px;
  line-height: 16px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 14px;
  }
`;

export const Step1Error = styled.p`
  color: ${DANGER_RED};
  /* MOBILE */
  font-size: 16px;
  line-height: 16px;
  margin-top: 8px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 14px;
    margin-bottom: 16px;
  }
`;

export const InputFile = styled.input`
  display: none;
`;

// STEP 2
export const Step2Wrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 32px;
  /* TABLET */
  ${mq['tablet']} {
    width: 407px;
    padding: 32px 40px;
  }
`;

export const Step2ContentWrapper = styled.div`
  /* TABLET */
  ${mq['tablet']} {
    display: flex;
    align-items: center;
  }
`;

export const Step2AvatarWrapper = styled.div`
  /* MOBILE */
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 0;
    margin-right: 17px;
  }
`;

export const Step2InfosWrapper = styled.div``;

export const Step2TitleWrapper = styled.div`
  /* MOBILE */
  margin-bottom: 16px;
  p {
    text-align: center;
  }
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 8px;
    p {
      text-align: left;
      padding-left: 4px;
    }
  }
`;

export const Step2ButtonWrapper = styled.div`
  display: flex;
  /* MOBILE */
  justify-content: center;
  /* TABLET */
  ${mq['tablet']} {
    justify-content: flex-start;
  }
`;

export const Step2Error = styled.p`
  color: ${DANGER_RED};
  /* MOBILE */
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  margin-top: 16px;
  /* TABLET */
  ${mq['tablet']} {
    text-align: left;
    font-size: 14px;
    margin-top: 8px;
    padding-left: 4px;
  }
`;
