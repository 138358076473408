import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/auth`;

export const signUp = (
  email: string,
  password: string,
  idWebMachine?: string
): Promise<AxiosResponse<API.Auth.Tokens>> => {
  return axios.post(`${baseUrl}/signup`, { email, password, idWebMachine });
};

export const signIn = (
  email: string,
  password: string,
  idWebMachine?: string
): Promise<AxiosResponse<API.Auth.Tokens>> => {
  return axios.post(`${baseUrl}/signin`, { email, password, idWebMachine });
};

export const googleAuth = (googleCode: string, idWebMachine?: string): Promise<AxiosResponse<API.Auth.Tokens>> => {
  return axios.put(`${baseUrl}/google`, { googleCode, idWebMachine });
};

export const checkToken = (accessToken: string): Promise<AxiosResponse<API.Auth.CheckToken>> => {
  return axios.get(`${baseUrl}/check`, { headers: { Authorization: `Bearer ${accessToken}` } });
};

export const refreshToken = (refreshToken: string): Promise<AxiosResponse<API.Auth.Tokens>> => {
  return axios.post(`${baseUrl}/refresh`, { refreshToken });
};

export const initRecoveryPassword = (email: string): Promise<AxiosResponse<API.Auth.InitRecoveryPassword>> => {
  return axios.post(`${baseUrl}/recovery/init`, { email });
};

export const checkRecoveryPassword = (
  email: string,
  code: string
): Promise<AxiosResponse<API.Auth.CheckRecoveryPassword>> => {
  return axios.post(`${baseUrl}/recovery/check`, { email, code });
};

export const updateRecoveryPassword = (
  email: string,
  code: string,
  password: string
): Promise<AxiosResponse<API.Auth.Tokens>> => {
  return axios.patch(`${baseUrl}/recovery/password`, { email, code, password });
};

export const updatePassword = (password: string): Promise<API.Auth.Tokens> => {
  return axios.patch(`${baseUrl}/password`, { password });
};
