import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import api from 'api';
import { QUERY_KEYS } from 'common/data/Constants';

interface Options {
  enabled: boolean;
}

interface AddNewBoardProps {
  name: string;
  isPrivate: boolean;
}

interface EditBoardProps {
  idBoard: number;
  name: string;
  isPrivate: boolean;
}

interface UpdateFontWeightBookmarkInBoardProps {
  idBoard: number;
  idFamily: string;
  idFont: number;
}

export const useBoard = () => {
  // COUNT PERSONAL BOARDS
  const useBoardCount = (options: Options = { enabled: true }) => {
    return useQuery([QUERY_KEYS.BOARD_COUNT], () => api.board.boardCount(), options);
  };

  // GET SIMPLE LIST PERSONAL BOARDS
  const useBoardListSimple = (options: Options = { enabled: true }) => {
    return useQuery([QUERY_KEYS.BOARD_LIST_SIMPLE], () => api.board.boardListSimple(), options);
  };

  // GET DETAILED LIST PERSONAL BOARDS
  const useBoardListDetailed = (options: Options = { enabled: true }) => {
    return useQuery([QUERY_KEYS.BOARD_LIST_DETAILED], () => api.board.boardListDetailed(), options);
  };

  // CREATE NEW BOARD
  const useAddNewBoard = (options?: {
    onSuccess?: (data: API.Board.BoardSimple) => void;
    onError?: (err: AxiosError<API.Error>) => void;
  }) => {
    return useMutation(
      (variables: AddNewBoardProps) => api.board.addNewBoard(variables.name, variables.isPrivate),
      options
    );
  };

  // GET INFOS BOARD
  const useGetBoard = (idBoard: number, options: Options) => {
    return useQuery([QUERY_KEYS.BOARD, { id: idBoard }], () => api.board.getBoard(idBoard), options);
  };

  // EDIT BOARD
  const useEditBoard = (options?: {
    onMutate?: (variables: EditBoardProps) => void;
    onSuccess?: (data: { board: API.Board.BoardSimple }) => void;
    onError?: (err: AxiosError<API.Error>, variable: EditBoardProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: EditBoardProps) => api.board.editBoard(variables.idBoard, variables.name, variables.isPrivate),
      options
    );
  };

  // DELETE BOARD
  const useDeleteBoard = (options?: {
    onSuccess?: (data: API.Board.DeleteBoard) => void;
    onError?: (err: AxiosError<API.Error>) => void;
  }) => {
    return useMutation((variables: { idBoard: number }) => api.board.deleteBoard(variables.idBoard), options);
  };

  // COUNT PUBLIC BOARDS OF A SPECIFIC USER
  const useCountPublicBoards = (idUser: number) => {
    return useQuery([QUERY_KEYS.PUBLIC_COUNT_USER_BOARDS, { idUser }], () => api.board.countPublicBoards(idUser));
  };

  // GET DETAILED LIST PUBLIC BOARDS OF A SPECIFIC USER
  const usePublicBoards = (idUser: number, options: Options = { enabled: true }) => {
    return useQuery([QUERY_KEYS.PUBLIC_USER_BOARDS, { idUser }], () => api.board.getPublicBoards(idUser), options);
  };

  // UPDATE CURRENT FONT WEIGHT ON A BOOKMARK IN A BOARD
  const useUpdateCurrentFontWeightBookmarkInBoard = (options?: {
    onMutate?: (variables: UpdateFontWeightBookmarkInBoardProps) => void;
    onSuccess?: (data: API.Board.UpdateFontWeightBookmarkInBoard) => void;
    onError?: (err: AxiosError<API.Error>, variable: UpdateFontWeightBookmarkInBoardProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: UpdateFontWeightBookmarkInBoardProps) =>
        api.board.updateCurrentFontWeightBookmarkInBoard(variables.idBoard, variables.idFamily, variables.idFont),
      options
    );
  };

  return {
    useBoardCount,
    useBoardListSimple,
    useBoardListDetailed,
    useAddNewBoard,
    useGetBoard,
    useEditBoard,
    useDeleteBoard,
    useCountPublicBoards,
    usePublicBoards,
    useUpdateCurrentFontWeightBookmarkInBoard,
  };
};
