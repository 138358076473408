import { useCallback, useMemo, useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { H3 } from 'design-system/atoms/h3';
import { IconButton } from 'design-system/atoms/icon-button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useModalContext, useSettingsContext } from 'context';
import Modal from 'components/Modal';
import {
  CloseButtonWrapper,
  ContentWrapper,
  Description,
  ImageWrapper,
  ModalWrapper,
  TitleWrapper,
} from './ContributeToFontsNinjaSuccess.styled';

const ContributeToFontsNinjaSuccess = () => {
  const { t } = useTranslation('common');
  const { darkMode } = useSettingsContext();
  const { openModalContributeToFontsNinjaSuccess, setOpenModalContributeToFontsNinjaSuccess } = useModalContext();

  const [startClose, setStartClose] = useState<boolean>(false);

  const themeMode = useMemo(() => {
    return darkMode ? 'dark' : 'light';
  }, [darkMode]);

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalContributeToFontsNinjaSuccess(null);
  }, [setOpenModalContributeToFontsNinjaSuccess]);

  const getGoodTitle = useCallback(() => {
    if (openModalContributeToFontsNinjaSuccess === 'list_typefaces') {
      return t('contribute-to-fonts-ninja.option-1.success.title');
    } else if (openModalContributeToFontsNinjaSuccess === 'correct_information') {
      return t('contribute-to-fonts-ninja.option-2.success.title');
    } else if (openModalContributeToFontsNinjaSuccess === 'contact_us') {
      return t('contribute-to-fonts-ninja.option-3.success.title');
    }
    return '';
  }, [openModalContributeToFontsNinjaSuccess, t]);

  const getGoodDescription = useCallback(() => {
    if (openModalContributeToFontsNinjaSuccess === 'list_typefaces') {
      return t('contribute-to-fonts-ninja.option-1.success.description');
    } else if (openModalContributeToFontsNinjaSuccess === 'correct_information') {
      return t('contribute-to-fonts-ninja.option-2.success.description');
    } else if (openModalContributeToFontsNinjaSuccess === 'contact_us') {
      return t('contribute-to-fonts-ninja.option-3.success.description');
    }
  }, [openModalContributeToFontsNinjaSuccess, t]);

  if (openModalContributeToFontsNinjaSuccess) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            <ImageWrapper>
              <Image
                src={`/images/contribute-to-fonts-ninja/${themeMode}/fonts-ninja.png`}
                alt={getGoodTitle()}
                fill={true}
              />
            </ImageWrapper>
            <TitleWrapper>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {getGoodTitle()}
              </H3>
            </TitleWrapper>
            <Description>{getGoodDescription()}</Description>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default ContributeToFontsNinjaSuccess;
