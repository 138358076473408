import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useAuthContext, useModalContext, useSettingsContext } from 'context';
import { useBoard } from 'hooks/queries/useBoard';
import Modal from 'components/Modal';
import { transformStringToUrl } from 'common/utils';
import { QUERY_KEYS } from 'common/data/Constants';
import {
  ButtonDeleteWrapper,
  ButtonsWrapper,
  CloseButtonWrapper,
  ContentWrapper,
  Description,
  GlobalError,
  ModalWrapper,
  TitleWrapper,
} from './DeleteBoard.styled';

const DeleteBoard = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { darkMode } = useSettingsContext();
  const { userId, userName } = useAuthContext();
  const { openModalDeleteBoard, setOpenModalDeleteBoard, setOpenModalEditBoard } = useModalContext();

  const [startClose, setStartClose] = useState<boolean>(false);
  const [buttonDeleteDisabled, setButtonDeleteDisabled] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { useDeleteBoard } = useBoard();

  const deleteBoardMutation = useDeleteBoard({
    onSuccess: () => {
      setButtonDeleteDisabled(false);
      handleClose();
      router.push(`/${transformStringToUrl(userName ?? '')}-${userId}`);
    },
    onError: () => {
      setButtonDeleteDisabled(false);
      setGlobalError(true);
    },
  });

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalDeleteBoard(null);
  }, [setOpenModalDeleteBoard]);

  const handleCancel = useCallback(() => {
    setStartClose(true);
    setOpenModalEditBoard({
      idBoard: openModalDeleteBoard!.idBoard,
      name: openModalDeleteBoard!.name,
      isPrivate: openModalDeleteBoard!.isPrivate,
    });
  }, [openModalDeleteBoard, setOpenModalEditBoard]);

  const handleDeleteBoard = useCallback(() => {
    if (openModalDeleteBoard) {
      setButtonDeleteDisabled(true);
      deleteBoardMutation.mutate({ idBoard: openModalDeleteBoard.idBoard });
    }
  }, [deleteBoardMutation, openModalDeleteBoard]);

  const handleRouteCompleteChange = useCallback(() => {
    // Remove board page queries
    queryClient.removeQueries({ queryKey: [QUERY_KEYS.BOARD_COUNT] });
    queryClient.removeQueries({ queryKey: [QUERY_KEYS.BOARD_LIST_DETAILED] });
    queryClient.removeQueries({ queryKey: [QUERY_KEYS.BOARD_LIST_SIMPLE] });
    queryClient.removeQueries({ queryKey: [QUERY_KEYS.FAMILY_SEARCH] });
  }, [queryClient]);

  // Listen route start change
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteCompleteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteCompleteChange);
    };
  }, [handleRouteCompleteChange, router.events]);

  useEffect(() => {
    if (!openModalDeleteBoard) {
      setButtonDeleteDisabled(false);
      setGlobalError(false);
    }
  }, [openModalDeleteBoard]);

  if (openModalDeleteBoard) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            <TitleWrapper>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('delete-board.title')}
              </H3>
            </TitleWrapper>
            <Description dangerouslySetInnerHTML={{ __html: t('delete-board.description') }} />
            <ButtonsWrapper>
              <Button
                type="button"
                render="contrast"
                darkMode={darkMode}
                onClick={handleCancel}
              >
                {t('delete-board.cancel')}
              </Button>
              <ButtonDeleteWrapper darkMode={darkMode}>
                <Button
                  type="button"
                  darkMode={darkMode}
                  disabled={buttonDeleteDisabled}
                  onClick={handleDeleteBoard}
                >
                  {t('delete-board.delete-board')}
                </Button>
              </ButtonDeleteWrapper>
            </ButtonsWrapper>
            {globalError && <GlobalError>{t('errors.generic')}</GlobalError>}
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default DeleteBoard;
