import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface ToggleButtonWrapperProps {
  darkMode?: boolean;
}

interface ToggleButtonProps {
  isSelected: boolean;
  darkMode?: boolean;
}

export const ToggleButtonWrapperStyled = styled.div`
  display: inline-flex;
  flex-direction: row;
  height: 36px;
  background-color: ${(props: ToggleButtonWrapperProps) => (props.darkMode ? GREY_6 : WHITE)};
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
`;

export const ToggleButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-top: 1px solid ${(props: ToggleButtonProps) => (props.darkMode ? GREY_2 : GREY_6)};
  border-bottom: 1px solid ${(props: ToggleButtonProps) => (props.darkMode ? GREY_2 : GREY_6)};
  border-right: 1px solid ${(props: ToggleButtonProps) => (props.darkMode ? GREY_2 : GREY_6)};
  background-color: ${(props: ToggleButtonProps) => (props.darkMode ? GREY_6 : WHITE)};
  color: ${(props: ToggleButtonProps) => (props.darkMode ? WHITE : GREY_6)};
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 16px;
  cursor: pointer;
  &:first-of-type {
    border-left: 1px solid ${(props: ToggleButtonProps) => (props.darkMode ? GREY_2 : GREY_6)};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-of-type {
    border-right: 1px solid ${(props: ToggleButtonProps) => (props.darkMode ? GREY_2 : GREY_6)};
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  [class^='icon-'],
  [class*=' icon-'] {
    color: ${(props: ToggleButtonProps) => (props.darkMode ? WHITE : GREY_6)};
  }
  /* IS SELECTED */
  ${(props: ToggleButtonProps) =>
    props.isSelected &&
    css`
      background-color: ${props.darkMode ? WHITE : GREY_6};
      border-top: 1px solid ${props.darkMode ? WHITE : GREY_6};
      border-bottom: 1px solid ${props.darkMode ? WHITE : GREY_6};
      border-right: 1px solid ${props.darkMode ? WHITE : GREY_6};
      color: ${props.darkMode ? GREY_6 : WHITE};
      cursor: default;
      &:first-of-type {
        border-left: 1px solid ${props.darkMode ? WHITE : GREY_6};
      }
      &:last-of-type {
        border-right: 1px solid ${props.darkMode ? WHITE : GREY_6};
      }
      [class^='icon-'],
      [class*=' icon-'] {
        color: ${props.darkMode ? GREY_6 : WHITE};
      }
      /* IF ONLY ONE ELEMENT, CURSOR IS ALWAYS POINTER */
      &:only-of-type {
        cursor: pointer;
      }
    `}
`;
