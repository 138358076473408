import { HOSTNAME_URL } from 'common/data/Hostname';

export const getHostnameUrl = (): string => {
  return HOSTNAME_URL;
};

export const getCanonicalUrl = (asPath: string): string => {
  const cleanPath = asPath.split('#')[0].split('?')[0];
  const canonicalUrl = `${HOSTNAME_URL}` + (asPath === '/' ? '' : cleanPath);
  return canonicalUrl;
};
