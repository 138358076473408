import { PropsWithChildren } from 'react';
import { Router } from 'next/router';
import { useAuthContext } from 'context';
import { ROUTES } from 'common/data/Constants';

interface Props extends PropsWithChildren<Record<string, unknown>> {
  router: Router;
}

//check if you are on the client (browser) or server
const isBrowser = () => typeof window !== 'undefined';

const ProtectedRoute = ({ router, children }: Props): JSX.Element => {
  const { authReady, isLoggedIn } = useAuthContext();

  const protectedRoutes = [ROUTES.PROFILE];

  const protectedPath = protectedRoutes.indexOf(router.pathname) !== -1;

  if (isBrowser() && authReady && !isLoggedIn && protectedPath) {
    router.push(ROUTES.HOME);
  }

  return children as JSX.Element;
};

export default ProtectedRoute;
