import { css } from '@emotion/react';
// @ts-ignore
import IconFont from './assets/fonts/FontsNinja-Icons.woff';

export const InjectIconFontsStyled = css`
  @font-face {
    font-family: 'FontsNinja-Icons';
    src: url(${IconFont}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'FontsNinja-Icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-font_options:before {
    content: '\\e932';
  }
  .icon-plus_border:before {
    content: '\\e95e';
  }
  .icon-copy:before {
    content: '\\e95d';
  }
  .icon-book:before {
    content: '\\e956';
  }
  .icon-other:before {
    content: '\\e957';
  }
  .icon-podcast:before {
    content: '\\e958';
  }
  .icon-smiley_sad:before {
    content: '\\e959';
  }
  .icon-video:before {
    content: '\\e95a';
  }
  .icon-article:before {
    content: '\\e95b';
  }
  .icon-quote:before {
    content: '\\e95c';
  }
  .icon-email:before {
    content: '\\e954';
  }
  .icon-lock:before {
    content: '\\e955';
  }
  .icon-tools:before {
    content: '\\e953';
  }
  .icon-purchase:before {
    content: '\\e925';
  }
  .icon-italic:before {
    content: '\\e952';
  }
  .icon-all:before {
    content: '\\e94a';
  }
  .icon-ending_rounded:before {
    content: '\\e94b';
  }
  .icon-ending_square:before {
    content: '\\e94c';
  }
  .icon-aspect_0:before {
    content: '\\e94d';
  }
  .icon-aspect_1:before {
    content: '\\e94e';
  }
  .icon-aspect_2:before {
    content: '\\e94f';
  }
  .icon-aspect_3:before {
    content: '\\e950';
  }
  .icon-aspect_4:before {
    content: '\\e951';
  }
  .icon-weight_100:before {
    content: '\\e937';
  }
  .icon-weight_200:before {
    content: '\\e938';
  }
  .icon-weight_300:before {
    content: '\\e939';
  }
  .icon-weight_400:before {
    content: '\\e93a';
  }
  .icon-weight_500:before {
    content: '\\e93b';
  }
  .icon-weight_600:before {
    content: '\\e93c';
  }
  .icon-weight_700:before {
    content: '\\e93d';
  }
  .icon-weight_800:before {
    content: '\\e93e';
  }
  .icon-weight_900:before {
    content: '\\e93f';
  }
  .icon-width_0:before {
    content: '\\e940';
  }
  .icon-width_50:before {
    content: '\\e941';
  }
  .icon-width_100:before {
    content: '\\e942';
  }
  .icon-width_150:before {
    content: '\\e943';
  }
  .icon-width_200:before {
    content: '\\e944';
  }
  .icon-contrast_0:before {
    content: '\\e945';
  }
  .icon-contrast_25:before {
    content: '\\e946';
  }
  .icon-contrast_50:before {
    content: '\\e947';
  }
  .icon-contrast_75:before {
    content: '\\e948';
  }
  .icon-contrast_100:before {
    content: '\\e949';
  }
  .icon-top_small:before {
    content: '\\e934';
  }
  .icon-plus_small:before {
    content: '\\e936';
  }
  .icon-list_appearance:before {
    content: '\\e930';
  }
  .icon-price_range:before {
    content: '\\e931';
  }
  .icon-search:before {
    content: '\\e933';
  }
  .icon-logout:before {
    content: '\\e92f';
  }
  .icon-profile:before {
    content: '\\e92e';
  }
  .icon-notif_filled:before {
    content: '\\e900';
  }
  .icon-font_folder:before {
    content: '\\e92c';
  }
  .icon-world:before {
    content: '\\e92d';
  }
  .icon-bottom:before {
    content: '\\e901';
  }
  .icon-settings:before {
    content: '\\e905';
  }
  .icon-bookmark_folder:before {
    content: '\\e906';
  }
  .icon-font_size:before {
    content: '\\e907';
  }
  .icon-letter_spacing:before {
    content: '\\e908';
  }
  .icon-line_height:before {
    content: '\\e909';
  }
  .icon-left:before {
    content: '\\e90a';
  }
  .icon-right:before {
    content: '\\e90b';
  }
  .icon-link:before {
    content: '\\e90c';
  }
  .icon-back:before {
    content: '\\e90d';
  }
  .icon-warning:before {
    content: '\\e90e';
  }
  .icon-edit:before {
    content: '\\e90f';
  }
  .icon-font:before {
    content: '\\e910';
  }
  .icon-less:before {
    content: '\\e911';
  }
  .icon-plus:before {
    content: '\\e912';
  }
  .icon-play:before {
    content: '\\e913';
  }
  .icon-notif_false:before {
    content: '\\e914';
  }
  .icon-notif_true:before {
    content: '\\e915';
  }
  .icon-info:before {
    content: '\\e916';
  }
  .icon-google:before {
    content: '\\e917';
  }
  .icon-instagram:before {
    content: '\\e918';
  }
  .icon-adress:before {
    content: '\\e919';
  }
  .icon-close_big:before {
    content: '\\e91a';
  }
  .icon-close:before {
    content: '\\e91b';
  }
  .icon-reset_small:before {
    content: '\\e91c';
  }
  .icon-board_multi:before {
    content: '\\e91d';
  }
  .icon-reset:before {
    content: '\\e91e';
  }
  .icon-link_mini:before {
    content: '\\e91f';
  }
  .icon-more:before {
    content: '\\e920';
  }
  .icon-new_board:before {
    content: '\\e921';
  }
  .icon-not_visible:before {
    content: '\\e922';
  }
  .icon-visible:before {
    content: '\\e923';
  }
  .icon-download:before {
    content: '\\e924';
  }
  .icon-trial:before {
    content: '\\e926';
  }
  .icon-app:before {
    content: '\\e927';
  }
  .icon-desktop:before {
    content: '\\e928';
  }
  .icon-web:before {
    content: '\\e929';
  }
  .icon-not_bookmarked:before {
    content: '\\e902';
  }
  .icon-bookmarked:before {
    content: '\\e903';
  }
  .icon-add_bookmark:before {
    content: '\\e904';
  }
  .icon-confirm_bookmark:before {
    content: '\\e92a';
  }
  .icon-remove_bookmark:before {
    content: '\\e92b';
  }
  .icon-top:before {
    content: '\\e935';
  }
`;
