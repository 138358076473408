import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';
import { easeInOutQuart } from 'common/easing/Easing';
import { mq } from 'styles/mediaqueries.styled';

interface ModalWrapperProps {
  modalWrapperHeight?: string;
}

interface TitleWrapperProps {
  displaySeparator: boolean;
}

interface SeparatorProps {
  displaySeparator: boolean;
  darkMode: boolean;
}

interface FollowersWrapperProps {
  displaySeparator: boolean;
}

export const ModalWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin: -8px -8px;
  padding: 0 8px;
  transition: height 0.3s ${easeInOutQuart};
  /* MOBILE */
  height: ${(props: ModalWrapperProps) => (props.modalWrapperHeight ? 'auto' : '151px')};
  /* TABLET */
  ${mq['tablet']} {
    height: ${(props: ModalWrapperProps) => (props.modalWrapperHeight ? 'auto' : '167px')};
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: 4px;
  right: 4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 13px;
    right: 13px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 32px 16px 0;
  /* TABLET */
  ${mq['tablet']} {
    width: 361px;
    padding: 40px 32px 0;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 32px;
  ${(props: TitleWrapperProps) =>
    props.displaySeparator &&
    css`
      margin-top: -8px;
      /* MOBILE */
      margin-bottom: 20px;
      /* TABLET */
      ${mq['tablet']} {
        margin-bottom: 28px;
      }
    `}
`;

export const Separator = styled.div`
  display: ${(props: SeparatorProps) => (props.displaySeparator ? 'block' : 'none')};
  margin: 0 -40px;
  height: 1px;
  background-color: ${(props: SeparatorProps) => (props.darkMode ? GREY_2 : GREY_6)};
  /* MOBILE */
  margin-bottom: 24px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 32px;
  }
`;

export const FollowersLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
`;

export const FollowersWrapper = styled.div`
  overflow-y: auto;
  min-height: 26px;
  /* MOBILE */
  max-height: 394px;
  margin: 0 -16px;
  padding: 0 16px 32px;
  ${(props: FollowersWrapperProps) =>
    props.displaySeparator &&
    css`
      margin: -24px -16px 0;
      padding: 20px 16px 24px;
    `}
  /* TABLET */
  ${mq['tablet']} {
    max-height: 496px;
    margin: 0 -32px;
    padding: 0 32px 40px;
    ${(props: FollowersWrapperProps) =>
      props.displaySeparator &&
      css`
        margin: -32px -32px 0;
        padding: 28px 32px 32px;
      `}
  }
`;

export const FollowerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FollowerItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  &:first-of-type {
    margin-top: 4px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const FollowerAvatarName = styled.div`
  position: relative;
  display: flex;
`;

export const FollowerName = styled.p`
  margin-left: 8px;
  font-size: 16px;
  line-height: 22px;
  &:hover {
    text-decoration: underline;
  }
`;

export const FollowButtonWrapper = styled.div`
  position: relative;
`;
