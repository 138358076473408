import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';
import { easeOutQuart } from 'common/easing/Easing';
import { DESKTOP_CARD_WIDTH, MOBILE_CARD_WIDTH, TABLET_CARD_WIDTH } from 'common/data/Constants';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  displayMode?: string;
  darkMode?: boolean;
  backgroundColor?: string;
  scaleValue?: number;
  isMobile?: boolean;
  isLoadingFonts?: boolean;
  block1Width?: number;
  block1Height?: number;
  block2Width?: number;
  block2Height?: number;
  block3Width?: number;
  block3Height?: number;
  block4Width?: number;
  block4Height?: number;
}

const getBookmarkButtonWrapperPadding = (scaleValue: number, isMobile: boolean) => {
  if (isMobile) {
    return `0 ${Math.round(16 * scaleValue)}px 16px`;
  } else {
    return `0 ${Math.round(32 * scaleValue)}px 24px`;
  }
};

export const MOBILE_CARD_HEIGHT = 290;
export const TABLET_CARD_HEIGHT = 504;
export const DESKTOP_CARD_HEIGHT = 504;

export const Card = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    .card-footer {
      opacity: 0;
    }
    .bookmark-background {
      opacity: 1;
    }
    .bookmark-button-wrapper {
      transform: translateY(0);
    }
  }
`;

export const CardContainer = styled.div`
  transform-origin: top left;
  transform: scale(1);
  ${(props: Props) =>
    props.scaleValue &&
    css`
      /* MOBILE */
      transform: scale(${props.scaleValue});
      height: calc(${MOBILE_CARD_HEIGHT}px * ${props.scaleValue});
      /* TABLET */
      ${mq['tablet']} {
        width: calc(${TABLET_CARD_WIDTH}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        height: calc(${TABLET_CARD_HEIGHT}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        transform: scale(${props.scaleValue > 1 ? 1 : props.scaleValue});
      }
      /* DESKTOP */
      ${mq['desktop']} {
        width: calc(${DESKTOP_CARD_WIDTH}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        height: calc(${DESKTOP_CARD_HEIGHT}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        transform: scale(${props.scaleValue > 1 ? 1 : props.scaleValue});
      }
    `}
`;

export const CardLoader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* MOBILE */
  width: ${(props: Props) => `calc(100% / ${props.scaleValue && props.scaleValue > 1 ? props.scaleValue : 1})`};
  height: ${MOBILE_CARD_HEIGHT}px;
  /* TABLET */
  ${mq['tablet']} {
    width: ${TABLET_CARD_WIDTH}px;
    height: ${TABLET_CARD_HEIGHT}px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    width: ${DESKTOP_CARD_WIDTH}px;
    height: ${DESKTOP_CARD_HEIGHT}px;
  }
  z-index: 1;
`;

export const CardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  border-radius: 8px;
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  // NO BACKGROUND COLOR
  ${(props: Props) =>
    props.displayMode === 'poster' &&
    css`
      background-color: ${props.darkMode ? GREY_6 : WHITE};
    `}
  // BACKGROUND COLOR
  ${(props: Props) =>
    props.displayMode === 'colored poster' &&
    css`
      background-color: ${props.backgroundColor};
    `}
  /* MOBILE */
  width: ${MOBILE_CARD_WIDTH}px;
  height: ${MOBILE_CARD_HEIGHT}px;
  padding: 16px 16px 0;
  /* TABLET */
  ${mq['tablet']} {
    min-width: ${TABLET_CARD_WIDTH}px;
    width: auto;
    height: ${TABLET_CARD_HEIGHT}px;
    padding: 32px 32px 0;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    min-width: ${DESKTOP_CARD_WIDTH}px;
    width: auto;
    height: ${DESKTOP_CARD_HEIGHT}px;
    padding: 32px 32px 0;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  opacity: ${(props: Props) => (props.isLoadingFonts ? 0 : 1)};

  .renderIfVisible {
    height: 100%;
  }
`;

export const ContentRenderedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
`;

export const ImagesWrapper = styled.div``;

export const Block1ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  margin-bottom: 10px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 16px;
  }
`;

export const Block1Wrapper = styled.div`
  position: relative;
  user-select: none;
  /* MOBILE */
  max-width: ${MOBILE_CARD_WIDTH - 32}px;
  max-height: 242px;
  ${(props: Props) =>
    props.block1Width &&
    props.block1Height &&
    css`
      width: ${(props.block1Width / props.block1Height) * 242}px;
      height: ${(props.block1Height / props.block1Width) * (MOBILE_CARD_WIDTH - 32)}px;
    `}
  /* TABLET */
  ${mq['tablet']} {
    max-width: ${TABLET_CARD_WIDTH - 64}px;
    max-height: 288px;
    ${(props: Props) =>
      props.block1Width &&
      props.block1Height &&
      css`
        width: ${(props.block1Width / props.block1Height) * 288}px;
        height: ${(props.block1Height / props.block1Width) * (TABLET_CARD_WIDTH - 64)}px;
      `}
  }
  /* DESKTOP */
  ${mq['desktop']} {
    max-width: ${DESKTOP_CARD_WIDTH - 64}px;
    max-height: 288px;
    ${(props: Props) =>
      props.block1Width &&
      props.block1Height &&
      css`
        width: ${(props.block1Width / props.block1Height) * 288}px;
        height: ${(props.block1Height / props.block1Width) * (DESKTOP_CARD_WIDTH - 64)}px;
      `}
  }
`;

export const Block2ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  margin-bottom: 10px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 16px;
  }
`;

export const Block2Wrapper = styled.div`
  position: relative;
  user-select: none;
  /* MOBILE */
  max-width: ${MOBILE_CARD_WIDTH - 32}px;
  max-height: 58px;
  ${(props: Props) =>
    props.block2Width &&
    props.block2Height &&
    css`
      width: ${(props.block2Width / props.block2Height) * 58}px;
      height: ${(props.block2Height / props.block2Width) * (MOBILE_CARD_WIDTH - 32)}px;
    `}
  /* TABLET */
  ${mq['tablet']} {
    max-width: ${TABLET_CARD_WIDTH - 64}px;
    max-height: 167px;
    ${(props: Props) =>
      props.block2Width &&
      props.block2Height &&
      css`
        width: ${(props.block2Width / props.block2Height) * 167}px;
        height: ${(props.block2Height / props.block2Width) * (TABLET_CARD_WIDTH - 64)}px;
      `}
  }
  /* DESKTOP */
  ${mq['desktop']} {
    max-width: ${DESKTOP_CARD_WIDTH - 64}px;
    max-height: 167px;
    ${(props: Props) =>
      props.block2Width &&
      props.block2Height &&
      css`
        width: ${(props.block2Width / props.block2Height) * 167}px;
        height: ${(props.block2Height / props.block2Width) * (DESKTOP_CARD_WIDTH - 64)}px;
      `}
  }
`;

export const Block3ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  display: none;
  /* TABLET */
  ${mq['tablet']} {
    display: block;
    margin-bottom: 16px;
  }
`;

export const Block3Wrapper = styled.div`
  position: relative;
  user-select: none;
  /* TABLET */
  ${mq['tablet']} {
    max-width: ${TABLET_CARD_WIDTH - 64}px;
    max-height: 50px;
    ${(props: Props) =>
      props.block3Width &&
      props.block3Height &&
      css`
        width: ${(props.block3Width / props.block3Height) * 50}px;
        height: ${(props.block3Height / props.block3Width) * (TABLET_CARD_WIDTH - 64)}px;
      `}
  }
  /* DESKTOP */
  ${mq['desktop']} {
    max-width: ${DESKTOP_CARD_WIDTH - 64}px;
    max-height: 50px;
    ${(props: Props) =>
      props.block3Width &&
      props.block3Height &&
      css`
        width: ${(props.block3Width / props.block3Height) * 50}px;
        height: ${(props.block3Height / props.block3Width) * (DESKTOP_CARD_WIDTH - 64)}px;
      `}
  }
`;

export const Block4ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  margin-bottom: 10px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 16px;
  }
`;

export const Block4Wrapper = styled.div`
  position: relative;
  user-select: none;
  /* MOBILE */
  max-width: ${MOBILE_CARD_WIDTH - 32}px;
  max-height: 40px;
  ${(props: Props) =>
    props.block4Width &&
    props.block4Height &&
    css`
      width: ${(props.block4Width / props.block4Height) * 40}px;
      height: ${(props.block4Height / props.block4Width) * (MOBILE_CARD_WIDTH - 32)}px;
    `}
  /* TABLET */
  ${mq['tablet']} {
    max-width: ${TABLET_CARD_WIDTH - 64}px;
    max-height: 62px;
    ${(props: Props) =>
      props.block4Width &&
      props.block4Height &&
      css`
        width: ${(props.block4Width / props.block4Height) * 62}px;
        height: ${(props.block4Height / props.block4Width) * (TABLET_CARD_WIDTH - 64)}px;
      `}
  }
  /* DESKTOP */
  ${mq['desktop']} {
    max-width: ${DESKTOP_CARD_WIDTH - 64}px;
    max-height: 62px;
    ${(props: Props) =>
      props.block4Width &&
      props.block4Height &&
      css`
        width: ${(props.block4Width / props.block4Height) * 62}px;
        height: ${(props.block4Height / props.block4Width) * (DESKTOP_CARD_WIDTH - 64)}px;
      `}
  }
`;

export const FontUnauthorizedWrapper = styled.div`
  text-align: center;
  /* MOBILE */
  margin: 42px auto 0;
  /* TABLET */
  ${mq['tablet']} {
    margin: 88px auto 0;
  }
`;

export const FontUnauthorizedText = styled.p`
  text-align: center;
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  /* MOBILE */
  font-size: 16px;
  line-height: 16px;
  width: 262px;
  margin-top: 11px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 20px;
    line-height: normal;
    width: 328px;
    margin-top: 24px;
  }
`;

export const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  opacity: ${(props: Props) => (props.isLoadingFonts ? 0 : 1)};
  transition: opacity 0.2s ${easeOutQuart};
  // NO BACKGROUND COLOR
  ${(props: Props) =>
    props.displayMode === 'poster' &&
    css`
      background-color: ${props.darkMode ? GREY_6 : WHITE};
    `}
  // BACKGROUND COLOR
  ${(props: Props) =>
    props.displayMode === 'colored poster' &&
    css`
      background-color: ${props.backgroundColor};
    `}
  /* MOBILE */
  padding: 0 8px 16px;
  margin-left: -8px;
  width: calc(100% + 16px);
  /* TABLET */
  ${mq['tablet']} {
    padding: 0 24px 24px;
    margin-left: -24px;
    width: calc(100% + 48px);
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 4px;
  background-color: ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};

  &.separator-designers {
    /* MOBILE */
    display: none;
    /* TABLET */
    ${mq['tablet']} {
      display: block;
    }
  }
`;

export const FontFoundryInfosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* MOBILE */
  margin-bottom: 8px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 16px;
  }
`;

export const FontInfos = styled.p`
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-size: 16px;
  width: 50%;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FoundryInfos = styled.p`
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-size: 16px;
  width: 50%;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DesignersInfosWrapper = styled.div`
  /* MOBILE */
  display: none;
  /* TABLET */
  ${mq['tablet']} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
`;

export const DesignersLabel = styled.p`
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-size: 16px;
  width: 50%;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DesignersInfos = styled.p`
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-size: 16px;
  width: 50%;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StylesPricingsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StylesInfos = styled.p`
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-size: 16px;
  width: 50%;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PricingInfos = styled.p`
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-size: 16px;
  width: 50%;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const HoverWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  /* MOBILE */
  width: ${(props: Props) => (props.scaleValue ? `calc(100% * ${props.scaleValue})` : '100%')};
  max-width: 100%;
  /* TABLET */
  ${mq['tablet']} {
    width: 100%;
    max-width: none;
  }
`;

export const BookmarkBackground = styled.div`
  position: absolute;
  width: calc(100% - 16px);
  bottom: 0;
  margin: 8px 8px 1px;
  opacity: 0;
  transition: opacity 0.2s ${easeOutQuart};
  // NO BACKGROUND COLOR
  ${(props: Props) =>
    props.displayMode === 'poster' &&
    css`
      background-color: ${props.darkMode ? GREY_6 : WHITE};
    `}
  // BACKGROUND COLOR
  ${(props: Props) =>
    props.displayMode === 'colored poster' &&
    css`
      background-color: ${props.backgroundColor};
    `}
  /* MOBILE */
  height: 52px;
  /* TABLET */
  ${mq['tablet']} {
    height: 52px;
  }
`;

export const BookmarkButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  pointer-events: auto;
  padding: ${(props: Props) => getBookmarkButtonWrapperPadding(props.scaleValue ?? 1, props.isMobile ?? false)};
  transform: translateY(70px);
  transition: transform 0.2s ${easeOutQuart};
`;
