import styled from '@emotion/styled';
import { GREY_6 } from 'design-system/global/colors';

const SvgWrapper = styled.svg`
  filter: drop-shadow(4px 4px 0px ${GREY_6});
`;

const Icon = () => {
  return (
    <SvgWrapper
      width="38"
      height="28"
      viewBox="0 0 38 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="37.8885"
        height="27.9973"
        rx="4.41709"
        fill="black"
      />
      <path
        d="M17.7402 17.7037C18.0591 18.0432 18.4912 18.2338 18.9416 18.2338C19.392 18.2338 19.8241 18.0432 20.1431 17.7037L33.3073 3.65269C33.3967 3.55765 33.4595 3.43792 33.4888 3.30692C33.518 3.17591 33.5125 3.03881 33.4728 2.91097C33.4332 2.78312 33.361 2.66958 33.2643 2.58306C33.1676 2.49654 33.0503 2.44046 32.9254 2.42109C32.7963 2.40078 32.6659 2.3906 32.5354 2.39063H5.35327C5.22182 2.39046 5.09053 2.40064 4.96048 2.42109C4.83563 2.44046 4.71829 2.49654 4.62161 2.58306C4.52493 2.66958 4.45272 2.78312 4.41308 2.91097C4.37344 3.03881 4.36792 3.17591 4.39715 3.30692C4.42638 3.43792 4.4892 3.55765 4.57858 3.65269L17.7402 17.7037Z"
        fill="white"
      />
      <path
        d="M35.1016 5.05225C35.0561 5.03166 35.006 5.02605 34.9575 5.03613C34.9091 5.04621 34.8646 5.07153 34.8297 5.10883L26.4033 14.1029C26.3397 14.1711 26.304 14.2634 26.304 14.3596C26.304 14.4559 26.3397 14.5482 26.4033 14.6164L33.2573 21.932C33.3547 22.0324 33.4324 22.1524 33.4858 22.2851C33.5393 22.4178 33.5675 22.5605 33.5687 22.705C33.57 22.8495 33.5443 22.9927 33.4931 23.1265C33.4419 23.2602 33.3662 23.3817 33.2706 23.4839C33.1749 23.5861 33.0611 23.667 32.9359 23.7217C32.8106 23.7765 32.6764 23.8041 32.5411 23.8029C32.4057 23.8017 32.272 23.7718 32.1476 23.7148C32.0232 23.6579 31.9107 23.575 31.8166 23.4712L24.9626 16.1555C24.9311 16.1218 24.8936 16.095 24.8523 16.0767C24.811 16.0584 24.7668 16.049 24.7221 16.049C24.6774 16.049 24.6331 16.0584 24.5919 16.0767C24.5506 16.095 24.5131 16.1218 24.4815 16.1555L21.588 19.244C20.8871 19.9916 19.9368 20.4116 18.9459 20.4116C17.955 20.4116 17.0046 19.9916 16.3038 19.244L13.4075 16.1584C13.3436 16.0906 13.2571 16.0525 13.167 16.0525C13.0768 16.0525 12.9903 16.0906 12.9264 16.1584L6.07243 23.4741C5.8801 23.6722 5.62256 23.7817 5.3553 23.7791C5.08803 23.7765 4.83242 23.6619 4.64352 23.4601C4.45461 23.2583 4.34753 22.9854 4.34534 22.7001C4.34314 22.4148 4.44601 22.14 4.63178 21.9349L11.4857 14.6193C11.5493 14.5511 11.585 14.4588 11.585 14.3625C11.585 14.2663 11.5493 14.174 11.4857 14.1058L3.05929 5.11173C3.02465 5.07409 2.98013 5.04853 2.93161 5.03843C2.88308 5.02834 2.83283 5.03416 2.78747 5.05515C2.74282 5.07501 2.7046 5.1084 2.67755 5.1512C2.65049 5.194 2.63579 5.24433 2.63525 5.29596V22.7038C2.63525 23.4733 2.92164 24.2112 3.4314 24.7553C3.94116 25.2994 4.63255 25.6051 5.35346 25.6051H32.5356C33.2565 25.6051 33.9479 25.2994 34.4576 24.7553C34.9674 24.2112 35.2538 23.4733 35.2538 22.7038V5.29596C35.254 5.24379 35.2396 5.19275 35.2125 5.14935C35.1854 5.10594 35.1468 5.07214 35.1016 5.05225V5.05225Z"
        fill="white"
      />
    </SvgWrapper>
  );
};

export default Icon;
