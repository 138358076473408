import { useState } from 'react';

export type SignInUp = 'signIn' | 'signUp' | null;

export interface AddBookmarkToBoardOptions {
  idFamily: string;
  idFont: number;
  familyName: string;
  fontWeight?: string;
  source?: string;
  page?: number;
  forcedBoardsSelected?: Array<API.Board.BoardSimple>;
}

export interface CreateNewBoardOptions {
  idFamily: string;
  idFont: number;
  familyName: string;
  fontWeight?: string;
  source?: string;
}

export interface EditBoardOptions {
  idBoard: number;
  name: string;
  isPrivate: boolean;
}

export interface DeleteBoardOptions {
  idBoard: number;
  name: string;
  isPrivate: boolean;
}

export interface BoardCollaboratorsListOptions {
  idBoard: number;
}

export interface BoardCollaboratorsInviteOptions {
  idBoard: number;
}

export interface FollowersUserOptions {
  idUser: number;
  count: number;
}

export interface FollowingUserOptions {
  idUser: number;
  count: number;
}

export interface FollowedFoundriesOptions {
  idUser: number;
  count: number;
}

export interface FollowingFoundryOptions {
  idFoundry: string;
  count: number;
}

export interface SupportedLanguageSystemsOptions {
  supportedLanguages: API.SupportedLanguages;
}

export interface FontBuyLinksOptions {
  links: API.BuyLinks;
  fontData: {
    fontName: string;
    fontWeight: string;
    price: number | null;
    priceCurrency: string;
    foundryName: string;
  };
}

export interface ModalContextData {
  openModalSignInUp: SignInUp;
  setOpenModalSignInUp: (value: SignInUp) => void;
  openModalPasswordRetrieval: boolean;
  setOpenModalPasswordRetrieval: (value: boolean) => void;
  openModalChangePassword: boolean;
  setOpenModalChangePassword: (value: boolean) => void;
  openModalEditProfilePicture: boolean;
  setOpenModalEditProfilePicture: (value: boolean) => void;
  openModalPriceRangeWarning: boolean;
  setOpenModalPriceRangeWarning: (value: boolean) => void;
  openModalAddBookmarkToBoard: AddBookmarkToBoardOptions | null;
  setOpenModalAddBookmarkToBoard: (value: AddBookmarkToBoardOptions | null) => void;
  openModalCreateNewBoard: CreateNewBoardOptions | null;
  setOpenModalCreateNewBoard: (value: CreateNewBoardOptions | null) => void;
  openModalEditBoard: EditBoardOptions | null;
  setOpenModalEditBoard: (value: EditBoardOptions | null) => void;
  openModalDeleteBoard: DeleteBoardOptions | null;
  setOpenModalDeleteBoard: (value: DeleteBoardOptions | null) => void;
  openModalBoardCollaboratorsList: BoardCollaboratorsListOptions | null;
  setOpenModalBoardCollaboratorsList: (value: BoardCollaboratorsListOptions | null) => void;
  openModalBoardCollaboratorsInvite: BoardCollaboratorsInviteOptions | null;
  setOpenModalBoardCollaboratorsInvite: (value: BoardCollaboratorsInviteOptions | null) => void;
  openModalFollowers: FollowersUserOptions | null;
  setOpenModalFollowers: (value: FollowersUserOptions | null) => void;
  openModalFollowing: FollowingUserOptions | null;
  setOpenModalFollowing: (value: FollowingUserOptions | null) => void;
  openModalFollowedFoundries: FollowedFoundriesOptions | null;
  setOpenModalFollowedFoundries: (value: FollowedFoundriesOptions | null) => void;
  openModalFollowingFoundry: FollowingFoundryOptions | null;
  setOpenModalFollowingFoundry: (value: FollowingFoundryOptions | null) => void;
  openModalContributeToFontsNinja: 'correct_information' | 'list_typefaces' | 'contact_us' | null;
  setOpenModalContributeToFontsNinja: (value: 'correct_information' | 'list_typefaces' | 'contact_us' | null) => void;
  openModalContributeToFontsNinjaSuccess: 'correct_information' | 'list_typefaces' | 'contact_us' | null;
  setOpenModalContributeToFontsNinjaSuccess: (
    value: 'correct_information' | 'list_typefaces' | 'contact_us' | null
  ) => void;
  openModalDeleteFontsNinjaAccount: boolean;
  setOpenModalDeleteFontsNinjaAccount: (value: boolean) => void;
  openModalDeleteAccountConfirmation: boolean;
  setOpenModalDeleteAccountConfirmation: (value: boolean) => void;
  openModalSupportedLanguageSystems: SupportedLanguageSystemsOptions | null;
  setOpenModalSupportedLanguageSystems: (value: SupportedLanguageSystemsOptions | null) => void;
  openModalFontBuyLinks: FontBuyLinksOptions | null;
  setOpenModalFontBuyLinks: (value: FontBuyLinksOptions | null) => void;
}

export const useModalState = (data: ModalContextData): ModalContextData => {
  // SIGN-IN-UP
  const [openModalSignInUp, setOpenModalSignInUp] = useState<SignInUp>(data.openModalSignInUp);

  // PASSWORD RETRIEVAL
  const [openModalPasswordRetrieval, setOpenModalPasswordRetrieval] = useState<boolean>(
    data.openModalPasswordRetrieval
  );

  // CHANGE PASSWORD
  const [openModalChangePassword, setOpenModalChangePassword] = useState<boolean>(data.openModalChangePassword);

  // EDIT PROFILE PICTURE
  const [openModalEditProfilePicture, setOpenModalEditProfilePicture] = useState<boolean>(
    data.openModalEditProfilePicture
  );

  // PRICE RANGE WARNING
  const [openModalPriceRangeWarning, setOpenModalPriceRangeWarning] = useState<boolean>(
    data.openModalPriceRangeWarning
  );

  // ADD BOOKMARK TO BOARD
  const [openModalAddBookmarkToBoard, setOpenModalAddBookmarkToBoard] = useState<AddBookmarkToBoardOptions | null>(
    data.openModalAddBookmarkToBoard
  );

  // CREATE NEW BOARD
  const [openModalCreateNewBoard, setOpenModalCreateNewBoard] = useState<CreateNewBoardOptions | null>(
    data.openModalCreateNewBoard
  );

  // EDIT BOARD
  const [openModalEditBoard, setOpenModalEditBoard] = useState<EditBoardOptions | null>(data.openModalEditBoard);

  // DELETE BOARD
  const [openModalDeleteBoard, setOpenModalDeleteBoard] = useState<DeleteBoardOptions | null>(
    data.openModalDeleteBoard
  );

  // BOARD COLLABORATORS LIST
  const [openModalBoardCollaboratorsList, setOpenModalBoardCollaboratorsList] =
    useState<BoardCollaboratorsListOptions | null>(data.openModalBoardCollaboratorsList);

  // BOARD COLLABORATORS INVITE
  const [openModalBoardCollaboratorsInvite, setOpenModalBoardCollaboratorsInvite] =
    useState<BoardCollaboratorsInviteOptions | null>(data.openModalBoardCollaboratorsInvite);

  // FOLLOWERS
  const [openModalFollowers, setOpenModalFollowers] = useState<FollowersUserOptions | null>(data.openModalFollowers);

  // FOLLOWING
  const [openModalFollowing, setOpenModalFollowing] = useState<FollowingUserOptions | null>(data.openModalFollowing);

  // FOLLOWED FOUNDRIES
  const [openModalFollowedFoundries, setOpenModalFollowedFoundries] = useState<FollowedFoundriesOptions | null>(
    data.openModalFollowedFoundries
  );

  // FOLLOWING FOUNDRIES
  const [openModalFollowingFoundry, setOpenModalFollowingFoundry] = useState<FollowingFoundryOptions | null>(
    data.openModalFollowingFoundry
  );

  // CONTRIBUTE TO FONTS NINJA
  const [openModalContributeToFontsNinja, setOpenModalContributeToFontsNinja] = useState<
    'correct_information' | 'list_typefaces' | 'contact_us' | null
  >(data.openModalContributeToFontsNinja);

  // CONTRIBUTE TO FONTS NINJA SUCCESS
  const [openModalContributeToFontsNinjaSuccess, setOpenModalContributeToFontsNinjaSuccess] = useState<
    'correct_information' | 'list_typefaces' | 'contact_us' | null
  >(data.openModalContributeToFontsNinjaSuccess);

  // DELETE FONTS NINJA ACCOUNT
  const [openModalDeleteFontsNinjaAccount, setOpenModalDeleteFontsNinjaAccount] = useState<boolean>(
    data.openModalDeleteFontsNinjaAccount
  );

  // DELETE ACCOUNT CONFIRMATION
  const [openModalDeleteAccountConfirmation, setOpenModalDeleteAccountConfirmation] = useState<boolean>(
    data.openModalDeleteAccountConfirmation
  );

  // SUPPORTED LANGUAGE SYSTEMS
  const [openModalSupportedLanguageSystems, setOpenModalSupportedLanguageSystems] =
    useState<SupportedLanguageSystemsOptions | null>(data.openModalSupportedLanguageSystems);

  // FONT BUY LINKS
  const [openModalFontBuyLinks, setOpenModalFontBuyLinks] = useState<FontBuyLinksOptions | null>(
    data.openModalFontBuyLinks
  );

  return {
    openModalSignInUp,
    setOpenModalSignInUp,
    openModalPasswordRetrieval,
    setOpenModalPasswordRetrieval,
    openModalChangePassword,
    setOpenModalChangePassword,
    openModalEditProfilePicture,
    setOpenModalEditProfilePicture,
    openModalPriceRangeWarning,
    setOpenModalPriceRangeWarning,
    openModalCreateNewBoard,
    setOpenModalCreateNewBoard,
    openModalEditBoard,
    setOpenModalEditBoard,
    openModalDeleteBoard,
    setOpenModalDeleteBoard,
    openModalBoardCollaboratorsList,
    setOpenModalBoardCollaboratorsList,
    openModalBoardCollaboratorsInvite,
    setOpenModalBoardCollaboratorsInvite,
    openModalFollowers,
    setOpenModalFollowers,
    openModalFollowing,
    setOpenModalFollowing,
    openModalFollowedFoundries,
    setOpenModalFollowedFoundries,
    openModalFollowingFoundry,
    setOpenModalFollowingFoundry,
    openModalAddBookmarkToBoard,
    setOpenModalAddBookmarkToBoard,
    openModalContributeToFontsNinja,
    setOpenModalContributeToFontsNinja,
    openModalContributeToFontsNinjaSuccess,
    setOpenModalContributeToFontsNinjaSuccess,
    openModalDeleteFontsNinjaAccount,
    setOpenModalDeleteFontsNinjaAccount,
    openModalDeleteAccountConfirmation,
    setOpenModalDeleteAccountConfirmation,
    openModalSupportedLanguageSystems,
    setOpenModalSupportedLanguageSystems,
    openModalFontBuyLinks,
    setOpenModalFontBuyLinks,
  };
};
