import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

export const SpinnerWrapper = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  animation: ${rotation} 1.4s infinite linear;
`;
