import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'api';
import { AxiosError } from 'axios';
import { QUERY_KEYS } from 'common/data/Constants';

interface Options {
  enabled: boolean;
}

interface PatchFamilyTagProps {
  idFamily: string;
  category: string;
}

export const useFamily = () => {
  // GET FAMILY DETAILS
  const useGetFamilyDetails = (idFamily: string, currency: string, options: Options = { enabled: true }) => {
    return useQuery(
      [QUERY_KEYS.FAMILY_DETAILS, { idFamily, currency }],
      () => api.family.getFamilyDetails(idFamily, currency),
      options
    );
  };

  // GET FAMILY FONT IN USE LIST
  const useGetFamilyFontInUseList = (
    idFamily: string,
    pageSize: number,
    page: number,
    options: Options = { enabled: true }
  ) => {
    return useQuery(
      [QUERY_KEYS.FAMILY_FONT_IN_USE_LIST, { idFamily, page }],
      () => api.family.getFamilyFontInUseList(idFamily, pageSize, page),
      options
    );
  };

  // GET FAMILY SIBLINGS
  const useGetFamilySiblingList = (
    idFamily: string,
    idFont: number,
    currency: string,
    options: Options = { enabled: true }
  ) => {
    return useQuery(
      [QUERY_KEYS.FAMILY_SIBLING_LIST, { idFamily, idFont, currency }],
      () => api.family.getFamilySiblingList(idFamily, idFont, currency),
      options
    );
  };

  // GET FAMILY PAIRINGS
  const useGetFamilyPairingList = (
    idFamily: string,
    pageSize: number,
    page: number,
    options: Options = { enabled: true }
  ) => {
    return useQuery(
      [QUERY_KEYS.FAMILY_PAIRING_LIST, { idFamily, page }],
      () => api.family.getFamilyPairingList(idFamily, pageSize, page),
      options
    );
  };

  // GET FAMILY TAG LIST
  const useGetFamilyTagList = (idFamily: string, options: Options = { enabled: true }) => {
    return useQuery([QUERY_KEYS.FAMILY_TAG_LIST, { idFamily }], () => api.family.getFamilyTagList(idFamily), options);
  };

  // PATCH FAMILY TAG LIST
  const usePatchFamilyTag = (options?: {
    onMutate?: (variables: PatchFamilyTagProps) => void;
    onSuccess?: (data: API.Family.FamilyTagList) => void;
    onError?: (err: AxiosError<API.Error>, variable: PatchFamilyTagProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: PatchFamilyTagProps) => api.family.patchFamilyTagList(variables.idFamily, variables.category),
      options
    );
  };

  return {
    useGetFamilyDetails,
    useGetFamilyFontInUseList,
    useGetFamilySiblingList,
    useGetFamilyPairingList,
    useGetFamilyTagList,
    usePatchFamilyTag,
  };
};
