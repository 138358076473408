import axios from 'axios';
import { Filters } from 'api/familySearch.api';
import { API_BASE_URL } from 'common/data/Api';

interface Price {
  min: number;
  max?: number;
  currency: string;
  license: string;
}

const baseUrl = `${API_BASE_URL}/font`;

// SEARCH FONT BY ID
export const searchFontById = (idFont: number, idFontVersion: number | null): Promise<API.Font.SearchFont> => {
  let routeUrl = `${baseUrl}/search/${idFont}`;
  if (idFontVersion) {
    routeUrl = `${routeUrl}?idFontVersion=${idFontVersion}`;
  }
  return axios.get(routeUrl).then(({ data }) => {
    return data;
  });
};

// GET SIMILAR FONTS
export const getSimilarFontList = (
  idFont: number,
  pageSize: number,
  page: number,
  filters?: Filters,
  idSimilarityModel?: number
): Promise<API.Font.SimilarFontList> => {
  if (filters) {
    const cloneFilters: Filters = JSON.parse(JSON.stringify(filters));
    const priceFilters = cloneFilters.find((filter) => filter.type === 'price');
    if (priceFilters && (priceFilters.value as Price).max === 125) {
      priceFilters.value = {
        min: (priceFilters.value as Price).min,
        currency: (priceFilters.value as Price).currency,
      };
      filters = cloneFilters;
    }
  }
  return axios
    .get(`${baseUrl}/${idFont}/similar/list`, { params: { pageSize, page, filters, idSimilarityModel } })
    .then(({ data }) => {
      return data;
    });
};

// GET FONT TAG LIST
export const getFontTagList = (idFont: number): Promise<API.Font.FontTagList> => {
  return axios.get(`${baseUrl}/${idFont}/tag/list`).then(({ data }) => {
    return data;
  });
};
