import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';
import { easeOutQuart } from 'common/easing/Easing';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
  isLoggedIn?: boolean;
}

interface CardProps {
  selected: boolean;
  darkMode: boolean;
}

export const NavPageAppearanceTooltipWrapper = styled.div`
  position: absolute;
  border-radius: 8px;
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  box-shadow: ${(props: Props) => (props.darkMode ? 'none' : `8px 8px 0px ${GREY_6}`)};
  /* MOBILE */
  width: 327px;
  top: 44px;
  left: 28px;
  transform: translate(-100%, 0);
  /* TABLET */
  ${mq['tablet']} {
    left: 52px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    width: 482px;
    top: 50px;
    // Left position when user not logged-in
    ${(props: Props) =>
      !props.isLoggedIn &&
      css`
        left: 224px;
      `}
    // Left position when user logged-in
    ${(props: Props) =>
      props.isLoggedIn &&
      css`
        left: 86px;
      `}
  }
`;

export const TooltipArrow = styled.div`
  position: absolute;
  width: 24px;
  height: 16px;
  top: 0;
  line-height: normal;
  right: 0;
  transform: translate(0, -12px);
  // Firefox
  @-moz-document url-prefix() {
    transform: translate(0, -12.5px);
  }
  // Safari Mobile
  @supports (-webkit-touch-callout: none) {
    transform: translate(0, -13px);
  }
  /* MOBILE */
  display: none;
  /* TABLET */
  ${mq['tablet']} {
    display: block;
    right: 20px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    // Right arrow position when user not logged-in
    ${(props: Props) =>
      !props.isLoggedIn &&
      css`
        right: 190px;
      `}
    // Right arrow position when user logged-in
    ${(props: Props) =>
      props.isLoggedIn &&
      css`
        right: 54px;
      `}
  }
`;

export const SwitchModeWrapper = styled.div`
  /* MOBILE */
  padding: 32px 16px;
  > div {
    width: 100%;
    height: 44px;
    justify-content: space-between;
    > button {
      width: 100%;
    }
  }
  /* DESKTOP */
  ${mq['desktop']} {
    padding: 24px;
    > div {
      height: 36px;
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
`;

export const CardAppearanceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* MOBILE */
  padding: 32px 16px 8px;
  /* DESKTOP */
  ${mq['desktop']} {
    padding: 24px;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  transition: opacity 0.25s ${easeOutQuart};
  /* MOBILE */
  margin-bottom: 24px;
  margin-right: 16px;
  :nth-of-type(even) {
    margin-right: 0;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin-bottom: 0;
    margin-right: 16px;
    :nth-of-type(even) {
      margin-right: 16px;
    }
    :last-of-type {
      margin-right: 0;
    }
  }
  // Selected card
  ${(props: CardProps) =>
    props.selected &&
    css`
      opacity: 1;
      cursor: default;
      .card-container {
        box-shadow: ${props.darkMode ? 'none' : `4px 4px 0px ${GREY_6}`};
        transform: ${props.darkMode ? 'translate(0, 0)' : 'translate(-4px, -4px)'};
      }
    `}
  // Not Selected card
  ${(props: CardProps) =>
    !props.selected &&
    css`
      opacity: 0.5;
      cursor: pointer;
      @media (hover: hover) {
        &:hover {
          opacity: 1;
          .card-container {
            box-shadow: ${props.darkMode ? 'none' : `4px 4px 0px ${GREY_6}`};
            transform: ${props.darkMode ? 'translate(0, 0)' : 'translate(-4px, -4px)'};
          }
        }
      }
    `}
`;

export const CardContainer = styled.div`
  position: relative;
  border: 1px solid ${(props: CardProps) => (props.darkMode ? WHITE : GREY_6)};
  border-radius: 4px;
  overflow: hidden;
  transition:
    transform 0.25s ${easeOutQuart},
    box-shadow 0.25s ${easeOutQuart};
  /* MOBILE */
  width: 138px;
  height: 84px;
  &.card-poster {
    height: 121px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    width: 96px;
    height: 114px;
    &.card-poster {
      height: 114px;
    }
  }
`;

export const CardContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const CardLabel = styled.p`
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  text-align: center;
  /* MOBILE */
  font-size: 16px;
  line-height: 11px;
  margin-top: 14px;
  /* DESKTOP */
  ${mq['desktop']} {
    font-size: 14px;
    line-height: 10px;
    margin-top: 10px;
  }
`;

export const DebugModeWrapper = styled.div`
  /* MOBILE */
  margin-top: -8px;
  padding: 0 16px 24px;
  label > div {
    transition:
      transform 0.25s ${easeOutQuart},
      background-color 0s ${easeOutQuart},
      box-shadow 0.25s ${easeOutQuart};
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin-top: -2px;
    padding: 0 24px 24px;
    label {
      font-size: 14px;
    }
  }
`;
