import styled from '@emotion/styled';

interface IconButtonProps {
  size?: string;
}

export const IconButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  width: ${(props: IconButtonProps) => props.size ?? 'auto'};
  height: ${(props: IconButtonProps) => props.size ?? 'auto'};
  cursor: pointer;
`;
