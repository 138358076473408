import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { InputText } from 'design-system/atoms/input-text';
import { Button } from 'design-system/atoms/button';
import { useModalContext, useSettingsContext } from 'context';
import { useFeedback } from 'hooks/queries/useFeedback';
import { isValidEmail } from 'common/utils';
import { GOOGLE_RECAPTCHA_CLIENT_KEY } from 'common/data/ReCaptcha';
import {
  ButtonSendWrapper,
  Form,
  InputItem,
  InputTextWrapper,
  ResponseError,
  TextArea,
} from './CorrectInformationsForm.styled';

interface InputValue {
  value: string;
  error: string;
}

interface Props {
  onClose: () => void;
}

const CorrectInformationsForm = ({ onClose }: Props) => {
  const { t } = useTranslation('common');
  const { setOpenModalContributeToFontsNinjaSuccess } = useModalContext();
  const { darkMode } = useSettingsContext();

  const [name, setName] = useState<InputValue>({ value: '', error: '' });
  const [email, setEmail] = useState<InputValue>({ value: '', error: '' });
  const [message, setMessage] = useState<InputValue>({ value: '', error: '' });
  const [buttonSendDisabled, setButtonSendDisabled] = useState<boolean>(false);
  const [responseError, setReponseError] = useState<string>('');

  const { useSendCorrections } = useFeedback();
  const sendCorrectionsMutation = useSendCorrections({
    onSuccess: (data) => {
      if (data.success) {
        setButtonSendDisabled(false);
        onClose();
        setOpenModalContributeToFontsNinjaSuccess('correct_information');
      } else {
        setButtonSendDisabled(false);
        setReponseError(t('errors.generic'));
      }
    },
    onError: (err) => {
      setButtonSendDisabled(false);
      setReponseError(err.response ? err.response.data.message : t('errors.generic'));
    },
  });

  const handleChangeName = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setName({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleChangeEmail = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setEmail({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleChangeMessage = useCallback((event: SyntheticEvent<HTMLTextAreaElement>) => {
    setMessage({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleSubmitForm = useCallback(
    async (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      // Ensure `grecaptcha` is defined before calling it
      let token: string | null = null;
      if (window.grecaptcha) {
        token = await window.grecaptcha.execute(GOOGLE_RECAPTCHA_CLIENT_KEY, {
          action: 'submit',
        });
      }

      let nbError = 0;
      if (name.value.trim() === '') {
        setName({ value: name.value, error: t('contribute-to-fonts-ninja.mandatory') });
        nbError += 1;
      }
      if (!isValidEmail(email.value.trim())) {
        setEmail({ value: email.value, error: t('errors.email-error') });
        nbError += 1;
      }
      if (nbError > 0) return;

      setButtonSendDisabled(true);

      sendCorrectionsMutation.mutate({
        fullName: name.value,
        email: email.value,
        ...(message.value.trim() !== '' && { message: message.value }),
        ...(token && { recaptchaToken: token }),
      });
    },
    [email.value, message.value, name.value, sendCorrectionsMutation, t]
  );

  return (
    <Form onSubmit={handleSubmitForm}>
      <InputTextWrapper>
        <InputItem>
          <InputText
            placeholder={t('contribute-to-fonts-ninja.option-2.input-1')}
            value={name.value}
            error={name.error}
            darkMode={darkMode}
            onChange={handleChangeName}
          />
        </InputItem>
        <InputItem>
          <InputText
            type="email"
            placeholder={t('contribute-to-fonts-ninja.option-2.input-2')}
            value={email.value}
            error={email.error}
            darkMode={darkMode}
            onChange={handleChangeEmail}
          />
        </InputItem>
        <TextArea
          placeholder={t('contribute-to-fonts-ninja.option-2.textbox')}
          darkMode={darkMode}
          onChange={handleChangeMessage}
        />
      </InputTextWrapper>
      <ButtonSendWrapper>
        <Button
          disabled={buttonSendDisabled}
          darkMode={darkMode}
        >
          {t('contribute-to-fonts-ninja.option-2.button')}
        </Button>
      </ButtonSendWrapper>
      {responseError && responseError !== '' && <ResponseError>{responseError}</ResponseError>}
    </Form>
  );
};

export default CorrectInformationsForm;
