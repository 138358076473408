import { PropsWithChildren, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useAuthContext, useBreakpointContext, useSettingsContext } from 'context';
import Bridge from 'bridge/Bridge';
import { MainStyled } from './Main.styled';

const Main = ({ children }: PropsWithChildren<Record<string, ReactNode>>) => {
  const { authReady } = useAuthContext();
  const { isExtension } = useSettingsContext();
  const { breakpointsReady } = useBreakpointContext();

  const router = useRouter();
  const { isBot } = router.query;
  const isBotStr = isBot === undefined ? 'true' : (isBot as string);

  return (
    <>
      <MainStyled
        ready={authReady && breakpointsReady}
        isBot={isBotStr}
      >
        {children}
      </MainStyled>
      {isExtension && <Bridge />}
    </>
  );
};

export default Main;
