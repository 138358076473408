import { EXTENSION, NOT_FOUND_PAGE, PAGE_NAMES } from 'common/data/Constants';
import { Platform } from 'tracking/Mixpanel';

export const getPageNameWithRoutePathname = (pathname: string): string => {
  let pageName = PAGE_NAMES[pathname];
  if (pageName === undefined) pageName = NOT_FOUND_PAGE;
  return pageName;
};

export const getPlatform = (): Platform => {
  const urlParams = new URLSearchParams(window.location.search);
  const extensionParam = urlParams.get(EXTENSION) ?? null;
  const platform: Platform =
    extensionParam !== null || document.body.classList.contains(EXTENSION) ? 'extension' : 'web-app';
  return platform;
};
