import { createContext, PropsWithChildren, ReactElement, useContext } from 'react';
import { SettingsContextData, useSettingsState } from 'hooks';
import {
  initialWidth,
  initialContrast,
  initialDarkMode,
  initialDisplayedWeight,
  initialDisplayMode,
  initialEnding,
  initialFontStyles,
  initialMinStyles,
  initialPriceRange,
  initialSingleFontAppearance,
  initialSingleFontItalic,
  initialSingleFontTextTransform,
  initialTrialFonts,
  initialDisplayNavPageAppearanceTooltip,
  initialDisplayOptionsBarFilters,
  initialMonospacedFonts,
} from 'common/data/Settings';

const initialData: SettingsContextData = {
  isExtension: false,
  setIsExtension: (_value: boolean) => null,
  extensionDocumentHeight: 0,
  setExtensionDocumentHeight: (_value: number) => null,
  extensionScrollY: 0,
  setExtensionScrollY: (_value: number) => null,
  extensionWindowHeight: 0,
  setExtensionWindowHeight: (_value: number) => null,
  machineId: undefined,
  setMachineId: (_value?: string) => null,
  displayNavPageAppearanceTooltip: initialDisplayNavPageAppearanceTooltip,
  setDisplayNavPageAppearanceTooltip: (_value: boolean) => null,
  displayOptionsBarFilters: initialDisplayOptionsBarFilters,
  setDisplayOptionsBarFilters: (_value: boolean) => null,
  fontStyles: initialFontStyles,
  changeFontStyles: (_key: string, _value: boolean) => null,
  minStyles: initialMinStyles,
  changeMinStyles: (_value: number) => null,
  contrast: initialContrast,
  changeContrast: (_value: number) => null,
  width: initialWidth,
  changeWidth: (_value: number) => null,
  ending: initialEnding,
  changeEnding: (_value: number) => null,
  trialFonts: initialTrialFonts,
  changeTrialFonts: (_value: boolean) => null,
  monospacedFonts: initialMonospacedFonts,
  changeMonospacedFonts: (_value: boolean) => null,
  priceRange: initialPriceRange,
  changePriceRange: (_value: Array<number>) => null,
  displayMode: initialDisplayMode,
  changeDisplayMode: (_value: string) => null,
  displayedWeight: initialDisplayedWeight,
  changeDisplayedWeight: (_value: number) => null,
  darkMode: initialDarkMode,
  changeDarkMode: (_value: boolean) => null,
  singleFontAppearance: initialSingleFontAppearance,
  setSingleFontAppearance: (_value: string) => null,
  singleFontTextTransform: initialSingleFontTextTransform,
  setSingleFontTextTransform: (_value: string) => null,
  singleFontItalic: initialSingleFontItalic,
  setSingleFontItalic: (_value: string | undefined) => null,
  resetFontShapes: () => null,
  resetFontStyles: () => null,
  resetFontOptions: (_value: boolean) => null,
};

export const SettingsContext = createContext(initialData);
export const useSettingsContext = () => useContext(SettingsContext);

export const SettingsContextProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): ReactElement => {
  const state = useSettingsState(initialData);

  return <SettingsContext.Provider value={state}>{children}</SettingsContext.Provider>;
};
