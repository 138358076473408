import { RECAPTCHA } from './Constants';

const getReCaptchaClientKey = () => {
  if (process.env.NODE_ENV === 'development') {
    return RECAPTCHA.development;
  } else if (process.env.API_ENV === 'preprod') {
    return RECAPTCHA.preprod;
  } else {
    return RECAPTCHA.production;
  }
};

export const GOOGLE_RECAPTCHA_CLIENT_KEY = getReCaptchaClientKey();
