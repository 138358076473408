import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { IconButton } from 'design-system/atoms/icon-button';
import { GREY_6 } from 'design-system/global/colors';
import { CloseButtonWrapper, NotificationStyled } from './Notification.styled';

interface Props extends PropsWithChildren<Record<string, unknown>> {
  darkMode?: boolean;
  onClose?: () => void;
  onCloseComplete?: () => void;
}

const Notification = ({ darkMode, children, onClose, onCloseComplete }: Props) => {
  const [openState, setOpenState] = useState<boolean>(false);
  const [openCompleteState, setOpenCompleteState] = useState<boolean>(false);

  const notificationRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout>();

  const handleClose = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setOpenState(false);
    onClose && onClose();
  }, [onClose]);

  const handleOpenComplete = useCallback(() => {
    setOpenCompleteState(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setOpenCompleteState(false);
    onCloseComplete && onCloseComplete();
  }, [onCloseComplete]);

  useEffect(() => {
    if (openState) {
      intervalRef.current = setTimeout(() => {
        handleClose();
      }, 3000);
      notificationRef.current?.addEventListener('transitionend', () => {
        if (!openCompleteState) {
          handleOpenComplete();
        } else {
          handleCloseComplete();
        }
      });
    }
  }, [handleClose, handleCloseComplete, handleOpenComplete, openCompleteState, openState]);

  useEffect(() => {
    setOpenState(true);
  }, []);

  return (
    <NotificationStyled
      ref={notificationRef}
      darkMode={darkMode}
      open={openState}
    >
      <CloseButtonWrapper>
        <IconButton
          iconLabel="close"
          iconSize="8px"
          buttonSize="40px"
          color={GREY_6}
          onClick={handleClose}
        />
      </CloseButtonWrapper>
      {children}
    </NotificationStyled>
  );
};

export default Notification;
