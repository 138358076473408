import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { Dropdown } from 'design-system/atoms/dropdown';
import { useFamily } from 'hooks/queries/useFamily';
import { familyCategoryOptions } from 'common/data/Datas';
import { QUERY_KEYS } from 'common/data/Constants';
import { Debug, DebugChangeCategoryWrapper, DropdownCategoryContainer, Item } from './BareBoneDebug.styled';

interface Props {
  idFamily: string;
  darkMode?: boolean;
}

const BareBoneDebug = ({ idFamily, darkMode }: Props) => {
  const { t } = useTranslation('common');

  const queryClient = useQueryClient();

  const { useGetFamilyTagList, usePatchFamilyTag } = useFamily();
  const { data: tagData } = useGetFamilyTagList(idFamily);

  const patchFamilyTagMutation = usePatchFamilyTag({
    onMutate: (variables) => {
      // Snapshot the previous value
      const previousData = queryClient.getQueryData<API.Family.FamilyTagList>([
        QUERY_KEYS.FAMILY_TAG_LIST,
        { idFamily },
      ]);
      return { previousData };
    },
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEYS.FAMILY_TAG_LIST, { idFamily }], data);
    },
    onError: (err, vaiables, context) => {
      if (context?.previousData) {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData([QUERY_KEYS.FAMILY_TAG_LIST, { idFamily }], context.previousData);
        setCategoryState(familyCategoryOptions.find((option) => option.value === context.previousData.category));
      }
    },
  });

  const [categoryState, setCategoryState] = useState<Option | undefined>(undefined);

  const handleChangeFamilyCategory = useCallback(
    (option: any) => {
      setCategoryState(option);
      patchFamilyTagMutation.mutate({ idFamily, category: option.value });
    },
    [idFamily, patchFamilyTagMutation]
  );

  useEffect(() => {
    if (tagData) {
      setCategoryState(familyCategoryOptions.find((option) => option.value === tagData.category));
    }
  }, [tagData]);

  return (
    <Debug darkMode={darkMode}>
      {tagData && (
        <>
          <p>
            <b>Category:</b> <Item active={true}>{categoryState?.label ?? ''}</Item>
          </p>
          <DebugChangeCategoryWrapper className="change-category-wrapper">
            <DropdownCategoryContainer>
              <Dropdown
                className="dropdown-category"
                value={categoryState}
                options={familyCategoryOptions}
                onChange={handleChangeFamilyCategory}
              />
            </DropdownCategoryContainer>
            {/* <Button>{t('card.download')}</Button> */}
          </DebugChangeCategoryWrapper>
        </>
      )}
    </Debug>
  );
};

export default BareBoneDebug;
