import { useDebounceFn, useMount } from 'ahooks';

export function useFixScroll(hasMore: boolean, fetchMore: () => void, scrollThreshold: number = 0) {
  // debounce is necessary
  const fixFetch = useDebounceFn(
    () => {
      if (
        document.body &&
        hasMore &&
        window.scrollY > document.body.getBoundingClientRect().height - window.innerHeight - scrollThreshold
      ) {
        fetchMore();
      }
    },
    {
      wait: 500,
    }
  );
  // useMount equals useEffect(()=>{doSomething();},[]);
  useMount(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          fixFetch.run();
        }
      }
    });
    if (!hasMore) {
      return;
    }
    // scroll-items-container is the container for the items.
    const targetNode = document.getElementById('scroll-items-container');
    if (!targetNode) {
      return;
    }
    const config = { childList: true, subtree: false };
    observer.observe(targetNode, config);
    return () => {
      observer.disconnect();
    };
  });
}
