import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { Dropdown } from 'design-system/atoms/dropdown';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useModalContext, useSettingsContext } from 'context';
import Modal from 'components/Modal';
import ListTypefacesForm from './ListTypefacesForm';
import CorrectInformationsForm from './CorrectInformationsForm';
import ContactUsForm from './ContactUsForm';
import {
  CloseButtonWrapper,
  ContentWrapper,
  DropdownWrapper,
  GoogleRecaptchaTerms,
  ModalWrapper,
  TitleWrapper,
  Video,
  VideoWrapper,
} from './ContributeToFontsNinja.styled';

const ContributeToFontsNinja = () => {
  const { t } = useTranslation('common');
  const { darkMode } = useSettingsContext();
  const { openModalContributeToFontsNinja, setOpenModalContributeToFontsNinja } = useModalContext();

  const options: Options = useMemo(
    () => [
      {
        value: 'option-1',
        label: t('contribute-to-fonts-ninja.dropdown.option-1'),
      },
      {
        value: 'option-2',
        label: t('contribute-to-fonts-ninja.dropdown.option-2'),
      },
      {
        value: 'option-3',
        label: t('contribute-to-fonts-ninja.dropdown.option-3'),
      },
    ],
    [t]
  );

  const [startClose, setStartClose] = useState<boolean>(false);
  const [dropdownOption, setDropdownOption] = useState<Option>(options[0]);

  const themeMode = useMemo(() => {
    return darkMode ? 'dark' : 'light';
  }, [darkMode]);

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalContributeToFontsNinja(null);
  }, [setOpenModalContributeToFontsNinja]);

  const handleDropdownChange = useCallback((option: any) => {
    const selectedOption = option as Option;
    setDropdownOption(selectedOption);
  }, []);

  useEffect(() => {
    if (openModalContributeToFontsNinja === 'list_typefaces') {
      setDropdownOption(options[0]);
    } else if (openModalContributeToFontsNinja === 'correct_information') {
      setDropdownOption(options[1]);
    } else if (openModalContributeToFontsNinja === 'contact_us') {
      setDropdownOption(options[2]);
    }
  }, [openModalContributeToFontsNinja, options]);

  if (openModalContributeToFontsNinja) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        modalContainerOverflowHidden={false}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            <VideoWrapper>
              <Video
                src={`/videos/contribute-${themeMode}.mp4`}
                autoPlay={true}
                loop={true}
                muted={true}
                playsInline
              />
            </VideoWrapper>
            <TitleWrapper>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('contribute-to-fonts-ninja.title')}
              </H3>
            </TitleWrapper>
            <DropdownWrapper>
              <Dropdown
                width="100%"
                optionsWidth="100%"
                isMulti={false}
                isSearchable={false}
                darkMode={darkMode}
                options={options}
                value={dropdownOption}
                placeholder={t('contribute-to-fonts-ninja.dropdown.placeholder')}
                noOptionsMessage={() => t('contribute-to-fonts-ninja.dropdown.no-options')}
                onChange={handleDropdownChange}
              />
            </DropdownWrapper>
            {dropdownOption.value === 'option-1' && <ListTypefacesForm onClose={handleClose} />}
            {dropdownOption.value === 'option-2' && <CorrectInformationsForm onClose={handleClose} />}
            {dropdownOption.value === 'option-3' && <ContactUsForm onClose={handleClose} />}
          </ContentWrapper>
        </ModalWrapper>
        <GoogleRecaptchaTerms
          dangerouslySetInnerHTML={{
            __html: t('contribute-to-fonts-ninja.google-recaptcha-terms'),
          }}
        />
      </Modal>
    );
  }
  return null;
};

export default ContributeToFontsNinja;
