import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DANGER_RED, LIGHT_GREY, WHITE } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  error?: boolean;
}

export const UL = styled.ul`
  padding: 0 0 0 14px;
  margin: 0 0 24px;
`;

export const LI = styled.li`
  /* MOBILE */
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 4px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const Form = styled.form`
  position: relative;
`;

export const InputTextWrapper = styled.div`
  margin-bottom: 32px;
`;

export const InputItem = styled.div`
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const CheckboxWrapper = styled.div`
  max-width: 320px;
  margin-bottom: 24px;
  label {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .checkbox-wrapper {
      position: relative;
      top: 1px;
      min-width: 14px;
    }
  }
  ${(props: Props) =>
    props.error &&
    css`
      label {
        color: ${DANGER_RED};
        .checkbox-wrapper {
          border-color: ${DANGER_RED};
        }
      }
    `}
`;

export const InputFile = styled.input`
  display: none;
`;

export const ButtonSendWrapper = styled.div`
  button {
    &:disabled {
      color: ${WHITE};
      background-color: ${LIGHT_GREY};
      border-color: ${LIGHT_GREY};
      &:hover {
        border-color: ${LIGHT_GREY};
      }
    }
  }
`;

export const ResponseError = styled.p`
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${DANGER_RED};
`;
