import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/suggestions`;

export const getSuggestionsSearch = (text: string): Promise<API.Suggestions.ResultSuggestionsSearch> => {
  return axios.get(`${baseUrl}/${text}`).then(({ data }) => {
    return data;
  });
};
