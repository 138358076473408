import React, { useCallback, useRef } from 'react';
import { Icon } from 'design-system/atoms/icon';
import { GREY_6, WHITE } from 'design-system/global/colors';
import Arrow from './arrow';
import { SliderMark, SliderStyled, SliderThumb, SliderTooltip, SliderTrack, SliderWrapper } from './slider.styled';

export interface SliderProps {
  defaultValue?: number | Array<number>;
  value?: number | Array<number>;
  defaultIcon?: string | Array<string>;
  defaultLabel?: string | Array<string>;
  withTooltip?: boolean;
  disabled?: boolean;
  marks?: boolean | number | Array<number>;
  min?: number;
  max?: number;
  step?: number;
  overlapable?: boolean;
  beforeValue?: string | Array<string>;
  afterValue?: string | Array<string>;
  darkMode?: boolean;
  onChange?: (value: number | readonly number[], index: number) => void;
  onBeforeChange?: (value: number | readonly number[], index: number) => void;
  onAfterChange?: (value: number | readonly number[], index: number) => void;
}

export function Slider({
  defaultValue = 0,
  value,
  defaultIcon,
  defaultLabel,
  withTooltip,
  disabled,
  marks,
  min,
  max,
  step,
  overlapable,
  beforeValue,
  afterValue,
  darkMode = false,
  onChange,
  onBeforeChange,
  onAfterChange,
}: SliderProps) {
  const sliderWrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef0 = useRef<HTMLDivElement>(null);
  const tooltipRef1 = useRef<HTMLDivElement>(null);

  const handleBeforeChange = useCallback(
    (value: number | readonly number[], index: number) => {
      if (withTooltip) {
        if (index === 0 && tooltipRef0.current) {
          tooltipRef0.current.style.visibility = 'visible';
        }
        if (index === 1 && tooltipRef1.current) {
          tooltipRef1.current.style.visibility = 'visible';
        }
      }
      if (onBeforeChange) {
        onBeforeChange(value, index);
      }
    },
    [withTooltip, onBeforeChange]
  );

  const handleAfterChange = useCallback(
    (value: number | readonly number[], index: number) => {
      if (withTooltip) {
        if (index === 0 && tooltipRef0.current) {
          tooltipRef0.current.removeAttribute('style');
        }
        if (index === 1 && tooltipRef1.current) {
          tooltipRef1.current.removeAttribute('style');
        }
      }
      if (onAfterChange) {
        onAfterChange(value, index);
      }
    },
    [withTooltip, onAfterChange]
  );

  const Thumb = (props: any, state: any) => {
    return (
      <SliderThumb
        darkMode={darkMode}
        {...props}
      >
        {defaultIcon ? (
          !Array.isArray(defaultIcon) ? (
            <Icon
              label={defaultIcon}
              color={darkMode ? GREY_6 : WHITE}
            />
          ) : (
            <Icon
              label={defaultIcon[state.index]}
              color={darkMode ? GREY_6 : WHITE}
            />
          )
        ) : defaultLabel ? (
          !Array.isArray(defaultLabel) ? (
            defaultLabel
          ) : (
            defaultLabel[state.index]
          )
        ) : (
          <>
            {beforeValue &&
              (!Array.isArray(beforeValue) || beforeValue[state.index] !== '') &&
              beforeValue[state.index]}
            {state.valueNow}
            {afterValue && (!Array.isArray(afterValue) || afterValue[state.index] !== '') && afterValue[state.index]}
          </>
        )}
        {withTooltip && (
          <SliderTooltip
            className="slider-tooltip"
            ref={state.index === 0 ? tooltipRef0 : tooltipRef1}
            darkMode={darkMode}
          >
            <Arrow darkMode={darkMode} />
            {defaultLabel ? (!Array.isArray(defaultLabel) ? defaultLabel : defaultLabel[state.index]) : state.valueNow}
          </SliderTooltip>
        )}
      </SliderThumb>
    );
  };

  const Track = (props: any, state: any) => {
    const nbTracks = defaultValue
      ? Array.isArray(defaultValue)
        ? defaultValue.length
        : 1
      : Array.isArray(value)
        ? value.length
        : 1;

    return (
      <SliderTrack
        index={state.index}
        nbTracks={nbTracks}
        darkMode={darkMode}
        {...props}
      />
    );
  };

  const Mark = (props: any) => {
    return (
      <SliderMark
        darkMode={darkMode}
        {...props}
      />
    );
  };

  return (
    <SliderWrapper ref={sliderWrapperRef}>
      <SliderStyled
        renderTrack={Track}
        renderThumb={Thumb}
        {...(defaultValue !== undefined && { defaultValue: defaultValue as never })}
        {...(value !== undefined && !defaultValue && { value: value })}
        {...(disabled && { disabled: disabled })}
        {...(min && { min: min })}
        {...(max && { max: max })}
        {...(step && { step: step })}
        {...(marks && { marks: marks, renderMark: Mark })}
        {...(overlapable === false &&
          Array.isArray(defaultValue) &&
          defaultValue.length > 1 && { minDistance: step ?? 1 })}
        {...(withTooltip && { onBeforeChange: handleBeforeChange, onAfterChange: handleAfterChange })}
        {...(onChange && { onChange: onChange })}
        {...(onBeforeChange && { onBeforeChange: handleBeforeChange })}
        {...(onAfterChange && { onAfterChange: handleAfterChange })}
      />
    </SliderWrapper>
  );
}
