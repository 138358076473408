import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';
import { easeOutQuart } from 'common/easing/Easing';
import { DESKTOP_CARD_WIDTH, MOBILE_CARD_WIDTH, TABLET_CARD_WIDTH } from 'common/data/Constants';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
  scaleValue?: number;
  isMobile?: boolean;
  fontPangramWidth?: number;
  fontPangramHeight?: number;
  isLoadingFonts?: boolean;
}

const getBookmarkButtonWrapperPadding = (scaleValue: number, isMobile: boolean) => {
  if (isMobile) {
    return `0 ${Math.round(16 * scaleValue)}px 24px`;
  } else {
    return `0 ${Math.round(32 * scaleValue)}px 24px`;
  }
};

const MOBILE_CARD_HEIGHT = 247;
const TABLET_CARD_HEIGHT = 298;
const DESKTOP_CARD_HEIGHT = 298;

export const Card = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    .pangram-hover {
      opacity: 1;
    }
    .buttons-wrapper {
      transform: translateY(0);
    }
  }
`;

export const CardContainer = styled.div`
  transform-origin: top left;
  transform: scale(1);
  ${(props: Props) =>
    props.scaleValue &&
    css`
      /* MOBILE */
      transform: scale(${props.scaleValue});
      height: calc(${MOBILE_CARD_HEIGHT}px * ${props.scaleValue});
      /* TABLET */
      ${mq['tablet']} {
        width: calc(${TABLET_CARD_WIDTH}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        height: calc(${TABLET_CARD_HEIGHT}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        transform: scale(${props.scaleValue > 1 ? 1 : props.scaleValue});
      }
      /* DESKTOP */
      ${mq['desktop']} {
        width: calc(${DESKTOP_CARD_WIDTH}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        height: calc(${DESKTOP_CARD_HEIGHT}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        transform: scale(${props.scaleValue > 1 ? 1 : props.scaleValue});
      }
    `}
`;

export const CardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  border-radius: 8px;
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  /* MOBILE */
  width: ${MOBILE_CARD_WIDTH}px;
  height: ${MOBILE_CARD_HEIGHT}px;
  /* TABLET */
  ${mq['tablet']} {
    min-width: ${TABLET_CARD_WIDTH}px;
    width: auto;
    height: ${TABLET_CARD_HEIGHT}px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    min-width: ${DESKTOP_CARD_WIDTH}px;
    width: auto;
    height: ${DESKTOP_CARD_HEIGHT}px;
  }
  @media (hover: hover) {
    &:hover {
      .card-hover {
        display: block;
      }
    }
  }
`;

export const CardLoader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
`;

export const ContentWrapper = styled.div`
  opacity: ${(props: Props) => (props.isLoadingFonts ? 0 : 1)};
`;

export const PangramWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  opacity: 0;
  transition: opacity 0.2s ${easeOutQuart};
  /* MOBILE */
  padding: 12px 17px 0 16px;
  /* TABLET */
  ${mq['tablet']} {
    padding: 24px 30px 0 32px;
  }
`;

export const PangramName = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PangramImage = styled.div`
  position: relative;
  width: ${(props: Props) => (props.fontPangramWidth ? `${props.fontPangramWidth}px` : 'auto')};
  height: ${(props: Props) => (props.fontPangramHeight ? `${props.fontPangramHeight}px` : 'auto')};
  user-select: none;
`;

export const PangramNoScript = styled.p`
  font-weight: 700;
  font-size: 38px;
  line-height: 40px;
  ${mq['tablet']} {
    font-size: 45px;
    line-height: 50px;
  }
`;

export const FontUnauthorizedWrapper = styled.div`
  display: flex;
`;

export const FontUnauthorizedText = styled.p`
  line-height: normal;
  /* MOBILE */
  font-size: 12px;
  width: 194px;
  margin-left: 8px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 16px;
    width: 262px;
    margin-left: 10px;
  }
`;

export const HoverWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  /* MOBILE */
  width: ${(props: Props) => (props.scaleValue ? `calc(100% * ${props.scaleValue})` : '100%')};
  max-width: 100%;
  /* TABLET */
  ${mq['tablet']} {
    width: 100%;
    max-width: none;
  }
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  bottom: 0;
  pointer-events: auto;
  padding: ${(props: Props) => getBookmarkButtonWrapperPadding(props.scaleValue ?? 1, props.isMobile ?? false)};
  transform: translateY(70px);
  transition: transform 0.2s ${easeOutQuart};
`;

export const ButtonWebsiteWrapper = styled.div`
  margin-right: 8px;
  [class^='icon-'],
  [class*=' icon-'] {
    margin-right: 0 !important;
  }
`;
