import styled from '@emotion/styled';
import { GREY_6 } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
}

export const SmileyWrapper = styled.div`
  position: relative;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  box-shadow: ${(props: Props) => (props.darkMode ? 'none' : `8px 8px 0px ${GREY_6}`)};
  /* MOBILE */
  transform: scale(0.69);
  /* TABLET */
  ${mq['tablet']} {
    transform: scale(1);
  }
`;
