import { useState } from 'react';

export interface NotificationBookmark {
  familyId: string;
  fontId: number;
  fontName: string;
  type: 'add' | 'update';
  nbBoard?: number;
  idBoards?: Array<number>;
}

export interface NotificationContextData {
  openNotificationBookmark: NotificationBookmark | null;
  setOpenNotificationBookmark: (value: NotificationBookmark | null) => void;
  openNotificationBoardInvitationLink: boolean;
  setOpenNotificationBoardInvitationLink: (value: boolean) => void;
  openNotificationEmailInvitationSend: string | null;
  setOpenNotificationEmailInvitationSend: (value: string | null) => void;
  openNotificationBoardJoinedByInvitationLink: boolean;
  setOpenNotificationBoardJoinedByInvitationLink: (value: boolean) => void;
}

export const useNotificationState = (data: NotificationContextData): NotificationContextData => {
  // ADD BOOKMARK
  const [openNotificationBookmark, setOpenNotificationBookmark] = useState<NotificationBookmark | null>(
    data.openNotificationBookmark
  );

  // BOARD INVITATION LINK
  const [openNotificationBoardInvitationLink, setOpenNotificationBoardInvitationLink] = useState<boolean>(
    data.openNotificationBoardInvitationLink
  );

  // EMAIL INVITATION SEND
  const [openNotificationEmailInvitationSend, setOpenNotificationEmailInvitationSend] = useState<string | null>(
    data.openNotificationEmailInvitationSend
  );

  // BOARD JOINED BY INVITATION LINK
  const [openNotificationBoardJoinedByInvitationLink, setOpenNotificationBoardJoinedByInvitationLink] =
    useState<boolean>(data.openNotificationBoardJoinedByInvitationLink);

  return {
    openNotificationBookmark,
    setOpenNotificationBookmark,
    openNotificationBoardInvitationLink,
    setOpenNotificationBoardInvitationLink,
    openNotificationEmailInvitationSend,
    setOpenNotificationEmailInvitationSend,
    openNotificationBoardJoinedByInvitationLink,
    setOpenNotificationBoardJoinedByInvitationLink,
  };
};
