import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import RenderIfVisible from 'react-render-if-visible';
import { Icon } from 'design-system/atoms/icon';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { usePrevious } from 'hooks';
import { useBreakpointContext, useSettingsContext } from 'context';
import { useFontImage } from 'hooks/queries/useFontImage';
import { FontImageParameters } from 'api/fontImage.api';
import { DESKTOP_CARD_WIDTH, MACHINE_ID, MOBILE_CARD_WIDTH, ROUTES } from 'common/data/Constants';
import { convertSvgStringToDataUrl } from 'common/utils';
import BookmarkButtonWithSlider from 'containers/BookmarkButtonWithSlider';
import Spinner from 'components/Spinner';
import FontUnauthorized from 'components/Icons/FontUnauthorized';
import Marker from '../Marker';
import {
  ButtonsWrapper,
  ButtonWebsiteWrapper,
  Card,
  CardContainer,
  CardLoader,
  CardWrapper,
  ContentWrapper,
  FontUnauthorizedText,
  FontUnauthorizedWrapper,
  HoverWrapper,
  PangramImage,
  PangramName,
  PangramNoScript,
  PangramWrapper,
} from './FontInUse.styled';
import { MarkerContainer, Variable } from '../Card.styled';

interface Props {
  index: number;
  family: API.Family;
  scaleValue?: number;
  forceLoading?: boolean;
  onClickBookmarkButton: (idFont: number) => void;
  onClickFontLink?: (idFont: number) => void;
  onSaveCurrentFontWeightInBoard?: (idBoard: number, idFamily: string, idFont: number) => void;
}

const FontInUse = ({
  index,
  family,
  scaleValue,
  forceLoading,
  onClickBookmarkButton,
  onClickFontLink,
  onSaveCurrentFontWeightInBoard,
}: Props) => {
  const { t } = useTranslation('common');
  const { isMobile } = useBreakpointContext();
  const { darkMode, isExtension, machineId } = useSettingsContext();

  const router = useRouter();
  const { isBot } = router.query;

  const [familyState, setFamilyState] = useState<API.Family>(family);
  const [fontPangram, setFontPangram] = useState<API.FontImage.Image | null>(null);

  const fontId = useMemo(() => family.idFont, [family.idFont]);
  const previousFontId = usePrevious(fontId);

  useEffect(() => {
    setFamilyState(family);
    if (forceLoading) {
      setFontPangram(null);
    }
  }, [family, forceLoading]);

  const { useGenerateFontImage } = useFontImage();

  // Font Pangram Image
  const fontPangramImageParameters: FontImageParameters = {
    text: t('card.pangram'),
    fontSize: isMobile ? 39 : 45,
    minFontSize: isMobile ? 19 : 23,
    maxFontSize: isMobile ? 58 : 68,
    maxWidth: isMobile ? MOBILE_CARD_WIDTH - 28 : DESKTOP_CARD_WIDTH - 56,
    maxHeight: isMobile ? 140 : 152,
    lineHeight: 1.1,
    maxLines: isMobile ? 3 : 4,
    wordWrap: true,
    vAlign: 'top',
    vTrim: true,
    hTrim: true,
    pixelTrim: true,
    backgroundColor: 'transparent',
    fillColor: GREY_6,
    strokeColor: GREY_6,
  };
  const { data: fontPangramData } = useGenerateFontImage(
    'font',
    familyState.idFont,
    fontPangramImageParameters,
    false,
    { enabled: familyState.authorized }
  );
  useEffect(() => {
    if (fontPangramData) {
      setFontPangram(fontPangramData);
    }
  }, [fontPangramData]);
  // Case fontId not change, so query not refetch, we reforce data
  useEffect(() => {
    if (fontPangramData && !forceLoading && fontId === previousFontId) {
      setFontPangram(fontPangramData);
    }
  }, [fontId, fontPangramData, forceLoading, previousFontId]);
  // Memo fontPangram SVG image
  const fontPangramSvg = useMemo(() => {
    // To force `darkMode` in useMemo Array
    const isDarkMode = darkMode;
    if (fontPangram) {
      return convertSvgStringToDataUrl(fontPangram.svg);
    }
    return null;
  }, [darkMode, fontPangram]);

  const checkIsLoadingFontImages = useCallback(() => {
    if (isBot === 'true') {
      return false;
    }
    if (!familyState.authorized) {
      return false;
    }
    return fontPangram ? false : true;
  }, [familyState.authorized, fontPangram, isBot]);

  const handleClickBookmarkButton = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClickBookmarkButton(familyState.idFont);
    },
    [familyState.idFont, onClickBookmarkButton]
  );

  const handleClickFontLink = useCallback(() => {
    if (onClickFontLink) {
      onClickFontLink(familyState.idFont);
    }
  }, [familyState.idFont, onClickFontLink]);

  const handleChangeFontId = useCallback(
    (fontId: number) => {
      const cloneFamilyState: API.Family = JSON.parse(JSON.stringify(familyState));
      cloneFamilyState.idFont = fontId;
      setFamilyState(cloneFamilyState);
    },
    [familyState]
  );

  const handleAfterChangeFontId = useCallback(
    (fontId: number) => {
      // Save current fontWeight if route is a "board" route
      if (router.route.indexOf(`${ROUTES.BOARD}/[boardQuery]`) > -1) {
        const boardSplitUrl = (router.query.boardQuery as string).split('-');
        const boardId = parseInt(boardSplitUrl[boardSplitUrl.length - 1]);
        if (onSaveCurrentFontWeightInBoard) {
          onSaveCurrentFontWeightInBoard(boardId, family.idFamily, fontId);
        }
      }
    },
    [family.idFamily, onSaveCurrentFontWeightInBoard, router.query.boardQuery, router.route]
  );

  const cardUrl = useMemo(() => {
    let url = family.fonts.find((font) => font.idFont === familyState.idFont)?.url ?? family.url;
    if (isExtension && machineId) {
      url += `?${MACHINE_ID}=${machineId}`;
    }
    return url;
  }, [family.fonts, family.url, familyState.idFont, isExtension, machineId]);

  return (
    <Card>
      <CardContainer scaleValue={scaleValue}>
        <Link
          href={cardUrl}
          prefetch={false}
          onClick={handleClickFontLink}
          {...(isExtension && { target: '_blank' })}
        >
          <CardWrapper darkMode={darkMode}>
            {checkIsLoadingFontImages() && (
              <CardLoader>
                <Spinner darkMode={darkMode} />
              </CardLoader>
            )}
            <ContentWrapper isLoadingFonts={checkIsLoadingFontImages()}>
              <Image
                src={family.fontInUse.screenshot}
                alt={family.fontInUse.url}
                fill={true}
              />
              <PangramWrapper
                className="pangram-hover"
                darkMode={darkMode}
              >
                {familyState.authorized && (
                  <RenderIfVisible
                    defaultHeight={0}
                    visibleOffset={400}
                  >
                    <PangramName>{`${family.name} — ${family.foundry.name}`}</PangramName>
                    <PangramImage
                      fontPangramWidth={fontPangram?.width}
                      fontPangramHeight={fontPangram?.height}
                    >
                      {fontPangramSvg && (
                        <Image
                          src={fontPangramSvg}
                          alt={t('card.pangram')}
                          fill={true}
                        />
                      )}
                    </PangramImage>
                  </RenderIfVisible>
                )}
                {!family.authorized && isBot === 'false' && (
                  <PangramName>{`${family.name} — ${family.foundry.name}`}</PangramName>
                )}
                <noscript>
                  <PangramName>{`${family.name} — ${family.foundry.name}`}</PangramName>
                  <PangramNoScript>{t('card.pangram')}</PangramNoScript>
                </noscript>
                {!familyState.authorized && isBot === 'false' && (
                  <FontUnauthorizedWrapper>
                    <FontUnauthorized
                      darkMode={darkMode}
                      width={isMobile ? 24 : 34}
                      height={isMobile ? 26 : 37}
                      shadowSize={isMobile ? 1 : 2}
                    />
                    <FontUnauthorizedText>{t('card.not-authorized')}</FontUnauthorizedText>
                  </FontUnauthorizedWrapper>
                )}
              </PangramWrapper>
              {!checkIsLoadingFontImages() && family.idFamily && (
                <MarkerContainer>
                  <Marker familyId={family.idFamily} />
                </MarkerContainer>
              )}
              {family.variable && <Variable darkMode={darkMode}>{t('card.variable')}</Variable>}
            </ContentWrapper>
          </CardWrapper>
        </Link>
      </CardContainer>
      {!checkIsLoadingFontImages() && (
        <HoverWrapper
          className="card-hover"
          scaleValue={scaleValue}
        >
          <ButtonsWrapper
            className="buttons-wrapper"
            scaleValue={scaleValue}
            isMobile={isMobile}
          >
            <ButtonWebsiteWrapper>
              <Button
                darkMode={darkMode}
                href={family.fontInUse.url}
                target="_blank"
                onClick={(event) => event.stopPropagation()}
              >
                <Icon
                  label="web"
                  color={darkMode ? GREY_6 : WHITE}
                />
              </Button>
            </ButtonWebsiteWrapper>
            <BookmarkButtonWithSlider
              familyId={family.idFamily}
              fontId={familyState.idFont}
              fonts={family.fonts}
              darkMode={darkMode}
              onClickBookmark={handleClickBookmarkButton}
              onChangeFontId={handleChangeFontId}
              onAfterChangeFontId={handleAfterChangeFontId}
            />
          </ButtonsWrapper>
        </HoverWrapper>
      )}
    </Card>
  );
};

export default FontInUse;
