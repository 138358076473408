import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DANGER_RED, GREY_1, GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface LabelProps {
  width?: string;
  darkMode?: boolean;
  error?: boolean;
}

interface InputProps {
  defaultType?: 'text' | 'email' | 'password';
  type?: 'text' | 'email' | 'password';
  width?: string;
  marginLeft?: boolean;
  darkMode?: boolean;
  iconLabel?: string;
  error?: boolean;
}

const BREAKPOINT_TABLET = 744;

const borderLightMode = `1px solid ${GREY_6}`;
const borderDarkMode = `1px solid ${GREY_2}`;
const borderRed = `1px solid ${DANGER_RED}`;

export const Label = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-style: normal;
  line-height: 16px;
  width: ${(props: LabelProps) => (props.width ? 'auto' : '100%')};
  color: ${(props: LabelProps) => (props.error ? DANGER_RED : props.darkMode ? WHITE : GREY_6)};
  /* MOBILE */
  font-size: 16px;
  /* TABLET */
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    font-size: 14px;
  }
`;

export const Input = styled.input`
  width: ${(props: InputProps) => props.width ?? '100%'};
  background-color: ${(props: InputProps) => (props.darkMode ? GREY_6 : WHITE)};
  border: ${(props: InputProps) => (props.error ? borderRed : props.darkMode ? borderDarkMode : borderLightMode)};
  border-radius: 8px;
  color: ${(props: InputProps) => (props.error ? DANGER_RED : props.darkMode ? WHITE : GREY_6)};
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-style: normal;
  line-height: 16px;
  margin-left: ${(props: InputProps) => props.marginLeft && '6px'};
  outline: none;
  box-sizing: border-box;
  /* MOBILE */
  height: 44px;
  font-size: 16px;
  padding: ${(props: InputProps) => (props.defaultType === 'text' ? '7px 14px 8px' : '7px 46px 8px 14px')};
  /* TABLET */
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    height: 36px;
    font-size: 14px;
    padding: ${(props: InputProps) => (props.defaultType === 'text' ? '7px 14px 8px' : '7px 40px 8px 14px')};
  }
  &::placeholder {
    color: ${GREY_1};
    opacity: 1;
  }

  // WITH ICON LABEL
  ${(props: InputProps) =>
    props.iconLabel &&
    css`
      /* MOBILE */
      padding: ${props.defaultType === 'text' ? '7px 14px 8px 38px' : '7px 46px 8px 38px'};
      /* TABLET */
      @media (min-width: ${BREAKPOINT_TABLET}px) {
        padding: ${props.defaultType === 'text' ? '7px 14px 8px 38px' : '7px 40px 8px 38px'};
      }
    `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 14px;
`;

export const ShowHidePasswordWrapper = styled.div`
  position: absolute;
  /* MOBILE */
  right: 4px;
  /* TABLET */
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    right: 0;
  }
`;

export const Error = styled.p`
  margin-top: 8px;
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${DANGER_RED};
`;
