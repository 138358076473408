import {
  Fragment,
  KeyboardEvent as ReactKeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Link from 'next/link';
import { InfiniteData } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IconButton } from 'design-system/atoms/icon-button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { trackFoundryClicked, trackSearchOpened, trackUserClicked } from 'tracking/Mixpanel';
import { oTelCountPage } from 'tracking/OpenTelemetry';
import { useAuthContext, useBreakpointContext, useSearchContext, useSettingsContext } from 'context';
import { useDebounce, usePrevious } from 'hooks';
import { useSuggestions } from 'hooks/queries/useSuggestions';
import { useFamilySearch } from 'hooks/queries/useFamilySearch';
import Card from 'containers/Card';
import Spinner from 'components/Spinner';
import Avatar from 'components/Avatar';
import SmileySad from 'components/SmileySad';
import { Filters } from 'api/familySearch.api';
import { initialCurrency, initialPriceRange } from 'common/data/Settings';
import { getPageNameWithRoutePathname, transformStringToUrl } from 'common/utils';
import {
  BIG_DESKTOP_GUTTER,
  DESKTOP,
  DESKTOP_1200,
  DESKTOP_CARD_GAP,
  DESKTOP_CARD_WIDTH,
  DESKTOP_GUTTER,
  MOBILE,
  MOBILE_CARD_WIDTH,
  MOBILE_GUTTER,
  PAGE_SIZE,
  ROUTES,
  SCROLL_THRESHOLD,
  TABLET,
  TABLET_CARD_GAP,
  TABLET_CARD_WIDTH,
  TABLET_GUTTER,
} from 'common/data/Constants';
import {
  ButtonCloseContainer,
  ButtonCloseWrapper,
  EmptyFamilyListText,
  EmptyFamilyListWrapper,
  FamilyListLoading,
  FamilyListWrapper,
  FoundriesList,
  FoundriesTitle,
  FoundriesUsersWrapper,
  FoundriesWrapper,
  FoundryName,
  FoundryNoResult,
  LoadingWrapper,
  SearchInput,
  SearchInputWrapper,
  SearchScreenContainer,
  SearchScreenWrapper,
  UserItem,
  UserItemWrapper,
  UserName,
  UserNoResult,
  UsersList,
  UsersTitle,
  UsersWrapper,
} from './SearchScreen.styled';

interface SearchIconProps {
  darkMode?: boolean;
}

const SearchIcon = ({ darkMode }: SearchIconProps) => {
  return (
    <svg
      className="search-icon"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4995 25.5602C25.3209 23.0862 28.0344 16.3616 25.5604 10.5403C23.0864 4.71895 16.3617 2.00542 10.5404 4.47942C4.7191 6.95341 2.00557 13.6781 4.47957 19.4994C6.95356 25.3207 13.6782 28.0342 19.4995 25.5602Z"
        stroke={darkMode ? WHITE : GREY_6}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1176 23.1177L32 32.0014"
        stroke={darkMode ? WHITE : GREY_6}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SearchScreen = () => {
  const router = useRouter();

  const { t } = useTranslation('common');
  const { preferredCurrency } = useAuthContext();
  const { darkMode } = useSettingsContext();
  const { breakpoint, isMobile } = useBreakpointContext();
  const { openSearch, setOpenSearch } = useSearchContext();

  const previousOpenSearch = usePrevious(openSearch);

  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [suggestionsState, setSuggestionState] = useState<API.Suggestions.ResultSuggestionsSearch | undefined>(
    undefined
  );
  const [familySearchState, setFamilySearchState] = useState<
    InfiniteData<API.FamilySearch.ResultFamilySearch> | undefined
  >(undefined);
  const [scaleCardValue, setScaleCardValue] = useState<number>(1);
  const [forceLoadingCard, setForceLoadingCard] = useState<boolean>(false);

  const searchScreenRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLTextAreaElement>(null);
  const familyListWrapperRef = useRef<HTMLDivElement>(null);

  const selectedCurrency = useMemo(() => preferredCurrency ?? initialCurrency, [preferredCurrency]);

  const debouncedSearchValue = useDebounce(searchValue, 250);
  const filters: Filters = useMemo(
    () => [
      { type: 'family', value: debouncedSearchValue },
      { type: 'price', value: { min: initialPriceRange[0], max: initialPriceRange[1], currency: selectedCurrency } },
    ],
    [debouncedSearchValue, selectedCurrency]
  );
  const previousFilters = usePrevious(filters);

  const {
    data: suggestionsData,
    isFetching: suggestionsFetching,
    isFetched: suggestionsFetched,
  } = useSuggestions(debouncedSearchValue, { enabled: openSearch && debouncedSearchValue.trim().length > 0 });

  useEffect(() => {
    if (searchValue.trim().length === 0) {
      setSuggestionState(undefined);
    } else if (suggestionsData) {
      setSuggestionState(suggestionsData);
    }
  }, [searchValue, suggestionsData]);

  const suggestionsMemo = useMemo(() => suggestionsState ?? suggestionsData, [suggestionsData, suggestionsState]);

  const {
    data: familySearchData,
    isStale: familySearchStale,
    isFetching: familySearchFetching,
    isFetched: familySearchFetched,
    isSuccess: familySearchSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useFamilySearch(PAGE_SIZE, filters, undefined, undefined, undefined, {
    enabled: openSearch && debouncedSearchValue.trim().length > 0,
  });

  useEffect(() => {
    if (
      suggestionsFetching ||
      familySearchFetching ||
      (debouncedSearchValue.trim().length > 0 && !suggestionsFetched && !familySearchFetched)
    ) {
      setIsLoading(true);
    } else if ((suggestionsFetched && familySearchFetched) || searchValue.trim().length === 0) {
      setIsLoading(false);
    }
  }, [
    debouncedSearchValue,
    familySearchFetched,
    familySearchFetching,
    searchValue,
    suggestionsFetched,
    suggestionsFetching,
  ]);

  useEffect(() => {
    if (familySearchSuccess && forceLoadingCard) {
      setForceLoadingCard(false);
    }
  }, [familySearchSuccess, forceLoadingCard]);

  useEffect(() => {
    if (searchValue.trim().length === 0) {
      setFamilySearchState(undefined);
    } else if (familySearchData) {
      setFamilySearchState(familySearchData);
    }
  }, [familySearchData, searchValue]);

  const familySearchList = useMemo(() => familySearchState ?? familySearchData, [familySearchData, familySearchState]);

  if (previousFilters && JSON.stringify(filters) !== JSON.stringify(previousFilters) && familyListWrapperRef.current) {
    if (familySearchStale && !forceLoadingCard) {
      setForceLoadingCard(true);
    }
  }

  const highlightText = useCallback(
    (text: string) => {
      if (debouncedSearchValue === '') return text;
      const highlightedText = new RegExp(`(${debouncedSearchValue})`, 'gi');
      return text.replace(highlightedText, `<span>$1</span>`);
    },
    [debouncedSearchValue]
  );

  const handleClickFoundryLink = useCallback((foundryName: string) => {
    // Track Mixpanel foundry clicked
    trackFoundryClicked('search', foundryName);
  }, []);

  const handleClickUserLink = useCallback(() => {
    // Track Mixpanel user clicked
    trackUserClicked('search');
  }, []);

  const handleClose = useCallback(() => {
    setOpenSearch(false);
  }, [setOpenSearch]);

  // Resize cards
  const handleResize = useCallback(() => {
    if (familyListWrapperRef.current) {
      let contentWidth: number;
      let maxContentWidth: number;
      let scaleValue: number = 1;
      if (breakpoint === MOBILE) {
        contentWidth = window.innerWidth - 2 * MOBILE_GUTTER;
        maxContentWidth = MOBILE_CARD_WIDTH;
        scaleValue = contentWidth / maxContentWidth;
      } else if (breakpoint === TABLET) {
        contentWidth = window.innerWidth - 2 * TABLET_GUTTER;
        maxContentWidth = 2 * TABLET_CARD_WIDTH + TABLET_CARD_GAP;
        scaleValue = contentWidth / maxContentWidth;
      } else if (breakpoint === DESKTOP) {
        contentWidth = window.innerWidth - 2 * DESKTOP_GUTTER;
        maxContentWidth = 3 * DESKTOP_CARD_WIDTH + 2 * DESKTOP_CARD_GAP;
        scaleValue = contentWidth / maxContentWidth;
      } else if (breakpoint === DESKTOP_1200) {
        contentWidth = window.innerWidth - 2 * BIG_DESKTOP_GUTTER;
        maxContentWidth = 3 * DESKTOP_CARD_WIDTH + 2 * DESKTOP_CARD_GAP;
        scaleValue = contentWidth / maxContentWidth;
      }
      setScaleCardValue(scaleValue);
    }
  }, [breakpoint]);

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (
        openSearch &&
        (document.activeElement?.contains(searchScreenRef.current) ||
          document.activeElement?.contains(searchInputRef.current))
      ) {
        if (event.key === 'Escape') {
          handleClose();
        }
      }
    },
    [handleClose, openSearch]
  );

  const handleKeyDownSearchInput = useCallback((event: ReactKeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  const handleChangeSearchInput = useCallback(
    (event: SyntheticEvent<HTMLTextAreaElement>) => {
      const value = event.currentTarget.value;
      if (isMobile && searchInputRef.current) {
        if (value.length > 0) {
          searchInputRef.current.setAttribute('rows', '1');
        } else {
          searchInputRef.current.setAttribute('rows', '2');
        }
      }
      setSearchValue(value);
    },
    [isMobile]
  );

  // If route start change we close search screen
  const handleRouteChangeComplete = useCallback(() => {
    setOpenSearch(false);
  }, [setOpenSearch]);

  // Close search screen
  useEffect(() => {
    if (previousOpenSearch && !openSearch) {
      setSearchValue('');
      setFamilySearchState(undefined);
      setScaleCardValue(1);
      setForceLoadingCard(false);
    }
  }, [openSearch, previousOpenSearch]);

  useEffect(() => {
    if (suggestionsFetched && familySearchFetched) {
      handleResize();
    }
  }, [familySearchFetched, handleResize, suggestionsFetched]);

  // Call `fetchNextPage` if page height is too big and there is more results to display
  // Impossible to call `useFixScroll` here because InfiniteSroll is rendered after mount
  useEffect(() => {
    setTimeout(() => {
      if (
        familySearchFetched &&
        !isFetchingNextPage &&
        hasNextPage &&
        familyListWrapperRef.current &&
        window.innerHeight >=
          familyListWrapperRef.current.offsetTop + familyListWrapperRef.current.offsetHeight - SCROLL_THRESHOLD
      ) {
        fetchNextPage();
      }
    }, 0);
  }, [familySearchFetched, fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (openSearch) {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
      document.body.style.overflow = 'hidden';
      // Track Mixpanel search opened
      trackSearchOpened(getPageNameWithRoutePathname(router.pathname));
      // Count OpenTelemetry page view
      oTelCountPage('/search');
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      if (openSearch) {
        document.body.style.overflow = '';
      }
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp, handleResize, openSearch, router.pathname]);

  // Listen route start change
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [handleRouteChangeComplete, router.events]);

  if (openSearch) {
    return (
      <SearchScreenContainer
        ref={searchScreenRef}
        id="searchScreen"
        darkMode={darkMode}
        tabIndex={-1}
      >
        <SearchScreenWrapper>
          <SearchInputWrapper>
            {isLoading ? (
              <LoadingWrapper>
                <Spinner darkMode={darkMode} />
              </LoadingWrapper>
            ) : (
              <SearchIcon darkMode={darkMode} />
            )}
            <SearchInput
              ref={searchInputRef}
              placeholder={t('search.search-placeholder')}
              rows={isMobile ? 2 : 1}
              darkMode={darkMode}
              isEmpty={searchValue.length === 0 ? true : false}
              multiline={isMobile && searchValue.length === 0 ? true : false}
              autoComplete="off"
              autoCorrect="off"
              onKeyDown={handleKeyDownSearchInput}
              onChange={handleChangeSearchInput}
            />
          </SearchInputWrapper>

          {suggestionsMemo && familySearchList && (
            <>
              {suggestionsMemo && (suggestionsMemo.totalFoundries > 0 || suggestionsMemo.totalUsers > 0) && (
                // Foundries
                <FoundriesUsersWrapper darkMode={darkMode}>
                  <FoundriesWrapper darkMode={darkMode}>
                    <FoundriesTitle darkMode={darkMode}>{t('search.foundries')}</FoundriesTitle>
                    <FoundriesList darkMode={darkMode}>
                      {suggestionsMemo.foundries.length === 0 && (
                        <FoundryNoResult dangerouslySetInnerHTML={{ __html: t('search.foundries-no-result') }} />
                      )}
                      {suggestionsMemo.foundries.map((foundry, index) => (
                        <Link
                          key={`${foundry.id}_${index}`}
                          href={`${ROUTES.FOUNDRY}/${foundry.id}`}
                          prefetch={false}
                          onClick={() => handleClickFoundryLink(foundry.name)}
                        >
                          <FoundryName
                            dangerouslySetInnerHTML={{ __html: highlightText(foundry.name) }}
                            darkMode={darkMode}
                          />
                        </Link>
                      ))}
                    </FoundriesList>
                  </FoundriesWrapper>
                  {/* Users */}
                  <UsersWrapper>
                    <UsersTitle darkMode={darkMode}>{t('search.users')}</UsersTitle>
                    <UsersList>
                      {suggestionsMemo.users.length === 0 && (
                        <UserNoResult dangerouslySetInnerHTML={{ __html: t('search.users-no-result') }} />
                      )}
                      {suggestionsMemo.users.map((user, index) => (
                        <UserItemWrapper key={`${user.id}_${index}`}>
                          <UserItem darkMode={darkMode}>
                            <Link
                              href={`/${transformStringToUrl(user.name)}-${user.id}`}
                              prefetch={false}
                              onClick={handleClickUserLink}
                            >
                              <Avatar
                                width={24}
                                height={24}
                                border={1}
                                darkMode={darkMode}
                                src={user.avatarUrl}
                                userId={user.id}
                                userName={user.name}
                                withShadow={false}
                              />
                              <UserName
                                dangerouslySetInnerHTML={{ __html: highlightText(user.name) }}
                                darkMode={darkMode}
                              />
                            </Link>
                          </UserItem>
                        </UserItemWrapper>
                      ))}
                    </UsersList>
                  </UsersWrapper>
                </FoundriesUsersWrapper>
              )}

              <FamilyListWrapper
                ref={familyListWrapperRef}
                scaleValue={scaleCardValue}
              >
                {/* No results */}
                {familySearchList && familySearchList.pages[0].totalFamilies === 0 && (
                  <EmptyFamilyListWrapper>
                    <SmileySad darkMode={darkMode} />
                    {suggestionsMemo && (suggestionsMemo.totalFoundries > 0 || suggestionsMemo.totalUsers > 0) ? (
                      <EmptyFamilyListText
                        dangerouslySetInnerHTML={{
                          __html: t('search.fonts-no-result', { searchQuery: debouncedSearchValue }),
                        }}
                      />
                    ) : (
                      <EmptyFamilyListText
                        dangerouslySetInnerHTML={{
                          __html: t('search.global-no-result', { searchQuery: debouncedSearchValue }),
                        }}
                      />
                    )}
                  </EmptyFamilyListWrapper>
                )}
                {/* Results */}
                {familySearchList && familySearchList.pages[0].totalFamilies > 0 && (
                  <div id="scroll-items-container">
                    <InfiniteScroll
                      scrollThreshold={`${SCROLL_THRESHOLD}px`}
                      dataLength={familySearchList.pages.reduce((acc, page) => acc + page.families.length, 0)}
                      next={() => {
                        if (!isFetchingNextPage) {
                          fetchNextPage();
                        }
                      }}
                      hasMore={!!hasNextPage}
                      loader={null}
                      scrollableTarget="searchScreen"
                    >
                      {familySearchList.pages.map((page, pageIndex) => (
                        <Fragment key={pageIndex}>
                          {page.families.map((family, index) => (
                            <div key={`${pageIndex}_${index}`}>
                              <Card
                                index={pageIndex * PAGE_SIZE + index}
                                family={family}
                                page={pageIndex}
                                scaleValue={scaleCardValue}
                                source="search"
                                forceLoading={forceLoadingCard}
                              />
                            </div>
                          ))}
                        </Fragment>
                      ))}
                    </InfiniteScroll>
                    {familySearchFetching && familySearchList.pages[0].totalFamilies > PAGE_SIZE && (
                      <FamilyListLoading>
                        <Spinner darkMode={darkMode} />
                      </FamilyListLoading>
                    )}
                  </div>
                )}
              </FamilyListWrapper>
            </>
          )}
        </SearchScreenWrapper>
        <ButtonCloseContainer>
          <ButtonCloseWrapper>
            <IconButton
              iconLabel="close_big"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </ButtonCloseWrapper>
        </ButtonCloseContainer>
      </SearchScreenContainer>
    );
  }
  return null;
};

export default SearchScreen;
