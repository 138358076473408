import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import api from 'api';
import { QUERY_KEYS } from 'common/data/Constants';

interface Options {
  enabled: boolean;
}

interface FollowUnfollowFoundryProps {
  idFoundry: string;
}

interface FollowUnfollowUserProps {
  idUser: number;
}

export const useSocial = () => {
  // COUNT FOLLOWERS FOUNDRY
  const useCountFollowersByFoundry = (idFoundry: string) => {
    return useQuery([QUERY_KEYS.COUNT_FOLLOWERS_BY_FOUNDRY, { idFoundry }], () =>
      api.social.getCountFollowersByFoundry(idFoundry)
    );
  };

  // LIST FOLLOWERS FOUNDRY
  const useListFollowersByFoundry = (idFoundry: string, pageSize: number, options: Options = { enabled: true }) => {
    return useInfiniteQuery(
      [QUERY_KEYS.LIST_FOLLOWERS_BY_FOUNDRY, { idFoundry }],
      ({ pageParam = 1 }) => api.social.getListFollowersByFoundry(idFoundry, pageSize, pageParam),
      {
        getNextPageParam: (lastPage, allPages) =>
          Math.ceil(lastPage.totalUsers / pageSize) > allPages.length ? allPages.length + 1 : undefined,
        enabled: options.enabled,
      }
    );
  };

  // GET STATUS FOLLOWING FOUNDRY
  const useGetFollowFoundryStatus = (idFoundry: string, options: Options = { enabled: true }) => {
    return useQuery(
      [QUERY_KEYS.FOLLOW_FOUNDRY_STATUS, { idFoundry }],
      () => api.social.getFollowFoundryStatus(idFoundry),
      options
    );
  };

  // FOLLOW FOUNDRY
  const useFollowFoundry = (options?: {
    onMutate?: (variables: FollowUnfollowFoundryProps) => void;
    onSuccess?: (data: API.Social.FollowFoundry, variables: FollowUnfollowFoundryProps) => void;
    onError?: (err: AxiosError<API.Error>, variables: FollowUnfollowFoundryProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: FollowUnfollowFoundryProps) => api.social.followFoundry(variables.idFoundry),
      options
    );
  };

  // UNFOLLOW FOUNDRY
  const useUnfollowFoundry = (options?: {
    onMutate?: (variables: FollowUnfollowFoundryProps) => void;
    onSuccess?: (data: API.Social.FollowFoundry, variables: FollowUnfollowFoundryProps) => void;
    onError?: (err: AxiosError<API.Error>, variables: FollowUnfollowFoundryProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: FollowUnfollowFoundryProps) => api.social.unfollowFoundry(variables.idFoundry),
      options
    );
  };

  // COUNT FOLLOWERS USER
  const useCountFollowersByUser = (idUser: number) => {
    return useQuery([QUERY_KEYS.COUNT_FOLLOWERS_BY_USER, { idUser }], () => api.social.getCountFollowersByUser(idUser));
  };

  // LIST FOLLOWERS USER
  const useListFollowersByUser = (idUser: number, pageSize: number, options: Options = { enabled: true }) => {
    return useInfiniteQuery(
      [QUERY_KEYS.LIST_FOLLOWERS_BY_USER, { idUser }],
      ({ pageParam = 1 }) => api.social.getListFollowersByUser(idUser, pageSize, pageParam),
      {
        getNextPageParam: (lastPage, allPages) =>
          Math.ceil(lastPage.totalUsers / pageSize) > allPages.length ? allPages.length + 1 : undefined,
        enabled: options.enabled,
      }
    );
  };

  // COUNT FOLLOWING USER
  const useCountFollowingByUser = (idUser: number) => {
    return useQuery([QUERY_KEYS.COUNT_FOLLOWING_BY_USER, { idUser }], () => api.social.getCountFollowingByUser(idUser));
  };

  // LIST FOLLOWING USER
  const useListFollowingByUser = (idUser: number, pageSize: number, options: Options = { enabled: true }) => {
    return useInfiniteQuery(
      [QUERY_KEYS.LIST_FOLLOWING_BY_USER, { idUser }],
      ({ pageParam = 1 }) => api.social.getListFollowingByUser(idUser, pageSize, pageParam),
      {
        getNextPageParam: (lastPage, allPages) =>
          Math.ceil(lastPage.totalUsers / pageSize) > allPages.length ? allPages.length + 1 : undefined,
        enabled: options.enabled,
      }
    );
  };

  // LIST FOLLOWED FOUNDRIES BY USER
  const useListFollowedFoundriesByUser = (idUser: number, pageSize: number, options: Options = { enabled: true }) => {
    return useInfiniteQuery(
      [QUERY_KEYS.LIST_FOLLOWED_FOUNDRIES_BY_USER, { idUser }],
      ({ pageParam = 1 }) => api.social.getListFollowedFoundriesByUser(idUser, pageSize, pageParam),
      {
        getNextPageParam: (lastPage, allPages) =>
          Math.ceil(lastPage.totalFoundries / pageSize) > allPages.length ? allPages.length + 1 : undefined,
        enabled: options.enabled,
      }
    );
  };

  // GET STATUS FOLLOWING USER
  const useGetFollowUserStatus = (idUser: number, options: Options = { enabled: true }) => {
    return useQuery([QUERY_KEYS.FOLLOW_USER_STATUS, { idUser }], () => api.social.getFollowUserStatus(idUser), options);
  };

  // FOLLOW USER
  const useFollowUser = (options?: {
    onMutate?: (variables: FollowUnfollowUserProps) => void;
    onSuccess?: (data: API.Social.FollowUser, variables: FollowUnfollowUserProps) => void;
    onError?: (err: AxiosError<API.Error>, variables: FollowUnfollowUserProps, context: any) => void;
  }) => {
    return useMutation((variables: FollowUnfollowUserProps) => api.social.followUser(variables.idUser), options);
  };

  // UNFOLLOW USER
  const useUnfollowUser = (options?: {
    onMutate?: (variables: FollowUnfollowUserProps) => void;
    onSuccess?: (data: API.Social.FollowUser, variables: FollowUnfollowUserProps) => void;
    onError?: (err: AxiosError<API.Error>, variables: FollowUnfollowUserProps, context: any) => void;
  }) => {
    return useMutation((variables: FollowUnfollowUserProps) => api.social.unfollowUser(variables.idUser), options);
  };

  // SOCIAL SUMMARY FOR CURRENT USER
  const useSocialSummaryForCurrentUser = (options: Options = { enabled: true }) => {
    return useQuery([QUERY_KEYS.USER_SOCIAL_SUMMARY], () => api.social.getSocialSummaryForCurrentUser(), options);
  };

  return {
    useCountFollowersByFoundry,
    useListFollowersByFoundry,
    useGetFollowFoundryStatus,
    useFollowFoundry,
    useUnfollowFoundry,
    useCountFollowersByUser,
    useListFollowersByUser,
    useCountFollowingByUser,
    useListFollowingByUser,
    useListFollowedFoundriesByUser,
    useGetFollowUserStatus,
    useFollowUser,
    useUnfollowUser,
    useSocialSummaryForCurrentUser,
  };
};
