import { SyntheticEvent, useCallback, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useSearchContext, useSettingsContext } from 'context';
import NavPageAppearanceTooltip from 'containers/NavPageAppearanceTooltip';
import NavProfileDesktopArrow from 'containers/NavProfileDesktop/NavProfileDesktopArrow';
import {
  NavSearchPageAppearanceItem,
  NavSearchPageAppearanceWrapper,
  Tooltip,
  TooltipArrow,
  TooltipLabel,
} from './NavSearchPageAppearance.styled';

interface Props {
  burgerMenuOpen?: boolean;
}

const NavSearchPageAppearance = ({ burgerMenuOpen }: Props) => {
  const { t } = useTranslation('nav');
  const { displayNavPageAppearanceTooltip, setDisplayNavPageAppearanceTooltip, darkMode } = useSettingsContext();
  const { openSearch, setOpenSearch } = useSearchContext();

  const tooltipSearchRef = useRef<HTMLDivElement>(null);
  const tooltipPageAppearanceRef = useRef<HTMLDivElement>(null);

  const handleOverNavItem = useCallback((event: SyntheticEvent<HTMLDivElement>) => {
    if (event.currentTarget.classList.contains('nav-search') && tooltipSearchRef.current) {
      tooltipSearchRef.current.removeAttribute('style');
    }
    if (event.currentTarget.classList.contains('nav-page-appearance') && tooltipPageAppearanceRef.current) {
      tooltipPageAppearanceRef.current.removeAttribute('style');
    }
  }, []);

  const handleSearch = useCallback(() => {
    setOpenSearch(!openSearch);
    if (tooltipSearchRef.current) {
      tooltipSearchRef.current.style.display = 'none';
    }
  }, [openSearch, setOpenSearch]);

  const handleTogglePageAppearanceTooltip = useCallback(() => {
    setDisplayNavPageAppearanceTooltip(!displayNavPageAppearanceTooltip);
    if (tooltipPageAppearanceRef.current) {
      tooltipPageAppearanceRef.current.style.display = 'none';
    }
  }, [displayNavPageAppearanceTooltip, setDisplayNavPageAppearanceTooltip]);

  return (
    <NavSearchPageAppearanceWrapper burgerMenuOpen={burgerMenuOpen}>
      <NavSearchPageAppearanceItem
        className="nav-search"
        darkMode={darkMode}
        displayNavPageAppearanceTooltip={displayNavPageAppearanceTooltip}
        onMouseEnter={handleOverNavItem}
      >
        <IconButton
          aria-label={t('search')}
          iconLabel="search"
          buttonSize="40px"
          color={darkMode ? WHITE : GREY_6}
          onClick={handleSearch}
        />
        <Tooltip
          ref={tooltipSearchRef}
          className="tooltip-search"
          darkMode={darkMode}
        >
          <TooltipArrow>
            <NavProfileDesktopArrow darkMode={darkMode} />
          </TooltipArrow>
          <TooltipLabel darkMode={darkMode}>{t('search')}</TooltipLabel>
        </Tooltip>
      </NavSearchPageAppearanceItem>
      <NavSearchPageAppearanceItem
        id="navPageAppearanceButton"
        className="nav-page-appearance"
        darkMode={darkMode}
        displayNavPageAppearanceTooltip={displayNavPageAppearanceTooltip}
        onMouseEnter={handleOverNavItem}
      >
        <IconButton
          aria-label={t('page-appearance')}
          iconLabel="list_appearance"
          buttonSize="40px"
          color={darkMode ? WHITE : GREY_6}
          onClick={handleTogglePageAppearanceTooltip}
        />
        <Tooltip
          ref={tooltipPageAppearanceRef}
          className="tooltip-page-appearance"
          darkMode={darkMode}
        >
          <TooltipArrow>
            <NavProfileDesktopArrow darkMode={darkMode} />
          </TooltipArrow>
          <TooltipLabel darkMode={darkMode}>{t('page-appearance')}</TooltipLabel>
        </Tooltip>
        {displayNavPageAppearanceTooltip && <NavPageAppearanceTooltip />}
      </NavSearchPageAppearanceItem>
    </NavSearchPageAppearanceWrapper>
  );
};

export default NavSearchPageAppearance;
