import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import jwt from 'jsonwebtoken';
import api, { injectAccessTokenToHeaders, removeAccessTokenFromHeaders } from 'api';
import { AddBookmarkToBoardOptions } from 'hooks';
import { identifyActiveUser, resetActiveUser } from 'tracking/Mixpanel';
import { getItem, removeItem, setItem } from 'common/utils';
import { ACCESS_TOKEN, EXTENSION, REFRESH_TOKEN, X_AUTH_TOKEN } from 'common/data/Constants';

type FollowType = 'followers' | 'following' | 'followedFoundries' | 'FollowingFoundry';

export interface FollowNotLoggedIn {
  type: FollowType;
  id: string | number;
  count: number;
}

export interface AccessTokenData {
  id: number;
  name: string;
  email: string;
  avatarUrl: string;
  loginType: 'email' | 'google';
  preferredCurrency: string;
  superadmin: boolean;
}

export interface AuthContextData {
  authReady: boolean;
  isLoggedIn: boolean;
  accessToken: string | null;
  setAccessToken: (accessToken: string) => void;
  refreshToken: string | null;
  setRefreshToken: (refreshToken: string) => void;
  userId: number | null;
  userName: string | null;
  email: string | null;
  avatar: string | null;
  loginType: 'email' | 'google' | null;
  preferredCurrency: string | null;
  superAdmin: boolean;
  bookmarkNotLoggedCacheData: AddBookmarkToBoardOptions | null;
  setBookmarkNotLoggedCacheData: (data: AddBookmarkToBoardOptions | null) => void;
  followFoundryIdNotLogged: string | null;
  setFollowFoundryIdNotLogged: (idFoundry: string | null) => void;
  followUserIdNotLogged: number | null;
  setFollowUserIdNotLogged: (iduser: number | null) => void;
  openModalFollowNotLogged: FollowNotLoggedIn | null;
  setOpenModalFollowNotLogged: (data: FollowNotLoggedIn | null) => void;
  setTokens: (data: API.Auth.Tokens) => void;
  setAuthToken: (authToken: string) => void;
  checkAccessToken: (accessToken: string) => void;
  logout: () => void;
}

export const useAuthState = (data: AuthContextData): AuthContextData => {
  const queryClient = useQueryClient();
  const router = useRouter();

  const [authReady, setAuthReady] = useState<boolean>(data.authReady);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(data.isLoggedIn);
  const [accessToken, setAccessToken] = useState<string | null>(data.accessToken);
  const [refreshToken, setRefreshToken] = useState<string | null>(data.refreshToken);
  const [userId, setUserId] = useState<number | null>(data.userId);
  const [userName, setUserName] = useState<string | null>(data.userName);
  const [email, setEmail] = useState<string | null>(data.email);
  const [avatar, setAvatar] = useState<string | null>(data.avatar);
  const [loginType, setLoginType] = useState<'email' | 'google' | null>(data.loginType);
  const [preferredCurrency, setPreferredCurrency] = useState<string | null>(data.preferredCurrency);
  const [superAdmin, setSuperAdmin] = useState<boolean>(data.superAdmin);
  const [bookmarkNotLoggedCacheData, setBookmarkNotLoggedCacheData] = useState<AddBookmarkToBoardOptions | null>(
    data.bookmarkNotLoggedCacheData
  );
  const [followFoundryIdNotLogged, setFollowFoundryIdNotLogged] = useState<string | null>(
    data.followFoundryIdNotLogged
  );
  const [followUserIdNotLogged, setFollowUserIdNotLogged] = useState<number | null>(data.followUserIdNotLogged);
  const [openModalFollowNotLogged, setOpenModalFollowNotLogged] = useState<FollowNotLoggedIn | null>(
    data.openModalFollowNotLogged
  );

  const setTokens = useCallback((data: API.Auth.Tokens) => {
    // Inject accessToken in Axios headers
    injectAccessTokenToHeaders(data.accessToken);
    // Store tokens data
    setAuthReady(true);
    setIsLoggedIn(true);
    setAccessToken(data.accessToken);
    setRefreshToken(data.refreshToken);
    // Parse accessToken
    const accessTokenData = jwt.decode(data.accessToken) as unknown as AccessTokenData;
    setUserId(accessTokenData.id);
    setUserName(accessTokenData.name);
    setEmail(accessTokenData.email);
    setAvatar(accessTokenData.avatarUrl !== '' ? accessTokenData.avatarUrl : null);
    setLoginType(accessTokenData.loginType);
    setPreferredCurrency(accessTokenData.preferredCurrency);
    setSuperAdmin(accessTokenData.superadmin);
    // Save into local storage
    setItem(ACCESS_TOKEN, data.accessToken);
    setItem(REFRESH_TOKEN, data.refreshToken);
    // Mixpanel: Identify active user
    identifyActiveUser(accessTokenData.id);
  }, []);

  const setAuthToken = useCallback((authToken: string) => {
    // Inject accessToken in Axios headers
    injectAccessTokenToHeaders(authToken);
    // Store tokens data
    setAccessToken(authToken);
    // Parse accessToken
    const accessTokenData = jwt.decode(authToken) as unknown as AccessTokenData;
    setUserId(accessTokenData.id);
    setUserName(accessTokenData.name);
    setEmail(accessTokenData.email);
    setAvatar(accessTokenData.avatarUrl !== '' ? accessTokenData.avatarUrl : null);
    setLoginType(accessTokenData.loginType);
    setPreferredCurrency(accessTokenData.preferredCurrency);
    setSuperAdmin(accessTokenData.superadmin);
    // Save into local storage
    setItem(ACCESS_TOKEN, authToken);
  }, []);

  const logout = useCallback(() => {
    // Clear all react queries cache
    queryClient.clear();
    // Remove accessToken in Axios headers
    removeAccessTokenFromHeaders();
    // re-init default values
    setIsLoggedIn(false);
    setAccessToken(null);
    setRefreshToken(null);
    setUserId(null);
    setUserName(null);
    setEmail(null);
    setAvatar(null);
    setLoginType(null);
    setPreferredCurrency(null);
    setSuperAdmin(false);
    // Remove local storage
    removeItem(ACCESS_TOKEN);
    removeItem(REFRESH_TOKEN);
    // Mixpanel: Reset active user
    resetActiveUser();
  }, [queryClient]);

  const checkAccessToken = useCallback((token: string) => {
    api.authentication
      .checkToken(token)
      .then((response) => {
        setAuthReady(true);
        setIsLoggedIn(true);
        // Parse accessToken
        const accessTokenData = jwt.decode(token) as unknown as AccessTokenData;
        setUserId(accessTokenData.id);
        setUserName(accessTokenData.name);
        setEmail(accessTokenData.email);
        setAvatar(accessTokenData.avatarUrl !== '' ? accessTokenData.avatarUrl : null);
        setLoginType(accessTokenData.loginType);
        setPreferredCurrency(accessTokenData.preferredCurrency);
        setSuperAdmin(accessTokenData.superadmin);
        // Mixpanel: Identify active user
        identifyActiveUser(accessTokenData.id);
      })
      .catch(() => {});
  }, []);

  const resetTokens = useCallback(
    (token: string, axiosConfigToRetry: AxiosRequestConfig<any>) => {
      api.authentication
        .refreshToken(token)
        .then((response) => {
          setTokens(response.data);
          // Update 'Authorization' header to send
          axiosConfigToRetry.headers!['Authorization'] = `Bearer ${response.data.accessToken}`;
          // Retry failed request
          axios(axiosConfigToRetry);
        })
        .catch((error: AxiosError<API.Error>) => {
          setAuthReady(true);
          // Logout user
          setIsLoggedIn(false);
          setAccessToken(null);
          setRefreshToken(null);
          setUserId(null);
          setUserName(null);
          setEmail(null);
          setAvatar(null);
          setLoginType(null);
          setPreferredCurrency(null);
          setSuperAdmin(false);
          // Remove local storage
          removeItem(ACCESS_TOKEN);
          removeItem(REFRESH_TOKEN);
        });
    },
    [setTokens]
  );

  // Axios interceptors (/!\ Should be the first useEffect to be call)
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        const token = response.headers[X_AUTH_TOKEN];
        if (token) {
          // Inject accessToken in Axios headers
          injectAccessTokenToHeaders(token);
          // Store accessToken
          setAccessToken(token);
          // Save into local storage
          setItem(ACCESS_TOKEN, token);
        }
        return response;
      },
      (error: AxiosError<API.Error>) => {
        const rToken = getItem(REFRESH_TOKEN);
        if (
          error.response?.status === 401 &&
          (error.response?.data.code === 40100 || error.response?.data.code === 40101) &&
          error.config &&
          rToken
        ) {
          resetTokens(rToken, error.config);
        }
        return Promise.reject(error);
      }
    );
  }, [resetTokens]);

  useEffect(() => {
    const isExtension = router.query.hasOwnProperty(EXTENSION);
    if (!isExtension) {
      // Site not displayed inside extension
      // We try to retrieve tokens
      const aToken = getItem(ACCESS_TOKEN);
      const rToken = getItem(REFRESH_TOKEN);
      if (aToken && rToken) {
        setAccessToken(aToken);
        setRefreshToken(rToken);
        checkAccessToken(aToken);
      } else {
        setAuthReady(true);
      }
    } else {
      // Site displayed inside extension
      // We don't want to retrieve tokens inside localStorage
      // Extension will send tokens if exists
      setAuthReady(true);
    }
  }, [checkAccessToken, router.query]);

  return {
    authReady,
    isLoggedIn,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    userId,
    userName,
    email,
    avatar,
    loginType,
    preferredCurrency,
    superAdmin,
    bookmarkNotLoggedCacheData,
    setBookmarkNotLoggedCacheData,
    followFoundryIdNotLogged,
    setFollowFoundryIdNotLogged,
    followUserIdNotLogged,
    setFollowUserIdNotLogged,
    openModalFollowNotLogged,
    setOpenModalFollowNotLogged,
    setTokens,
    setAuthToken,
    checkAccessToken,
    logout,
  };
};
