type PasswordCheckObject = {
  hasCorrectLength: boolean;
  hasSpecialCharacter: boolean;
  hasUpperLowerInt: boolean;
};

const passWordChecker = (value: string): PasswordCheckObject => ({
  hasCorrectLength: value.length >= 8,
  hasSpecialCharacter: new RegExp('[@#$%^&+=\\-_!?.,<>[\\]\'";:/|(){}*]').test(value),
  hasUpperLowerInt: new RegExp('^.*(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]).*$').test(value),
});

export const isValidPassword = (value: string): boolean => {
  const checks = passWordChecker(value);

  return Object.values(checks).every((isChecked) => isChecked === true);
};
