import { useCallback, useEffect, useState } from 'react';
import { DESKTOP, DESKTOP_1200, DESKTOP_1440, MOBILE, TABLET } from 'common/data/Constants';

export interface BreakpointContextData {
  breakpointsReady: boolean;
  breakpoint: string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isDesktop1200: boolean;
  isDesktop1440: boolean;
}

export const useBreakpointState = (data: BreakpointContextData): BreakpointContextData => {
  const [breakpointsReady, setBreakpointsReady] = useState<boolean>(data.breakpointsReady);
  const [breakpoint, setBreakpoint] = useState<string>(data.breakpoint);
  const [isMobile, setIsMobile] = useState<boolean>(data.isMobile);
  const [isTablet, setIsTablet] = useState<boolean>(data.isTablet);
  const [isDesktop, setIsDesktop] = useState<boolean>(data.isDesktop);
  const [isDesktop1200, setIsDesktop1200] = useState<boolean>(data.isDesktop1200);
  const [isDesktop1440, setIsDesktop1440] = useState<boolean>(data.isDesktop1440);

  const handleResize = useCallback(() => {
    const bodyEl = document.querySelector('body');
    const breakpointValue = window
      .getComputedStyle(bodyEl!, ':before')
      .getPropertyValue('content')
      .replace(/\"/g, '')
      .replace(/\'/g, '');
    setBreakpoint(breakpointValue);
    switch (breakpointValue) {
      case MOBILE:
        setIsMobile(true);
        setIsTablet(false);
        setIsDesktop(false);
        setIsDesktop1200(false);
        setIsDesktop1440(false);
        break;
      case TABLET:
        setIsMobile(false);
        setIsTablet(true);
        setIsDesktop(false);
        setIsDesktop1200(false);
        setIsDesktop1440(false);
        break;
      case DESKTOP:
        setIsMobile(false);
        setIsTablet(false);
        setIsDesktop(true);
        setIsDesktop1200(false);
        setIsDesktop1440(false);
        break;
      case DESKTOP_1200:
        setIsMobile(false);
        setIsTablet(false);
        setIsDesktop(false);
        setIsDesktop1200(true);
        setIsDesktop1440(false);
        break;
      case DESKTOP_1440:
        setIsMobile(false);
        setIsTablet(false);
        setIsDesktop(false);
        setIsDesktop1200(false);
        setIsDesktop1440(true);
        break;
    }
    if (!breakpointsReady) {
      setBreakpointsReady(true);
    }
  }, [breakpointsReady]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return {
    breakpointsReady,
    breakpoint,
    isMobile,
    isTablet,
    isDesktop,
    isDesktop1200,
    isDesktop1440,
  };
};
