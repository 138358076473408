import mixpanel from 'mixpanel-browser';
import { getPlatform } from 'common/utils';
import {
  ABOUT_US_PAGE,
  BOARD_PAGE,
  BOOKMARKS_PAGE,
  EXTENSION_DEMO_PAGE,
  FONT_PAGE,
  FOUNDRY_PAGE,
  HOME_PAGE,
  MIXPANEL,
  NOT_FOUND_PAGE,
  OVERVIEW_PAGE,
  PROFILE_PAGE,
  TOOLS_PAGE,
  USER_PAGE,
} from 'common/data/Constants';

export type ConnectionType = 'email' | 'google_connect';

export type Platform = 'web-app' | 'extension';

export type BoardType = 'public' | 'private';

export type Owner = 'own' | 'other';

export type FontFilter =
  | 'category'
  | 'min-styles'
  | 'contrast'
  | 'width'
  | 'aspect'
  | 'ending'
  | 'variable'
  | 'trial'
  | 'price'
  | 'currency'
  | 'licensing-type'
  | 'monospace';

export type ListStyleFilter = 'display-mode' | 'displayed-weight';

export const initMixpanel = (): void => {
  const token =
    process.env.NODE_ENV === 'development'
      ? MIXPANEL.development
      : process.env.API_ENV === 'preprod'
      ? MIXPANEL.preprod
      : MIXPANEL.production;
  const debug = process.env.NODE_ENV === 'development' ? true : false;

  mixpanel.init(token, {
    cookie_expiration: 365,
    cross_site_cookie: true,
    cross_subdomain_cookie: true,
    opt_out_tracking_cookie_prefix: '__mp_opt_in_out_',
    opt_out_tracking_persistence_type: 'cookie',
    opt_out_tracking_by_default: true,
    persistence: 'cookie',
    debug,
  });
};

/** ALLOW MIXPANEL TRACKING */
export const optInTracking = (): void => {
  mixpanel.opt_in_tracking();
};

/** NOT ALLOW MIXPANEL TRACKING */
export const optOutTracking = (): void => {
  mixpanel.opt_out_tracking({
    delete_user: true,
  });
};

/** IDENTIFY ACTIVE USER */
export const identifyActiveUser = (userId: number): void => {
  mixpanel.identify(userId.toString());
};

/** RESET ACTIVE USER */
export const resetActiveUser = (): void => {
  mixpanel.reset();
};

/** HOME */
export const trackHomePage = (pageSearch: number): void => {
  mixpanel.track('Page viewed', {
    'Page name': HOME_PAGE,
    Scroll: pageSearch,
    Platform: getPlatform(),
  });
};

/** SINGLE PAGE FONT */
export const trackSinglePageFont = (fontName: string): void => {
  mixpanel.track('Page viewed', {
    'Page name': FONT_PAGE,
    Font: fontName,
    Platform: getPlatform(),
  });
};

/** FOUNDRY */
export const trackFoundryPage = (foundryName: string): void => {
  mixpanel.track('Page viewed', {
    'Page name': FOUNDRY_PAGE,
    Foundry: foundryName,
    Platform: getPlatform(),
  });
};

/** USER */
export const trackUserPage = (userId: number, owner: Owner): void => {
  mixpanel.track('Page viewed', {
    'Page name': USER_PAGE,
    'User ID': userId,
    Owner: owner,
    Platform: getPlatform(),
  });
};

/** BOOKMARKS */
export const trackBookmarksPage = (isLoggedIn: boolean): void => {
  mixpanel.track('Page viewed', {
    'Page name': BOOKMARKS_PAGE,
    'Logged in': isLoggedIn,
    Platform: getPlatform(),
  });
};

/** BOARD */
export const trackBoardPage = (type: BoardType, owner: Owner): void => {
  mixpanel.track('Page viewed', {
    'Page name': BOARD_PAGE,
    Type: type,
    Owner: owner,
    Platform: getPlatform(),
  });
};

/** PROFILE */
export const trackProfilePage = (): void => {
  mixpanel.track('Page viewed', {
    'Page name': PROFILE_PAGE,
    Platform: getPlatform(),
  });
};

/** ABOUT-US */
export const trackAboutUsPage = (): void => {
  mixpanel.track('Page viewed', {
    'Page name': ABOUT_US_PAGE,
    Platform: getPlatform(),
  });
};

/** TOOLS */
export const trackToolsPage = (): void => {
  mixpanel.track('Page viewed', {
    'Page name': TOOLS_PAGE,
    Platform: getPlatform(),
  });
};

/** EXTENSION-DEMO */
export const trackExtensionDemoPage = (numSlide: number): void => {
  mixpanel.track('Page viewed', {
    'Page name': EXTENSION_DEMO_PAGE,
    Slide: numSlide,
    Platform: getPlatform(),
  });
};

/** OVERVIEW */
export const trackOverviewPage = (): void => {
  mixpanel.track('Page viewed', {
    'Page name': OVERVIEW_PAGE,
    Platform: getPlatform(),
  });
};

/** PAGE 404 */
export const trackPage404 = (): void => {
  mixpanel.track('Page viewed', {
    'Page name': NOT_FOUND_PAGE,
    Platform: getPlatform(),
  });
};

/** SIGN-UP STARTED */
export const trackSignupStarted = (pageName: string): void => {
  mixpanel.track('Signup started', {
    'Entry point': pageName,
    Platform: getPlatform(),
  });
};

/** SIGN-UP COMPLETED */
export const trackSignupCompleted = (
  connectionType: ConnectionType,
  email: string,
  createdAt: string,
  userId: number
): void => {
  mixpanel.track('Signup completed', {
    'Connexion type': connectionType,
    $email: email,
    'Created at': createdAt,
    'User ID': userId,
    Platform: getPlatform(),
  });
  mixpanel.people.set({
    $email: email,
    'Signup platform': getPlatform(),
  });
  mixpanel.people.set_once({
    'Created at': createdAt,
  });
};

/** LOGIN STARTED */
export const trackLoginStarted = (pageName: string): void => {
  mixpanel.track('Login started', {
    'Entry point': pageName,
    Platform: getPlatform(),
  });
};

/** LOGIN COMPLETED */
export const trackLoginCompleted = (connectionType: ConnectionType): void => {
  mixpanel.track('Login completed', {
    'Connexion type': connectionType,
    Platform: getPlatform(),
  });
};

/** USER UPDATE EMAIL */
export const trackUpdateUserEmail = (email: string): void => {
  mixpanel.people.set({
    $email: email,
  });
};

/** LOGGED-OUT */
export const trackLoggedOut = (): void => {
  mixpanel.track('Logged out', {
    Platform: getPlatform(),
  });
};

/** ACCOUNT DELETED */
export const trackAccountDeleted = (): void => {
  mixpanel.track('Account deleted', {
    Platform: getPlatform(),
  });
  mixpanel.people.delete_user();
};

/** BOARD CREATED */
export const trackBoardCreated = (type: BoardType): void => {
  mixpanel.track('Board created', {
    Type: type,
    Platform: getPlatform(),
  });
};

/** FONT BOOKMARKED */
export const trackFontBookmarked = (source: string, fontName: string, fontWeight: string): void => {
  mixpanel.track('Font bookmarked', {
    Source: source,
    'Font name': fontName,
    'Font weight': fontWeight,
    Platform: getPlatform(),
  });
};

/** FONT CLICKED */
export const trackFontClicked = (source: string, fontName: string, fontWeight: string): void => {
  mixpanel.track('Font clicked', {
    Source: source,
    'Font name': fontName,
    'Font weight': fontWeight,
    Platform: getPlatform(),
  });
};

/** FOUNDRY CLICKED */
export const trackFoundryClicked = (source: string, foundryName: string): void => {
  mixpanel.track('Foundry clicked', {
    Source: source,
    Foundry: foundryName,
    Platform: getPlatform(),
  });
};

/** USER CLICKED */
export const trackUserClicked = (source: string): void => {
  mixpanel.track('User clicked', {
    Source: source,
    Platform: getPlatform(),
  });
};

/** FONT FILTER CHANGED */
export const trackFontFilterChanged = (filter: FontFilter, value: unknown): void => {
  mixpanel.track('Font filter changed', {
    Filter: filter,
    Value: value,
    Platform: getPlatform(),
  });
};

/** LIST STYLE CHANGED */
export const trackListStyleChanged = (styleName: ListStyleFilter, value: unknown): void => {
  mixpanel.track('List style changed', {
    Style: styleName,
    Value: value,
    Platform: getPlatform(),
  });
};

/** SEARCH OPENED */
export const trackSearchOpened = (pageName: string): void => {
  mixpanel.track('Search opened', {
    'Entry point': pageName,
    Platform: getPlatform(),
  });
};

/** BUY FONT BUTTON CLICKED */
export const trackBuyButtonClicked = (
  fontName: string,
  fontWeight: string,
  price: number | null,
  priceCurrency: string,
  websiteCategory: string,
  foundryName: string
): void => {
  mixpanel.track('Buy button clicked', {
    'Font name': fontName,
    'Font weight': fontWeight,
    Price: price,
    'Price currency': priceCurrency,
    'Website category': websiteCategory,
    Foundry: foundryName,
    Platform: getPlatform(),
  });
};

/** TRIAL FONT BUTTON CLICKED */
export const trackTrialButtonClicked = (
  fontName: string,
  fontWeight: string,
  price: number | null,
  priceCurrency: string,
  websiteCategory: string,
  foundryName: string
): void => {
  mixpanel.track('Trial button clicked', {
    'Font name': fontName,
    'Font weight': fontWeight,
    Price: price,
    'Price currency': priceCurrency,
    'Website category': websiteCategory,
    Foundry: foundryName,
    Platform: getPlatform(),
  });
};

/** OPEN SOURCE FONT BUTTON CLICKED */
export const trackGetItButtonClicked = (
  fontName: string,
  fontWeight: string,
  websiteCategory: string,
  foundryName: string
): void => {
  mixpanel.track('Get it button clicked', {
    'Font name': fontName,
    'Font weight': fontWeight,
    'Website category': websiteCategory,
    Foundry: foundryName,
    Platform: getPlatform(),
  });
};
