import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_1, GREY_2, GREY_6, WHITE } from 'design-system/global/colors';
import { easeOutQuart } from 'common/easing/Easing';
import { DESKTOP_CARD_WIDTH, MOBILE_CARD_WIDTH, TABLET_CARD_WIDTH } from 'common/data/Constants';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  displayMode?: string;
  darkMode?: boolean;
  scaleValue?: number;
  isMobile?: boolean;
  fontNameWidth?: number;
  fontNameHeight?: number;
  fontFoundryWidth?: number;
  fontFoundryHeight?: number;
  fontPangramWidth?: number;
  fontPangramHeight?: number;
  isLoadingFonts?: boolean;
  authorized?: boolean;
}

const getBookmarkButtonWrapperPadding = (scaleValue: number, isMobile: boolean) => {
  if (isMobile) {
    return `0 ${Math.round(16 * scaleValue)}px 24px`;
  } else {
    return `0 ${Math.round(32 * scaleValue)}px 24px`;
  }
};

const MOBILE_PANGRAM_HEIGHT = 247;
const MOBILE_TYPEFACE_HEIGHT = 172;
const TABLET_CARD_HEIGHT = 298;
const DESKTOP_CARD_HEIGHT = 298;

export const Card = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    .card-footer {
      opacity: 0;
    }
    .bookmark-button-wrapper {
      transform: translateY(0);
    }
    .change-category-wrapper {
      transform: translateY(0);
    }
  }
`;

export const CardContainer = styled.div`
  transform-origin: top left;
  transform: scale(1);
  ${(props: Props) =>
    props.scaleValue &&
    css`
      /* MOBILE */
      transform: scale(${props.scaleValue});
      height: calc(
        ${props.displayMode === 'typeface name' ? MOBILE_TYPEFACE_HEIGHT : MOBILE_PANGRAM_HEIGHT}px *
          ${props.scaleValue}
      );
      /* TABLET */
      ${mq['tablet']} {
        width: calc(${TABLET_CARD_WIDTH}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        height: calc(${TABLET_CARD_HEIGHT}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        transform: scale(${props.scaleValue > 1 ? 1 : props.scaleValue});
      }
      /* DESKTOP */
      ${mq['desktop']} {
        width: calc(${DESKTOP_CARD_WIDTH}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        height: calc(${DESKTOP_CARD_HEIGHT}px * ${props.scaleValue > 1 ? 1 : props.scaleValue});
        transform: scale(${props.scaleValue > 1 ? 1 : props.scaleValue});
      }
    `}
`;

export const CardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: auto;
  border-radius: 8px;
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  /* MOBILE */
  width: ${MOBILE_CARD_WIDTH}px;
  height: ${(props: Props) =>
    props.displayMode === 'typeface name' ? `${MOBILE_TYPEFACE_HEIGHT}px` : `${MOBILE_PANGRAM_HEIGHT}px`};
  /* TABLET */
  ${mq['tablet']} {
    min-width: ${TABLET_CARD_WIDTH}px;
    width: auto;
    height: ${TABLET_CARD_HEIGHT}px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    min-width: ${DESKTOP_CARD_WIDTH}px;
    width: auto;
    height: ${DESKTOP_CARD_HEIGHT}px;
  }
`;

export const CardLoader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const TypefaceWrapper = styled.div`
  opacity: ${(props: Props) => (props.isLoadingFonts ? 0 : 1)};
  /* MOBILE */
  padding: 18px 25px 0 16px;
  /* TABLET */
  ${mq['tablet']} {
    padding: 32px 15px 0 32px;
  }
`;

export const TypefaceFontName = styled.div`
  position: relative;
  width: ${(props: Props) => (props.fontNameWidth ? `${props.fontNameWidth}px` : 'auto')};
  height: ${(props: Props) => (props.fontNameHeight ? `${props.fontNameHeight}px` : 'auto')};
  user-select: none;
  /* MOBILE */
  margin-bottom: 8px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 10px;
  }
`;

export const TypefaceFontNameUnauthorized = styled.p`
  color: ${GREY_1};
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* MOBILE */
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 2px;
  @supports (-webkit-line-clamp: 1) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  /* TABLET */
  ${mq['tablet']} {
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 8px;
    @supports (-webkit-line-clamp: 2) {
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export const TypefaceFontNameNoScript = styled.h3`
  font-weight: 700;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 8px;
  ${[mq['tablet']]} {
    font-size: 60px;
    line-height: 60px;
    margin-bottom: 10px;
  }
`;

export const TypefaceFoundryName = styled.div`
  position: relative;
  width: ${(props: Props) => (props.fontFoundryWidth ? `${props.fontFoundryWidth}px` : 'auto')};
  height: ${(props: Props) => (props.fontFoundryHeight ? `${props.fontFoundryHeight}px` : 'auto')};
  user-select: none;
`;

export const TypefaceFoundryNameUnauthorized = styled.p`
  font-size: 16px;
  line-height: normal;
`;

export const TypefaceFoundryNameNoScript = styled.p`
  font-size: 16px;
  line-height: 18px;
`;

export const Footer = styled.div`
  opacity: ${(props: Props) => (props.isLoadingFonts ? 0 : 1)};
  position: absolute;
  width: 100%;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.2s ${easeOutQuart};
  /* MOBILE */
  padding: 0 25px 16px 16px;
  /* TABLET */
  ${mq['tablet']} {
    padding: 0 15px 20px 32px;
  }
`;

export const PangramWrapper = styled.div`
  opacity: ${(props: Props) => (props.isLoadingFonts ? 0 : 1)};
  /* MOBILE */
  padding: 16px 17px 0 16px;
  /* TABLET */
  ${mq['tablet']} {
    padding: 24px 30px 0 32px;
  }
`;

export const PangramName = styled.p`
  font-size: 16px;
  line-height: 22px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* MOBILE */
  margin-bottom: 8px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 16px;
  }
`;

export const PangramImage = styled.div`
  position: relative;
  width: ${(props: Props) => (props.fontPangramWidth ? `${props.fontPangramWidth}px` : 'auto')};
  height: ${(props: Props) => (props.fontPangramHeight ? `${props.fontPangramHeight}px` : 'auto')};
  user-select: none;
`;

export const PangramNoScript = styled.p`
  font-weight: 700;
  font-size: 38px;
  line-height: 40px;
  ${mq['tablet']} {
    font-size: 45px;
    line-height: 50px;
  }
`;

export const FooterText = styled.p`
  display: inline;
  font-size: 12px;
  line-height: 14px;
  color: ${GREY_1};
`;

export const FontUnauthorizedWrapper = styled.div`
  display: flex;
  /* MOBILE */
  margin-bottom: 8px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 20px;
  }
`;

export const FontUnauthorizedText = styled.p`
  line-height: normal;
  /* MOBILE */
  font-size: 12px;
  width: 194px;
  margin-left: 8px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 16px;
    width: 262px;
    margin-left: 10px;
  }
`;

export const HoverWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  /* MOBILE */
  width: ${(props: Props) => (props.scaleValue ? `calc(100% * ${props.scaleValue})` : '100%')};
  max-width: 100%;
  /* TABLET */
  ${mq['tablet']} {
    width: 100%;
    max-width: none;
  }
`;

export const BookmarkButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  pointer-events: auto;
  padding: ${(props: Props) => getBookmarkButtonWrapperPadding(props.scaleValue ?? 1, props.isMobile ?? false)};
  transform: translateY(70px);
  transition: transform 0.2s ${easeOutQuart};
`;
