import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/bookmark`;

export const bookmarkCount = (): Promise<API.Bookmark.TotalBookmarks> => {
  return axios.get(`${baseUrl}/count`).then(({ data }) => data);
};

export const getBookmark = (idFamily: string): Promise<{ bookmark: API.Bookmark.Bookmark }> => {
  return axios.get(`${baseUrl}/${idFamily}`).then(({ data }) => {
    return data;
  });
};

export const addBookmark = (
  idFamily: string,
  idFont: number,
  idBoards?: Array<number>,
  idRequest?: number
): Promise<API.Bookmark.Bookmark> => {
  return axios.put(`${baseUrl}/${idFamily}`, { idBoards, idFont, idRequest }).then(({ data }) => {
    return data;
  });
};

export const deleteBookmark = (idFamily: string): Promise<API.Bookmark.DeleteBookmark> => {
  return axios.delete(`${baseUrl}/${idFamily}`).then(({ data }) => {
    return data;
  });
};

export const getBookmarkStatus = (idFamily: string): Promise<API.Bookmark.BookmarkStatus> => {
  return axios.get(`${baseUrl}/${idFamily}/isBookmarked`).then(({ data }) => {
    return data;
  });
};
