import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Button } from 'design-system/atoms/button';
import { useAuthContext } from 'context';
import { useBookmark } from 'hooks/queries/useBookmark';
import { ButtonWrapper } from './BookmarkButton.styled';

interface Props {
  familyId: string;
  darkMode: boolean;
  onClickBookmark: (Event: SyntheticEvent<HTMLButtonElement>) => void;
}

const NOT_BOOKMARKED: string = 'add_bookmark';
const BOOKMARKED: string = 'bookmarked';
const ADD_BOOKMARK: string = 'add_bookmark';
const REMOVE_BOOKMARK: string = 'remove_bookmark';

const BookmarkButton = ({ familyId, darkMode, onClickBookmark }: Props) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthContext();

  const [isTouch, setIsTouch] = useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const [iconState, setIconState] = useState<string>(isBookmarked ? BOOKMARKED : NOT_BOOKMARKED);

  const { useGetBookmarkStatus } = useBookmark();
  const { data: bookmarkDataStatus } = useGetBookmarkStatus(familyId, { enabled: isLoggedIn });
  useEffect(() => {
    setIsBookmarked(bookmarkDataStatus?.success ?? false);
    setIconState(bookmarkDataStatus?.success ? BOOKMARKED : NOT_BOOKMARKED);
  }, [bookmarkDataStatus?.success]);

  const handleTouchStart = useCallback(() => {
    setIsTouch(true);
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (!isTouch) {
      setIconState(isBookmarked ? REMOVE_BOOKMARK : ADD_BOOKMARK);
    }
  }, [isBookmarked, isTouch]);

  const handleMouseLeave = useCallback(() => {
    if (!isTouch) {
      setIconState(isBookmarked ? BOOKMARKED : NOT_BOOKMARKED);
    }
  }, [isBookmarked, isTouch]);

  const handleClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement | HTMLLinkElement>) => {
      setIsTouch(false);
      onClickBookmark(event as SyntheticEvent<HTMLButtonElement>);
    },
    [onClickBookmark]
  );

  return (
    <ButtonWrapper
      darkMode={darkMode}
      onTouchStart={handleTouchStart}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        iconLabel={iconState}
        darkMode={darkMode}
        onClick={handleClick}
      >
        {t('bookmark-button.bookmark')}
      </Button>
    </ButtonWrapper>
  );
};

export default BookmarkButton;
