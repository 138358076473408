import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import api from 'api';
import { QUERY_KEYS } from 'common/data/Constants';

interface Options {
  enabled: boolean;
}

interface InviteByEmailProps {
  idBoard: number;
  email: string;
}

interface InviteByIdUserProps {
  idBoard: number;
  idUser: number;
}

interface DeleteBoardEditorProps {
  idBoard: number;
  idUser: number;
}

interface ValidateBoardInvitationCodeProps {
  invitationCode: string;
}

export const useBoardEditors = () => {
  // LIST BOARD EDITORS SIMPLE
  const useBoardListEditorsSimple = (idBoard: number, pageSize: number, options: Options = { enabled: true }) => {
    return useQuery(
      [QUERY_KEYS.BOARD_EDITORS_LIST_SIMPLE, { idBoard }],
      () => api.boardEditors.getBoardListEditors(idBoard, pageSize, 1),
      options
    );
  };

  // LIST BOARD EDITORS
  const useBoardListEditors = (idBoard: number, pageSize: number, options: Options = { enabled: true }) => {
    return useInfiniteQuery(
      [QUERY_KEYS.BOARD_EDITORS_LIST, { idBoard }],
      ({ pageParam = 1 }) => api.boardEditors.getBoardListEditors(idBoard, pageSize, pageParam),
      {
        getNextPageParam: (lastPage, allPages) =>
          Math.ceil(lastPage.totalUsers / pageSize) > allPages.length ? allPages.length + 1 : undefined,
        enabled: options.enabled,
      }
    );
  };

  // INVITE BY EMAIL
  const useInviteEditorByEmail = (options?: {
    onMutate?: (variables: InviteByEmailProps) => void;
    onSuccess?: (data: API.BoardEditors.InviteByEmail, variables: InviteByEmailProps) => void;
    onError?: (err: AxiosError<API.Error>, variables: InviteByEmailProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: InviteByEmailProps) => api.boardEditors.inviteByEmail(variables.idBoard, variables.email),
      options
    );
  };

  // INVITE BY ID USER
  const useInviteEditorByIdUser = (options?: {
    onMutate?: (variables: InviteByIdUserProps) => void;
    onSuccess?: (data: API.BoardEditors.InviteByIdUser, variables: InviteByIdUserProps) => void;
    onError?: (err: AxiosError<API.Error>, variables: InviteByIdUserProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: InviteByIdUserProps) => api.boardEditors.inviteByIdUser(variables.idBoard, variables.idUser),
      options
    );
  };

  // REMOVE A BOARD EDITOR
  const useDeleteBoardEditor = (options?: {
    onMutate?: (variables: DeleteBoardEditorProps) => void;
    onSuccess?: (data: API.BoardEditors.DeleteBoardEditor, variables: DeleteBoardEditorProps) => void;
    onError?: (err: AxiosError<API.Error>, variables: DeleteBoardEditorProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: DeleteBoardEditorProps) => api.boardEditors.deleteBoardEditor(variables.idBoard, variables.idUser),
      options
    );
  };

  // GET BOARD INVITATION CODE
  const useGetBoardInvitationCode = (idBoard: number, options: Options = { enabled: true }) => {
    return useQuery(
      [QUERY_KEYS.BOARD_EDITORS_INVITATION_CODE, { idBoard }],
      () => api.boardEditors.getBoardInvitationCode(idBoard),
      options
    );
  };

  // VALIDATE BOARD INVITATION CODE
  const useValidateBoardInvitationCode = (options?: {
    onMutate?: (variables: ValidateBoardInvitationCodeProps) => void;
    onSuccess?: (data: API.BoardEditors.ValidateInvitationCode, variables: ValidateBoardInvitationCodeProps) => void;
    onError?: (err: AxiosError<API.Error>, variables: ValidateBoardInvitationCodeProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: ValidateBoardInvitationCodeProps) =>
        api.boardEditors.validateBoardInvitationCode(variables.invitationCode),
      options
    );
  };

  // SEARCH USERS TO INVITE
  const useSearchUsersToInvite = (idBoard: number, searchValue: string, options: Options = { enabled: true }) => {
    return useQuery(
      [QUERY_KEYS.BOARD_EDITORS_SEARCH_USERS, { idBoard, searchValue }],
      () => api.boardEditors.searchUsersToInvite(idBoard, searchValue),
      options
    );
  };

  return {
    useBoardListEditorsSimple,
    useBoardListEditors,
    useInviteEditorByEmail,
    useInviteEditorByIdUser,
    useDeleteBoardEditor,
    useValidateBoardInvitationCode,
    useGetBoardInvitationCode,
    useSearchUsersToInvite,
  };
};
