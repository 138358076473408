import { InfiniteData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import api from 'api';
import { Filters } from 'api/familySearch.api';
import { QUERY_KEYS } from 'common/data/Constants';

interface Options {
  enabled: boolean;
}

interface AddBookmarkProps {
  idFamily: string;
  idFont: number;
  idBoards?: Array<number>;
  idRequest?: number;
}

interface DeleteBookmarkProps {
  idFamily: string;
}

interface DeleteBookmarkFromBookmarkPageProps {
  idFamily: string;
  page: number;
  filters: Filters;
}

export const useBookmark = () => {
  // BOOKMARK COUNT
  const useBookmarkCount = (options: Options = { enabled: true }) => {
    return useQuery([QUERY_KEYS.BOOKMARK_COUNT], () => api.bookmark.bookmarkCount(), options);
  };

  // GET BOOKMARK DATA
  const useGetBookmark = (idFamily: string, options: Options = { enabled: true }) => {
    return useQuery([QUERY_KEYS.BOOKMARK, { idFamily }], () => api.bookmark.getBookmark(idFamily), options);
  };

  // ADD BOOKMARK
  const useAddBookmark = (options?: {
    onSuccess?: (data: API.Bookmark.Bookmark) => void;
    onError?: (err: AxiosError<API.Error>) => void;
  }) => {
    return useMutation(
      (variables: AddBookmarkProps) =>
        api.bookmark.addBookmark(variables.idFamily, variables.idFont, variables.idBoards, variables.idRequest),
      options
    );
  };

  // DELETE BOOKMARK (ALL PAGES EXCEPT BOOKMARK LOGGED-IN PAGE)
  const useDeleteBookmark = (options?: {
    onSuccess?: (data: API.Bookmark.DeleteBookmark) => void;
    onError?: (err: AxiosError<API.Error>) => void;
  }) => {
    return useMutation((variables: DeleteBookmarkProps) => api.bookmark.deleteBookmark(variables.idFamily), options);
  };

  // DELETE BOOKMARK ON BOOKMARK LOGGED-IN PAGE
  const useDeleteBookmarkFromBookmarkPage = () => {
    const queryClient = useQueryClient();
    return useMutation(
      (variables: DeleteBookmarkFromBookmarkPageProps) => api.bookmark.deleteBookmark(variables.idFamily),
      {
        onMutate: (variables: DeleteBookmarkFromBookmarkPageProps) => {
          // OPTIMISTIC UPDATE
          // Cancel any outgoing refetches
          queryClient.cancelQueries([QUERY_KEYS.FAMILY_SEARCH, { filters: variables.filters }]);
          // Snapshot the previous value
          const previousData = queryClient.getQueryData<InfiniteData<API.FamilySearch.ResultFamilySearch>>([
            QUERY_KEYS.FAMILY_SEARCH,
            { filters: variables.filters },
          ]);
          if (previousData) {
            // Clone previous data collection
            const newData: InfiniteData<API.FamilySearch.ResultFamilySearch> = JSON.parse(JSON.stringify(previousData));
            const deleteFamilyIndex = newData.pages[variables.page].families.findIndex(
              (family) => family.idFamily === variables.idFamily
            );
            if (deleteFamilyIndex > -1) {
              newData.pages[variables.page].families.splice(deleteFamilyIndex, 1);
              newData.pages.forEach((page) => {
                page.totalFamilies = page.totalFamilies - 1;
              });
            }
            // Optimistically update to the new value
            queryClient.setQueriesData<InfiniteData<API.FamilySearch.ResultFamilySearch>>(
              [QUERY_KEYS.FAMILY_SEARCH, { filters: variables.filters }],
              newData
            );
            return { previousData };
          }
        },
        onSuccess: (data, variables) => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FAMILY_SEARCH] });
          queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BOARD_LIST_DETAILED] });
        },
        onError: (err, variables, context) => {
          if (context?.previousData) {
            // If the mutation fails, use the context returned from onMutate to roll back
            queryClient.setQueryData<InfiniteData<API.FamilySearch.ResultFamilySearch>>(
              [QUERY_KEYS.FAMILY_SEARCH, { filters: variables.filters }],
              context.previousData
            );
          }
        },
      }
    );
  };

  // GET BOOKMARK STATUS
  const useGetBookmarkStatus = (idFamily: string, options: Options = { enabled: true }) => {
    return useQuery(
      [QUERY_KEYS.BOOKMARK_STATUS, { idFamily }],
      () => api.bookmark.getBookmarkStatus(idFamily),
      options
    );
  };

  return {
    useBookmarkCount,
    useGetBookmark,
    useAddBookmark,
    useDeleteBookmark,
    useDeleteBookmarkFromBookmarkPage,
    useGetBookmarkStatus,
  };
};
