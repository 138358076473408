import { SyntheticEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { ToggleButton } from 'design-system/atoms/toggle-button';
import { Switch } from 'design-system/atoms/switch';
import { useAuthContext, useSettingsContext } from 'context';
import NavProfileDesktopArrow from 'containers/NavProfileDesktop/NavProfileDesktopArrow';
import FontName from 'components/CardsAppearance/FontName';
import Pangram from 'components/CardsAppearance/Pangram';
import Poster from 'components/CardsAppearance/Poster';
import ColoredPoster from 'components/CardsAppearance/ColoredPoster';
import { displayModeOptions } from 'common/data/Datas';
import {
  CardAppearanceWrapper,
  CardContainer,
  CardContent,
  CardLabel,
  CardWrapper,
  DebugModeWrapper,
  NavPageAppearanceTooltipWrapper,
  Separator,
  SwitchModeWrapper,
  TooltipArrow,
} from './NavPageAppearanceTooltip.styled';

const NavPageAppearanceTooltip = () => {
  const { t } = useTranslation(['nav', 'common']);
  const { isLoggedIn, superAdmin } = useAuthContext();
  const {
    displayNavPageAppearanceTooltip,
    setDisplayNavPageAppearanceTooltip,
    darkMode,
    changeDarkMode,
    displayMode,
    changeDisplayMode,
  } = useSettingsContext();

  const fontName = 'typeface name';
  const pangram = 'pangram';
  const pangramDebug = 'pangram debug';
  const poster = 'poster';
  const coloredPoster = 'colored poster';

  const pageAppearanceTooltipRef = useRef<HTMLDivElement>(null);

  const switchModeOptions: Options = useMemo(
    () => [
      { value: false, label: t('tooltip-page-appearance.switch-mode.light-mode') },
      { value: true, label: t('tooltip-page-appearance.switch-mode.dark-mode') },
    ],
    [t]
  );

  const debugModeValue = useMemo(() => displayMode === pangramDebug, [displayMode]);

  const cardsData: Options = useMemo(() => displayModeOptions, []);

  const handleClickSwitchModeAppearance = useCallback(
    (option: Option) => {
      const darkModeValue = option.value as boolean;
      changeDarkMode(darkModeValue);
    },
    [changeDarkMode]
  );

  const handleClickCardAppearance = useCallback(
    (event: SyntheticEvent<HTMLDivElement>, cardData: Option) => {
      const cursorStyle = window.getComputedStyle(event.currentTarget as HTMLElement)['cursor'];
      if (cursorStyle === 'default') {
        return;
      }
      const value = cardData.value as string;
      changeDisplayMode(value);
    },
    [changeDisplayMode]
  );

  const handleChangeDebugMode = useCallback(() => {
    if (displayMode === pangramDebug) {
      changeDisplayMode(pangram);
    } else {
      changeDisplayMode(pangramDebug);
    }
  }, [changeDisplayMode, displayMode]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (displayNavPageAppearanceTooltip) {
        const navPageAppearanceBtn = document.getElementById('navPageAppearanceButton') as HTMLElement;
        if (
          pageAppearanceTooltipRef.current &&
          !pageAppearanceTooltipRef.current.contains(target) &&
          navPageAppearanceBtn &&
          !navPageAppearanceBtn.contains(target)
        ) {
          setDisplayNavPageAppearanceTooltip(false);
        }
      }
    },
    [displayNavPageAppearanceTooltip, setDisplayNavPageAppearanceTooltip]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <NavPageAppearanceTooltipWrapper
      ref={pageAppearanceTooltipRef}
      darkMode={darkMode}
      isLoggedIn={isLoggedIn}
    >
      <TooltipArrow isLoggedIn={isLoggedIn}>
        <NavProfileDesktopArrow darkMode={darkMode} />
      </TooltipArrow>
      <SwitchModeWrapper>
        <ToggleButton
          options={switchModeOptions}
          defaultValue={switchModeOptions.find((o) => o.value === darkMode)}
          darkMode={darkMode}
          onClick={handleClickSwitchModeAppearance}
        />
      </SwitchModeWrapper>
      <Separator darkMode={darkMode} />
      <CardAppearanceWrapper>
        {cardsData.map((card, index) => (
          <CardWrapper
            key={index}
            selected={displayMode === card.value || (card.value === pangram && displayMode === pangramDebug)}
            darkMode={darkMode}
            onClick={(event) => {
              handleClickCardAppearance(event, card);
            }}
          >
            <CardContainer
              className={
                (card.value as string).indexOf('poster') === -1 ? 'card-container' : 'card-container card-poster'
              }
              selected={displayMode === card.value}
              darkMode={card.value === coloredPoster ? false : darkMode}
            >
              <CardContent>
                {card.value === fontName && <FontName darkMode={darkMode} />}
                {card.value === pangram && <Pangram darkMode={darkMode} />}
                {card.value === poster && <Poster darkMode={darkMode} />}
                {card.value === coloredPoster && <ColoredPoster />}
              </CardContent>
            </CardContainer>
            <CardLabel darkMode={darkMode}>{t(card.label, { ns: 'common' })}</CardLabel>
          </CardWrapper>
        ))}
      </CardAppearanceWrapper>
      {superAdmin && (
        <DebugModeWrapper>
          <Switch
            checked={debugModeValue}
            darkMode={darkMode}
            onChange={handleChangeDebugMode}
          >
            {t('tooltip-page-appearance.debug-info')}
          </Switch>
        </DebugModeWrapper>
      )}
    </NavPageAppearanceTooltipWrapper>
  );
};

export default NavPageAppearanceTooltip;
