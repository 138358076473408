import styled from '@emotion/styled';
import { GREY_6, WHITE } from 'design-system/global/colors';

interface Props {
  darkMode?: boolean;
}

export const LogoStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoImg = styled.div`
  width: 28px;
  height: 31px;
  margin-right: 2px;
`;

export const LogoTitle = styled.p`
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: -0.04em;
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
`;
