import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, NINJA_GREEN, OLD_NINJA_GREEN_LIGHT, WHITE } from 'design-system/global/colors';
import { BIG_DESKTOP_GUTTER, DESKTOP_GUTTER, MAX_WIDTH, MOBILE_GUTTER, TABLET_GUTTER } from 'common/data/Constants';
import { easeInOutQuart, easeOutQuart } from 'common/easing/Easing';
import { mq } from 'styles/mediaqueries.styled';

interface NavProps {
  ready?: boolean;
  isBot?: string;
  active?: boolean;
  burgerMenuOpen?: boolean;
  sticky?: boolean;
  darkMode?: boolean;
}

export const navPaddingTop = 48;
export const navStickyPaddingTop = 16;

export const NavStyled = styled.nav`
  top: 0;
  width: 100%;
  background-color: ${(props: NavProps) => (props.darkMode ? GREY_6 : WHITE)};
  visibility: ${(props: NavProps) => (props.ready ? 'visible' : 'hidden')};
  border-bottom: 1px solid transparent;
  /* MOBILE */
  position: fixed;
  padding: 28px 0;
  z-index: 2;
  /* TABLET */
  ${mq['tablet']} {
    padding: 24px 0;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    position: sticky;
    margin: ${navPaddingTop - navStickyPaddingTop}px 0;
    padding: ${navStickyPaddingTop}px 0;
    transition: border-bottom 0.3s ${easeOutQuart};
  }

  // STICKY
  ${(props: NavProps) =>
    props.sticky &&
    css`
      border-bottom: 1px solid ${props.darkMode ? GREY_2 : GREY_6};
    `}

  // Bot userAgent
  ${(props: NavProps) =>
    props.isBot === 'true' &&
    css`
      visibility: visible;
    `}
`;

export const NavWrapper = styled.div`
  /* MOBILE */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${MOBILE_GUTTER}px;
  /* TABLET */
  ${mq['tablet']} {
    padding: 0 ${TABLET_GUTTER}px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    max-width: ${MAX_WIDTH}px;
    margin: 0 auto;
    padding: 0 ${DESKTOP_GUTTER}px;
    transition: padding 0.3s ${easeOutQuart};
    // STICKY
    ${(props: NavProps) =>
      props.sticky &&
      css`
        padding: 0 32px;
      `}
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    padding: 0 ${BIG_DESKTOP_GUTTER}px;
    // STICKY
    ${(props: NavProps) =>
      props.sticky &&
      css`
        padding: 0 32px;
      `}
  }
`;

export const NavLogo = styled.div`
  display: flex;
`;

export const Layout = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${GREY_6};
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ${easeInOutQuart};
  // BURGER MENU OPEN
  ${(props: NavProps) =>
    props.burgerMenuOpen &&
    css`
      opacity: 0.8;
      pointer-events: auto;
    `}
`;

export const BurgerButton = styled.button`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 18px;
  height: 14px;
  background-color: transparent;
  padding: 0;
  margin-right: 8px;
  margin-top: 9px;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    top: -13px;
    left: -11px;
  }
  // BURGER MENU OPEN
  ${(props: NavProps) =>
    props.burgerMenuOpen &&
    css`
      div:nth-of-type(1) {
        top: 6px;
        transform: rotate(-45deg);
        transition:
          top 0.2s ${easeOutQuart},
          transform 0.2s ${easeOutQuart} 0.2s;
      }
      div:nth-of-type(2) {
        opacity: 0;
      }
      div:nth-of-type(3) {
        top: -6px;
        transform: rotate(45deg);
        transition:
          top 0.2s ${easeOutQuart},
          transform 0.2s ${easeOutQuart} 0.2s;
      }
    `}
  // BURGER MENU CLOSE
  ${(props: NavProps) =>
    !props.burgerMenuOpen &&
    css`
      div:nth-of-type(1) {
        transition:
          top 0.2s ${easeOutQuart} 0.2s,
          transform 0.2s ${easeOutQuart};
      }
      div:nth-of-type(3) {
        transition:
          top 0.2s ${easeOutQuart} 0.2s,
          transform 0.2s ${easeOutQuart};
      }
    `}
`;

export const BurgerButtonBar = styled.div`
  position: relative;
  top: 0;
  width: 100%;
  height: 2px;
  background-color: ${(props: NavProps) => (props.darkMode ? WHITE : GREY_6)};
  margin-bottom: 4px;
  transform: rotate(0deg);
`;

export const LogoWrapper = styled.div`
  transform-origin: center left;
  /* MOBILE */
  display: inline-block;
  vertical-align: top;
  transform: scale(0.8);
  /* DESKTOP */
  ${mq['desktop']} {
    transform: scale(1);
    transition: transform 0.3s ${easeOutQuart};
    // STICKY
    ${(props: NavProps) =>
      props.sticky &&
      css`
        transform: scale(0.8, 0.8);
      `}
  }
`;

export const Beta = styled.p`
  display: block;
  position: absolute;
  top: -3px;
  left: 136px;
  color: ${GREY_6};
  font-weight: 700;
  font-size: 10px;
  line-height: 11px;
  padding: 3px 6px;
  border-radius: 56px;
  background-color: ${NINJA_GREEN};
`;

export const UL = styled.ul`
  list-style-type: none;
  margin: 0;
  /* MOBILE */
  position: fixed;
  top: 0;
  left: -296px;
  width: 296px;
  height: 100vh;
  padding: 112px 20px 0;
  background-color: ${(props: NavProps) => (props.darkMode ? GREY_6 : WHITE)};
  transition: left 0.3s ${easeOutQuart};
  // BURGER MENU OPEN
  ${(props: NavProps) =>
    props.burgerMenuOpen &&
    css`
      left: 0;
    `}
  /* TABLET */
  ${mq['tablet']} {
    left: -278px;
    width: 278px;
    padding: 114px 20px 0 48px;
    // BURGER MENU OPEN
    ${(props: NavProps) =>
      props.burgerMenuOpen &&
      css`
        left: 0;
      `}
  }
  /* DESKTOP */
  ${mq['desktop']} {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    padding: unset;
    background-color: transparent;
  }
`;

export const LI = styled.li`
  position: relative;
  /* MOBILE */
  display: block;
  margin-bottom: 32px;
  &:last-of-type {
    margin-bottom: 0;
  }
  a {
    color: ${(props: NavProps) => (props.darkMode ? WHITE : GREY_6)};
    font-size: 16px;
    line-height: 18px;
    font-weight: ${(props: NavProps) => (props.active ? 700 : 400)};
    cursor: ${(props: NavProps) => (props.active ? 'default' : 'pointer')};
    &:hover {
      color: ${(props: NavProps) => (props.active ? 'inherit' : OLD_NINJA_GREEN_LIGHT)};
    }
    ${(props: NavProps) =>
      props.active &&
      css`
        color: ${props.darkMode ? WHITE : GREY_6};
      `}
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 40px;
      top: -8px;
    }
    // Tips to avoid nav to move on hover when text change font-weight
    &::after {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0px;
      color: transparent;
      overflow: hidden;
      visibility: hidden;
    }
    span {
      display: flex;
      align-items: center;
      [class^='icon-'],
      [class*=' icon-'] {
        margin-right: 12px;
      }
    }
  }
  /* DESKTOP */
  ${mq['desktop']} {
    display: inline-block;
    vertical-align: top;
    line-height: 22px;
    height: 22px;
    margin-right: 28px;
    margin-bottom: 0;
    text-align: center;
    &:last-of-type {
      margin-right: 0;
    }
  }

  &:hover {
    a {
      color: ${(props: NavProps) => (props.active ? 'inherit' : OLD_NINJA_GREEN_LIGHT)};
    }
    [class^='icon-'],
    [class*=' icon-'] {
      color: ${(props: NavProps) => (props.active ? 'inherit' : OLD_NINJA_GREEN_LIGHT)};
    }
  }

  ${(props: NavProps) =>
    props.active &&
    css`
      a {
        color: ${props.darkMode ? WHITE : GREY_6};
      }
      [class^='icon-'],
      [class*=' icon-'] {
        color: ${props.darkMode ? WHITE : GREY_6};
      }
    `}
`;

export const Separator = styled.div`
  position: relative;
  top: 50%;
  height: 1px;
  background-color: ${(props: NavProps) => (props.darkMode ? WHITE : GREY_6)};
  /* MOBILE */
  width: 182px;
  /* DESKTOP */
  ${mq['desktop']} {
    width: 32px;
  }
`;

export const SignInUpWrapper = styled.div`
  position: relative;
  /* DESKTOP */
  ${mq['desktop']} {
    top: -7px;
  }
  button {
    /* MOBILE */
    margin-right: 16px;
    /* DESKTOP */
    ${mq['desktop']} {
      margin-right: 8px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const LoggedInWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -1px;
`;

export const NotifDesktopWrapper = styled.div`
  height: 16px;
  margin-left: 16px;
  a {
    &:before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      transform: translate(-12px, 0);
    }
    &:after {
      content: none;
    }
  }
`;
