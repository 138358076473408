import { SyntheticEvent, useCallback, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Icon } from 'design-system/atoms/icon';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { trackLoggedOut } from 'tracking/Mixpanel';
import Avatar from 'components/Avatar';
import { useAuthContext, useSettingsContext } from 'context';
import { ROUTES } from 'common/data/Constants';
import NavProfileDesktopArrow from './NavProfileDesktopArrow';
import {
  AvatarWrapper,
  LinkContainer,
  NavProfileContainer,
  Tooltip,
  TooltipArrow,
  TooltipItem,
} from './NavProfileDesktop.styled';

const NavProfileDesktop = () => {
  const { t } = useTranslation('nav');
  const { avatar, userId, userName, logout } = useAuthContext();
  const { darkMode, displayNavPageAppearanceTooltip } = useSettingsContext();
  const router = useRouter();

  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleOverAvatar = useCallback(() => {
    if (tooltipRef.current) {
      tooltipRef.current.removeAttribute('style');
    }
  }, []);

  const handleClickLink = useCallback(() => {
    if (tooltipRef.current) {
      tooltipRef.current.style.display = 'none';
    }
  }, []);

  const handleLogout = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      logout();
      // Track Mixpanel Logout
      trackLoggedOut();
    },
    [logout]
  );

  return (
    <NavProfileContainer displayNavPageAppearanceTooltip={displayNavPageAppearanceTooltip}>
      <AvatarWrapper onMouseEnter={handleOverAvatar}>
        <Link href={ROUTES.PROFILE}>
          <Avatar
            width={24}
            height={24}
            border={1}
            darkMode={darkMode}
            src={avatar}
            userId={userId}
            userName={userName}
          />
        </Link>
      </AvatarWrapper>
      <Tooltip
        ref={tooltipRef}
        className="tooltip-profile"
        darkMode={darkMode}
      >
        <TooltipArrow>
          <NavProfileDesktopArrow darkMode={darkMode} />
        </TooltipArrow>
        <TooltipItem
          active={router.pathname === ROUTES.PROFILE}
          darkMode={darkMode}
        >
          <Link
            href={ROUTES.PROFILE}
            title={t('profile')}
            onClick={handleClickLink}
          >
            <LinkContainer>
              <Icon
                label="profile"
                color={darkMode ? WHITE : GREY_6}
              />
              {t('profile')}
            </LinkContainer>
          </Link>
        </TooltipItem>
        <TooltipItem darkMode={darkMode}>
          <a
            href="#"
            onClick={handleLogout}
          >
            <LinkContainer>
              <Icon
                label="logout"
                color={darkMode ? WHITE : GREY_6}
              />
              {t('log-out')}
            </LinkContainer>
          </a>
        </TooltipItem>
      </Tooltip>
    </NavProfileContainer>
  );
};

export default NavProfileDesktop;
