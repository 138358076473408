import styled from '@emotion/styled';
import { GREY_6 } from 'design-system/global/colors';

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  margin-right: 16px;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${GREY_6};
`;
