import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_1, GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface LabelProps {
  checked: boolean;
  darkMode?: boolean;
}

interface SwitchWrapperProps {
  checked: boolean;
  darkMode?: boolean;
  hasMarginRight?: boolean;
}

interface SwitchSelectorProps {
  checked: boolean;
  darkMode?: boolean;
}

const easeOutQuart: string = 'cubic-bezier(0.165, 0.840, 0.440, 1.000)';

// LABEL STYLES
const labelStyleOff = css`
  color: ${GREY_1};
`;

const labelStyleOn = css`
  color: ${GREY_6};
`;

const labelStyleOffDarkMode = css`
  color: ${GREY_2};
`;

const labelStyleOnDarkMode = css`
  color: ${WHITE};
`;

// SWITCH WRAPPER STYLES
const switchWrapperStyleOff = css`
  background-color: ${WHITE};
  border: 1px solid ${GREY_1};
`;

const switchWrapperStyleOn = css`
  background-color: ${WHITE};
  border: 1px solid ${GREY_6};
  box-shadow: 4px 4px 0 ${GREY_6};
`;

const switchWrapperStyleOffDarkMode = css`
  background-color: ${GREY_6};
  border: 1px solid ${GREY_2};
`;

const switchWrapperStyleOnDarkMode = css`
  background-color: ${WHITE};
  border: 1px solid ${GREY_2};
`;

// SWITCH SELECTOR STYLES
const switchSelectorStyleOff = css`
  background-color: ${GREY_1};
`;

const switchSelectorStyleOn = css`
  background-color: ${GREY_6};
  transform: translate(18px, 0);
`;

const switchSelectorStyleOffDarkMode = css`
  background-color: ${GREY_2};
`;

const switchSelectorStyleOnDarkMode = css`
  background-color: ${GREY_6};
  transform: translate(18px, 0);
`;

// COMPONENTS
export const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  transition: color 0.25s ${easeOutQuart};
  // OFF / NORMAL MODE
  ${(props: LabelProps) =>
    !props.checked &&
    !props.darkMode &&
    css`
      ${labelStyleOff}
    `}
  // ON / NORMAL MODE
  ${(props: LabelProps) =>
    props.checked &&
    !props.darkMode &&
    css`
      ${labelStyleOn}
    `}
  // OFF / DARK MODE
  ${(props: LabelProps) =>
    !props.checked &&
    props.darkMode &&
    css`
      ${labelStyleOffDarkMode}
    `}
  // ON / DARK MODE
  ${(props: LabelProps) =>
    props.checked &&
    props.darkMode &&
    css`
      ${labelStyleOnDarkMode}
    `}
`;

export const SwitchWrapper = styled.div`
  display: inline-block;
  width: 38px;
  height: 20px;
  border-radius: 68px;
  box-sizing: border-box;
  transition:
    transform 0.25s ${easeOutQuart},
    background-color 0.25s ${easeOutQuart},
    box-shadow 0.25s ${easeOutQuart};
  // OFF / NORMAL MODE
  ${(props: SwitchWrapperProps) =>
    !props.checked &&
    !props.darkMode &&
    css`
      ${switchWrapperStyleOff}
    `}
  // ON / NORMAL MODE
  ${(props: SwitchWrapperProps) =>
    props.checked &&
    !props.darkMode &&
    css`
      ${switchWrapperStyleOn}
    `}
  // OFF / DARK MODE
  ${(props: SwitchWrapperProps) =>
    !props.checked &&
    props.darkMode &&
    css`
      ${switchWrapperStyleOffDarkMode}
    `}
  // ON / DARK MODE
  ${(props: SwitchWrapperProps) =>
    props.checked &&
    props.darkMode &&
    css`
      ${switchWrapperStyleOnDarkMode}
    `}
  ${(props: SwitchWrapperProps) =>
    props.hasMarginRight &&
    css`
      margin-right: 8px;
    `}
`;

export const SwitchSelector = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition:
    transform 0.25s ${easeOutQuart},
    background-color 0.25s ${easeOutQuart};
  // OFF / NORMAL MODE
  ${(props: SwitchSelectorProps) =>
    !props.checked &&
    !props.darkMode &&
    css`
      ${switchSelectorStyleOff}
    `}
  // ON / NORMAL MODE
  ${(props: SwitchSelectorProps) =>
    props.checked &&
    !props.darkMode &&
    css`
      ${switchSelectorStyleOn}
    `}
  // OFF / DARK MODE
  ${(props: SwitchSelectorProps) =>
    !props.checked &&
    props.darkMode &&
    css`
      ${switchSelectorStyleOffDarkMode}
    `}
  // ON / DARK MODE
  ${(props: SwitchSelectorProps) =>
    props.checked &&
    props.darkMode &&
    css`
      ${switchSelectorStyleOnDarkMode}
    `}
`;

export const Input = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
