import styled from '@emotion/styled';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
}

export const MarkerContainer = styled.div`
  position: absolute;
  top: -1px;
  /* MOBILE */
  left: 8px;
  /* TABLET */
  ${mq['tablet']} {
    left: 16px;
  }
`;

export const Variable = styled.div`
  position: absolute;
  top: 44px;
  right: -22px;
  width: 91px;
  height: 24px;
  padding: 5px 24px;
  background-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  font-size: 12px;
  line-height: 14px;
  transform: rotate(45deg);
  transform-origin: top right;
`;
