import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_1, GREY_6, OLD_NINJA_GREEN_LIGHT, WHITE } from 'design-system/global/colors';

interface Props {
  darkMode?: boolean;
  active?: boolean;
}

export const Debug = styled.div`
  position: relative;
  padding-top: 16px;
  p {
    color: ${GREY_1};
    font-size: 14px;
    line-height: 19px;
  }
  p > b {
    color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
    font-weight: 700;
  }
  a {
    margin-top: 24px;
  }
`;

export const Item = styled.span`
  font-weight: ${(props: Props) => (props.active ? 700 : 400)};
  ${(props: Props) =>
    props.active &&
    css`
      color: ${OLD_NINJA_GREEN_LIGHT};
    `}
`;

export const DebugChangeCategoryWrapper = styled.div`
  position: relative;
  display: flex;
  padding-top: 8px;
`;

export const DropdownCategoryContainer = styled.div`
  display: flex;
  flex: 1;
  /* margin-right: 8px; */

  > div {
    display: flex;
    flex: 1;
  }

  .dropdown-category {
    display: flex;
    flex: 1;
  }

  .dropdown__control {
    display: flex;
    flex: 1;
    width: auto !important;
  }

  .dropdown__menu {
    display: flex;
    flex: 1;
    width: 100% !important;
    .dropdown__menu-list {
      width: 100%;
    }
  }
`;
