import api from 'api';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';

interface SendTypefacesProps {
  fullName: string;
  foundry?: string;
  email: string;
  file: File;
  recaptchaToken: string | null;
}

interface SendCorrectionsProps {
  fullName: string;
  email: string;
  message?: string;
  recaptchaToken?: string;
}

interface SendContactUsProps {
  fullName: string;
  email: string;
  subject: string;
  message?: string;
  recaptchaToken?: string;
}

export const useFeedback = () => {
  // TYPEFACES
  const useSendTypefaces = (options?: {
    onSuccess?: (data: API.Feedback.Typefaces) => void;
    onError?: (err: AxiosError<API.Error>) => void;
  }) => {
    return useMutation((variables: SendTypefacesProps) => api.feedback.sendTypefaces(variables), options);
  };

  // CORRECTIONS
  const useSendCorrections = (options?: {
    onSuccess?: (data: API.Feedback.Corrections) => void;
    onError?: (err: AxiosError<API.Error>) => void;
  }) => {
    return useMutation(
      (variables: SendCorrectionsProps) =>
        api.feedback.sendCorrections(variables.fullName, variables.email, variables.message, variables.recaptchaToken),
      options
    );
  };

  // CONTACT US
  const useSendContactUs = (options?: {
    onSuccess?: (data: API.Feedback.ContactUs) => void;
    onError?: (err: AxiosError<API.Error>) => void;
  }) => {
    return useMutation(
      (variables: SendContactUsProps) =>
        api.feedback.sendContactUs(
          variables.fullName,
          variables.email,
          variables.subject,
          variables.message,
          variables.recaptchaToken
        ),
      options
    );
  };

  return {
    useSendTypefaces,
    useSendCorrections,
    useSendContactUs,
  };
};
