export const BRIDGE_MESSAGES = {
  FONTS_NINJA_PAGE_LOADED: 'fontsNinjaPageLoaded',
  FONTS_NINJA_PAGE_HEIGHT: 'fontsNinjaPageHeight',
  FONTS_NINJA_DARK_MODE: 'fontsNinjaDarkMode',
  FONTS_NINJA_ACCESS_TOKEN: 'fontsNinjaAccessToken',
  FONTS_NINJA_REFRESH_TOKEN: 'fontsNinjaRefreshToken',
  FONTS_NINJA_IFRAME_SCROLL_Y: 'fontsNinjaIframeScrollY',
  FONTS_NINJA_IFRAME_WINDOW_HEIGHT: 'fontsNinjaIframeWindowHeight',
  FONTS_NINJA_GET_GPDR_VALIDATION: 'fontsNinjaGetGpdrValidation',
  FONTS_NINJA_SAVE_GPDR_VALIDATION: 'fontsNinjaSaveGpdrValidation',
};
