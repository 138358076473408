import { useQueries, useQuery } from '@tanstack/react-query';
import { GREY_6, WHITE } from 'design-system/global/colors';
import api from 'api';
import { FontImageParameters, FontType } from 'api/fontImage.api';
import { useSettingsContext } from 'context';
import { QUERY_KEYS } from 'common/data/Constants';

interface Options {
  enabled: boolean;
}

export const useFontImage = () => {
  const { darkMode } = useSettingsContext();

  const useGenerateFontImage = (
    fontType: FontType,
    fontId: number,
    fontParameters?: FontImageParameters,
    cancelDarkMode: boolean = false,
    options: Options = { enabled: true }
  ) => {
    const query = useQuery(
      [QUERY_KEYS.FONT_IMAGE, { fontId, fontParameters }],
      () => api.fontImage.generateFontImage(fontType, fontId, fontParameters),
      options
    );

    // change "fill" and "stroke" values if darkMode
    if (query && query.data && darkMode && !cancelDarkMode) {
      query.data.svg = query.data.svg.replaceAll(`fill="${GREY_6}"`, `fill="${WHITE}"`);
      query.data.svg = query.data.svg.replaceAll(`stroke="${GREY_6}"`, `stroke="${WHITE}"`);
    } else if (query && query.data && !darkMode) {
      query.data.svg = query.data.svg.replaceAll(`fill="${WHITE}"`, `fill="${GREY_6}"`);
      query.data.svg = query.data.svg.replaceAll(`stroke="${WHITE}"`, `stroke="${GREY_6}"`);
    } else if (query && query.data && cancelDarkMode) {
      query.data.svg = query.data.svg.replaceAll(`fill="${WHITE}"`, `fill="${GREY_6}"`);
      query.data.svg = query.data.svg.replaceAll(`stroke="${WHITE}"`, `stroke="${GREY_6}"`);
    }

    return query;
  };

  const useGenerateMultiFontImages = (
    fontType: FontType,
    fontIds: Array<number>,
    fontParameters?: FontImageParameters,
    cancelDarkMode: boolean = false,
    enabled: boolean = true
  ) => {
    const queriesParameters: Array<any> = [];
    fontIds.forEach((fontId) => {
      queriesParameters.push({
        queryKey: [QUERY_KEYS.FONT_IMAGE, { fontId, fontParameters }],
        queryFn: () => api.fontImage.generateFontImage(fontType, fontId, fontParameters),
        enabled,
      });
    });

    const queries = useQueries({ queries: queriesParameters });

    // change "fill" and "stroke" values if darkMode
    if (queries && darkMode && !cancelDarkMode) {
      queries.forEach((query) => {
        const data = query.data as API.FontImage.Image;
        if (data) {
          data.svg = data.svg.replaceAll(`fill="${GREY_6}"`, `fill="${WHITE}"`);
          data.svg = data.svg.replaceAll(`stroke="${GREY_6}"`, `stroke="${WHITE}"`);
        }
      });
    } else if (queries && !darkMode) {
      queries.forEach((query) => {
        const data = query.data as API.FontImage.Image;
        if (data) {
          data.svg = data.svg.replaceAll(`fill="${WHITE}"`, `fill="${GREY_6}"`);
          data.svg = data.svg.replaceAll(`stroke="${WHITE}"`, `stroke="${GREY_6}"`);
        }
      });
    } else if (queries && cancelDarkMode) {
      queries.forEach((query) => {
        const data = query.data as API.FontImage.Image;
        if (data) {
          data.svg = data.svg.replaceAll(`fill="${WHITE}"`, `fill="${GREY_6}"`);
          data.svg = data.svg.replaceAll(`stroke="${WHITE}"`, `stroke="${GREY_6}"`);
        }
      });
    }

    return queries;
  };

  return {
    useGenerateFontImage,
    useGenerateMultiFontImages,
  };
};
