import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_1, GREY_2, GREY_6, LIGHT_GREY, WHITE } from 'design-system/global/colors';
import {
  BIG_DESKTOP_GUTTER,
  DESKTOP_CARD_GAP,
  DESKTOP_GUTTER,
  MAX_WIDTH,
  MOBILE_CARD_GAP,
  MOBILE_GUTTER,
  TABLET_CARD_GAP,
  TABLET_GUTTER,
} from 'common/data/Constants';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
  isEmpty?: boolean;
  multiline?: boolean;
  scaleValue?: number;
}

export const SearchScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;
`;

export const SearchScreenWrapper = styled.div`
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
  /* MOBILE */
  padding: 0 ${MOBILE_GUTTER}px;
  margin-bottom: ${MOBILE_CARD_GAP}px;
  /* TABLET */
  ${mq['tablet']} {
    padding: 0 ${TABLET_GUTTER}px;
    margin-bottom: ${TABLET_CARD_GAP}px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    padding: 0 ${DESKTOP_GUTTER}px;
    margin-bottom: ${DESKTOP_CARD_GAP}px;
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    padding: 0 ${BIG_DESKTOP_GUTTER}px;
  }
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  /* MOBILE */
  min-height: 53px;
  margin-top: 66px;
  margin-bottom: 5px;
  .search-icon {
    width: 24px;
    height: 24px;
  }
  /* TABLET */
  ${mq['tablet']} {
    min-height: 0;
    margin-top: 50px;
    margin-bottom: 54px;
    .search-icon {
      width: 34px;
      height: 34px;
    }
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin-top: 100px;
    margin-bottom: 36px;
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    margin-top: 111px;
    margin-bottom: 48px;
  }
`;

export const LoadingWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 24px;
  height: 24px;
  top: 0;
  left: -1px;
  transform: scale(0.8);
  /* TABLET */
  ${mq['tablet']} {
    width: 34px;
    height: 34px;
    top: 4px;
    left: 2px;
    transform: scale(1);
  }
  /* DESKTOP */
  ${mq['desktop']} {
    top: 6px;
  }
`;

export const SearchInput = styled.textarea`
  flex: 1;
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-weight: 700;
  letter-spacing: -0.01em;
  text-transform: ${(props: Props) => (props.isEmpty ? 'none' : 'capitalize')};
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
  /* MOBILE */
  margin-left: 8px;
  margin-top: -1px;
  font-size: 24px;
  line-height: 27px;
  &::placeholder {
    color: ${(props: Props) => (props.darkMode ? GREY_1 : LIGHT_GREY)};
    opacity: 1;
  }
  /* TABLET */
  ${mq['tablet']} {
    margin-top: -2px;
    font-size: 32px;
    line-height: 36px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin-top: -11px;
    font-size: 48px;
    line-height: 55px;
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    margin-top: -15px;
    font-size: 56px;
    line-height: 64px;
  }

  ${(props: Props) =>
    !props.multiline &&
    css`
      white-space: nowrap;
      overflow: hidden;
    `}
`;

export const FoundriesUsersWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  box-shadow: ${(props: Props) => (props.darkMode ? 'none' : `8px 8px 0px ${GREY_6}`)};
  /* MOBILE */
  flex-direction: column;
  margin-bottom: 40px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 56px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    flex-direction: row;
    margin-bottom: 32px;
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    margin-bottom: 54px;
  }
`;

export const FoundriesWrapper = styled.div`
  /* MOBILE */
  padding: 16px;
  border-bottom: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  /* TABLET */
  ${mq['tablet']} {
    padding: 16px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    width: 50%;
    padding: 24px 32px 30px;
    border-bottom: none;
    border-right: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    padding: 24px 32px 24px;
  }
`;

export const FoundriesTitle = styled.p`
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  /* MOBILE */
  margin-bottom: 18px;
  /* DESKTOP */
  ${mq['desktop']} {
    margin-bottom: 16px;
  }
`;

export const FoundriesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  /* MOBILE */
  max-height: 16px;
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    max-height: 48px;
  }

  a {
    min-width: 0;
  }
  a > p {
    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
        text-decoration-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
      }
    }
  }
`;

export const FoundryName = styled.p`
  color: ${GREY_1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;
  margin-right: 40px;
  margin-bottom: 16px;

  span {
    color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  }
`;

export const FoundryNoResult = styled.p`
  color: ${GREY_1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;
`;

export const UsersWrapper = styled.div`
  /* MOBILE */
  padding: 16px 16px 20px;
  /* TABLET */
  ${mq['tablet']} {
    padding: 16px 16px 20px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    width: 50%;
    padding: 24px 32px 24px;
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    padding: 24px 32px 26px;
  }
`;

export const UsersTitle = styled.p`
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  /* MOBILE */
  margin-bottom: 16px;
  /* DESKTOP */
  ${mq['desktop']} {
    margin-bottom: 14px;
  }
`;

export const UsersList = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  /* MOBILE */
  max-height: 24px;
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    max-height: 56px;
  }
`;

export const UserItemWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const UserItem = styled.div`
  position: relative;
  overflow: hidden;
  margin-right: 40px;
  margin-bottom: 8px;

  a {
    display: flex;
    align-items: center;
    p {
      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
          text-decoration-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
        }
      }
    }
  }
`;

export const UserName = styled.p`
  color: ${GREY_1};
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;

  span {
    color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  }
`;

export const UserNoResult = styled.p`
  color: ${GREY_1};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;
`;

export const FamilyListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transform-origin: top left;

  .infinite-scroll-component {
    display: grid;
    overflow: visible !important;
    /* MOBILE */
    grid-template-columns: 1fr;
    row-gap: ${(props: Props) =>
      props.scaleValue ? `${props.scaleValue * MOBILE_CARD_GAP}px` : `${MOBILE_CARD_GAP}px`};
    /* TABLET */
    ${mq['tablet']} {
      grid-template-columns: repeat(2, 1fr);
      column-gap: ${(props: Props) =>
        props.scaleValue ? `${props.scaleValue * TABLET_CARD_GAP}px` : `${TABLET_CARD_GAP}px`};
      row-gap: ${(props: Props) =>
        props.scaleValue ? `${props.scaleValue * TABLET_CARD_GAP}px` : `${TABLET_CARD_GAP}px`};
    }
    /* DESKTOP */
    ${mq['desktop']} {
      grid-template-columns: repeat(3, 1fr);
      column-gap: ${(props: Props) =>
        props.scaleValue ? `${props.scaleValue * DESKTOP_CARD_GAP}px` : `${DESKTOP_CARD_GAP}px`};
      row-gap: ${(props: Props) =>
        props.scaleValue ? `${props.scaleValue * DESKTOP_CARD_GAP}px` : `${DESKTOP_CARD_GAP}px`};
    }
  }
`;

export const FamilyListLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* MOBILE */
  margin-top: ${MOBILE_CARD_GAP}px;
  /* TABLET */
  ${mq['tablet']} {
    margin-top: ${TABLET_CARD_GAP}px;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    margin-top: ${DESKTOP_CARD_GAP}px;
  }
`;

export const EmptyFamilyListWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* MOBILE */
  width: 300px;
  padding-top: 32px;
  margin: 0 auto 150px;
  /* TABLET */
  ${mq['tablet']} {
    width: 480px;
    padding-top: 68px;
    margin: 0 auto 200px;
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    padding-top: 108px;
    margin: 0 auto 200px;
  }
`;

export const EmptyFamilyListText = styled.p`
  font-weight: 700;
  letter-spacing: -0.01em;
  text-align: center;
  word-break: break-all;
  /* MOBILE */
  padding-top: 4px;
  font-size: 20px;
  line-height: 23px;
  /* TABLET */
  ${mq['tablet']} {
    padding-top: 32px;
    font-size: 32px;
    line-height: 36px;
  }
`;

export const ButtonCloseContainer = styled.div`
  position: fixed;
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
`;

export const ButtonCloseWrapper = styled.div`
  position: absolute;
  /* MOBILE */
  top: 28px;
  right: 8px;
  /* TABLET */
  ${mq['tablet']} {
    top: 12px;
    right: 4px;
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    top: 20px;
    right: 10px;
  }
`;
