import { GREY_6, WHITE } from 'design-system/global/colors';
import { SpinnerWrapper } from './Spinner.styled';

interface Props {
  darkMode: boolean;
  fillTransparent?: boolean;
  className?: string;
}

const Spinner = ({ darkMode, fillTransparent, className }: Props) => {
  const timestamp = Date.now();
  return (
    <SpinnerWrapper className={className}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="12.5"
          cy="12.5"
          r="11"
          fill={fillTransparent ? 'transparent' : darkMode ? GREY_6 : WHITE}
          stroke={darkMode ? WHITE : GREY_6}
          strokeWidth="3"
          opacity={0.15}
        />
        <mask
          id={`mask_${timestamp}`}
          maskUnits="userSpaceOnUse"
          x="1"
          y="-3"
          width="28"
          height="33"
        >
          <path
            d="M12.4074 5.90476V-3L29 3.80952L27.963 24.7619C23.1235 26.5079 13.3407 30 12.9259 30C12.5111 30 4.80247 26.5079 1 24.7619L12.4074 11.6667V5.90476Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask={`url(#mask_${timestamp})`}>
          <circle
            cx="12.5"
            cy="12.5"
            r="11"
            fill={fillTransparent ? 'transparent' : darkMode ? GREY_6 : WHITE}
            stroke={darkMode ? WHITE : GREY_6}
            strokeWidth="3"
          />
        </g>
      </svg>
    </SpinnerWrapper>
  );
};

export default Spinner;
