import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { InputText } from 'design-system/atoms/input-text';
import { Icon } from 'design-system/atoms/icon';
import { Switch } from 'design-system/atoms/switch';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { trackBoardCreated } from 'tracking/Mixpanel';
import { useModalContext, useSettingsContext } from 'context';
import { useBoard } from 'hooks/queries/useBoard';
import { QUERY_KEYS } from 'common/data/Constants';
import Modal from 'components/Modal';
import {
  ButtonsWrapper,
  CloseButtonWrapper,
  ContentWrapper,
  FormWrapper,
  InputTextWrapper,
  ModalWrapper,
  SwitchWrapper,
  TitleWrapper,
} from './CreateNewBoard.styled';

interface InputValue {
  value: string;
  error: string;
}

const CreateNewBoard = () => {
  const { t } = useTranslation('common');
  const { darkMode } = useSettingsContext();
  const { openModalCreateNewBoard, setOpenModalCreateNewBoard, setOpenModalAddBookmarkToBoard } = useModalContext();

  const [startClose, setStartClose] = useState<boolean>(false);
  const [boardName, setBoardName] = useState<InputValue>({ value: '', error: '' });
  const [isPrivateBoard, setIsPrivateBoard] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { useAddNewBoard } = useBoard();
  const addNewBoardMutation = useAddNewBoard({
    onSuccess: (data) => {
      // Track Mixpanel board created
      trackBoardCreated(isPrivateBoard ? 'private' : 'public');
      const boardListData = queryClient.getQueryData([QUERY_KEYS.BOARD_LIST_DETAILED]);
      // Optimistic update
      const newBoard = { ...data } as API.Board.BoardDetailed;
      newBoard.bookmarks = [];
      newBoard.totalBookmarks = 0;
      if (boardListData) {
        (boardListData as API.Board.BoardListDetailed).boards.unshift(newBoard);
      } else {
        queryClient.setQueryData([QUERY_KEYS.BOARD_LIST_DETAILED], {
          boards: [newBoard],
          totalBoards: 1,
        });
      }
      // Invalidate boardList query
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BOARD_LIST_DETAILED] });
      setButtonDisabled(false);
      setStartClose(true);
      setOpenModalAddBookmarkToBoard({
        idFamily: openModalCreateNewBoard!.idFamily,
        idFont: openModalCreateNewBoard!.idFont,
        familyName: openModalCreateNewBoard!.familyName,
        fontWeight: openModalCreateNewBoard!.fontWeight,
        source: openModalCreateNewBoard!.source,
        forcedBoardsSelected: [data],
      });
    },
    onError: (err) => {
      if (err.response?.data.code === 40022) {
        setBoardName({ value: boardName.value, error: t('errors.board-already-exists') });
      }
      setButtonDisabled(false);
    },
  });

  const handleClose = useCallback(() => {
    setStartClose(true);
    setOpenModalAddBookmarkToBoard({
      idFamily: openModalCreateNewBoard!.idFamily,
      idFont: openModalCreateNewBoard!.idFont,
      familyName: openModalCreateNewBoard!.familyName,
      fontWeight: openModalCreateNewBoard!.fontWeight,
      source: openModalCreateNewBoard!.source,
    });
  }, [openModalCreateNewBoard, setOpenModalAddBookmarkToBoard]);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalCreateNewBoard(null);
  }, [setOpenModalCreateNewBoard]);

  const handleChangeBoardName = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setBoardName({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleChangePrivateBoard = useCallback(() => {
    setIsPrivateBoard(!isPrivateBoard);
  }, [isPrivateBoard]);

  const handleSubmitForm = useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      setButtonDisabled(true);
      let nbError = 0;
      if (boardName.value.trim() === '') {
        setBoardName({ value: boardName.value, error: t('create-new-board.empty-board-name') });
        setButtonDisabled(false);
        nbError += 1;
      }
      if (nbError > 0) return;

      addNewBoardMutation.mutate({ name: boardName.value, isPrivate: isPrivateBoard });
    },
    [addNewBoardMutation, boardName.value, isPrivateBoard, t]
  );

  useEffect(() => {
    if (!openModalCreateNewBoard) {
      setBoardName({ value: '', error: '' });
      setIsPrivateBoard(false);
      setButtonDisabled(false);
    }
  }, [openModalCreateNewBoard]);

  if (openModalCreateNewBoard) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            <TitleWrapper>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('create-new-board.title')}
              </H3>
            </TitleWrapper>
            <FormWrapper onSubmit={handleSubmitForm}>
              <InputTextWrapper isPrivate={isPrivateBoard}>
                <InputText
                  placeholder={t('create-new-board.placeholder')}
                  value={boardName.value}
                  error={boardName.error}
                  maxLength={70}
                  darkMode={darkMode}
                  onChange={handleChangeBoardName}
                />
                {isPrivateBoard && (
                  <Icon
                    label="not_visible"
                    color={darkMode ? WHITE : GREY_6}
                  />
                )}
              </InputTextWrapper>
              <SwitchWrapper>
                <Switch
                  checked={isPrivateBoard}
                  darkMode={darkMode}
                  onChange={handleChangePrivateBoard}
                >
                  {t('create-new-board.private-board')}
                </Switch>
              </SwitchWrapper>
              <ButtonsWrapper>
                <Button
                  darkMode={darkMode}
                  disabled={buttonDisabled}
                >
                  {t('create-new-board.create-board')}
                </Button>
              </ButtonsWrapper>
            </FormWrapper>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default CreateNewBoard;
