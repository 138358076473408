import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/foundry`;

export const getFoundry = (idFoundry: string): Promise<API.Foundry.FoundryDetails> => {
  return axios.get(`${baseUrl}/${idFoundry}`).then(({ data }) => {
    return data;
  });
};
