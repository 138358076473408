import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useBreakpointContext, useModalContext, useSettingsContext } from 'context';
import { useBoardEditors } from 'hooks/queries/useBoardEditors';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Avatar from 'components/Avatar';
import { transformStringToUrl } from 'common/utils';
import { PAGE_SIZE_FOLLOW } from 'common/data/Constants';
import {
  CloseButtonWrapper,
  CollaboratorAvatar,
  CollaboratorAvatarName,
  CollaboratorItem,
  CollaboratorLoading,
  CollaboratorName,
  CollaboratorsLoadingWrapper,
  CollaboratorStatus,
  CollaboratorsWrapper,
  ContentWrapper,
  ModalWrapper,
  Separator,
  TitleWrapper,
} from './BoardCollaboratorsList.styled';

const BoardCollaboratorsList = () => {
  const { t } = useTranslation('common');
  const { darkMode } = useSettingsContext();
  const { isMobile } = useBreakpointContext();
  const { openModalBoardCollaboratorsList, setOpenModalBoardCollaboratorsList } = useModalContext();

  const { useBoardListEditors } = useBoardEditors();

  const {
    data: collaboratorsData,
    isLoading: isLoadingCollaborators,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useBoardListEditors(openModalBoardCollaboratorsList?.idBoard ?? 0, PAGE_SIZE_FOLLOW, {
    enabled: !!openModalBoardCollaboratorsList,
  });

  const modalWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const [startClose, setStartClose] = useState<boolean>(false);
  const [modalWrapperHeight, setModalWrapperHeight] = useState<string | undefined>(undefined);

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalBoardCollaboratorsList(null);
  }, [setOpenModalBoardCollaboratorsList]);

  const displaySeparator = useCallback(() => {
    if (isMobile) {
      if (collaboratorsData && collaboratorsData?.pages[0].totalUsers >= 10) {
        return true;
      }
    } else {
      if (collaboratorsData && collaboratorsData?.pages[0].totalUsers >= 12) {
        return true;
      }
    }
    return false;
  }, [collaboratorsData, isMobile]);

  useEffect(() => {
    // transition resize ModalWrapper
    setTimeout(() => {
      if (modalWrapperRef.current && contentWrapperRef.current && collaboratorsData) {
        const contentWrapperHeight = contentWrapperRef.current.getBoundingClientRect().height;
        modalWrapperRef.current!.style.height = `${contentWrapperHeight}px`;
        modalWrapperRef.current!.addEventListener('transitionend', () => {
          setModalWrapperHeight('auto');
          modalWrapperRef.current!.removeAttribute('style');
        });
      }
    }, 10);
    // transition resize ModalWrapper: Security for Safari when `transitionend` not fired
    setTimeout(() => {
      if (modalWrapperRef.current && modalWrapperRef.current.style.height !== '') {
        setModalWrapperHeight('auto');
        modalWrapperRef.current.removeAttribute('style');
      }
    }, 400);
  }, [collaboratorsData]);

  useEffect(() => {
    if (!openModalBoardCollaboratorsList) {
      setModalWrapperHeight(undefined);
    }
  }, [openModalBoardCollaboratorsList]);

  if (openModalBoardCollaboratorsList !== null) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper
          ref={modalWrapperRef}
          modalWrapperHeight={modalWrapperHeight}
        >
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper ref={contentWrapperRef}>
            <TitleWrapper displaySeparator={displaySeparator()}>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('board-collaborators-list.title')}
              </H3>
            </TitleWrapper>
            <Separator
              displaySeparator={displaySeparator()}
              darkMode={darkMode}
            />
            <CollaboratorsWrapper
              id="collaboratorsWrapper"
              displaySeparator={displaySeparator()}
            >
              {isLoadingCollaborators && (
                <CollaboratorsLoadingWrapper>
                  <Spinner darkMode={darkMode} />
                </CollaboratorsLoadingWrapper>
              )}
              {!isLoadingCollaborators && collaboratorsData && (
                <InfiniteScroll
                  dataLength={collaboratorsData.pages.reduce((acc, page) => acc + page.users.length, 0)}
                  next={() => {
                    if (!isFetchingNextPage) {
                      fetchNextPage();
                    }
                  }}
                  hasMore={!!hasNextPage}
                  loader={
                    <CollaboratorLoading>
                      <Spinner darkMode={darkMode} />
                    </CollaboratorLoading>
                  }
                  scrollableTarget="collaboratorsWrapper"
                >
                  {collaboratorsData.pages.map((page, pageIndex) => (
                    <Fragment key={pageIndex}>
                      {page.users.map((user, index) => (
                        <CollaboratorItem key={`${pageIndex}_${index}`}>
                          <CollaboratorAvatarName>
                            {user.status !== 'invited' ? (
                              <Link
                                href={`/${transformStringToUrl(user.name)}-${user.id}`}
                                onClick={handleClose}
                              >
                                <CollaboratorAvatar status={user.status}>
                                  <Avatar
                                    width={24}
                                    height={24}
                                    border={1}
                                    darkMode={darkMode}
                                    src={user.avatarUrl}
                                    userId={user.id}
                                    userName={user.name}
                                    withShadow={false}
                                  />
                                </CollaboratorAvatar>
                              </Link>
                            ) : (
                              <CollaboratorAvatar status={user.status}>
                                <Avatar
                                  width={24}
                                  height={24}
                                  border={1}
                                  darkMode={darkMode}
                                  src={user.avatarUrl}
                                  userId={user.id}
                                  userName={user.name}
                                  withShadow={false}
                                />
                              </CollaboratorAvatar>
                            )}
                            {user.status !== 'invited' ? (
                              <Link
                                href={`/${transformStringToUrl(user.name)}-${user.id}`}
                                onClick={handleClose}
                              >
                                <CollaboratorName status={user.status}>{user.name}</CollaboratorName>
                              </Link>
                            ) : (
                              <CollaboratorName status={user.status}>{user.name}</CollaboratorName>
                            )}
                          </CollaboratorAvatarName>
                          {user.status && user.status === 'owner' && (
                            <CollaboratorStatus>{t('board-collaborators-list.owner')}</CollaboratorStatus>
                          )}
                          {user.status && user.status === 'invited' && (
                            <CollaboratorStatus>{t('board-collaborators-list.pending')}</CollaboratorStatus>
                          )}
                        </CollaboratorItem>
                      ))}
                    </Fragment>
                  ))}
                </InfiniteScroll>
              )}
            </CollaboratorsWrapper>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default BoardCollaboratorsList;
