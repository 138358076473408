import React, { MouseEventHandler, ReactNode } from 'react';
import { Icon } from 'design-system/atoms/icon';
import { ButtonStyled, IconImg } from './button.styled';

export interface ButtonProps {
  type?: 'button' | 'submit';
  render?: 'default' | 'contrast';
  darkMode?: boolean;
  size?: 'normal' | 'small';
  href?: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
  iconLabel?: string;
  iconSrc?: string;
  disabled?: boolean;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLLinkElement>;
}

export function Button({
  render = 'default',
  darkMode = false,
  size = 'normal',
  href,
  target,
  iconLabel,
  iconSrc,
  children,
  ...props
}: ButtonProps) {
  return (
    <ButtonStyled
      render={render}
      darkMode={darkMode}
      size={size}
      {...(href && { as: 'a', href, draggable: false })}
      {...(href && target && { target })}
      {...props}
    >
      {iconLabel && <Icon label={iconLabel} />}
      {!iconLabel && iconSrc && (
        <IconImg
          className="button-icon-img"
          src={iconSrc}
          alt=""
          draggable={false}
          size={size}
        />
      )}
      {children}
    </ButtonStyled>
  );
}
