import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DANGER_RED, GREY_0, GREY_6, WHITE } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  codeError?: boolean;
  darkMode?: boolean;
}

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 5px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 32px 16px;
  /* TABLET */
  ${mq['tablet']} {
    width: 397px;
    padding: 32px 32px 40px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 16px;
`;

export const DescriptionWrapper = styled.div`
  /* MOBILE */
  margin-bottom: 24px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 16px;
  }
`;

export const Form = styled.form`
  position: relative;
`;

export const InputTextWrapper = styled.div`
  /* MOBILE */
  margin-bottom: 24px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 16px;
  }
`;

export const InputCodeWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
  box-shadow: 4px 4px 0px ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  /* MOBILE */
  margin-bottom: 10px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 0;
  }
  ${(props: Props) =>
    props.codeError &&
    css`
      input {
        color: ${DANGER_RED};
      }
    `}
`;

export const InputCode = styled.input`
  text-align: center;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  caret-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  border-radius: 0;
  border-right: 1px solid ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  border-top: 1px solid ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  border-bottom: 1px solid ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  &:first-of-type {
    border-left: 1px solid ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  &:last-of-type {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  &::placeholder {
    color: ${GREY_0};
    opacity: 1;
  }
  /* MOBILE */
  width: 75px;
  height: 82px;
  font-size: 55px;
  line-height: 63px;
  /* TABLET */
  ${mq['tablet']} {
    width: 83px;
    height: 96px;
    font-size: 64px;
    line-height: 73px;
  }
`;
