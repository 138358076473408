import { createContext, PropsWithChildren, ReactElement, useContext } from 'react';
import {
  AddBookmarkToBoardOptions,
  BoardCollaboratorsInviteOptions,
  BoardCollaboratorsListOptions,
  CreateNewBoardOptions,
  DeleteBoardOptions,
  EditBoardOptions,
  FollowedFoundriesOptions,
  FollowersUserOptions,
  FollowingFoundryOptions,
  FollowingUserOptions,
  FontBuyLinksOptions,
  ModalContextData,
  SignInUp,
  SupportedLanguageSystemsOptions,
  useModalState,
} from 'hooks';

const initialData: ModalContextData = {
  // SIGN-IN-UP
  openModalSignInUp: null,
  setOpenModalSignInUp: (_value: SignInUp) => null,
  // PASSWORD RETRIEVAL
  openModalPasswordRetrieval: false,
  setOpenModalPasswordRetrieval: (_value: boolean) => null,
  // CHANGE PASSWORD
  openModalChangePassword: false,
  setOpenModalChangePassword: (_value: boolean) => null,
  // EDIT PROFILE PICTURE
  openModalEditProfilePicture: false,
  setOpenModalEditProfilePicture: (_value: boolean) => null,
  // PRICE RANGE WARNING
  openModalPriceRangeWarning: false,
  setOpenModalPriceRangeWarning: (_value: boolean) => null,
  // ADD BOOKMARK TO BOARD
  openModalAddBookmarkToBoard: null,
  setOpenModalAddBookmarkToBoard: (_value: AddBookmarkToBoardOptions | null) => null,
  // CREATE NEW BOARD
  openModalCreateNewBoard: null,
  setOpenModalCreateNewBoard: (_value: CreateNewBoardOptions | null) => null,
  // EDIT BOARD
  openModalEditBoard: null,
  setOpenModalEditBoard: (_value: EditBoardOptions | null) => null,
  // DELETE BOARD
  openModalDeleteBoard: null,
  setOpenModalDeleteBoard: (_value: DeleteBoardOptions | null) => null,
  // BOARD COLLABORATORS LIST
  openModalBoardCollaboratorsList: null,
  setOpenModalBoardCollaboratorsList: (_value: BoardCollaboratorsListOptions | null) => null,
  // BOARD COLLABORATORS INVITE
  openModalBoardCollaboratorsInvite: null,
  setOpenModalBoardCollaboratorsInvite: (_value: BoardCollaboratorsInviteOptions | null) => null,
  // FOLLOWERS
  openModalFollowers: null,
  setOpenModalFollowers: (_value: FollowersUserOptions | null) => null,
  // FOLLOWING
  openModalFollowing: null,
  setOpenModalFollowing: (_value: FollowingUserOptions | null) => null,
  // FOLLOWED FOUNDRIES
  openModalFollowedFoundries: null,
  setOpenModalFollowedFoundries: (_value: FollowedFoundriesOptions | null) => null,
  // FOLLOWING FOUNDRIES
  openModalFollowingFoundry: null,
  setOpenModalFollowingFoundry: (_value: FollowingFoundryOptions | null) => null,
  // CONTRIBUTE TO FONTS NINJA
  openModalContributeToFontsNinja: null,
  setOpenModalContributeToFontsNinja: (_value: 'correct_information' | 'list_typefaces' | 'contact_us' | null) => null,
  // CONTRIBUTE TO FONTS NINJA SUCCESS
  openModalContributeToFontsNinjaSuccess: null,
  setOpenModalContributeToFontsNinjaSuccess: (_value: 'correct_information' | 'list_typefaces' | 'contact_us' | null) =>
    null,
  // DELETE FONTS NINJA ACCOUNT
  openModalDeleteFontsNinjaAccount: false,
  setOpenModalDeleteFontsNinjaAccount: (_value: boolean) => null,
  // DELETE ACCOUNT CONFIRMATION
  openModalDeleteAccountConfirmation: false,
  setOpenModalDeleteAccountConfirmation: (_value: boolean) => null,
  // SUPPORTED LANGUAGE SYSTEMS
  openModalSupportedLanguageSystems: null,
  setOpenModalSupportedLanguageSystems: (_value: SupportedLanguageSystemsOptions | null) => null,
  // FONT BUY LINKS
  openModalFontBuyLinks: null,
  setOpenModalFontBuyLinks: (_value: FontBuyLinksOptions | null) => null,
};

export const ModalContext = createContext(initialData);
export const useModalContext = () => useContext(ModalContext);

export const ModalContextProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): ReactElement => {
  const state = useModalState(initialData);

  return <ModalContext.Provider value={state}>{children}</ModalContext.Provider>;
};
