import { IconWrapper } from './FontUnauthorized.styled';

interface Props {
  darkMode: boolean;
  width: number;
  height: number;
  shadowSize: number;
}

const FontUnauthorized = ({ darkMode, width, height, shadowSize }: Props) => {
  return (
    <IconWrapper
      darkMode={darkMode}
      shadowSize={shadowSize}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 73 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.4219 3.1725L43.9791 2.71045H43.339H16.7106C11.4081 2.71045 6.92105 6.75928 6.92105 11.9476V55.4206C6.92105 60.6133 11.4127 64.6578 16.7106 64.6578H50.6578C55.9604 64.6578 60.4474 60.609 60.4474 55.4206V20.4947V19.8919L60.0303 19.4568L44.4219 3.1725Z"
          fill="white"
          stroke="#121212"
          strokeWidth="3"
        />
        <path
          d="M59.6506 20.4897H43.21V3.43555"
          stroke="black"
          strokeWidth="3"
        />
        <path
          d="M21.4726 26.4966H45.6098H45.6439V35.4989H41.5448V30.9872H35.5923V50.8668H38.8492V55.3574H28.2326V50.8668H31.4895V30.9872H25.5793V35.4989H21.4764L21.4726 30.9872V26.4966Z"
          fill="#121212"
        />
        <circle
          cx="57"
          cy="59"
          r="14"
          fill="white"
          stroke="black"
          strokeWidth="3"
        />
        <line
          x1="67.0607"
          y1="49.0607"
          x2="48.0607"
          y2="68.0607"
          stroke="black"
          strokeWidth="3"
        />
      </svg>
    </IconWrapper>
  );
};

export default FontUnauthorized;
