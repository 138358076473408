import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import api from 'api';
import { QUERY_KEYS } from 'common/data/Constants';

interface Options {
  enabled: boolean;
}

interface UpdateUserProps {
  userId: number;
  name: string;
  email: string;
  website: string;
  instagram: string;
  notifications: API.User.Notifications;
  preferredCurrency: string;
}

interface UpdateAvatarProps {
  file: File;
}

interface UpdatePasswordProps {
  password: string;
}

export const useUser = () => {
  // GET USER PROFILE DATA
  const useUserProfile = (options: Options = { enabled: true }) => {
    return useQuery([QUERY_KEYS.USER_PROFILE], () => api.user.getUserProfile(), options);
  };

  // UPDATE USER DATA
  const useUpdateUser = (options?: {
    onMutate?: (variables: UpdateUserProps) => void;
    onSuccess?: (data: API.User.UpdateUserProfile) => void;
    onError?: (err: AxiosError<API.Error>, variable: UpdateUserProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: UpdateUserProps) =>
        api.user.updateUser(
          variables.userId,
          variables.name,
          variables.email,
          variables.website,
          variables.instagram,
          variables.notifications,
          variables.preferredCurrency
        ),
      options
    );
  };

  // UPDATE PASSWORD
  const useUpdatePassword = (options?: {
    onMutate?: (variables: UpdatePasswordProps) => void;
    onSuccess?: (data: API.Auth.Tokens) => void;
    onError?: (err: AxiosError<API.Error>, variable: UpdatePasswordProps, context: any) => void;
  }) => {
    return useMutation(
      (variables: UpdatePasswordProps) => api.authentication.updatePassword(variables.password),
      options
    );
  };

  // DELETE USER
  const useDeleteUser = (options?: {
    onMutate?: () => void;
    onSuccess?: (data: API.User.DeleteUser) => void;
    onError?: (err: AxiosError<API.Error>) => void;
  }) => {
    return useMutation(() => api.user.deleteUser(), options);
  };

  // UPDATE AVATAR
  const useUpdateAvatar = (options?: {
    onMutate?: (variables: UpdateAvatarProps) => void;
    onSuccess?: (data: API.User.UserAvatar) => void;
    onError?: (err: AxiosError<API.Error>, variable: UpdateAvatarProps, context: any) => void;
  }) => {
    return useMutation((variables: UpdateAvatarProps) => api.user.updateAvatar(variables.file), options);
  };

  // GET PUBLIC USER DATA
  const usePublicUserData = (userId: number) => {
    return useQuery([QUERY_KEYS.PUBLIC_USER_DATA, { idUser: userId }], () => api.user.getPublicUserData(userId));
  };

  return {
    useUserProfile,
    useUpdateUser,
    useUpdatePassword,
    useDeleteUser,
    useUpdateAvatar,
    usePublicUserData,
  };
};
