import styled from '@emotion/styled';
import { DANGER_RED, GREY_6, WHITE } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
}

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 5px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 32px 16px;
  /* TABLET */
  ${mq['tablet']} {
    width: 384px;
    padding: 32px 32px 24px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Description = styled.p`
  margin-bottom: 40px;
  /* MOBILE */
  font-size: 16px;
  line-height: 22px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const ButtonDeleteWrapper = styled.div`
  margin-left: 8px;
  button {
    background-color: ${DANGER_RED};
    border-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
    color: ${WHITE};
  }
`;

export const GlobalError = styled.p`
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${DANGER_RED};
`;
