import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useBreakpointContext, useModalContext, useSettingsContext } from 'context';
import { useSocial } from 'hooks/queries/useSocial';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import { PAGE_SIZE_FOLLOW, QUERY_KEYS, ROUTES } from 'common/data/Constants';
import {
  CloseButtonWrapper,
  ContentWrapper,
  FollowButtonWrapper,
  FollowedFoundriesLoadingWrapper,
  FollowedFoundriesWrapper,
  FollowedFoundryItem,
  FollowedFoundryLoading,
  FollowedFoundryName,
  ModalWrapper,
  Separator,
  TitleWrapper,
} from './FollowedFoundries.styled';

const FollowedFoundries = () => {
  const { t } = useTranslation('common');
  const { darkMode } = useSettingsContext();
  const { isMobile } = useBreakpointContext();
  const { openModalFollowedFoundries, setOpenModalFollowedFoundries } = useModalContext();

  const queryClient = useQueryClient();

  const { useListFollowedFoundriesByUser, useFollowFoundry, useUnfollowFoundry } = useSocial();

  const {
    data: followedFoundriesData,
    isLoading: isLoadingFollowedFoundries,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useListFollowedFoundriesByUser(openModalFollowedFoundries?.idUser ?? 0, PAGE_SIZE_FOLLOW, {
    enabled: !!openModalFollowedFoundries,
  });

  const followFoundryMutation = useFollowFoundry({
    onMutate: (variables) => {
      if (followedFoundriesData) {
        // Snapshot the previous value
        const previousData = queryClient.getQueryData<InfiniteData<API.Social.ListFollowedFoundriesUser>>([
          QUERY_KEYS.LIST_FOLLOWED_FOUNDRIES_BY_USER,
          { idUser: openModalFollowedFoundries!.idUser },
        ]);
        const cloneFollowedFoundriesData: InfiniteData<API.Social.ListFollowedFoundriesUser> = JSON.parse(
          JSON.stringify(previousData)
        );
        for (let i = 0; i < cloneFollowedFoundriesData.pages.length; i++) {
          const currentFoundryIndex = cloneFollowedFoundriesData.pages[i].foundries.findIndex(
            (foundry) => foundry.id === variables.idFoundry
          );
          if (currentFoundryIndex !== -1) {
            cloneFollowedFoundriesData.pages[i].foundries[currentFoundryIndex].followed = true;
            queryClient.setQueryData(
              [QUERY_KEYS.LIST_FOLLOWED_FOUNDRIES_BY_USER, { idUser: openModalFollowedFoundries!.idUser }],
              cloneFollowedFoundriesData
            );
          }
        }
        return { previousData };
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_SOCIAL_SUMMARY] });
    },
    onError: (err, variables, context) => {
      if (context?.previousData) {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData(
          [QUERY_KEYS.LIST_FOLLOWED_FOUNDRIES_BY_USER, { idUser: openModalFollowedFoundries!.idUser }],
          context.previousData
        );
      }
    },
  });

  const unfollowFoundryMutation = useUnfollowFoundry({
    onMutate: (variables) => {
      if (followedFoundriesData) {
        // Snapshot the previous value
        const previousData = queryClient.getQueryData<InfiniteData<API.Social.ListFollowedFoundriesUser>>([
          QUERY_KEYS.LIST_FOLLOWED_FOUNDRIES_BY_USER,
          { idUser: openModalFollowedFoundries!.idUser },
        ]);
        const cloneFollowedFoundriesData: InfiniteData<API.Social.ListFollowedFoundriesUser> = JSON.parse(
          JSON.stringify(previousData)
        );
        for (let i = 0; i < cloneFollowedFoundriesData.pages.length; i++) {
          const currentFoundryIndex = cloneFollowedFoundriesData.pages[i].foundries.findIndex(
            (foundry) => foundry.id === variables.idFoundry
          );
          if (currentFoundryIndex !== -1) {
            cloneFollowedFoundriesData.pages[i].foundries[currentFoundryIndex].followed = false;
            queryClient.setQueryData(
              [QUERY_KEYS.LIST_FOLLOWED_FOUNDRIES_BY_USER, { idUser: openModalFollowedFoundries!.idUser }],
              cloneFollowedFoundriesData
            );
          }
        }
        return { previousData };
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_SOCIAL_SUMMARY] });
    },
    onError: (err, variables, context) => {
      if (context?.previousData) {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData(
          [QUERY_KEYS.LIST_FOLLOWED_FOUNDRIES_BY_USER, { idUser: openModalFollowedFoundries!.idUser }],
          context.previousData
        );
      }
    },
  });

  const modalWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const [startClose, setStartClose] = useState<boolean>(false);
  const [modalWrapperHeight, setModalWrapperHeight] = useState<string | undefined>(undefined);

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalFollowedFoundries(null);
  }, [setOpenModalFollowedFoundries]);

  const displaySeparator = useCallback(() => {
    if (isMobile) {
      if (followedFoundriesData && followedFoundriesData?.pages[0].totalFoundries >= 9) {
        return true;
      }
    } else {
      if (followedFoundriesData && followedFoundriesData?.pages[0].totalFoundries >= 12) {
        return true;
      }
    }
    return false;
  }, [followedFoundriesData, isMobile]);

  const handleFollow = useCallback(
    (foundryId: string) => {
      if (followedFoundriesData) {
        followFoundryMutation.mutate({ idFoundry: foundryId });
      }
    },
    [followFoundryMutation, followedFoundriesData]
  );

  const handleUnfollow = useCallback(
    (foundryId: string) => {
      if (followedFoundriesData) {
        unfollowFoundryMutation.mutate({ idFoundry: foundryId });
      }
    },
    [followedFoundriesData, unfollowFoundryMutation]
  );

  useEffect(() => {
    // transition resize ModalWrapper
    setTimeout(() => {
      if (modalWrapperRef.current && contentWrapperRef.current && followedFoundriesData) {
        const contentWrapperHeight = contentWrapperRef.current.getBoundingClientRect().height;
        modalWrapperRef.current!.style.height = `${contentWrapperHeight}px`;
        modalWrapperRef.current!.addEventListener('transitionend', () => {
          setModalWrapperHeight('auto');
          modalWrapperRef.current!.removeAttribute('style');
        });
      }
    }, 10);
    // transition resize ModalWrapper: Security for Safari when `transitionend` not fired
    setTimeout(() => {
      if (modalWrapperRef.current && modalWrapperRef.current.style.height !== '') {
        setModalWrapperHeight('auto');
        modalWrapperRef.current.removeAttribute('style');
      }
    }, 400);
  }, [followedFoundriesData]);

  useEffect(() => {
    if (!openModalFollowedFoundries) {
      setModalWrapperHeight(undefined);
    }
  }, [openModalFollowedFoundries]);

  if (openModalFollowedFoundries !== null) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper
          ref={modalWrapperRef}
          modalWrapperHeight={modalWrapperHeight}
        >
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper ref={contentWrapperRef}>
            <TitleWrapper displaySeparator={displaySeparator()}>
              {openModalFollowedFoundries.count > 1 ? (
                <H3
                  as="p"
                  color={darkMode ? WHITE : GREY_6}
                >
                  {`${openModalFollowedFoundries.count} ${t('followed-foundry.followed-foundries')}`}
                </H3>
              ) : (
                <H3
                  as="p"
                  color={darkMode ? WHITE : GREY_6}
                >
                  {`${openModalFollowedFoundries.count} ${t('followed-foundry.followed-foundry')}`}
                </H3>
              )}
            </TitleWrapper>
            <Separator
              displaySeparator={displaySeparator()}
              darkMode={darkMode}
            />
            <FollowedFoundriesWrapper
              id="followedFoundriesWrapper"
              displaySeparator={displaySeparator()}
            >
              {isLoadingFollowedFoundries && (
                <FollowedFoundriesLoadingWrapper>
                  <Spinner darkMode={darkMode} />
                </FollowedFoundriesLoadingWrapper>
              )}
              {!isLoadingFollowedFoundries && followedFoundriesData && (
                <InfiniteScroll
                  dataLength={followedFoundriesData.pages.reduce((acc, page) => acc + page.foundries.length, 0)}
                  next={() => {
                    if (!isFetchingNextPage) {
                      fetchNextPage();
                    }
                  }}
                  hasMore={!!hasNextPage}
                  loader={
                    <FollowedFoundryLoading>
                      <Spinner darkMode={darkMode} />
                    </FollowedFoundryLoading>
                  }
                  scrollableTarget="followedFoundriesWrapper"
                >
                  {followedFoundriesData.pages.map((page, pageIndex) => (
                    <Fragment key={pageIndex}>
                      {page.foundries.map((foundry, index) => (
                        <FollowedFoundryItem key={`${pageIndex}_${index}`}>
                          <Link
                            href={`${ROUTES.FOUNDRY}/${foundry.id}`}
                            onClick={handleClose}
                          >
                            <FollowedFoundryName>{foundry.name}</FollowedFoundryName>
                          </Link>
                          <FollowButtonWrapper>
                            {foundry.followed ? (
                              <Button
                                size="small"
                                render="contrast"
                                darkMode={darkMode}
                                onClick={() => handleUnfollow(foundry.id)}
                              >
                                {t('followed-foundry.following')}
                              </Button>
                            ) : (
                              <Button
                                size="small"
                                darkMode={darkMode}
                                onClick={() => handleFollow(foundry.id)}
                              >
                                {t('followed-foundry.follow')}
                              </Button>
                            )}
                          </FollowButtonWrapper>
                        </FollowedFoundryItem>
                      ))}
                    </Fragment>
                  ))}
                </InfiniteScroll>
              )}
            </FollowedFoundriesWrapper>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default FollowedFoundries;
