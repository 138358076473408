import { createContext, PropsWithChildren, ReactElement, useContext } from 'react';
import { BreakpointContextData, useBreakpointState } from 'hooks';
import { MOBILE } from 'common/data/Constants';

const initialData: BreakpointContextData = {
  breakpointsReady: false,
  breakpoint: MOBILE,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isDesktop1200: false,
  isDesktop1440: false,
};

export const BreakpointContext = createContext(initialData);
export const useBreakpointContext = () => useContext(BreakpointContext);

export const BreakpointContextProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): ReactElement => {
  const state = useBreakpointState(initialData);

  return <BreakpointContext.Provider value={state}>{children}</BreakpointContext.Provider>;
};
