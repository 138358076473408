import { GREY_6, WHITE } from 'design-system/global/colors';
import { useBreakpointContext } from 'context';

interface Props {
  darkMode: boolean;
}

const Pangram = ({ darkMode }: Props) => {
  const { isMobile, isTablet } = useBreakpointContext();

  return (
    <>
      {isMobile || isTablet ? (
        <svg
          width="139"
          height="84"
          viewBox="0 0 139 84"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="139"
            height="84"
            rx="3.3194"
            fill={darkMode ? GREY_6 : WHITE}
          />
          <path
            d="M36.7905 11.8597C35.8013 11.8597 35.1108 11.156 35.1108 10.127C35.1108 9.09797 35.8013 8.39426 36.7905 8.39426C37.7796 8.39426 38.4701 9.09797 38.4701 10.127C38.4701 11.156 37.7796 11.8597 36.7905 11.8597ZM36.7905 11.3751C37.461 11.3751 37.9124 10.8506 37.9124 10.127C37.9124 9.40336 37.461 8.87889 36.7905 8.87889C36.1199 8.87889 35.6685 9.40336 35.6685 10.127C35.6685 10.8506 36.1199 11.3751 36.7905 11.3751Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M34.8002 8.43408H34.9529V8.96519H34.6807C33.9703 8.96519 33.7778 9.55604 33.7778 10.0938V11.8199H33.2334V8.43408H33.7048L33.7778 8.94527C33.9371 8.68636 34.196 8.43408 34.8002 8.43408Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M29.3711 11.8199V7.1727H31.1569C32.0864 7.1727 32.6971 7.73036 32.6971 8.60004C32.6971 9.46973 32.0864 10.034 31.1569 10.034H29.9487V11.8199H29.3711ZM31.1105 7.7038H29.9487V9.50292H31.1038C31.7146 9.50292 32.1063 9.15106 32.1063 8.60004C32.1063 8.04902 31.7212 7.7038 31.1105 7.7038Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M24.1636 11.8199V7.1727H24.708V10.2332L26.4075 8.43407H27.078L25.7569 9.83486L27.171 11.8199H26.5137L25.3918 10.2265L24.708 10.9303V11.8199H24.1636Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M23.1521 7.86314C22.953 7.86314 22.8003 7.71708 22.8003 7.51128C22.8003 7.30548 22.953 7.15942 23.1521 7.15942C23.3513 7.15942 23.504 7.30548 23.504 7.51128C23.504 7.71708 23.3513 7.86314 23.1521 7.86314ZM22.8733 11.8199V8.43407H23.4177V11.8199H22.8733Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M20.8116 8.39426C21.575 8.39426 22.1792 8.8125 22.1792 9.93446V11.8199H21.6348V9.96765C21.6348 9.26394 21.3095 8.89217 20.7186 8.89217C20.0813 8.89217 19.6962 9.36352 19.6962 10.127V11.8199H19.1519V8.43409H19.6232L19.6962 8.89217C19.8954 8.63325 20.2406 8.39426 20.8116 8.39426Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M16.8481 11.8597C15.8589 11.8597 15.1685 11.156 15.1685 10.127C15.1685 9.09797 15.8589 8.39426 16.8481 8.39426C17.8373 8.39426 18.5277 9.09797 18.5277 10.127C18.5277 11.156 17.8373 11.8597 16.8481 11.8597ZM16.8481 11.3751C17.5186 11.3751 17.97 10.8506 17.97 10.127C17.97 9.40336 17.5186 8.87889 16.8481 8.87889C16.1776 8.87889 15.7261 9.40336 15.7261 10.127C15.7261 10.8506 16.1776 11.3751 16.8481 11.3751Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M13.1332 11.8597C12.1175 11.8597 11.4536 11.1626 11.4536 10.127C11.4536 9.09797 12.1042 8.39426 13.0602 8.39426C14.0162 8.39426 14.6402 8.99839 14.6668 10.0008C14.6668 10.0739 14.6602 10.1535 14.6535 10.2332H12.0246V10.2797C12.0445 10.9236 12.4494 11.3751 13.0934 11.3751C13.5714 11.3751 13.9432 11.1228 14.056 10.6846H14.607C14.4743 11.3618 13.9166 11.8597 13.1332 11.8597ZM12.0577 9.78177H14.0826C14.0295 9.19755 13.6311 8.87225 13.0668 8.87225C12.5689 8.87225 12.1109 9.23075 12.0577 9.78177Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M7.6512 11.8199H7.05371L8.82627 7.1727H9.51007L11.276 11.8199H10.6586L10.1673 10.5651H8.14248L7.6512 11.8199ZM9.15157 7.80339L8.31508 10.0407H9.9947L9.15157 7.80339Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M40.1299 10.1958V9.73776H45.5538V10.1958H40.1299Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M66.8056 11.6863C65.7899 11.6863 65.126 10.9892 65.126 9.95352C65.126 8.92451 65.7766 8.22079 66.7326 8.22079C67.6886 8.22079 68.3126 8.82493 68.3392 9.82739C68.3392 9.90041 68.3325 9.98008 68.3259 10.0597H65.6969V10.1062C65.7168 10.7502 66.1218 11.2016 66.7658 11.2016C67.2438 11.2016 67.6155 10.9493 67.7284 10.5112H68.2794C68.1466 11.1883 67.589 11.6863 66.8056 11.6863ZM65.7301 9.60831H67.7549C67.7018 9.02409 67.3035 8.69879 66.7392 8.69879C66.2413 8.69879 65.7832 9.05728 65.7301 9.60831Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M62.9967 8.22079C63.9328 8.22079 64.6232 8.85148 64.6232 9.95352C64.6232 10.9892 63.9328 11.6863 62.9967 11.6863C62.4258 11.6863 62.0407 11.4539 61.8349 11.1286V12.9742H61.2905V8.26063H61.7619L61.8349 8.80501C62.0606 8.49299 62.4258 8.22079 62.9967 8.22079ZM62.9436 11.2016C63.6141 11.2016 64.0655 10.6904 64.0655 9.95352C64.0655 9.21662 63.6141 8.70543 62.9436 8.70543C62.2797 8.70543 61.8283 9.21662 61.8283 9.94025C61.8283 10.6838 62.2797 11.2016 62.9436 11.2016Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M58.9993 11.6663L57.6782 8.26062H58.2558L59.2981 10.9626L60.3072 8.26062H60.8848L59.3246 12.2173C59.1122 12.7617 58.9861 12.9742 58.5081 12.9742H57.8575V12.4763H58.3421C58.6541 12.4763 58.7072 12.3966 58.8334 12.0779L58.9993 11.6663Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M56.0712 10.7635V8.75854H55.4604V8.26063H56.0712V7.31128H56.6156V8.26063H57.4919V8.75854H56.6156V10.7568C56.6156 11.0423 56.7152 11.1485 57.0073 11.1485H57.545V11.6464H56.9542C56.3036 11.6464 56.0712 11.361 56.0712 10.7635Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M53.5536 11.6863C52.5645 11.6863 51.874 10.9825 51.874 9.95352C51.874 8.92451 52.5645 8.22079 53.5536 8.22079C54.5428 8.22079 55.2333 8.92451 55.2333 9.95352C55.2333 10.9825 54.5428 11.6863 53.5536 11.6863ZM53.5536 11.2016C54.2242 11.2016 54.6756 10.6772 54.6756 9.95352C54.6756 9.22989 54.2242 8.70543 53.5536 8.70543C52.8831 8.70543 52.4317 9.22989 52.4317 9.95352C52.4317 10.6772 52.8831 11.2016 53.5536 11.2016Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M49.3843 11.6862C48.0499 11.6862 47.2134 10.7435 47.2134 9.32283C47.2134 7.9154 48.0831 6.95941 49.4307 6.95941C50.4598 6.95941 51.2099 7.5569 51.3825 8.51953H50.7718C50.5925 7.87557 50.1013 7.49052 49.4042 7.49052C48.4283 7.49052 47.8042 8.24734 47.8042 9.32283C47.8042 10.3917 48.4017 11.1551 49.3776 11.1551C50.0946 11.1551 50.5925 10.7767 50.7651 10.1261H51.3759C51.2033 11.0887 50.4332 11.6862 49.3843 11.6862Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M71.1646 66.1849C71.1646 65.3919 71.7471 64.8256 72.54 64.8256C73.3168 64.8256 73.9155 65.3919 73.9155 66.1849C73.9155 66.9616 73.3168 67.528 72.54 67.528C71.7471 67.528 71.1646 66.9616 71.1646 66.1849Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M67.5352 60.2622L67.7294 59.1133H69.736V67.1882C69.736 69.2433 68.4738 70.7644 65.2859 70.7644C62.9718 70.7644 61.386 69.7126 61.2727 67.7869H63.5706C63.781 68.5798 64.4121 69.0329 65.4639 69.0329C66.7423 69.0329 67.5028 68.4018 67.5028 67.1073V66.2334C66.9526 66.9293 66.1274 67.3824 64.9622 67.3824C62.7291 67.3985 61.0947 65.7641 61.0947 63.1912C61.0947 60.6506 62.7291 59 64.9461 59C66.1435 59 66.985 59.534 67.5352 60.2622ZM65.4639 65.5538C66.7099 65.5538 67.4867 64.599 67.4867 63.2236C67.4867 61.8319 66.7099 60.8448 65.4639 60.8448C64.2179 60.8448 63.4249 61.8157 63.4249 63.2074C63.4249 64.599 64.2179 65.5538 65.4639 65.5538Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M55.7769 67.528C53.1231 67.528 51.3916 65.8289 51.3916 63.2721C51.3916 60.7153 53.1231 59.0162 55.7769 59.0162C58.4308 59.0162 60.1623 60.7153 60.1623 63.2721C60.1623 65.845 58.4308 67.528 55.7769 67.528ZM55.7769 65.667C57.0553 65.667 57.8483 64.6799 57.8483 63.2721C57.8483 61.8643 57.0553 60.8771 55.7769 60.8771C54.4986 60.8771 53.7218 61.8643 53.7218 63.2721C53.7218 64.6799 54.4986 65.667 55.7769 65.667Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M47.843 60.1489V56.1034H50.1246V67.4309H48.1342L47.9077 66.282C47.3575 66.994 46.5484 67.528 45.3509 67.528C43.1178 67.528 41.4834 65.8936 41.4834 63.2559C41.4834 60.683 43.1178 59.0162 45.3347 59.0162C46.4837 59.0162 47.309 59.4693 47.843 60.1489ZM45.8526 65.667C47.0986 65.667 47.8753 64.6961 47.8753 63.2883C47.8753 61.8642 47.0986 60.8771 45.8526 60.8771C44.6065 60.8771 43.8136 61.8481 43.8136 63.2721C43.8136 64.6961 44.6065 65.667 45.8526 65.667Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M31.354 67.4794L28.1499 59.1133H30.5772L32.6 65.0359L34.5742 59.1133H37.0177L33.3282 68.6122C32.8103 69.9876 32.3896 70.6673 31.095 70.6673H28.8781V68.7416H30.1403C30.7229 68.7416 30.917 68.596 31.1436 68.0296L31.354 67.4794Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M20.6727 61.0389V59.1133H27.7119V60.6668L23.5531 65.5052H27.9061V67.4309H20.4785V65.8774L24.6373 61.0389H20.6727Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M19.6474 65.5052H19.9063V67.4309H18.7412C17.5599 67.4309 17.1877 66.8645 17.2039 66.0716C16.6213 66.994 15.7799 67.528 14.4853 67.528C12.6891 67.528 11.3945 66.6703 11.3945 65.0845C11.3945 63.3206 12.7215 62.3174 15.2135 62.3174H16.8641V61.9128C16.8641 61.1684 16.3301 60.683 15.3753 60.683C14.4853 60.683 13.8866 61.0875 13.7895 61.7024H11.5887C11.7505 60.0842 13.2393 59.0162 15.4401 59.0162C17.7703 59.0162 19.1134 60.0519 19.1134 62.0423V64.9874C19.1134 65.4243 19.2914 65.5052 19.6474 65.5052ZM16.8641 64.0003V63.8385H15.1973C14.2426 63.8385 13.6924 64.2268 13.6924 64.9227C13.6924 65.5052 14.1617 65.8774 14.9222 65.8774C16.1197 65.8774 16.8479 65.133 16.8641 64.0003Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M7.84668 67.4309V56.1034H10.1284V67.4309H7.84668Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M130.805 48.5052H131.064V50.4309H129.898C128.717 50.4309 128.345 49.8645 128.361 49.0716C127.779 49.994 126.937 50.528 125.643 50.528C123.846 50.528 122.552 49.6703 122.552 48.0845C122.552 46.3206 123.879 45.3174 126.371 45.3174H128.021V44.9128C128.021 44.1684 127.487 43.683 126.533 43.683C125.643 43.683 125.044 44.0875 124.947 44.7024H122.746C122.908 43.0842 124.397 42.0162 126.597 42.0162C128.927 42.0162 130.271 43.0519 130.271 45.0423V47.9874C130.271 48.4243 130.449 48.5052 130.805 48.5052ZM128.021 47.0003V46.8385H126.355C125.4 46.8385 124.85 47.2268 124.85 47.9227C124.85 48.5052 125.319 48.8774 126.079 48.8774C127.277 48.8774 128.005 48.133 128.021 47.0003Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M117.454 42.1133H117.842V44.1846H116.936C115.577 44.1846 115.059 45.0908 115.059 46.353V50.4309H112.777V42.1133H114.849L115.059 43.3593C115.512 42.6149 116.159 42.1133 117.454 42.1133Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M107.485 50.528C104.718 50.528 103.051 48.8612 103.051 46.2883C103.051 43.6991 104.75 42.0162 107.323 42.0162C109.815 42.0162 111.498 43.5697 111.53 46.0132C111.53 46.2559 111.514 46.531 111.465 46.7899H105.43V46.9032C105.478 48.0845 106.239 48.7965 107.388 48.7965C108.31 48.7965 108.957 48.3919 109.151 47.6152H111.401C111.142 49.2334 109.702 50.528 107.485 50.528ZM105.478 45.3012H109.232C109.071 44.2817 108.375 43.7153 107.339 43.7153C106.352 43.7153 105.608 44.3141 105.478 45.3012Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M97.1836 50.4309L94.1899 42.1133H96.6334L98.64 48.1007L100.614 42.1133H103.009L100.015 50.4309H97.1836Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M89.753 50.528C87.0992 50.528 85.3677 48.8289 85.3677 46.2721C85.3677 43.7153 87.0992 42.0162 89.753 42.0162C92.4069 42.0162 94.1384 43.7153 94.1384 46.2721C94.1384 48.845 92.4069 50.528 89.753 50.528ZM89.753 48.667C91.0314 48.667 91.8243 47.6799 91.8243 46.2721C91.8243 44.8643 91.0314 43.8771 89.753 43.8771C88.4746 43.8771 87.6979 44.8643 87.6979 46.2721C87.6979 47.6799 88.4746 48.667 89.753 48.667Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M72.708 47.6638H74.8764C74.9573 48.392 75.5884 48.8936 76.705 48.8936C77.6597 48.8936 78.2423 48.5861 78.2423 48.0036C78.2423 47.3239 77.6759 47.2754 76.3328 47.0974C74.391 46.8708 72.886 46.4339 72.886 44.7186C72.886 43.068 74.3262 41.9838 76.4623 42C78.6954 42 80.2327 42.9871 80.346 44.6863H78.1614C78.0966 44.0228 77.4655 43.6182 76.5432 43.6182C75.6532 43.6182 75.0706 43.9419 75.0706 44.4759C75.0706 45.107 75.7826 45.1717 76.9801 45.3174C78.8896 45.5115 80.4754 45.9485 80.4754 47.8256C80.4754 49.4762 78.9219 50.528 76.6888 50.528C74.3748 50.528 72.8051 49.4438 72.708 47.6638Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M68.0252 42.0162C70.2745 42 71.8927 43.6668 71.8927 46.2883C71.8927 48.8774 70.2745 50.528 68.0414 50.528C66.8924 50.528 66.0671 50.0587 65.5331 49.379V53.6673H63.2515V42.1133H65.2419L65.4684 43.2622C66.0024 42.5502 66.8439 42.0162 68.0252 42.0162ZM67.5235 48.667C68.7696 48.667 69.5787 47.6799 69.5787 46.2559C69.5787 44.8481 68.7696 43.8771 67.5235 43.8771C66.2775 43.8771 65.5008 44.8481 65.5008 46.2559C65.5008 47.6799 66.2775 48.667 67.5235 48.667Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M58.7435 42.0162C60.4588 42.0162 61.8181 42.9871 61.8181 45.6572V50.4309H59.5364V45.7866C59.5364 44.5406 59.0833 43.8933 58.0962 43.8933C57.1091 43.8933 56.4456 44.6215 56.4456 45.8837V50.4309H54.164V45.7866C54.164 44.5406 53.7109 43.8933 52.7076 43.8933C51.7205 43.8933 51.0732 44.6539 51.0732 45.8999V50.4309H48.7915V42.1133H50.7819L50.9923 43.149C51.4939 42.5017 52.2221 42.0324 53.4358 42.0162C54.4552 42 55.41 42.3722 55.9278 43.4241C56.5104 42.534 57.4813 42.0162 58.7435 42.0162Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M44.9236 46.2721V42.1133H47.2053V50.4309H45.1825L44.9722 49.4276C44.4382 50.0749 43.71 50.528 42.5287 50.528C40.8134 50.528 39.2275 49.6703 39.2275 46.7252V42.1133H41.5092V46.4177C41.5092 47.9065 41.9947 48.6347 43.1274 48.6347C44.2602 48.6347 44.9236 47.8094 44.9236 46.2721Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M36.6524 41.2233C35.908 41.2233 35.3255 40.6731 35.3255 39.9449C35.3255 39.2005 35.908 38.6503 36.6524 38.6503C37.3968 38.6503 37.9793 39.2005 37.9793 39.9449C37.9793 40.6731 37.3968 41.2233 36.6524 41.2233ZM33.8853 53.6673V51.7416H34.6782C35.2445 51.7416 35.5035 51.4665 35.5035 50.8193V42.1133H37.7851V51.1429C37.7851 52.6802 36.976 53.6673 35.3902 53.6673H33.8853Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M21.8281 50.4309L24.9189 46.1912L21.9738 42.1133H24.5791L26.2944 44.573L28.0582 42.1133H30.5179L27.5889 46.1912L30.6797 50.4309H28.0744L26.1649 47.8256L24.2716 50.4309H21.8281Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M17.4552 50.528C14.8013 50.528 13.0698 48.8289 13.0698 46.2721C13.0698 43.7153 14.8013 42.0162 17.4552 42.0162C20.109 42.0162 21.8405 43.7153 21.8405 46.2721C21.8405 48.845 20.109 50.528 17.4552 50.528ZM17.4552 48.667C18.7336 48.667 19.5265 47.6799 19.5265 46.2721C19.5265 44.8643 18.7336 43.8771 17.4552 43.8771C16.1768 43.8771 15.4 44.8643 15.4 46.2721C15.4 47.6799 16.1768 48.667 17.4552 48.667Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M7.06982 44.039V42.1133H8.4453V41.4984C8.4453 39.9611 9.27059 39.1034 10.9535 39.1034H12.8145V41.0453H11.3581C10.905 41.0453 10.6784 41.2556 10.6784 41.7249V42.1133H12.8145V44.039H10.727V50.4309H8.4453V44.039H7.06982Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M128.976 25.0162C130.773 25.0162 132.31 25.9709 132.31 28.819V33.4309H130.028V29.0455C130.028 27.6377 129.494 26.8933 128.345 26.8933C127.164 26.8933 126.484 27.7348 126.484 29.175V33.4309H124.203V25.1133H126.193L126.403 26.149C126.937 25.5017 127.698 25.0162 128.976 25.0162Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M112.87 33.4309L110.151 25.1133H112.562L114.229 30.9712L115.799 25.1133H117.854L119.375 30.9874L121.074 25.1133H123.404L120.67 33.4309H118.242L116.786 28.4145L115.297 33.4309H112.87Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M105.603 33.528C102.949 33.528 101.218 31.8289 101.218 29.2721C101.218 26.7153 102.949 25.0162 105.603 25.0162C108.257 25.0162 109.988 26.7153 109.988 29.2721C109.988 31.845 108.257 33.528 105.603 33.528ZM105.603 31.667C106.882 31.667 107.674 30.6799 107.674 29.2721C107.674 27.8643 106.882 26.8771 105.603 26.8771C104.325 26.8771 103.548 27.8643 103.548 29.2721C103.548 30.6799 104.325 31.667 105.603 31.667Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M100.403 25.1133H100.791V27.1846H99.8849C98.5256 27.1846 98.0078 28.0908 98.0078 29.353V33.4309H95.7261V25.1133H97.7974L98.0078 26.3593C98.4608 25.615 99.1081 25.1133 100.403 25.1133Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M90.6078 25.0162C92.8409 25.0162 94.4591 26.6182 94.4591 29.2559C94.4591 31.8289 92.8409 33.528 90.5916 33.528C89.3941 33.528 88.5688 33.0102 88.0348 32.2981L87.8083 33.4309H85.8179V22.1034H88.0995V26.1651C88.6497 25.5017 89.4588 25.0162 90.6078 25.0162ZM90.0899 31.667C91.336 31.667 92.1451 30.6961 92.1451 29.2721C92.1451 27.8481 91.336 26.8771 90.0899 26.8771C88.8439 26.8771 88.0672 27.8481 88.0672 29.2559C88.0672 30.6799 88.8439 31.667 90.0899 31.667Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M72.812 33.4309V22.1034H75.0937V28.641L78.3463 25.1133H80.9192L77.9256 28.3821L81.0811 33.4309H78.4434L76.3397 30.065L75.0937 31.4081V33.4309H72.812Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M67.5151 33.528C64.8774 33.528 63.2754 31.8936 63.2754 29.2721C63.2754 26.6991 64.9583 25.0162 67.596 25.0162C69.8615 25.0162 71.2693 26.246 71.593 28.2203H69.2142C69.02 27.395 68.4537 26.8771 67.5636 26.8771C66.3662 26.8771 65.6056 27.8643 65.6056 29.2721C65.6056 30.6961 66.3662 31.667 67.5636 31.667C68.4537 31.667 69.0524 31.133 69.2142 30.2916H71.6092C71.2855 32.282 69.7806 33.528 67.5151 33.528Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M60.8763 24.2233C60.1319 24.2233 59.5493 23.6731 59.5493 22.9449C59.5493 22.2005 60.1319 21.6503 60.8763 21.6503C61.6206 21.6503 62.2032 22.2005 62.2032 22.9449C62.2032 23.6731 61.6206 24.2233 60.8763 24.2233ZM59.7273 33.4309V25.1133H62.0252V33.4309H59.7273Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M55.8592 29.2721V25.1133H58.1409V33.4309H56.1181L55.9077 32.4276C55.3737 33.0749 54.6455 33.528 53.4642 33.528C51.7489 33.528 50.1631 32.6703 50.1631 29.7252V25.1133H52.4448V29.4177C52.4448 30.9065 52.9302 31.6347 54.063 31.6347C55.1957 31.6347 55.8592 30.8094 55.8592 29.2721Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M46.5009 26.2299L46.7275 25.1133H48.7179V36.6673H46.4362V32.3629C45.886 33.0425 45.0769 33.528 43.928 33.528C41.7111 33.528 40.0767 31.9259 40.0767 29.2883C40.0767 26.7153 41.7111 25 43.9442 25.0162C45.1255 25.0162 45.9669 25.5178 46.5009 26.2299ZM44.4458 31.667C45.6918 31.667 46.4686 30.6799 46.4686 29.2721C46.4686 27.8481 45.6918 26.8771 44.4458 26.8771C43.1998 26.8771 42.4069 27.8481 42.4069 29.2559C42.4069 30.6799 43.1998 31.667 44.4458 31.667Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M31.094 33.528C28.3269 33.528 26.6602 31.8612 26.6602 29.2883C26.6602 26.6991 28.3593 25.0162 30.9322 25.0162C33.4243 25.0162 35.1072 26.5697 35.1396 29.0132C35.1396 29.2559 35.1234 29.531 35.0748 29.7899H29.0389V29.9032C29.0875 31.0845 29.848 31.7965 30.997 31.7965C31.9193 31.7965 32.5666 31.3919 32.7608 30.6152H35.0101C34.7512 32.2334 33.311 33.528 31.094 33.528ZM29.0875 28.3012H32.8417C32.6799 27.2817 31.9841 26.7153 30.9484 26.7153C29.9613 26.7153 29.2169 27.3141 29.0875 28.3012Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M22.2121 25.0162C24.0568 25.0162 25.5779 26.0033 25.5779 28.819V33.4309H23.2963V29.0132C23.2963 27.6539 22.7461 26.8933 21.6133 26.8933C20.4482 26.8933 19.7524 27.7348 19.7524 29.1588V33.4309H17.4707V22.1034H19.7524V26.1328C20.2864 25.4855 21.0308 25.0162 22.2121 25.0162Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M7.37744 24.1909V22.1034H16.3585V24.1909H13.0735V33.4309H10.6462V24.1909H7.37744Z"
            fill={darkMode ? WHITE : GREY_6}
          />
        </svg>
      ) : (
        <svg
          width="96"
          height="114"
          viewBox="0 0 96 114"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H92C94.2091 0 96 1.79086 96 4V110C96 112.209 94.2091 114 92 114H4C1.79086 114 0 112.209 0 110V4Z"
            fill={darkMode ? GREY_6 : WHITE}
          />
          <path
            d="M44.6869 12.0319C43.9219 12.0319 43.4219 11.5069 43.4219 10.7269C43.4219 9.95187 43.9119 9.42188 44.6319 9.42188C45.3519 9.42188 45.8219 9.87688 45.8419 10.6319C45.8419 10.6869 45.8369 10.7469 45.8319 10.8069H43.8519V10.8419C43.8669 11.3269 44.1719 11.6669 44.6569 11.6669C45.0169 11.6669 45.2969 11.4769 45.3819 11.1469H45.7969C45.6969 11.6569 45.2769 12.0319 44.6869 12.0319ZM43.8769 10.4669H45.4019C45.3619 10.0269 45.0619 9.78187 44.6369 9.78187C44.2619 9.78187 43.9169 10.0519 43.8769 10.4669Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M41.8202 9.42188C42.5252 9.42188 43.0452 9.89687 43.0452 10.7269C43.0452 11.5069 42.5252 12.0319 41.8202 12.0319C41.3902 12.0319 41.1002 11.8569 40.9452 11.6119V13.0019H40.5352V9.45187H40.8902L40.9452 9.86187C41.1152 9.62687 41.3902 9.42188 41.8202 9.42188ZM41.7802 11.6669C42.2852 11.6669 42.6252 11.2819 42.6252 10.7269C42.6252 10.1719 42.2852 9.78687 41.7802 9.78687C41.2802 9.78687 40.9402 10.1719 40.9402 10.7169C40.9402 11.2769 41.2802 11.6669 41.7802 11.6669Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M38.8075 12.0103L37.8125 9.44531H38.2475L39.0325 11.4803L39.7925 9.44531H40.2275L39.0525 12.4253C38.8925 12.8353 38.7975 12.9953 38.4375 12.9953H37.9475V12.6203H38.3125C38.5475 12.6203 38.5875 12.5603 38.6825 12.3203L38.8075 12.0103Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M35.1875 8.9V8.5H37.8925V8.9H36.7575V12H36.3225V8.9H35.1875Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M34.0033 12.0319C33.2583 12.0319 32.7383 11.5019 32.7383 10.7269C32.7383 9.95187 33.2583 9.42188 34.0033 9.42188C34.7483 9.42188 35.2683 9.95187 35.2683 10.7269C35.2683 11.5019 34.7483 12.0319 34.0033 12.0319ZM34.0033 11.6669C34.5083 11.6669 34.8483 11.2719 34.8483 10.7269C34.8483 10.1819 34.5083 9.78687 34.0033 9.78687C33.4983 9.78687 33.1583 10.1819 33.1583 10.7269C33.1583 11.2719 33.4983 11.6669 34.0033 11.6669Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M30.8616 12.0287C29.8566 12.0287 29.2266 11.3187 29.2266 10.2487C29.2266 9.18875 29.8816 8.46875 30.8966 8.46875C31.6716 8.46875 32.2366 8.91875 32.3666 9.64375H31.9066C31.7716 9.15875 31.4016 8.86875 30.8766 8.86875C30.1416 8.86875 29.6716 9.43875 29.6716 10.2487C29.6716 11.0537 30.1216 11.6287 30.8566 11.6287C31.3966 11.6287 31.7716 11.3437 31.9016 10.8537H32.3616C32.2316 11.5787 31.6516 12.0287 30.8616 12.0287Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M27.4502 10.7513H25.7852V10.4062H27.4502V10.7513Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M21.9453 12V8.5H22.3553V10.805L23.6353 9.45H24.1403L23.1453 10.505L24.2103 12H23.7153L22.8703 10.8L22.3553 11.33V12H21.9453Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M21.183 9.02219C21.033 9.02219 20.918 8.91219 20.918 8.75719C20.918 8.60219 21.033 8.49219 21.183 8.49219C21.333 8.49219 21.448 8.60219 21.448 8.75719C21.448 8.91219 21.333 9.02219 21.183 9.02219ZM20.973 12.0022V9.45219H21.383V12.0022H20.973Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M19.4219 9.42188C19.9969 9.42188 20.4519 9.73687 20.4519 10.5819V12.0019H20.0419V10.6069C20.0419 10.0769 19.7969 9.79688 19.3519 9.79688C18.8719 9.79688 18.5819 10.1519 18.5819 10.7269V12.0019H18.1719V9.45187H18.5269L18.5819 9.79688C18.7319 9.60187 18.9919 9.42188 19.4219 9.42188Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M16.4369 12.0319C15.6919 12.0319 15.1719 11.5019 15.1719 10.7269C15.1719 9.95187 15.6919 9.42188 16.4369 9.42188C17.1819 9.42188 17.7019 9.95187 17.7019 10.7269C17.7019 11.5019 17.1819 12.0319 16.4369 12.0319ZM16.4369 11.6669C16.9419 11.6669 17.2819 11.2719 17.2819 10.7269C17.2819 10.1819 16.9419 9.78687 16.4369 9.78687C15.9319 9.78687 15.5919 10.1819 15.5919 10.7269C15.5919 11.2719 15.9319 11.6669 16.4369 11.6669Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M13.6361 12.0319C12.8711 12.0319 12.3711 11.5069 12.3711 10.7269C12.3711 9.95187 12.8611 9.42188 13.5811 9.42188C14.3011 9.42188 14.7711 9.87688 14.7911 10.6319C14.7911 10.6869 14.7861 10.7469 14.7811 10.8069H12.8011V10.8419C12.8161 11.3269 13.1211 11.6669 13.6061 11.6669C13.9661 11.6669 14.2461 11.4769 14.3311 11.1469H14.7461C14.6461 11.6569 14.2261 12.0319 13.6361 12.0319ZM12.8261 10.4669H14.3511C14.3111 10.0269 14.0111 9.78187 13.5861 9.78187C13.2111 9.78187 12.8661 10.0519 12.8261 10.4669Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M9.50859 12H9.05859L10.3936 8.5H10.9086L12.2386 12H11.7736L11.4036 11.055H9.87859L9.50859 12ZM10.6386 8.975L10.0086 10.66H11.2736L10.6386 8.975Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M55.6953 66.42C56.2653 66.42 56.7303 66.735 56.7303 67.58V69H56.3203V67.605C56.3203 67.075 56.0653 66.795 55.6253 66.795C55.1503 66.795 54.8553 67.15 54.8553 67.725V69H54.4453V65.5H54.8553V66.87C55.0053 66.63 55.2653 66.42 55.6953 66.42Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M52.7498 66.4219C53.4548 66.4219 53.9748 66.8969 53.9748 67.7269C53.9748 68.5069 53.4548 69.0319 52.7498 69.0319C52.3198 69.0319 52.0298 68.8569 51.8748 68.6119V70.0019H51.4648V66.4519H51.8198L51.8748 66.8619C52.0448 66.6269 52.3198 66.4219 52.7498 66.4219ZM52.7098 68.6669C53.2148 68.6669 53.5548 68.2819 53.5548 67.7269C53.5548 67.1719 53.2148 66.7869 52.7098 66.7869C52.2098 66.7869 51.8698 67.1719 51.8698 67.7169C51.8698 68.2769 52.2098 68.6669 52.7098 68.6669Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M49.7411 69.0103L48.7461 66.4453H49.1811L49.9661 68.4803L50.7261 66.4453H51.1611L49.9861 69.4253C49.8261 69.8353 49.7311 69.9953 49.3711 69.9953H48.8811V69.6203H49.2461C49.4811 69.6203 49.5211 69.5603 49.6161 69.3203L49.7411 69.0103Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M48.0273 69V65.5H48.4373V69H48.0273Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M45.8677 69.0287C44.8977 69.0287 44.2227 68.3087 44.2227 67.2487C44.2227 66.1887 44.8777 65.4688 45.8927 65.4688C46.6677 65.4688 47.2527 65.9187 47.3727 66.6387H46.9077C46.7877 66.1537 46.4127 65.8687 45.8827 65.8687C45.1477 65.8687 44.6677 66.4387 44.6677 67.2487C44.6677 68.0587 45.1477 68.6287 45.8727 68.6287C46.5177 68.6287 46.9827 68.2437 46.9827 67.6237V67.5037H45.9327V67.1037H47.4077V68.9987H47.0327L47.0027 68.4887C46.8427 68.7437 46.4677 69.0287 45.8677 69.0287Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M40.9656 68.9953L40.1406 66.4453H40.5706L41.2056 68.5203L41.8756 66.4453H42.2556L42.8906 68.5203L43.5556 66.4453H43.9706L43.1206 68.9953H42.6706L42.0556 67.1003L41.4156 68.9953H40.9656Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M38.8431 69.0319C38.0781 69.0319 37.5781 68.5069 37.5781 67.7269C37.5781 66.9519 38.0681 66.4219 38.7881 66.4219C39.5081 66.4219 39.9781 66.8769 39.9981 67.6319C39.9981 67.6869 39.9931 67.7469 39.9881 67.8069H38.0081V67.8419C38.0231 68.3269 38.3281 68.6669 38.8131 68.6669C39.1731 68.6669 39.4531 68.4769 39.5381 68.1469H39.9531C39.8531 68.6569 39.4331 69.0319 38.8431 69.0319ZM38.0331 67.4669H39.5581C39.5181 67.0269 39.2181 66.7819 38.7931 66.7819C38.4181 66.7819 38.0731 67.0519 38.0331 67.4669Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M34.7687 69H34.3438V65.5H34.7437L36.6238 68.2V65.5H37.0487V69H36.6488L34.7687 66.3V69Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M32.4384 67.7513H30.7734V67.4062H32.4384V67.7513Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M29.0375 68.6269H29.1675V69.0019H28.9425C28.6025 69.0019 28.4875 68.8569 28.4825 68.6069C28.3225 68.8369 28.0675 69.0319 27.6275 69.0319C27.0675 69.0319 26.6875 68.7519 26.6875 68.2869C26.6875 67.7769 27.0425 67.4919 27.7125 67.4919H28.4625V67.3169C28.4625 66.9869 28.2275 66.7869 27.8275 66.7869C27.4675 66.7869 27.2275 66.9569 27.1775 67.2169H26.7675C26.8275 66.7169 27.2325 66.4219 27.8475 66.4219C28.4975 66.4219 28.8725 66.7469 28.8725 67.3419V68.4569C28.8725 68.5919 28.9225 68.6269 29.0375 68.6269ZM28.4625 67.9619V67.8369H27.6725C27.3075 67.8369 27.1025 67.9719 27.1025 68.2619C27.1025 68.5119 27.3175 68.6819 27.6575 68.6819C28.1675 68.6819 28.4625 68.3869 28.4625 67.9619Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M25.1998 69.0319C24.4548 69.0319 23.9648 68.5269 23.9648 67.7319C23.9648 66.9519 24.4698 66.4219 25.2098 66.4219C25.8348 66.4219 26.2298 66.7719 26.3348 67.3319H25.9048C25.8298 66.9919 25.5798 66.7869 25.2048 66.7869C24.7148 66.7869 24.3848 67.1769 24.3848 67.7319C24.3848 68.2869 24.7148 68.6669 25.2048 68.6669C25.5698 68.6669 25.8198 68.4569 25.8998 68.1319H26.3348C26.2348 68.6819 25.8198 69.0319 25.1998 69.0319Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M23.2923 66.0222C23.1423 66.0222 23.0273 65.9122 23.0273 65.7572C23.0273 65.6022 23.1423 65.4922 23.2923 65.4922C23.4423 65.4922 23.5573 65.6022 23.5573 65.7572C23.5573 65.9122 23.4423 66.0222 23.2923 66.0222ZM23.0823 69.0022V66.4522H23.4923V69.0022H23.0823Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M21.6163 68.3344V66.8244H21.1562V66.4494H21.6163V65.7344H22.0262V66.4494H22.6862V66.8244H22.0262V68.3294C22.0262 68.5444 22.1012 68.6244 22.3212 68.6244H22.7262V68.9994H22.2812C21.7912 68.9994 21.6163 68.7844 21.6163 68.3344Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M20.8753 66.4453H20.9903V66.8453H20.7853C20.2503 66.8453 20.1053 67.2903 20.1053 67.6953V68.9953H19.6953V66.4453H20.0503L20.1053 66.8303C20.2253 66.6353 20.4203 66.4453 20.8753 66.4453Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M19.2484 68.6269H19.3784V69.0019H19.1534C18.8134 69.0019 18.6984 68.8569 18.6934 68.6069C18.5334 68.8369 18.2784 69.0319 17.8384 69.0319C17.2784 69.0319 16.8984 68.7519 16.8984 68.2869C16.8984 67.7769 17.2534 67.4919 17.9234 67.4919H18.6734V67.3169C18.6734 66.9869 18.4384 66.7869 18.0384 66.7869C17.6784 66.7869 17.4384 66.9569 17.3884 67.2169H16.9784C17.0384 66.7169 17.4434 66.4219 18.0584 66.4219C18.7084 66.4219 19.0834 66.7469 19.0834 67.3419V68.4569C19.0834 68.5919 19.1334 68.6269 19.2484 68.6269ZM18.6734 67.9619V67.8369H17.8834C17.5184 67.8369 17.3134 67.9719 17.3134 68.2619C17.3134 68.5119 17.5284 68.6819 17.8684 68.6819C18.3784 68.6819 18.6734 68.3869 18.6734 67.9619Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M15.5655 68.3344V66.8244H15.1055V66.4494H15.5655V65.7344H15.9755V66.4494H16.6355V66.8244H15.9755V68.3294C15.9755 68.5444 16.0505 68.6244 16.2705 68.6244H16.6755V68.9994H16.2305C15.7405 68.9994 15.5655 68.7844 15.5655 68.3344Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M13.8242 66.4219C14.3992 66.4219 14.8542 66.7369 14.8542 67.5819V69.0019H14.4442V67.6069C14.4442 67.0769 14.1992 66.7969 13.7542 66.7969C13.2742 66.7969 12.9842 67.1519 12.9842 67.7269V69.0019H12.5742V66.4519H12.9292L12.9842 66.7969C13.1342 66.6019 13.3942 66.4219 13.8242 66.4219Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M9.50859 69H9.05859L10.3936 65.5H10.9086L12.2386 69H11.7736L11.4036 68.055H9.87859L9.50859 69ZM10.6386 65.975L10.0086 67.66H11.2736L10.6386 65.975Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M48.1133 45.2355C48.1133 44.7518 48.4687 44.4062 48.9524 44.4062C49.4263 44.4062 49.7916 44.7518 49.7916 45.2355C49.7916 45.7094 49.4263 46.0549 48.9524 46.0549C48.4687 46.0549 48.1133 45.7094 48.1133 45.2355Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M45.9019 41.6294L46.0203 40.9285H47.2445V45.8548C47.2445 47.1086 46.4745 48.0366 44.5296 48.0366C43.1178 48.0366 42.1504 47.3949 42.0812 46.2201H43.4831C43.6115 46.7038 43.9965 46.9802 44.6382 46.9802C45.4181 46.9802 45.8821 46.5952 45.8821 45.8054V45.2723C45.5465 45.6968 45.043 45.9733 44.3322 45.9733C42.9698 45.9831 41.9727 44.986 41.9727 43.4163C41.9727 41.8664 42.9698 40.8594 44.3223 40.8594C45.0528 40.8594 45.5662 41.1852 45.9019 41.6294ZM44.6382 44.8577C45.3984 44.8577 45.8722 44.2752 45.8722 43.4361C45.8722 42.587 45.3984 41.9848 44.6382 41.9848C43.878 41.9848 43.3943 42.5772 43.3943 43.4262C43.3943 44.2752 43.878 44.8577 44.6382 44.8577Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M38.7262 46.0601C37.1071 46.0601 36.0508 45.0235 36.0508 43.4636C36.0508 41.9038 37.1071 40.8672 38.7262 40.8672C40.3453 40.8672 41.4016 41.9038 41.4016 43.4636C41.4016 45.0333 40.3453 46.0601 38.7262 46.0601ZM38.7262 44.9247C39.5061 44.9247 39.9899 44.3225 39.9899 43.4636C39.9899 42.6047 39.5061 42.0025 38.7262 42.0025C37.9463 42.0025 37.4724 42.6047 37.4724 43.4636C37.4724 44.3225 37.9463 44.9247 38.7262 44.9247Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M33.8877 41.554V39.0859H35.2797V45.9966H34.0654L33.9271 45.2957C33.5915 45.73 33.0979 46.0558 32.3673 46.0558C31.0049 46.0558 30.0078 45.0587 30.0078 43.4495C30.0078 41.8798 31.0049 40.863 32.3574 40.863C33.0584 40.863 33.5619 41.1394 33.8877 41.554ZM32.6734 44.9205C33.4335 44.9205 33.9074 44.3282 33.9074 43.4693C33.9074 42.6005 33.4335 41.9983 32.6734 41.9983C31.9132 41.9983 31.4294 42.5906 31.4294 43.4594C31.4294 44.3282 31.9132 44.9205 32.6734 44.9205Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M23.8258 46.0259L21.8711 40.9219H23.3519L24.586 44.5352L25.7904 40.9219H27.2812L25.0303 46.717C24.7143 47.5561 24.4577 47.9708 23.6679 47.9708H22.3154V46.7959H23.0854C23.4408 46.7959 23.5593 46.7071 23.6975 46.3616L23.8258 46.0259Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M17.3099 42.0967V40.9219H21.6044V41.8696L19.0672 44.8215H21.7228V45.9963H17.1914V45.0485L19.7286 42.0967H17.3099Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M16.6833 44.826H16.8413V46.0008H16.1305C15.4098 46.0008 15.1827 45.6553 15.1926 45.1715C14.8372 45.7343 14.3239 46.0601 13.5341 46.0601C12.4382 46.0601 11.6484 45.5368 11.6484 44.5693C11.6484 43.4932 12.458 42.8812 13.9783 42.8812H14.9853V42.6343C14.9853 42.1802 14.6595 41.884 14.077 41.884C13.5341 41.884 13.1688 42.1309 13.1095 42.506H11.7669C11.8656 41.5188 12.7739 40.8672 14.1165 40.8672C15.5382 40.8672 16.3576 41.499 16.3576 42.7133V44.5101C16.3576 44.7766 16.4662 44.826 16.6833 44.826ZM14.9853 43.9079V43.8092H13.9684C13.386 43.8092 13.0503 44.0461 13.0503 44.4706C13.0503 44.826 13.3366 45.0531 13.8006 45.0531C14.5312 45.0531 14.9754 44.5989 14.9853 43.9079Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M9.48438 45.9966V39.0859H10.8764V45.9966H9.48438Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M84.4998 33.826H84.6577V35.0008H83.9469C83.2262 35.0008 82.9992 34.6553 83.009 34.1715C82.6536 34.7343 82.1403 35.0601 81.3505 35.0601C80.2546 35.0601 79.4648 34.5368 79.4648 33.5693C79.4648 32.4932 80.2744 31.8812 81.7947 31.8812H82.8017V31.6343C82.8017 31.1802 82.4759 30.884 81.8934 30.884C81.3505 30.884 80.9852 31.1309 80.926 31.506H79.5833C79.682 30.5188 80.5903 29.8672 81.9329 29.8672C83.3546 29.8672 84.174 30.499 84.174 31.7133V33.5101C84.174 33.7766 84.2826 33.826 84.4998 33.826ZM82.8017 32.9079V32.8092H81.7849C81.2024 32.8092 80.8667 33.0461 80.8667 33.4706C80.8667 33.826 81.153 34.0531 81.617 34.0531C82.3476 34.0531 82.7918 33.5989 82.8017 32.9079Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M76.3531 29.9219H76.59V31.1855H76.0372C75.2079 31.1855 74.892 31.7384 74.892 32.5084V34.9963H73.5V29.9219H74.7637L74.892 30.682C75.1684 30.2279 75.5633 29.9219 76.3531 29.9219Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M70.2714 35.0601C68.5833 35.0601 67.5664 34.0432 67.5664 32.4735C67.5664 30.8939 68.603 29.8672 70.1727 29.8672C71.6931 29.8672 72.7198 30.8149 72.7395 32.3057C72.7395 32.4538 72.7297 32.6216 72.7 32.7795H69.0176V32.8486C69.0473 33.5693 69.5113 34.0037 70.2122 34.0037C70.7749 34.0037 71.1698 33.7569 71.2883 33.283H72.6605C72.5026 34.2703 71.624 35.0601 70.2714 35.0601ZM69.0473 31.8713H71.3377C71.2389 31.2493 70.8144 30.9038 70.1826 30.9038C69.5804 30.9038 69.1262 31.2691 69.0473 31.8713Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M63.9865 34.9963L62.1602 29.9219H63.6509L64.8751 33.5747L66.0795 29.9219H67.5406L65.7142 34.9963H63.9865Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M59.4567 35.0601C57.8376 35.0601 56.7812 34.0235 56.7812 32.4636C56.7812 30.9038 57.8376 29.8672 59.4567 29.8672C61.0757 29.8672 62.1321 30.9038 62.1321 32.4636C62.1321 34.0333 61.0757 35.0601 59.4567 35.0601ZM59.4567 33.9247C60.2366 33.9247 60.7203 33.3225 60.7203 32.4636C60.7203 31.6047 60.2366 31.0025 59.4567 31.0025C58.6767 31.0025 58.2029 31.6047 58.2029 32.4636C58.2029 33.3225 58.6767 33.9247 59.4567 33.9247Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M49.0547 33.3148H50.3776C50.4269 33.7591 50.812 34.0651 51.4932 34.0651C52.0756 34.0651 52.431 33.8775 52.431 33.5221C52.431 33.1075 52.0855 33.0779 51.2661 32.9693C50.0814 32.8311 49.1633 32.5645 49.1633 31.518C49.1633 30.5111 50.0419 29.8496 51.3451 29.8595C52.7075 29.8595 53.6453 30.4617 53.7144 31.4983H52.3817C52.3422 31.0935 51.9572 30.8467 51.3944 30.8467C50.8515 30.8467 50.4961 31.0442 50.4961 31.37C50.4961 31.755 50.9304 31.7945 51.661 31.8833C52.8259 32.0018 53.7934 32.2683 53.7934 33.4135C53.7934 34.4205 52.8457 35.0622 51.4833 35.0622C50.0715 35.0622 49.1139 34.4008 49.0547 33.3148Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M46.1975 29.8673C47.5698 29.8574 48.557 30.8742 48.557 32.4736C48.557 34.0531 47.5698 35.0601 46.2074 35.0601C45.5064 35.0601 45.003 34.7738 44.6772 34.3592V36.9754H43.2852V29.9265H44.4995L44.6377 30.6274C44.9635 30.193 45.4768 29.8673 46.1975 29.8673ZM45.8915 33.9248C46.6516 33.9248 47.1453 33.3226 47.1453 32.4538C47.1453 31.5949 46.6516 31.0026 45.8915 31.0026C45.1313 31.0026 44.6574 31.5949 44.6574 32.4538C44.6574 33.3226 45.1313 33.9248 45.8915 33.9248Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M40.5364 29.8675C41.5828 29.8675 42.4121 30.4598 42.4121 32.0888V35.0011H41.0201V32.1678C41.0201 31.4076 40.7437 31.0127 40.1415 31.0127C39.5392 31.0127 39.1345 31.4569 39.1345 32.227V35.0011H37.7425V32.1678C37.7425 31.4076 37.466 31.0127 36.854 31.0127C36.2517 31.0127 35.8568 31.4767 35.8568 32.2369V35.0011H34.4648V29.9267H35.6791L35.8075 30.5586C36.1135 30.1637 36.5578 29.8774 37.2982 29.8675C37.9202 29.8576 38.5026 30.0847 38.8186 30.7264C39.174 30.1834 39.7663 29.8675 40.5364 29.8675Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M32.104 32.4591V29.9219H33.496V34.9963H32.2619L32.1336 34.3842C31.8078 34.7791 31.3636 35.0555 30.6429 35.0555C29.5964 35.0555 28.6289 34.5323 28.6289 32.7355V29.9219H30.0209V32.5479C30.0209 33.4562 30.3171 33.9004 31.0081 33.9004C31.6992 33.9004 32.104 33.397 32.104 32.4591Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M27.0593 29.3822C26.6051 29.3822 26.2497 29.0465 26.2497 28.6023C26.2497 28.1482 26.6051 27.8125 27.0593 27.8125C27.5134 27.8125 27.8688 28.1482 27.8688 28.6023C27.8688 29.0465 27.5134 29.3822 27.0593 29.3822ZM25.3711 36.9741V35.7993H25.8548C26.2004 35.7993 26.3583 35.6314 26.3583 35.2365V29.9252H27.7503V35.434C27.7503 36.3718 27.2567 36.9741 26.2892 36.9741H25.3711Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M18.0156 34.9963L19.9012 32.4097L18.1045 29.9219H19.6939L20.7404 31.4225L21.8165 29.9219H23.3171L21.5302 32.4097L23.4158 34.9963H21.8264L20.6614 33.4068L19.5064 34.9963H18.0156Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M15.3473 35.0601C13.7282 35.0601 12.6719 34.0235 12.6719 32.4636C12.6719 30.9038 13.7282 29.8672 15.3473 29.8672C16.9664 29.8672 18.0227 30.9038 18.0227 32.4636C18.0227 34.0333 16.9664 35.0601 15.3473 35.0601ZM15.3473 33.9247C16.1272 33.9247 16.611 33.3225 16.611 32.4636C16.611 31.6047 16.1272 31.0025 15.3473 31.0025C14.5674 31.0025 14.0935 31.6047 14.0935 32.4636C14.0935 33.3225 14.5674 33.9247 15.3473 33.9247Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M9.01172 31.097V29.9222H9.85087V29.547C9.85087 28.6092 10.3544 28.0859 11.3811 28.0859H12.5164V29.2706H11.6279C11.3515 29.2706 11.2133 29.399 11.2133 29.6853V29.9222H12.5164V31.097H11.2429V34.9966H9.85087V31.097H9.01172Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M83.385 18.8672C84.4808 18.8672 85.4187 19.4497 85.4187 21.1872V24.0008H84.0267V21.3254C84.0267 20.4665 83.7009 20.0124 83 20.0124C82.2793 20.0124 81.8647 20.5257 81.8647 21.4044V24.0008H80.4727V18.9264H81.687L81.8153 19.5583C82.1411 19.1634 82.6051 18.8672 83.385 18.8672Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M73.557 23.9963L71.8984 18.9219H73.3694L74.3863 22.4957L75.3439 18.9219H76.5977L77.5257 22.5055L78.5623 18.9219H79.9839L78.3155 23.9963H76.8346L75.9461 20.9358L75.0379 23.9963H73.557Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M69.1246 24.0601C67.5056 24.0601 66.4492 23.0235 66.4492 21.4636C66.4492 19.9038 67.5056 18.8672 69.1246 18.8672C70.7437 18.8672 71.8 19.9038 71.8 21.4636C71.8 23.0333 70.7437 24.0601 69.1246 24.0601ZM69.1246 22.9247C69.9045 22.9247 70.3883 22.3225 70.3883 21.4636C70.3883 20.6047 69.9045 20.0025 69.1246 20.0025C68.3447 20.0025 67.8708 20.6047 67.8708 21.4636C67.8708 22.3225 68.3447 22.9247 69.1246 22.9247Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M65.9508 18.9219H66.1877V20.1855H65.6349C64.8056 20.1855 64.4897 20.7384 64.4897 21.5084V23.9963H63.0977V18.9219H64.3613L64.4897 19.682C64.7661 19.2279 65.161 18.9219 65.9508 18.9219Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M59.9769 18.863C61.3393 18.863 62.3265 19.8403 62.3265 21.4495C62.3265 23.0192 61.3393 24.0558 59.967 24.0558C59.2365 24.0558 58.733 23.7399 58.4072 23.3055L58.269 23.9966H57.0547V17.0859H58.4467V19.5639C58.7824 19.1591 59.276 18.863 59.9769 18.863ZM59.661 22.9205C60.4212 22.9205 60.9148 22.3282 60.9148 21.4594C60.9148 20.5906 60.4212 19.9983 59.661 19.9983C58.9008 19.9983 58.4269 20.5906 58.4269 21.4495C58.4269 22.3183 58.9008 22.9205 59.661 22.9205Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M49.1211 23.9966V17.0859H50.5131V21.0744L52.4974 18.9222H54.0672L52.2408 20.9164L54.1659 23.9966H52.5567L51.2733 21.9431L50.5131 22.7626V23.9966H49.1211Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M45.8873 24.0601C44.2781 24.0601 43.3008 23.0629 43.3008 21.4636C43.3008 19.8939 44.3275 18.8672 45.9367 18.8672C47.3188 18.8672 48.1777 19.6175 48.3752 20.8219H46.9239C46.8055 20.3184 46.4599 20.0025 45.917 20.0025C45.1864 20.0025 44.7224 20.6047 44.7224 21.4636C44.7224 22.3324 45.1864 22.9247 45.917 22.9247C46.4599 22.9247 46.8252 22.5989 46.9239 22.0856H48.3851C48.1876 23.2999 47.2695 24.0601 45.8873 24.0601Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M41.8369 18.3822C41.3828 18.3822 41.0273 18.0465 41.0273 17.6023C41.0273 17.1482 41.3828 16.8125 41.8369 16.8125C42.291 16.8125 42.6464 17.1482 42.6464 17.6023C42.6464 18.0465 42.291 18.3822 41.8369 18.3822ZM41.1359 23.9996V18.9252H42.5378V23.9996H41.1359Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M38.7759 21.4591V18.9219H40.1679V23.9963H38.9338L38.8055 23.3842C38.4797 23.7791 38.0354 24.0555 37.3147 24.0555C36.2683 24.0555 35.3008 23.5323 35.3008 21.7355V18.9219H36.6928V21.5479C36.6928 22.4562 36.989 22.9004 37.68 22.9004C38.3711 22.9004 38.7759 22.397 38.7759 21.4591Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M33.0678 19.6077L33.206 18.9265H34.4203V25.9754H33.0283V23.3493C32.6926 23.764 32.199 24.0601 31.4981 24.0601C30.1455 24.0601 29.1484 23.0828 29.1484 21.4736C29.1484 19.9039 30.1455 18.8574 31.5079 18.8673C32.2286 18.8673 32.742 19.1733 33.0678 19.6077ZM31.814 22.9248C32.5742 22.9248 33.048 22.3226 33.048 21.4637C33.048 20.5949 32.5742 20.0026 31.814 20.0026C31.0538 20.0026 30.5701 20.5949 30.5701 21.4538C30.5701 22.3226 31.0538 22.9248 31.814 22.9248Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M23.6699 24.0601C21.9817 24.0601 20.9648 23.0432 20.9648 21.4735C20.9648 19.8939 22.0014 18.8672 23.5712 18.8672C25.0915 18.8672 26.1182 19.8149 26.138 21.3057C26.138 21.4537 26.1281 21.6216 26.0985 21.7795H22.4161V21.8486C22.4457 22.5693 22.9097 23.0037 23.6106 23.0037C24.1734 23.0037 24.5683 22.7569 24.6867 22.283H26.059C25.901 23.2703 25.0224 24.0601 23.6699 24.0601ZM22.4457 20.8713H24.7361C24.6374 20.2493 24.2129 19.9038 23.581 19.9038C22.9788 19.9038 22.5247 20.2691 22.4457 20.8713Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M18.2481 18.863C19.3735 18.863 20.3015 19.4652 20.3015 21.183V23.9966H18.9095V21.3014C18.9095 20.4722 18.5739 20.0082 17.8828 20.0082C17.172 20.0082 16.7475 20.5215 16.7475 21.3903V23.9966H15.3555V17.0859H16.7475V19.5442C17.0733 19.1493 17.5274 18.863 18.2481 18.863Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M9.19922 18.3595V17.0859H14.6784V18.3595H12.6743V23.9966H11.1934V18.3595H9.19922Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M46.989 102.002H45.8945V100.633H46.989V102.002Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M42.0385 101.942C40.922 101.942 40.207 101.106 40.207 99.1312V98.9332C40.207 96.9532 40.922 96.1172 42.0385 96.1172C42.693 96.1172 43.1715 96.4472 43.3695 96.8762V96.2657H44.453V101.98C44.453 103.586 43.6445 104.213 42.363 104.213C41.065 104.213 40.383 103.432 40.383 102.415H41.4335C41.4335 103.02 41.758 103.421 42.3795 103.421C42.9625 103.421 43.3695 103.091 43.3695 102.074V101.183C43.1715 101.617 42.693 101.942 42.0385 101.942ZM42.385 101.117C43.078 101.117 43.4135 100.561 43.4135 99.0652V98.9992C43.4135 97.4977 43.078 96.9477 42.385 96.9477C41.692 96.9477 41.362 97.4977 41.362 98.9992V99.0652C41.362 100.561 41.692 101.117 42.385 101.117Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M37.0465 102.156C35.5395 102.156 34.918 101.155 34.918 99.3072V98.9662C34.918 97.1182 35.5395 96.1172 37.0465 96.1172C38.5645 96.1172 39.186 97.1182 39.186 98.9662V99.3072C39.186 101.155 38.5645 102.156 37.0465 102.156ZM37.0465 101.326C37.778 101.326 38.0585 100.682 38.0585 99.3347V98.9387C38.0585 97.5912 37.778 96.9477 37.0465 96.9477C36.326 96.9477 36.0455 97.5912 36.0455 98.9387V99.3347C36.0455 100.682 36.326 101.326 37.0465 101.326Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M31.226 102.148C30.115 102.148 29.3945 101.284 29.3945 99.2986V98.9686C29.3945 96.9831 30.115 96.1196 31.226 96.1196C31.9135 96.1196 32.3645 96.4826 32.557 96.9061V93.9141H33.6405V101.999H32.557V101.361C32.3645 101.785 31.9135 102.148 31.226 102.148ZM31.5725 101.306C32.2655 101.306 32.6065 100.734 32.6065 99.2326V99.0346C32.6065 97.5331 32.2655 96.9611 31.5725 96.9611C30.8795 96.9611 30.5495 97.5331 30.5495 99.0346V99.2326C30.5495 100.734 30.8795 101.306 31.5725 101.306Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M22.9722 104.114L23.7367 101.991L21.9492 96.2656H23.0547L24.3362 100.583L25.8157 96.2656H26.8827L24.0777 104.114H22.9722Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M17.375 101.997V101.15L19.9105 97.1566H17.4795V96.2656H21.1315V97.1126L18.596 101.106H21.203V101.997H17.375Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M13.7111 102.144C12.6771 102.144 12.0391 101.55 12.0391 100.417V100.362C12.0391 98.9372 13.1941 98.4478 15.0476 98.4478H15.0531V98.0077C15.0531 97.1497 14.7011 96.8527 14.1676 96.8527C13.5956 96.8527 13.3151 97.1772 13.3151 97.9527H12.2426C12.2426 96.6822 13.0346 96.0938 14.1896 96.0938C15.3941 96.0938 16.1366 96.5172 16.1311 98.1397L16.1201 100.422C16.1146 101.17 16.1311 101.561 16.1806 102.001H15.1466C15.1411 101.759 15.1411 101.572 15.1356 101.346C14.8771 101.847 14.3546 102.144 13.7111 102.144ZM15.0861 99.1957C13.8266 99.1957 13.1556 99.4762 13.1556 100.329V100.356C13.1556 101.038 13.4911 101.368 14.0246 101.368C14.6351 101.368 15.0861 100.95 15.0861 99.9657V99.1957Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M9.75781 101.999V93.9141H10.8358V101.999H9.75781Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M75.3595 92.1437C74.3255 92.1437 73.6875 91.5498 73.6875 90.4167V90.3617C73.6875 88.9372 74.8425 88.4477 76.696 88.4477H76.7015V88.0077C76.7015 87.1497 76.3495 86.8527 75.816 86.8527C75.244 86.8527 74.9635 87.1772 74.9635 87.9527H73.891C73.891 86.6822 74.683 86.0938 75.838 86.0938C77.0425 86.0938 77.785 86.5172 77.7795 88.1397L77.7685 90.4222C77.763 91.1702 77.7795 91.5607 77.829 92.0007H76.795C76.7895 91.7587 76.7895 91.5717 76.784 91.3462C76.5255 91.8467 76.003 92.1437 75.3595 92.1437ZM76.7345 89.1957C75.475 89.1957 74.804 89.4762 74.804 90.3287V90.3562C74.804 91.0382 75.1395 91.3682 75.673 91.3682C76.2835 91.3682 76.7345 90.9502 76.7345 89.9657V89.1957Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M68.6602 91.9969V86.2659H69.7272V87.1514C69.9637 86.5684 70.4807 86.2109 71.1847 86.2109H71.2397V87.2339H71.1187C70.2222 87.2339 69.7382 87.7014 69.7382 88.6034V91.9969H68.6602Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M65.3082 92.1562C63.8012 92.1562 63.1797 91.1607 63.1797 89.3072V88.9772C63.1797 87.1237 63.8672 86.1172 65.3082 86.1172C66.8647 86.1172 67.3872 87.0027 67.3872 89.4117V89.4227H64.2907C64.2962 90.7097 64.5877 91.3257 65.3082 91.3257C65.9132 91.3257 66.2267 90.9627 66.2267 90.3522H67.3157C67.3157 91.4357 66.6227 92.1562 65.3082 92.1562ZM64.2907 88.5482H66.2872V88.5427C66.2872 87.4482 66.0617 86.8982 65.3082 86.8982C64.6207 86.8982 64.3182 87.4262 64.2907 88.5482Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M59.6844 91.9966L58.0234 86.2656H59.1344L60.3664 90.7481L61.6094 86.2656H62.6764L60.9934 91.9966H59.6844Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M55.3629 92.1562C53.8559 92.1562 53.2344 91.1552 53.2344 89.3072V88.9662C53.2344 87.1182 53.8559 86.1172 55.3629 86.1172C56.8809 86.1172 57.5024 87.1182 57.5024 88.9662V89.3072C57.5024 91.1552 56.8809 92.1562 55.3629 92.1562ZM55.3629 91.3257C56.0944 91.3257 56.3749 90.6822 56.3749 89.3347V88.9387C56.3749 87.5912 56.0944 86.9477 55.3629 86.9477C54.6424 86.9477 54.3619 87.5912 54.3619 88.9387V89.3347C54.3619 90.6822 54.6424 91.3257 55.3629 91.3257Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M48.516 92.1562C47.2125 92.1562 46.5195 91.5402 46.5195 90.3687H47.5975C47.5975 91.0837 47.9165 91.3917 48.5215 91.3917C49.066 91.3917 49.4015 91.0947 49.4015 90.6052V90.5722C49.4015 90.0772 49.143 89.9122 48.703 89.7252L47.7955 89.3402C46.998 89.0157 46.591 88.6032 46.591 87.6792V87.6572C46.591 86.6507 47.3995 86.1172 48.395 86.1172C49.5335 86.1172 50.2705 86.6232 50.2705 87.7617H49.1925C49.1925 87.0907 48.835 86.8817 48.417 86.8817C47.977 86.8817 47.636 87.1127 47.636 87.6242V87.6407C47.636 88.0422 47.834 88.2622 48.241 88.4382L49.1485 88.8177C49.9185 89.1312 50.4465 89.4887 50.4465 90.4512V90.5062C50.4465 91.6392 49.6325 92.1562 48.516 92.1562Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M43.7036 92.1452C43.0106 92.1452 42.5651 91.7822 42.3671 91.3587V94.1142H41.2891V86.2657H42.3671V86.9037C42.5651 86.4802 43.0106 86.1172 43.7036 86.1172C44.8146 86.1172 45.5351 86.9807 45.5351 88.9662V89.2962C45.5351 91.2817 44.8146 92.1452 43.7036 92.1452ZM43.3516 91.3037C44.0446 91.3037 44.3801 90.7317 44.3801 89.2302V89.0322C44.3801 87.5307 44.0446 86.9587 43.3516 86.9587C42.6586 86.9587 42.3231 87.5307 42.3231 89.0322V89.2302C42.3231 90.7317 42.6586 91.3037 43.3516 91.3037Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M32.9609 91.9967V86.2657H34.0389V86.8047C34.2644 86.3922 34.7484 86.1172 35.3204 86.1172C36.0299 86.1172 36.5249 86.3757 36.7669 87.0082C37.0694 86.3757 37.6579 86.1172 38.2134 86.1172C39.2364 86.1172 39.8194 86.6617 39.8194 88.1082V91.9967H38.7359V88.3557C38.7359 87.3767 38.4389 86.9587 37.8339 86.9587C37.2344 86.9587 36.9319 87.3767 36.9319 88.3557V91.9967H35.8484V88.3557C35.8484 87.3767 35.5459 86.9587 34.9464 86.9587C34.3414 86.9587 34.0389 87.3767 34.0389 88.3557V91.9967H32.9609Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M29.0904 92.1396C28.0674 92.1396 27.4844 91.6006 27.4844 90.1541V86.2656H28.5624V89.9011C28.5624 90.8856 28.8649 91.3036 29.4699 91.3036C30.0694 91.3036 30.3719 90.8856 30.3719 89.9011V86.2656H31.4554V91.9966H30.3719V91.4521C30.1519 91.8646 29.6624 92.1396 29.0904 92.1396Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M24.9381 85.2561V83.9141H26.0161V85.2561H24.9381ZM24.1406 94.1166V93.2256H24.2176C24.8006 93.2256 24.9381 93.0606 24.9381 92.5326V86.2681H26.0161V92.5601C26.0161 93.7261 25.5376 94.1166 24.3716 94.1166H24.1406Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M17.3125 91.9966L19.0835 89.1091L17.4115 86.2656H18.6105L19.7105 88.2016L20.8435 86.2656H21.8995L20.222 89.0046L21.9875 91.9966H20.794L19.606 89.9176L18.374 91.9966H17.3125Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M14.6598 92.1562C13.1528 92.1562 12.5312 91.1552 12.5312 89.3072V88.9662C12.5312 87.1182 13.1528 86.1172 14.6598 86.1172C16.1778 86.1172 16.7992 87.1182 16.7992 88.9662V89.3072C16.7992 91.1552 16.1778 92.1562 14.6598 92.1562ZM14.6598 91.3257C15.3913 91.3257 15.6718 90.6822 15.6718 89.3347V88.9387C15.6718 87.5912 15.3913 86.9477 14.6598 86.9477C13.9393 86.9477 13.6587 87.5912 13.6587 88.9387V89.3347C13.6587 90.6822 13.9393 91.3257 14.6598 91.3257Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M9.95647 91.9991V87.1591H9.23047V86.2681H9.95647V85.6576C9.95647 84.4366 10.4625 83.9141 11.711 83.9141H11.9805V84.8051H11.8375C11.15 84.8051 11.04 85.0196 11.04 85.7071V86.2681H11.9805V87.1591H11.04V91.9991H9.95647Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M74.6836 81.9967V76.2657H75.7616V76.8047C75.9871 76.3922 76.4711 76.1172 77.0431 76.1172C78.0661 76.1172 78.6546 76.6617 78.6546 78.1082V81.9967H77.5711V78.3557C77.5711 77.3767 77.2686 76.9587 76.6691 76.9587C76.0641 76.9587 75.7616 77.3767 75.7616 78.3557V81.9967H74.6836Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M67.8826 81.9966L66.6836 76.2656H67.7671L68.6141 80.8196L69.5546 76.2656H70.7536L71.6886 80.8086L72.5356 76.2656H73.6191L72.4256 81.9966H71.1331L70.1541 77.3931L69.1861 81.9966H67.8826Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M63.8785 82.1562C62.3715 82.1562 61.75 81.1552 61.75 79.3072V78.9662C61.75 77.1182 62.3715 76.1172 63.8785 76.1172C65.3965 76.1172 66.018 77.1182 66.018 78.9662V79.3072C66.018 81.1552 65.3965 82.1562 63.8785 82.1562ZM63.8785 81.3257C64.61 81.3257 64.8905 80.6822 64.8905 79.3347V78.9387C64.8905 77.5912 64.61 76.9477 63.8785 76.9477C63.158 76.9477 62.8775 77.5912 62.8775 78.9387V79.3347C62.8775 80.6822 63.158 81.3257 63.8785 81.3257Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M58.6133 81.9969V76.2659H59.6803V77.1514C59.9168 76.5684 60.4338 76.2109 61.1378 76.2109H61.1928V77.2339H61.0718C60.1753 77.2339 59.6913 77.7014 59.6913 78.6034V81.9969H58.6133Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M55.5083 82.1476C54.8153 82.1476 54.3698 81.7846 54.1718 81.3611V81.9991H53.0938V73.9141H54.1718V76.9061C54.3698 76.4826 54.8153 76.1196 55.5083 76.1196C56.6193 76.1196 57.3398 76.9831 57.3398 78.9686V79.2986C57.3398 81.2841 56.6193 82.1476 55.5083 82.1476ZM55.1562 81.3061C55.8493 81.3061 56.1847 80.7341 56.1847 79.2326V79.0346C56.1847 77.5331 55.8493 76.9611 55.1562 76.9611C54.4633 76.9611 54.1278 77.5331 54.1278 79.0346V79.2326C54.1278 80.7341 54.4633 81.3061 55.1562 81.3061Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M46.2617 81.9991V73.9141H47.3397V78.6331L49.1437 76.2681H50.3812L48.3407 78.9521L50.4747 81.9991H49.1657L47.3397 79.3921V81.9991H46.2617Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M43.0336 82.1562C41.5596 82.1562 40.9766 81.1662 40.9766 79.3072V78.9662C40.9766 77.1072 41.5596 76.1172 43.0336 76.1172C44.3041 76.1172 44.9971 76.8487 44.9971 78.1522H43.9026C43.9026 77.3437 43.6441 76.9477 43.0336 76.9477C42.3516 76.9477 42.1041 77.5912 42.1041 78.9387V79.3347C42.1041 80.6822 42.3516 81.3257 43.0336 81.3257C43.6441 81.3257 43.9026 80.9297 43.9026 80.1212H44.9971C44.9971 81.4247 44.3041 82.1562 43.0336 82.1562Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M38.625 75.2561V73.9141H39.703V75.2561H38.625ZM38.625 81.9991V76.2681H39.703V81.9991H38.625Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M34.7505 82.1396C33.7275 82.1396 33.1445 81.6006 33.1445 80.1541V76.2656H34.2225V79.9011C34.2225 80.8856 34.525 81.3036 35.13 81.3036C35.7295 81.3036 36.032 80.8856 36.032 79.9011V76.2656H37.1155V81.9966H36.032V81.4521C35.812 81.8646 35.3225 82.1396 34.7505 82.1396Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M29.2612 82.1475C28.1502 82.1475 27.4297 81.284 27.4297 79.2985V78.974C27.4297 76.983 28.1502 76.125 29.2612 76.125C29.9487 76.125 30.3997 76.488 30.5922 76.906V76.268H31.6757V84.1165H30.5922V81.361C30.3997 81.7845 29.9487 82.1475 29.2612 82.1475ZM29.6077 81.306C30.3007 81.306 30.6417 80.734 30.6417 79.2325V79.04C30.6417 77.5385 30.3007 76.961 29.6077 76.961C28.9147 76.961 28.5847 77.5385 28.5847 79.04V79.2325C28.5847 80.734 28.9147 81.306 29.6077 81.306Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M22.523 82.1562C21.016 82.1562 20.3945 81.1607 20.3945 79.3072V78.9772C20.3945 77.1237 21.082 76.1172 22.523 76.1172C24.0795 76.1172 24.602 77.0027 24.602 79.4117V79.4227H21.5055C21.511 80.7097 21.8025 81.3257 22.523 81.3257C23.128 81.3257 23.4415 80.9627 23.4415 80.3522H24.5305C24.5305 81.4357 23.8375 82.1562 22.523 82.1562ZM21.5055 78.5482H23.502V78.5427C23.502 77.4482 23.2765 76.8982 22.523 76.8982C21.8355 76.8982 21.533 77.4262 21.5055 78.5482Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M15.2148 81.9991V73.9141H16.2928V76.8071C16.5183 76.3946 17.0023 76.1196 17.5743 76.1196C18.5973 76.1196 19.1858 76.6641 19.1858 78.1106V81.9991H18.1023V78.3581C18.1023 77.3791 17.7998 76.9611 17.2003 76.9611C16.5953 76.9611 16.2928 77.3791 16.2928 78.3581V81.9991H15.2148Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            d="M11.1728 81.9991V74.8876H9.21484V73.9141H14.2473V74.8876H12.2893V81.9991H11.1728Z"
            fill={darkMode ? WHITE : GREY_6}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 57L95 57V58L0 58V57Z"
            fill={darkMode ? WHITE : GREY_6}
          />
        </svg>
      )}
    </>
  );
};

export default Pangram;
