import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { SelectBoard } from 'design-system/atoms/select-board';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { trackFontBookmarked } from 'tracking/Mixpanel';
import {
  useAuthContext,
  useBreakpointContext,
  useModalContext,
  useNotificationContext,
  useSettingsContext,
} from 'context';
import { AddBookmarkToBoardOptions } from 'hooks';
import { useBoard } from 'hooks/queries/useBoard';
import { useBookmark } from 'hooks/queries/useBookmark';
import { Filters } from 'api/familySearch.api';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import { ID_REQUEST, PAGE_NAMES, QUERY_KEYS, ROUTES, USER_PAGE } from 'common/data/Constants';
import { initialCurrency, initialSearchValue } from 'common/data/Settings';
import { getCurrentFilters } from 'common/utils';
import {
  BoardItemWrapper,
  BoardsEmptyMessage,
  BoardsLoadingWrapper,
  BoardsWrapper,
  ButtonDeleteWrapper,
  ButtonSaveWrapper,
  ButtonsWrapper,
  CloseButtonWrapper,
  ContentWrapper,
  ModalWrapper,
  Separator,
  TitleWrapper,
} from './AddBookmarkToBoard.styled';

interface BoardItem {
  idBoard: number;
  name: string;
  active: boolean;
  isPrivate: boolean;
}

interface BoardItemProps extends BoardItem {
  darkMode: boolean;
  onChangeBoard: (idBoard: number, active: boolean) => void;
}

const BoardItem = ({ idBoard, name, active, isPrivate, darkMode, onChangeBoard }: BoardItemProps) => {
  const handleClickBoard = useCallback(
    (active: boolean) => {
      onChangeBoard(idBoard, active);
    },
    [idBoard, onChangeBoard]
  );

  return (
    <SelectBoard
      darkMode={darkMode}
      active={active}
      privateBoard={isPrivate}
      onClick={handleClickBoard}
    >
      {name}
    </SelectBoard>
  );
};

const AddBookmarkToBoard = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { userId, preferredCurrency } = useAuthContext();
  const { isMobile, isTablet } = useBreakpointContext();
  const { openModalAddBookmarkToBoard, setOpenModalAddBookmarkToBoard, setOpenModalCreateNewBoard } = useModalContext();
  const { setOpenNotificationBookmark } = useNotificationContext();
  const { contrast, darkMode, ending, fontStyles, minStyles, priceRange, trialFonts, monospacedFonts, width } =
    useSettingsContext();

  const routeUserId = useMemo(() => {
    const userQuery = typeof router.query?.userQuery === 'string' ? router.query.userQuery : '';
    const userQuerySplit = userQuery.split('-');
    const userId = userQuerySplit[userQuerySplit.length - 1] as unknown as number;
    return userId;
  }, [router.query.userQuery]);

  const idRequest = useMemo(() => {
    if (router.query.hasOwnProperty(ID_REQUEST)) {
      if (typeof router.query[ID_REQUEST] === 'string') {
        return parseInt(router.query[ID_REQUEST]);
      }
      return null;
    }
    return null;
  }, [router.query]);

  const selectedCurrency = useMemo(() => preferredCurrency ?? initialCurrency, [preferredCurrency]);

  const [addBookmarkToBoardState, setAddBookmarkToBoardState] = useState<AddBookmarkToBoardOptions | null>(null);
  const [startClose, setStartClose] = useState<boolean>(false);
  const [modalWrapperHeight, setModalWrapperHeight] = useState<string | undefined>(undefined);
  const [boardsState, setBoardsState] = useState<Array<BoardItem>>([]);
  const [displayButtonDelete, setDisplayButtonDelete] = useState<boolean>(false);
  const [disableButtonSave, setDisableButtonSave] = useState<boolean>(false);
  const [disableButtonDelete, setDisableButtonDelete] = useState<boolean>(false);

  const filters: Filters = useMemo(
    () =>
      getCurrentFilters(
        initialSearchValue,
        minStyles,
        contrast,
        width,
        ending,
        fontStyles,
        trialFonts,
        monospacedFonts,
        priceRange,
        selectedCurrency,
        { type: 'user', value: userId! }
      ),
    [contrast, ending, fontStyles, minStyles, monospacedFonts, priceRange, selectedCurrency, trialFonts, userId, width]
  );

  const modalWrapperRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  const { useBoardListSimple } = useBoard();
  // Get data boardListSimple if `openModalAddBookmarkToBoard` or `addBookmarkToBoardState` is not null
  const { data: boardsData, isLoading: isLoadingBoards } = useBoardListSimple({
    enabled: !!openModalAddBookmarkToBoard ?? !!addBookmarkToBoardState,
  });

  const { useGetBookmark, useAddBookmark, useDeleteBookmark, useDeleteBookmarkFromBookmarkPage } = useBookmark();
  // Get data bookmark if `openModalAddBookmarkToBoard` or `addBookmarkToBoardState` is not null
  const { data: bookmarkData, isLoading: isLoadingBookmark } = useGetBookmark(
    openModalAddBookmarkToBoard?.idFamily ?? addBookmarkToBoardState?.idFamily ?? '',
    {
      enabled: !!openModalAddBookmarkToBoard ?? !!addBookmarkToBoardState,
    }
  );

  const addBookmarkMutation = useAddBookmark({
    onSuccess: (data) => {
      // Invalidate queries in all cases
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BOOKMARK, { idFamily: addBookmarkToBoardState!.idFamily }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BOOKMARK_STATUS, { idFamily: addBookmarkToBoardState!.idFamily }],
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BOARD_LIST_DETAILED] });
      if (router.route.indexOf(`${ROUTES.BOARD}/[boardQuery]`) > -1) {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FAMILY_SEARCH] });
      }
      // Display Notification
      if (addBookmarkToBoardState && bookmarkData) {
        const type = bookmarkData.bookmark.boards.length > 0 ? 'update' : 'add';
        const nbBoardsSelected = boardsState.filter((board) => {
          if (board.active) {
            return true;
          }
          return false;
        }).length;
        const boardsSelected = boardsState.filter((board) => board.active);
        const idBoards = boardsSelected.map((board) => board.idBoard);
        setOpenNotificationBookmark({
          familyId: addBookmarkToBoardState.idFamily,
          fontId: addBookmarkToBoardState.idFont,
          fontName: addBookmarkToBoardState.familyName,
          type: type,
          nbBoard: nbBoardsSelected,
          idBoards: idBoards,
        });
      }
      // Track Mixpanel Font bookmarked
      if (addBookmarkToBoardState) {
        trackFontBookmarked(
          addBookmarkToBoardState.source ?? '',
          addBookmarkToBoardState.familyName,
          addBookmarkToBoardState.fontWeight ?? ''
        );
      }
      // Empty state `openModalAddBookmarkToBoard`
      setAddBookmarkToBoardState(null);
    },
    onError: () => {
      // Empty state `openModalAddBookmarkToBoard`
      setAddBookmarkToBoardState(null);
    },
  });

  const deleteBookmarkMutation = useDeleteBookmark({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BOOKMARK_STATUS, { idFamily: addBookmarkToBoardState!.idFamily }],
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.BOARD_LIST_DETAILED] });
      if (router.route.indexOf(`${ROUTES.BOARD}/[boardQuery]`) > -1) {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.FAMILY_SEARCH] });
      }
      // Empty state `openModalAddBookmarkToBoard`
      setAddBookmarkToBoardState(null);
    },
    onError: () => {
      // Empty state `openModalAddBookmarkToBoard`
      setAddBookmarkToBoardState(null);
    },
  });

  const deleteBookmarkMutationFromBookmarkPage = useDeleteBookmarkFromBookmarkPage();

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalAddBookmarkToBoard(null);
  }, [setOpenModalAddBookmarkToBoard]);

  const handleCheckDisplayButtonDelete = useCallback(
    (boardItems: Array<BoardItem>) => {
      if (
        boardItems.filter((board) => board.active === true).length === 0 &&
        bookmarkData &&
        bookmarkData.bookmark.boards.length > 0
      ) {
        return true;
      } else {
        if (boardItems.filter((board) => board.active === true).length === 0) {
          setDisableButtonSave(true);
        } else {
          setDisableButtonSave(false);
        }
        return false;
      }
    },
    [bookmarkData]
  );

  const displaySeparator = useCallback(() => {
    if (isMobile) {
      if (boardsState.length >= 6) {
        return true;
      }
    } else {
      if (boardsState.length >= 8) {
        return true;
      }
    }
    return false;
  }, [boardsState, isMobile]);

  const handleChangeBoard = useCallback(
    (idBoard: number, active: boolean) => {
      const selectedIndex = boardsState.findIndex((board) => board.idBoard === idBoard);
      if (selectedIndex > -1) {
        boardsState[selectedIndex].active = active;
      }
      setDisplayButtonDelete(handleCheckDisplayButtonDelete(boardsState));
    },
    [boardsState, handleCheckDisplayButtonDelete]
  );

  const handleDelete = useCallback(() => {
    // Save data `openModalAddBookmarkToBoard` in state because we close modal directly
    setAddBookmarkToBoardState(openModalAddBookmarkToBoard);
    // If bookmark page of connected iser, call delete bookmark with optimistic update
    if (PAGE_NAMES[router.pathname] === USER_PAGE && userId?.toString() === routeUserId.toString()) {
      deleteBookmarkMutationFromBookmarkPage.mutate({
        idFamily: openModalAddBookmarkToBoard!.idFamily,
        page: openModalAddBookmarkToBoard?.page ?? 0,
        filters: filters,
      });
    } else {
      deleteBookmarkMutation.mutate({ idFamily: openModalAddBookmarkToBoard!.idFamily });
    }
    setDisableButtonDelete(true);
    setStartClose(true);
  }, [
    deleteBookmarkMutation,
    deleteBookmarkMutationFromBookmarkPage,
    filters,
    openModalAddBookmarkToBoard,
    routeUserId,
    router.pathname,
    userId,
  ]);

  const handleSave = useCallback(() => {
    // Save data `openModalAddBookmarkToBoard` in state because we close modal directly
    setAddBookmarkToBoardState(openModalAddBookmarkToBoard);
    const activeIdBoards: Array<number> = [];
    boardsState.forEach((board) => {
      if (board.active) {
        activeIdBoards.push(board.idBoard);
      }
    });
    addBookmarkMutation.mutate({
      idFamily: openModalAddBookmarkToBoard!.idFamily,
      idFont: openModalAddBookmarkToBoard!.idFont,
      idBoards: activeIdBoards,
      ...(idRequest && { idRequest: idRequest }),
    });
    setDisableButtonSave(true);
    setStartClose(true);
  }, [addBookmarkMutation, boardsState, idRequest, openModalAddBookmarkToBoard]);

  const handleCreateNewBoard = useCallback(() => {
    setStartClose(true);
    setOpenModalCreateNewBoard({
      idFamily: openModalAddBookmarkToBoard!.idFamily,
      idFont: openModalAddBookmarkToBoard!.idFont,
      familyName: openModalAddBookmarkToBoard!.familyName,
      fontWeight: openModalAddBookmarkToBoard!.fontWeight,
      source: openModalAddBookmarkToBoard!.source,
    });
  }, [openModalAddBookmarkToBoard, setOpenModalCreateNewBoard]);

  useEffect(() => {
    if (!openModalAddBookmarkToBoard) {
      setModalWrapperHeight(undefined);
      setDisplayButtonDelete(false);
      setDisableButtonSave(false);
      setDisableButtonDelete(false);
    } else {
      setBoardsState([]);
    }
  }, [openModalAddBookmarkToBoard]);

  useEffect(() => {
    if (boardsData && bookmarkData && openModalAddBookmarkToBoard) {
      const boardItems: Array<BoardItem> = [];
      boardsData.boards.forEach((board) => {
        const boardItem: BoardItem = {
          idBoard: board.id,
          name: board.name,
          active: !!bookmarkData.bookmark.boards.find((b) => b.id === board.id),
          isPrivate: board.private,
        };
        // Look forced boards to select
        if (openModalAddBookmarkToBoard.forcedBoardsSelected) {
          if (openModalAddBookmarkToBoard.forcedBoardsSelected.find((board) => board.id === boardItem.idBoard)) {
            boardItem.active = true;
          }
        }
        boardItems.push(boardItem);
      });
      setBoardsState(boardItems);
      setDisplayButtonDelete(handleCheckDisplayButtonDelete(boardItems));

      // transition resize ModalWrapper
      setTimeout(() => {
        if (modalWrapperRef && contentWrapperRef.current && boardsData.totalBoards > 0) {
          const contentWrapperHeight = contentWrapperRef.current.getBoundingClientRect().height;
          modalWrapperRef.current!.style.height = `${contentWrapperHeight}px`;
          modalWrapperRef.current!.addEventListener('transitionend', () => {
            setModalWrapperHeight('auto');
            modalWrapperRef.current!.removeAttribute('style');
          });
        }
      }, 10);
      // transition resize ModalWrapper: Security for Safari when `transitionend` not fired
      setTimeout(() => {
        if (modalWrapperRef.current && modalWrapperRef.current.style.height !== '') {
          setModalWrapperHeight('auto');
          modalWrapperRef.current.removeAttribute('style');
        }
      }, 400);
    }
  }, [boardsData, bookmarkData, handleCheckDisplayButtonDelete, openModalAddBookmarkToBoard]);

  if (openModalAddBookmarkToBoard) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper
          ref={modalWrapperRef}
          modalWrapperHeight={modalWrapperHeight}
        >
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper ref={contentWrapperRef}>
            <TitleWrapper displaySeparator={displaySeparator()}>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('add-bookmark-to-board.title')}
              </H3>
            </TitleWrapper>
            <Separator
              displaySeparator={displaySeparator()}
              darkMode={darkMode}
            />
            <BoardsWrapper displaySeparator={displaySeparator()}>
              {(isLoadingBoards || isLoadingBookmark) && (
                <BoardsLoadingWrapper>
                  <Spinner darkMode={darkMode} />
                </BoardsLoadingWrapper>
              )}
              {!isLoadingBoards && !isLoadingBookmark && boardsData && boardsData.totalBoards === 0 && (
                <BoardsEmptyMessage dangerouslySetInnerHTML={{ __html: t('add-bookmark-to-board.empty-boards') }} />
              )}
              {!isLoadingBoards &&
                !isLoadingBookmark &&
                boardsState.map((board, index) => (
                  <BoardItemWrapper key={`${board.idBoard}_${index}`}>
                    <BoardItem
                      idBoard={board.idBoard}
                      name={board.name}
                      active={board.active}
                      isPrivate={board.isPrivate}
                      darkMode={darkMode}
                      onChangeBoard={handleChangeBoard}
                    />
                  </BoardItemWrapper>
                ))}
            </BoardsWrapper>
            <Separator
              displaySeparator={displaySeparator()}
              darkMode={darkMode}
            />
            <ButtonsWrapper display={boardsData ? 'flex' : 'none'}>
              {!isLoadingBoards && !isLoadingBookmark && boardsData && boardsData.totalBoards === 0 && (
                <Button
                  darkMode={darkMode}
                  render={'contrast'}
                  onClick={handleCreateNewBoard}
                >
                  {t('add-bookmark-to-board.create-new-board')}
                </Button>
              )}
              {!isLoadingBoards && !isLoadingBookmark && boardsData && boardsData.totalBoards > 0 && (
                <>
                  {displayButtonDelete ? (
                    <ButtonDeleteWrapper darkMode={darkMode}>
                      <Button
                        darkMode={darkMode}
                        disabled={disableButtonDelete}
                        onClick={handleDelete}
                      >
                        {isMobile || isTablet
                          ? t('add-bookmark-to-board.delete')
                          : t('add-bookmark-to-board.delete-bookmark')}
                      </Button>
                    </ButtonDeleteWrapper>
                  ) : (
                    <ButtonSaveWrapper>
                      <Button
                        darkMode={darkMode}
                        disabled={disableButtonSave}
                        onClick={handleSave}
                      >
                        {t('add-bookmark-to-board.save')}
                      </Button>
                    </ButtonSaveWrapper>
                  )}
                  <Button
                    darkMode={darkMode}
                    render={'contrast'}
                    onClick={handleCreateNewBoard}
                  >
                    {t('add-bookmark-to-board.create-new-board')}
                  </Button>
                </>
              )}
            </ButtonsWrapper>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default AddBookmarkToBoard;
