import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getItem, setItem } from 'common/utils';
import { DARK_MODE, DISPLAYED_WEIGHT, DISPLAY_MODE_OPTION, EXTENSION, MACHINE_ID } from 'common/data/Constants';
import {
  initialContrast,
  initialDisplayedWeight,
  initialEnding,
  initialFontStyles,
  initialMinStyles,
  initialMonospacedFonts,
  initialPriceRange,
  initialTrialFonts,
  initialWidth,
} from 'common/data/Settings';

export interface SettingsContextData {
  // Extension
  isExtension: boolean;
  setIsExtension: (value: boolean) => void;
  extensionDocumentHeight: number;
  setExtensionDocumentHeight: (value: number) => void;
  extensionScrollY: number;
  setExtensionScrollY: (value: number) => void;
  extensionWindowHeight: number;
  setExtensionWindowHeight: (value: number) => void;
  machineId?: string;
  setMachineId: (value?: string) => void;
  // Nav Page Appearance Tooltip
  displayNavPageAppearanceTooltip: boolean;
  setDisplayNavPageAppearanceTooltip: (value: boolean) => void;
  // List Appearance options
  displayMode: string;
  changeDisplayMode: (value: string) => void;
  darkMode: boolean;
  changeDarkMode: (value: boolean) => void;
  // Options bar filters tooltip/modal
  displayOptionsBarFilters: boolean;
  setDisplayOptionsBarFilters: (value: boolean) => void;
  // Font shapes
  fontStyles: Record<string, boolean>;
  changeFontStyles: (key: string, value: boolean) => void;
  // Font styles
  minStyles: number;
  changeMinStyles: (value: number) => void;
  contrast: number;
  changeContrast: (value: number) => void;
  width: number;
  changeWidth: (value: number) => void;
  ending: number;
  changeEnding: (value: number) => void;
  // Font options
  priceRange: Array<number>;
  changePriceRange: (value: Array<number>) => void;
  displayedWeight: number;
  changeDisplayedWeight: (value: number) => void;
  trialFonts: boolean;
  changeTrialFonts: (value: boolean) => void;
  monospacedFonts: boolean;
  changeMonospacedFonts: (value: boolean) => void;
  // Single Font options
  singleFontAppearance: string;
  setSingleFontAppearance: (value: string) => void;
  singleFontTextTransform: string;
  setSingleFontTextTransform: (value: string) => void;
  singleFontItalic: string | undefined;
  setSingleFontItalic: (value: string | undefined) => void;
  // Reset to default values for each categories in options bar
  resetFontShapes: () => void;
  resetFontStyles: () => void;
  resetFontOptions: (resetDisplayedWeight: boolean) => void;
}

export const useSettingsState = (data: SettingsContextData): SettingsContextData => {
  const router = useRouter();

  // Extension
  const [isExtension, setIsExtension] = useState<boolean>(data.isExtension);
  const [extensionDocumentHeight, setExtensionDocumentHeight] = useState<number>(data.extensionDocumentHeight);
  const [extensionScrollY, setExtensionScrollY] = useState<number>(data.extensionScrollY);
  const [extensionWindowHeight, setExtensionWindowHeight] = useState<number>(data.extensionWindowHeight);

  // machineId
  const [machineId, setMachineId] = useState<string | undefined>(data.machineId);

  // Nav Page Appearance Tooltip
  const [displayNavPageAppearanceTooltip, setDisplayNavPageAppearanceTooltip] = useState<boolean>(
    data.displayNavPageAppearanceTooltip
  );

  // List Appearance options
  const [displayMode, setDisplayMode] = useState<string>(data.displayMode);
  const [darkMode, setDarkMode] = useState<boolean>(data.darkMode);

  // Options bar filters tooltip/modal
  const [displayOptionsBarFilters, setDisplayOptionsBarFilters] = useState<boolean>(data.displayOptionsBarFilters);

  // Font shapes
  const [fontStyles, setFontStyles] = useState<Record<string, boolean>>(data.fontStyles);

  // Font styles
  const [minStyles, setMinStyles] = useState<number>(data.minStyles);
  const [contrast, setContrast] = useState<number>(data.contrast);
  const [width, setWidth] = useState<number>(data.width);
  const [ending, setEnding] = useState<number>(data.ending);

  // Font options
  const [priceRange, setPriceRange] = useState<Array<number>>(data.priceRange);
  const [displayedWeight, setDisplayedWeight] = useState<number>(data.displayedWeight);
  const [trialFonts, setTrialFonts] = useState<boolean>(data.trialFonts);
  const [monospacedFonts, setMonospacedFonts] = useState<boolean>(data.monospacedFonts);

  // Single Font options
  const [singleFontAppearance, setSingleFontAppearance] = useState<string>(data.singleFontAppearance);
  const [singleFontTextTransform, setSingleFontTextTransform] = useState<string>(data.singleFontTextTransform);
  const [singleFontItalic, setSingleFontItalic] = useState<string | undefined>(data.singleFontItalic);

  const changeFontStyles = useCallback(
    (key: string, value: boolean) => {
      const newFontStyles = { ...fontStyles };
      newFontStyles[key] = value;
      setFontStyles(newFontStyles);
    },
    [fontStyles]
  );

  const changeMinStyles = useCallback((value: number) => {
    setMinStyles(value);
  }, []);

  const changeContrast = useCallback((value: number) => {
    setContrast(value);
  }, []);

  const changeWidth = useCallback((value: number) => {
    setWidth(value);
  }, []);

  const changeEnding = useCallback((value: number) => {
    setEnding(value);
  }, []);

  const changeTrialFonts = useCallback((value: boolean) => {
    setTrialFonts(value);
  }, []);

  const changeMonospacedFonts = useCallback((value: boolean) => {
    setMonospacedFonts(value);
  }, []);

  const changePriceRange = useCallback((value: Array<number>) => {
    setPriceRange(value);
  }, []);

  const changeDisplayMode = useCallback((value: string) => {
    setDisplayMode(value);
    // Save into local storage
    setItem(DISPLAY_MODE_OPTION, value);
  }, []);

  const changeDisplayedWeight = useCallback((value: number) => {
    setDisplayedWeight(value);
    // Save into local storage
    setItem(DISPLAYED_WEIGHT, value.toString());
  }, []);

  const changeDarkMode = useCallback((value: boolean) => {
    setDarkMode(value);
    // Save into local storage
    setItem(DARK_MODE, value.toString());
  }, []);

  const resetFontShapes = useCallback(() => {
    setFontStyles(initialFontStyles);
  }, []);

  const resetFontStyles = useCallback(() => {
    setMinStyles(initialMinStyles);
    setContrast(initialContrast);
    setWidth(initialWidth);
    setEnding(initialEnding);
  }, []);

  const resetFontOptions = useCallback(
    (resetDisplayedWeight: boolean) => {
      setPriceRange(initialPriceRange);
      setTrialFonts(initialTrialFonts);
      setMonospacedFonts(initialMonospacedFonts);
      if (resetDisplayedWeight) {
        changeDisplayedWeight(initialDisplayedWeight);
      }
    },
    [changeDisplayedWeight]
  );

  useEffect(() => {
    if (getItem(DISPLAY_MODE_OPTION)) {
      setDisplayMode(getItem(DISPLAY_MODE_OPTION)!);
    }
    if (getItem(DISPLAYED_WEIGHT)) {
      setDisplayedWeight(parseInt(getItem(DISPLAYED_WEIGHT)! as string));
    }
    if (getItem(DARK_MODE)) {
      setDarkMode(getItem(DARK_MODE) === 'true' ? true : false);
    }
  }, []);

  useEffect(() => {
    const bodyEl = document.body;
    darkMode ? bodyEl.classList.add('dark-mode') : bodyEl.classList.remove('dark-mode');
  }, [darkMode]);

  useEffect(() => {
    // check query param `extension` exists
    if (router.query.hasOwnProperty(EXTENSION)) {
      setIsExtension(true);
      const bodyEl = document.body;
      bodyEl.classList.add(EXTENSION);
    }
    // check query param `machineId` exists
    if (router.query.hasOwnProperty(MACHINE_ID) && typeof router.query[MACHINE_ID] === 'string') {
      setMachineId(router.query[MACHINE_ID]);
    }
  }, [router]);

  return {
    isExtension,
    setIsExtension,
    extensionDocumentHeight,
    setExtensionDocumentHeight,
    extensionScrollY,
    setExtensionScrollY,
    extensionWindowHeight,
    setExtensionWindowHeight,
    machineId,
    setMachineId,
    displayNavPageAppearanceTooltip,
    setDisplayNavPageAppearanceTooltip,
    displayOptionsBarFilters,
    setDisplayOptionsBarFilters,
    fontStyles,
    changeFontStyles,
    minStyles,
    changeMinStyles,
    contrast,
    changeContrast,
    width,
    changeWidth,
    ending,
    changeEnding,
    trialFonts,
    changeTrialFonts,
    monospacedFonts,
    changeMonospacedFonts,
    priceRange,
    changePriceRange,
    displayMode,
    changeDisplayMode,
    displayedWeight,
    changeDisplayedWeight,
    darkMode,
    changeDarkMode,
    singleFontAppearance,
    setSingleFontAppearance,
    singleFontTextTransform,
    setSingleFontTextTransform,
    singleFontItalic,
    setSingleFontItalic,
    resetFontShapes,
    resetFontStyles,
    resetFontOptions,
  };
};
