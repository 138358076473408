import styled from '@emotion/styled';
import { mq } from 'styles/mediaqueries.styled';

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* DESKTOP */
  ${mq['desktop']} {
    top: 5px;
    right: 5px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 32px 16px;
  /* DESKTOP */
  ${mq['desktop']} {
    width: 397px;
    padding: 32px 26px 32px 30px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Description = styled.p`
  line-height: 22px;
  margin-bottom: 40px;
  /* MOBILE */
  font-size: 16px;
  /* DESKTOP */
  ${mq['desktop']} {
    font-size: 14px;
  }
`;

export const ButtonsWrapper = styled.div`
  position: relative;
  white-space: nowrap;
  button {
    margin-right: 16px;
  }
`;
