import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/user`;

export const getUserProfile = (): Promise<API.User.UserProfile> => {
  return axios.get(baseUrl).then(({ data }) => {
    return data;
  });
};

export const updateUser = (
  userId: number,
  name: string,
  email: string,
  website: string,
  instagram: string,
  notifications: API.User.Notifications,
  preferredCurrency: string
): Promise<API.User.UpdateUserProfile> => {
  const profileData = {
    id: userId,
    name: name,
    email: email,
    website: website,
    instagram: instagram,
    notifications: notifications,
    preferredCurrency: preferredCurrency,
  };
  return axios.patch(baseUrl, profileData).then(({ data, headers }) => {
    if (data) {
      data.authToken = headers['x-auth-token'];
    }
    return data;
  });
};

export const deleteUser = (): Promise<API.User.DeleteUser> => {
  return axios.delete(baseUrl).then(({ data }) => {
    return data;
  });
};

export const updateAvatar = (file: File): Promise<API.User.UserAvatar> => {
  const formData: FormData = new FormData();
  formData.append('avatar', file);

  return axios.put(`${baseUrl}/avatar`, formData).then(({ data, headers }) => {
    if (data.success) {
      data.authToken = headers['x-auth-token'];
    }
    return data;
  });
};

export const getPublicUserData = (userId: number): Promise<API.User.PublicUser> => {
  return axios.get(`${baseUrl}/${userId}`).then(({ data }) => {
    return data;
  });
};
