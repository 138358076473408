import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_0, GREY_2, GREY_3, GREY_6, WHITE } from 'design-system/global/colors';

interface Props {
  width?: string;
  optionsWidth?: string;
  menuMaxHeight?: string;
  darkMode?: boolean;
}

const BREAKPOINT_TABLET = 744;

const borderDarkMode = `1px solid ${GREY_2}`;
const borderLightMode = `1px solid ${GREY_6}`;

const textStyled = (props: Props) => css`
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${props.darkMode ? WHITE : GREY_6};
  padding: 0;
  margin: 0;
  /* MOBILE */
  font-size: 16px;
  /* TABLET */
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    font-size: 14px;
  }
`;

export const DropdownStyled = styled.div`
  .dropdown__control {
    width: ${(props: Props) => props.width};
    border-radius: 8px;
    background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
    border: ${(props: Props) => (props.darkMode ? borderDarkMode : borderLightMode)};
    padding: 0;
    margin: 0;
    box-shadow: none;
    transition: none;
    /* MOBILE */
    min-height: 44px;
    /* TABLET */
    @media (min-width: ${BREAKPOINT_TABLET}px) {
      min-height: 36px;
    }
    &--is-disabled {
      opacity: 0.5;
    }
    &:hover {
      border-color: ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
    }
  }
  .dropdown__value-container {
    padding: 0 0 0 16px;
    margin: 0;
  }
  .dropdown__placeholder {
    ${textStyled}
  }
  .dropdown__input-container {
    ${textStyled}
  }
  .dropdown__single-value {
    ${textStyled}
  }
  .dropdown__indicators {
    padding: 0 6px 0 0;
    margin: 0;
  }
  .dropdown__indicator-separator {
    display: none;
  }
  .dropdown__menu {
    width: ${(props: Props) => props.optionsWidth};
    background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
    border: ${(props: Props) => (props.darkMode ? borderDarkMode : borderLightMode)};
    border-radius: 8px;
    margin-top: 4px;
    margin-bottom: 0;
  }
  .dropdown__menu-list {
    max-height: ${(props: Props) => props.menuMaxHeight};
    padding: 4px;
  }
  .dropdown__menu-notice {
    font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
    /* MOBILE */
    font-size: 16px;
    line-height: 19px;
    padding: 10.5px 20px;
    /* TABLET */
    @media (min-width: ${BREAKPOINT_TABLET}px) {
      font-size: 14px;
      line-height: 17px;
      padding: 7.5px 20px;
    }
  }
  .dropdown__option {
    font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
    margin: 0 0 4px 0;
    border-radius: 4px;
    /* MOBILE */
    font-size: 16px;
    line-height: 19px;
    padding: 10.5px 20px;
    /* TABLET */
    @media (min-width: ${BREAKPOINT_TABLET}px) {
      font-size: 14px;
      line-height: 17px;
      padding: 7.5px 20px;
    }
    &:last-of-type {
      margin: 0;
    }
  }
  .dropdown__option--is-disabled {
    color: ${(props: Props) => (props.darkMode ? GREY_2 : GREY_0)};
    &.dropdown__option--is-focused {
      color: ${(props: Props) => (props.darkMode ? GREY_2 : GREY_0)};
    }
  }
  .dropdown__option--is-focused {
    background-color: ${GREY_3};
    color: ${WHITE};
    &:active {
      background-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
      color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
    }
  }
  .dropdown__option--is-selected {
    background-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
    color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
    &:active {
      background-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
      color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
    }
  }
  /* DROPDOWN MULTI */
  .dropdown__value-container--is-multi {
    padding: 0 0 0 4px;
    .dropdown__placeholder {
      padding-left: 12px;
    }
    .dropdown__input-container {
      padding-left: 12px;
    }
    .dropdown__multi-value {
      margin: 2px 4px 2px 0;
      background-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
      border-radius: 4px;
    }
    .dropdown__multi-value__label {
      font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
      padding: 0 4px 0 12px;
      /* MOBILE */
      font-size: 16px;
      line-height: 32px;
      /* TABLET */
      @media (min-width: ${BREAKPOINT_TABLET}px) {
        font-size: 14px;
        line-height: 28px;
      }
    }
    .dropdown__multi-value__remove {
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 0 4px 4px 0;
      &:hover {
        padding-left: 7px;
        padding-right: 7px;
        background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
        border: ${(props: Props) => (props.darkMode ? borderDarkMode : borderLightMode)};
        [class^='icon-'],
        [class*=' icon-'] {
          color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
        }
      }
    }
  }
  .dropdown__value-container--has-value {
    .dropdown__input-container {
      padding-left: 0;
    }
  }
  .dropdown__clear-indicator {
    display: none;
  }
`;
