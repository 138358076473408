import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useAuthContext, useModalContext, useSettingsContext } from 'context';
import Modal from 'components/Modal';
import { initialCurrency } from 'common/data/Settings';
import { currencyIcons } from 'common/data/Datas';
import {
  ButtonsWrapper,
  CloseButtonWrapper,
  ContentWrapper,
  Description,
  ModalWrapper,
  TitleWrapper,
} from './PriceRangeWarning.styled';

const PriceRangeWarning = () => {
  const { t } = useTranslation('common');
  const { preferredCurrency } = useAuthContext();
  const { darkMode, changePriceRange } = useSettingsContext();
  const { openModalPriceRangeWarning, setOpenModalPriceRangeWarning } = useModalContext();

  const selectedCurrency = useMemo(() => preferredCurrency ?? initialCurrency, [preferredCurrency]);

  const [startClose, setStartClose] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalPriceRangeWarning(false);
  }, [setOpenModalPriceRangeWarning]);

  const handleChangePriceRange = useCallback(() => {
    changePriceRange([0, 25]);
    handleClose();
  }, [changePriceRange, handleClose]);

  if (openModalPriceRangeWarning) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            <TitleWrapper>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('price-range-warning.title')}
              </H3>
            </TitleWrapper>
            <Description
              dangerouslySetInnerHTML={{
                __html: t('price-range-warning.description', {
                  currency: currencyIcons.find((o) => o.value === selectedCurrency)?.label ?? '$',
                }),
              }}
            />
            <ButtonsWrapper>
              <Button
                render="contrast"
                darkMode={darkMode}
                onClick={handleClose}
              >
                {t('price-range-warning.button-free')}
              </Button>
              <Button
                darkMode={darkMode}
                onClick={handleChangePriceRange}
              >
                {t('price-range-warning.button-25', {
                  currency: currencyIcons.find((o) => o.value === selectedCurrency)?.label ?? '$',
                })}
              </Button>
            </ButtonsWrapper>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default PriceRangeWarning;
