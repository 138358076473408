import React from 'react';
import { Global } from '@emotion/react';
import { InjectFontsStyled } from './inject-fonts.styled';

export function InjectFonts() {
  return (
    <Global
      styles={InjectFontsStyled}
    />
  );
}
