import { useCallback } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { GREY_6 } from 'design-system/global/colors';
import { useAuthContext, useNotificationContext, useSettingsContext } from 'context';
import { useFontImage } from 'hooks/queries/useFontImage';
import { FontImageParameters } from 'api/fontImage.api';
import Notification from 'components/Notification';
import { convertSvgStringToDataUrl, transformStringToUrl } from 'common/utils';
import { ROUTES } from 'common/data/Constants';
import BookmarkIcon from './BookmarkIcon';
import { BookmarkIconWrapper, BookmarkLetters, ContentWrapper, Text } from './BookmarkAdded.styled';

const MAX_CHARS = 27;

const BookmarkAdded = () => {
  const { t } = useTranslation('common');
  const { userId, userName } = useAuthContext();
  const { darkMode } = useSettingsContext();
  const { openNotificationBookmark, setOpenNotificationBookmark } = useNotificationContext();

  const { useGenerateFontImage } = useFontImage();

  // Font Letter Image
  const fontLetterImageParameters: FontImageParameters = {
    text: 'Aa',
    fontSize: 16,
    minFontSize: 14,
    maxFontSize: 20,
    maxWidth: 21,
    maxHeight: 16,
    lineHeight: 1.1,
    maxLines: 1,
    wordWrap: true,
    hAlign: 'center',
    vAlign: 'top',
    vTrim: true,
    hTrim: true,
    pixelTrim: true,
    backgroundColor: 'transparent',
    fillColor: GREY_6,
    strokeColor: GREY_6,
  };
  const { data: fontLettersData } = useGenerateFontImage(
    'font',
    (openNotificationBookmark && openNotificationBookmark.fontId) ?? 0,
    fontLetterImageParameters,
    true,
    { enabled: openNotificationBookmark !== null }
  );

  const handleCloseComplete = useCallback(() => {
    setOpenNotificationBookmark(null);
  }, [setOpenNotificationBookmark]);

  const getText = (): string => {
    if (openNotificationBookmark) {
      const fontName =
        openNotificationBookmark.fontName.length > MAX_CHARS
          ? `${openNotificationBookmark.fontName.substring(0, MAX_CHARS)}...`
          : openNotificationBookmark.fontName;
      if (openNotificationBookmark.type === 'update') {
        return t('notification-bookmark-added.updated', { fontName: fontName });
      } else {
        if (openNotificationBookmark.nbBoard && openNotificationBookmark.nbBoard > 1) {
          return t('notification-bookmark-added.multi-boards', {
            fontName: fontName,
            nbBoards: openNotificationBookmark.nbBoard,
          });
        } else {
          return t('notification-bookmark-added.one-board', { fontName: fontName });
        }
      }
    }
    return '';
  };

  if (openNotificationBookmark) {
    return (
      <Notification
        darkMode={darkMode}
        onCloseComplete={handleCloseComplete}
      >
        <Link
          href={
            openNotificationBookmark.nbBoard! > 1
              ? `/${transformStringToUrl(userName ?? '')}-${userId}`
              : `/${transformStringToUrl(userName ?? '')}-${userId}${ROUTES.BOARD}/${
                  openNotificationBookmark.idBoards![0]
                }`
          }
        >
          <ContentWrapper>
            <BookmarkIconWrapper>
              <BookmarkIcon />
              <BookmarkLetters>
                {fontLettersData && (
                  <Image
                    width={21}
                    height={16}
                    src={convertSvgStringToDataUrl(fontLettersData.svg)}
                    alt="Aa"
                  />
                )}
              </BookmarkLetters>
            </BookmarkIconWrapper>
            <Text>{getText()}</Text>
          </ContentWrapper>
        </Link>
      </Notification>
    );
  }
  return null;
};

export default BookmarkAdded;
