import React, { HTMLAttributes } from 'react';
import { GREY_5 } from 'design-system/global/colors';
import { IconStyled } from './icon.styled';

export interface IconProps extends HTMLAttributes<HTMLElement> {
  label: string;
  display?: 'block' | 'inline-block' | 'inline';
  color?: string;
  fontSize?: string;
  lineHeight?: string;
}

export function Icon({ display = 'inline', color = GREY_5, fontSize = '16px', lineHeight = '1', label }: IconProps) {
  return (
    <IconStyled
      className={`icon-${label}`}
      data-testid={`${label}`}
      display={display}
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
    />
  );
}
