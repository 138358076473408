import { GREY_2, GREY_6, NINJA_GREEN, WHITE } from 'design-system/global/colors';
import { useAuthContext, useBreakpointContext, useSettingsContext } from 'context';
import { useBookmark } from 'hooks/queries/useBookmark';
import { MarkerContainer, MarkerShadow, MarkerWrapper } from './Marker.styled';

interface Props {
  familyId: string;
}

const Marker = ({ familyId }: Props) => {
  const { isLoggedIn } = useAuthContext();
  const { darkMode, displayMode } = useSettingsContext();
  const { isMobile } = useBreakpointContext();

  const { useGetBookmarkStatus } = useBookmark();
  const { data: bookmarkDataStatus } = useGetBookmarkStatus(familyId, { enabled: isLoggedIn });

  return (
    <MarkerContainer visible={!!(bookmarkDataStatus && bookmarkDataStatus.success)}>
      {(displayMode === 'colored poster' || !darkMode) && (
        <MarkerShadow>
          <svg
            width={isMobile ? '15' : '20'}
            height={isMobile ? '22' : '29'}
            viewBox="0 0 20 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 0.5H19.5V27.251C19.5 27.6378 19.0797 27.8781 18.7464 27.6819L10.7609 22.9813C10.2913 22.7049 9.7087 22.7049 9.23909 22.9813L1.25364 27.6819C0.920323 27.8781 0.5 27.6378 0.5 27.251V0.5Z"
              fill={GREY_6}
              stroke={GREY_6}
            />
          </svg>
        </MarkerShadow>
      )}
      <MarkerWrapper>
        <svg
          width={isMobile ? '15' : '20'}
          height={isMobile ? '21' : '28'}
          viewBox="0 0 20 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 0.5H19.5V26.251C19.5 26.6378 19.0797 26.8781 18.7464 26.6819L10.7609 21.9813C10.2913 21.7049 9.7087 21.7049 9.23909 21.9813L1.25364 26.6819C0.920323 26.8781 0.5 26.6378 0.5 26.251V0.5Z"
            fill={displayMode === 'colored poster' ? WHITE : NINJA_GREEN}
            stroke={displayMode === 'colored poster' || !darkMode ? GREY_6 : GREY_2}
          />
        </svg>
      </MarkerWrapper>
    </MarkerContainer>
  );
};

export default Marker;
