import { useInfiniteQuery } from '@tanstack/react-query';
import api from 'api';
import { Display, Filters, Sorting } from 'api/familySearch.api';
import { QUERY_KEYS } from 'common/data/Constants';

interface Options {
  enabled: boolean;
}

export const useFamilySearch = (
  pageSize: number,
  filters?: Filters,
  display?: Display,
  sorting?: Sorting,
  seed?: string,
  options: Options = { enabled: true }
) => {
  return useInfiniteQuery(
    [QUERY_KEYS.FAMILY_SEARCH, { filters, sorting, seed }],
    ({ pageParam }) => {
      if (!pageParam) pageParam = { page: 1 };
      if (pageParam.idSearch) {
        return api.familySearch.continueFamilySearch(pageParam.idSearch, pageSize, pageParam.page, display);
      } else {
        return api.familySearch.initFamilySearch(pageSize, pageParam.page, filters, display, sorting, seed);
      }
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        Math.ceil(lastPage.totalFamilies / pageSize) > allPages.length
          ? {
              page: allPages.length + 1,
              idSearch: allPages[0].idSearch,
            }
          : undefined,
      enabled: options.enabled,
    }
  );
};
