import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { Button } from 'design-system/atoms/button';
import { useAuthContext, useModalContext, useSettingsContext } from 'context';
import { useUser } from 'hooks/queries/useUser';
import Modal from 'components/Modal';
import Avatar from 'components/Avatar';
import {
  CloseButtonWrapper,
  InputFile,
  ModalWrapper,
  Step1ContentWrapper,
  Step1Description,
  Step1Error,
  Step1TitleWrapper,
  Step1Wrapper,
  Step2AvatarWrapper,
  Step2ButtonWrapper,
  Step2ContentWrapper,
  Step2Error,
  Step2InfosWrapper,
  Step2TitleWrapper,
  Step2Wrapper,
} from './EditProfilePicture.styled';

const MB = 1048576;

const EditProfilePicture = () => {
  const { t } = useTranslation('common');
  const { userId, userName, setAuthToken } = useAuthContext();
  const { darkMode } = useSettingsContext();
  const { openModalEditProfilePicture, setOpenModalEditProfilePicture } = useModalContext();

  const inputFileRef = useRef<HTMLInputElement>(null);

  const [startClose, setStartClose] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [error, setError] = useState<string>('');
  const [file, setFile] = useState<File>();
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(false);

  const { useUpdateAvatar } = useUser();
  const updateAvatarMutation = useUpdateAvatar({
    onSuccess: (data) => {
      setButtonIsDisabled(false);
      if (data.success === false) {
        setError(t('errors.generic'));
      } else {
        setAuthToken(data.authToken!);
        handleClose();
      }
    },
    onError: (err) => {
      setError(t('errors.generic'));
    },
  });

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalEditProfilePicture(false);
  }, [setOpenModalEditProfilePicture]);

  const handleSelectPicture = useCallback(() => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  }, []);

  const handleFileChange = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setError('');
    const file: File = (event.target as any).files[0];
    setFile(file);
    setStep(2);
  }, []);

  const handleSaveProfilePicture = useCallback(() => {
    if (file) {
      setButtonIsDisabled(true);
      updateAvatarMutation.mutate({ file: file });
    }
  }, [file, updateAvatarMutation]);

  useEffect(() => {
    if (!openModalEditProfilePicture) {
      setStep(1);
      setError('');
      setFile(undefined);
      setButtonIsDisabled(false);
    }
  }, [openModalEditProfilePicture]);

  if (openModalEditProfilePicture) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          {step === 1 && (
            <Step1Wrapper>
              <Step1TitleWrapper>
                <H3
                  as="p"
                  color={darkMode ? WHITE : GREY_6}
                >
                  {t('edit-profile-picture.step-1.title')}
                </H3>
              </Step1TitleWrapper>
              <Step1ContentWrapper>
                <Step1Description>{t('edit-profile-picture.step-1.description')}</Step1Description>
                {error && <Step1Error>{error}</Step1Error>}
              </Step1ContentWrapper>
              <InputFile
                ref={inputFileRef}
                type="file"
                accept=".png,.jpeg,.jpg"
                onChange={handleFileChange}
              />
              <Button
                darkMode={darkMode}
                onClick={handleSelectPicture}
              >
                {t('edit-profile-picture.step-1.button')}
              </Button>
            </Step1Wrapper>
          )}
          {step === 2 && (
            <Step2Wrapper>
              <Step2ContentWrapper>
                <Step2AvatarWrapper>
                  <Avatar
                    width={104}
                    height={104}
                    border={3}
                    darkMode={darkMode}
                    src={URL.createObjectURL(file!)}
                    userId={userId}
                    userName={userName}
                    withShadow={true}
                  />
                </Step2AvatarWrapper>
                <Step2InfosWrapper>
                  <Step2TitleWrapper>
                    <H3
                      as="p"
                      color={darkMode ? WHITE : GREY_6}
                    >
                      {t('edit-profile-picture.step-2.title')}
                    </H3>
                  </Step2TitleWrapper>
                  <Step2ButtonWrapper>
                    <Button
                      disabled={buttonIsDisabled}
                      darkMode={darkMode}
                      onClick={handleSaveProfilePicture}
                    >
                      {t('edit-profile-picture.step-2.button')}
                    </Button>
                  </Step2ButtonWrapper>
                  {error && <Step2Error>{error}</Step2Error>}
                </Step2InfosWrapper>
              </Step2ContentWrapper>
            </Step2Wrapper>
          )}
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default EditProfilePicture;
