import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import RenderIfVisible from 'react-render-if-visible';
import { GREY_6 } from 'design-system/global/colors';
import { usePrevious } from 'hooks';
import { useFontImage } from 'hooks/queries/useFontImage';
import { FontImageParameters } from 'api/fontImage.api';
import { useBreakpointContext, useSettingsContext } from 'context';
import BookmarkButtonWithSlider from 'containers/BookmarkButtonWithSlider';
import Spinner from 'components/Spinner';
import FontUnauthorized from 'components/Icons/FontUnauthorized';
import { posterBackgroundColors } from 'common/data/Poster';
import { DESKTOP_CARD_WIDTH, MACHINE_ID, MOBILE_CARD_WIDTH, ROUTES } from 'common/data/Constants';
import { convertSvgStringToDataUrl } from 'common/utils';
import Marker from '../Marker';
import {
  Block1ContentWrapper,
  Block1Wrapper,
  Block2ContentWrapper,
  Block2Wrapper,
  Block3ContentWrapper,
  Block3Wrapper,
  Block4ContentWrapper,
  Block4Wrapper,
  BookmarkBackground,
  BookmarkButtonWrapper,
  Card,
  CardContainer,
  CardLoader,
  CardWrapper,
  ContentRenderedWrapper,
  ContentWrapper,
  DesignersInfos,
  DesignersInfosWrapper,
  DesignersLabel,
  FontFoundryInfosWrapper,
  FontInfos,
  FontUnauthorizedText,
  FontUnauthorizedWrapper,
  FooterWrapper,
  FoundryInfos,
  HoverWrapper,
  ImagesWrapper,
  PricingInfos,
  Separator,
  StylesInfos,
  StylesPricingsWrapper,
} from './Poster.styled';
import { MarkerContainer, Variable } from '../Card.styled';

interface Props {
  index: number;
  family: API.Family;
  scaleValue?: number;
  forceLoading?: boolean;
  onClickBookmarkButton: (idFont: number) => void;
  onClickFontLink?: (idFont: number) => void;
  onSaveCurrentFontWeightInBoard?: (idBoard: number, idFamily: string, idFont: number) => void;
}

const Poster = ({
  index,
  family,
  scaleValue,
  forceLoading,
  onClickBookmarkButton,
  onClickFontLink,
  onSaveCurrentFontWeightInBoard,
}: Props) => {
  const { t } = useTranslation('common');
  const { isMobile } = useBreakpointContext();
  const { darkMode, displayMode, isExtension, machineId } = useSettingsContext();

  const router = useRouter();
  const { isBot } = router.query;

  const [familyState, setFamilyState] = useState<API.Family>(family);
  const [fontBlock1, setFontBlock1] = useState<API.FontImage.Image | null>(null);
  const [fontBlock2, setFontBlock2] = useState<API.FontImage.Image | null>(null);
  const [fontBlock3, setFontBlock3] = useState<API.FontImage.Image | null>(null);
  const [fontBlock4, setFontBlock4] = useState<API.FontImage.Image | null>(null);

  const fontId = useMemo(() => family.idFont, [family.idFont]);
  const previousFontId = usePrevious(fontId);

  useEffect(() => {
    setFamilyState(family);
    if (forceLoading) {
      setFontBlock1(null);
      setFontBlock2(null);
      setFontBlock3(null);
      setFontBlock4(null);
    }
  }, [family, forceLoading]);

  const { useGenerateFontImage } = useFontImage();

  /**
   * BLOCK 1 IMAGE
   */
  const getBlock1Text = useCallback(() => {
    if (isMobile) {
      return family.posterBlocks.mobile[0];
    } else {
      return family.posterBlocks.desktop[0];
    }
  }, [family.posterBlocks.desktop, family.posterBlocks.mobile, isMobile]);

  const getBlock1FontSize = useCallback(() => {
    if (isMobile) {
      if (family.posterBlocks.mode === 'condensed') {
        return 126;
      } else if (family.posterBlocks.mode === 'ultracondensed') {
        return 222;
      } else {
        return 80;
      }
    } else {
      if (family.posterBlocks.mode === 'condensed') {
        return 142;
      } else if (family.posterBlocks.mode === 'ultracondensed') {
        return 268;
      } else {
        return 104;
      }
    }
  }, [family.posterBlocks.mode, isMobile]);

  const getBlock1MinFontSize = useCallback(() => {
    if (isMobile) {
      if (family.posterBlocks.mode === 'condensed') {
        return 96;
      } else if (family.posterBlocks.mode === 'ultracondensed') {
        return 142;
      } else {
        return 50;
      }
    } else {
      if (family.posterBlocks.mode === 'condensed') {
        return 112;
      } else if (family.posterBlocks.mode === 'ultracondensed') {
        return 188;
      } else {
        return 74;
      }
    }
  }, [family.posterBlocks.mode, isMobile]);

  const getBlock1MaxFontSize = useCallback(() => {
    if (isMobile) {
      if (family.posterBlocks.mode === 'condensed') {
        return 126;
      } else if (family.posterBlocks.mode === 'ultracondensed') {
        return 242;
      } else {
        return 80;
      }
    } else {
      if (family.posterBlocks.mode === 'condensed') {
        return 142;
      } else if (family.posterBlocks.mode === 'ultracondensed') {
        return 288;
      } else {
        return 104;
      }
    }
  }, [family.posterBlocks.mode, isMobile]);

  const block1ImageParameters: FontImageParameters = {
    text: getBlock1Text(),
    fontSize: getBlock1FontSize(),
    minFontSize: getBlock1MinFontSize(),
    maxFontSize: getBlock1MaxFontSize(),
    lineHeight: 1.1,
    maxLines: 1,
    wordWrap: true,
    vAlign: 'top',
    vTrim: true,
    hTrim: true,
    pixelTrim: true,
    backgroundColor: 'transparent',
    fillColor: GREY_6,
    strokeColor: GREY_6,
  };
  const { data: fontBlock1Data } = useGenerateFontImage(
    'font',
    familyState.idFont,
    block1ImageParameters,
    displayMode === 'colored poster' ? true : false,
    { enabled: familyState.authorized && !!(getBlock1Text() !== '') }
  );
  useEffect(() => {
    if (fontBlock1Data) {
      setFontBlock1(fontBlock1Data);
    }
  }, [fontBlock1Data]);
  // Case fontId not change, so query not refetch, we reforce data
  useEffect(() => {
    if (fontBlock1Data && !forceLoading && fontId === previousFontId) {
      setFontBlock1(fontBlock1Data);
    }
  }, [fontBlock1Data, fontId, forceLoading, previousFontId]);
  // Memo fontBlock1 SVG image
  const fontBlock1Svg = useMemo(() => {
    // To force `darkMode` in useMemo Array
    const isDarkMode = darkMode;
    if (fontBlock1 && displayMode) {
      return convertSvgStringToDataUrl(fontBlock1.svg);
    }
    return null;
  }, [darkMode, displayMode, fontBlock1]);

  /**
   * BLOCK 2 IMAGE
   */
  const getBlock2Text = useCallback(() => {
    if (isMobile) {
      if (family.posterBlocks.mode === 'condensed' || family.posterBlocks.mode === 'ultracondensed') {
        return '';
      } else {
        return family.posterBlocks.mobile[1];
      }
    } else {
      return family.posterBlocks.desktop[1];
    }
  }, [family.posterBlocks.desktop, family.posterBlocks.mobile, family.posterBlocks.mode, isMobile]);

  const getBlock2FontSize = useCallback(() => {
    if (isMobile) {
      return 38;
    } else {
      if (family.posterBlocks.mode === 'condensed') {
        return 70;
      } else if (family.posterBlocks.mode === 'ultracondensed') {
        return 147;
      } else {
        return 50;
      }
    }
  }, [family.posterBlocks.mode, isMobile]);

  const getBlock2MinFontSize = useCallback(() => {
    if (isMobile) {
      return 18;
    } else {
      if (family.posterBlocks.mode === 'condensed') {
        return 50;
      } else if (family.posterBlocks.mode === 'ultracondensed') {
        return 127;
      } else {
        return 30;
      }
    }
  }, [family.posterBlocks.mode, isMobile]);

  const getBlock2MaxFontSize = useCallback(() => {
    if (isMobile) {
      return 58;
    } else {
      if (family.posterBlocks.mode === 'condensed') {
        return 90;
      } else if (family.posterBlocks.mode === 'ultracondensed') {
        return 167;
      } else {
        return 50;
      }
    }
  }, [family.posterBlocks.mode, isMobile]);

  const block2ImageParameters: FontImageParameters = {
    text: getBlock2Text(),
    fontSize: getBlock2FontSize(),
    minFontSize: getBlock2MinFontSize(),
    maxFontSize: getBlock2MaxFontSize(),
    lineHeight: 1.1,
    maxLines: 1,
    wordWrap: true,
    vAlign: 'top',
    vTrim: true,
    hTrim: true,
    pixelTrim: true,
    backgroundColor: 'transparent',
    fillColor: GREY_6,
    strokeColor: GREY_6,
  };
  const { data: fontBlock2Data } = useGenerateFontImage(
    'font',
    familyState.idFont,
    block2ImageParameters,
    displayMode === 'colored poster' ? true : false,
    { enabled: familyState.authorized && !!(getBlock2Text() !== '') }
  );
  useEffect(() => {
    if (fontBlock2Data) {
      setFontBlock2(fontBlock2Data);
    }
  }, [fontBlock2Data]);
  // Case fontId not change, so query not refetch, we reforce data
  useEffect(() => {
    if (fontBlock2Data && !forceLoading && fontId === previousFontId) {
      setFontBlock2(fontBlock2Data);
    }
  }, [fontBlock2Data, fontId, forceLoading, previousFontId]);
  // Memo fontBlock2 SVG image
  const fontBlock2Svg = useMemo(() => {
    // To force `darkMode` in useMemo Array
    const isDarkMode = darkMode;
    if (fontBlock2 && displayMode) {
      return convertSvgStringToDataUrl(fontBlock2.svg);
    }
    return null;
  }, [darkMode, displayMode, fontBlock2]);

  /**
   * BLOCK 3 IMAGE
   */
  const getBlock3Text = useCallback(() => {
    if (isMobile) {
      return '';
    } else {
      if (family.posterBlocks.mode === 'condensed' || family.posterBlocks.mode === 'ultracondensed') {
        return '';
      } else {
        return family.posterBlocks.desktop[2];
      }
    }
  }, [family.posterBlocks.desktop, family.posterBlocks.mode, isMobile]);

  const getBlock3FontSize = useCallback(() => {
    return 35;
  }, []);

  const getBlock3MinFontSize = useCallback(() => {
    return 20;
  }, []);

  const getBlock3MaxFontSize = useCallback(() => {
    return 50;
  }, []);

  const block3ImageParameters: FontImageParameters = {
    text: getBlock3Text(),
    fontSize: getBlock3FontSize(),
    minFontSize: getBlock3MinFontSize(),
    maxFontSize: getBlock3MaxFontSize(),
    lineHeight: 1.1,
    maxLines: 1,
    wordWrap: true,
    vAlign: 'top',
    vTrim: true,
    hTrim: true,
    pixelTrim: true,
    backgroundColor: 'transparent',
    fillColor: GREY_6,
    strokeColor: GREY_6,
  };
  const { data: fontBlock3Data } = useGenerateFontImage(
    'font',
    familyState.idFont,
    block3ImageParameters,
    displayMode === 'colored poster' ? true : false,
    { enabled: familyState.authorized && !!(getBlock3Text() !== '') }
  );
  useEffect(() => {
    if (fontBlock3Data) {
      setFontBlock3(fontBlock3Data);
    }
  }, [fontBlock3Data]);
  // Case fontId not change, so query not refetch, we reforce data
  useEffect(() => {
    if (fontBlock3Data && !forceLoading && fontId === previousFontId) {
      setFontBlock3(fontBlock3Data);
    }
  }, [fontBlock3Data, fontId, forceLoading, previousFontId]);
  // Memo fontBlock3 SVG image
  const fontBlock3Svg = useMemo(() => {
    // To force `darkMode` in useMemo Array
    const isDarkMode = darkMode;
    if (fontBlock3 && displayMode) {
      return convertSvgStringToDataUrl(fontBlock3.svg);
    }
    return null;
  }, [darkMode, displayMode, fontBlock3]);

  /**
   * BLOCK 4 IMAGE
   */
  const getBlock4Text = useCallback(() => {
    if (isMobile) {
      if (family.posterBlocks.mode === 'ultracondensed') {
        return '';
      } else if (family.posterBlocks.mode === 'condensed') {
        return family.posterBlocks.mobile[1];
      } else {
        return family.posterBlocks.mobile[2];
      }
    } else {
      if (family.posterBlocks.mode === 'ultracondensed') {
        return '';
      } else if (family.posterBlocks.mode === 'condensed') {
        return family.posterBlocks.desktop[2];
      } else {
        return family.posterBlocks.desktop[3];
      }
    }
  }, [family.posterBlocks.desktop, family.posterBlocks.mobile, family.posterBlocks.mode, isMobile]);

  const getBlock4FontSize = useCallback(() => {
    if (isMobile) {
      if (family.posterBlocks.mode === 'condensed') {
        return 20;
      } else {
        return 15;
      }
    } else {
      if (family.posterBlocks.mode === 'condensed') {
        return 28;
      } else {
        return 20;
      }
    }
  }, [family.posterBlocks.mode, isMobile]);

  const getBlock4MinFontSize = useCallback(() => {
    if (isMobile) {
      if (family.posterBlocks.mode === 'condensed') {
        return 15;
      } else {
        return 10;
      }
    } else {
      if (family.posterBlocks.mode === 'condensed') {
        return 23;
      } else {
        return 15;
      }
    }
  }, [family.posterBlocks.mode, isMobile]);

  const getBlock4MaxFontSize = useCallback(() => {
    if (isMobile) {
      if (family.posterBlocks.mode === 'condensed') {
        return 25;
      } else {
        return 20;
      }
    } else {
      if (family.posterBlocks.mode === 'condensed') {
        return 33;
      } else {
        return 25;
      }
    }
  }, [family.posterBlocks.mode, isMobile]);

  const block4ImageParameters: FontImageParameters = {
    text: getBlock4Text(),
    fontSize: getBlock4FontSize(),
    minFontSize: getBlock4MinFontSize(),
    maxFontSize: getBlock4MaxFontSize(),
    maxWidth: isMobile ? MOBILE_CARD_WIDTH - 32 : DESKTOP_CARD_WIDTH - 64,
    maxHeight: isMobile ? 40 : 62,
    lineHeight: 1.1,
    maxLines: family.posterBlocks.mode === 'condensed' ? 2 : 3,
    wordWrap: true,
    vAlign: 'top',
    vTrim: true,
    hTrim: true,
    pixelTrim: true,
    backgroundColor: 'transparent',
    fillColor: GREY_6,
    strokeColor: GREY_6,
  };
  const { data: fontBlock4Data } = useGenerateFontImage(
    'font',
    familyState.idFont,
    block4ImageParameters,
    displayMode === 'colored poster' ? true : false,
    { enabled: familyState.authorized && !!(getBlock4Text() !== '') }
  );
  useEffect(() => {
    if (fontBlock4Data) {
      setFontBlock4(fontBlock4Data);
    }
  }, [fontBlock4Data]);
  // Case fontId not change, so query not refetch, we reforce data
  useEffect(() => {
    if (fontBlock4Data && !forceLoading && fontId === previousFontId) {
      setFontBlock4(fontBlock4Data);
    }
  }, [fontBlock4Data, fontId, forceLoading, previousFontId]);
  const fontBlock4Svg = useMemo(() => {
    // To force `darkMode` in useMemo Array
    const isDarkMode = darkMode;
    if (fontBlock4 && displayMode) {
      return convertSvgStringToDataUrl(fontBlock4.svg);
    }
    return null;
  }, [darkMode, displayMode, fontBlock4]);

  const handleClickBookmarkButton = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClickBookmarkButton(familyState.idFont);
    },
    [familyState.idFont, onClickBookmarkButton]
  );

  const handleClickFontLink = useCallback(() => {
    if (onClickFontLink) {
      onClickFontLink(familyState.idFont);
    }
  }, [familyState.idFont, onClickFontLink]);

  const handleChangeFontId = useCallback(
    (fontId: number) => {
      const cloneFamilyState: API.Family = JSON.parse(JSON.stringify(familyState));
      cloneFamilyState.idFont = fontId;
      setFamilyState(cloneFamilyState);
    },
    [familyState]
  );

  const handleAfterChangeFontId = useCallback(
    (fontId: number) => {
      // Save current fontWeight if route is a "board" route
      if (router.route.indexOf(`${ROUTES.BOARD}/[boardQuery]`) > -1) {
        const boardSplitUrl = (router.query.boardQuery as string).split('-');
        const boardId = parseInt(boardSplitUrl[boardSplitUrl.length - 1]);
        if (onSaveCurrentFontWeightInBoard) {
          onSaveCurrentFontWeightInBoard(boardId, family.idFamily, fontId);
        }
      }
    },
    [family.idFamily, onSaveCurrentFontWeightInBoard, router.query.boardQuery, router.route]
  );

  const checkIsLoadingFontImages = useCallback(() => {
    if (isBot === 'true') {
      return false;
    }
    if (!familyState.authorized) {
      return false;
    }
    if (forceLoading) {
      return true;
    }
    let count = 0;
    const fontImagesToCheck = [];
    if (getBlock1Text() !== '') {
      fontImagesToCheck.push(fontBlock1);
    }
    if (getBlock2Text() !== '') {
      fontImagesToCheck.push(fontBlock2);
    }
    if (getBlock3Text() !== '') {
      fontImagesToCheck.push(fontBlock3);
    }
    if (getBlock4Text() !== '') {
      fontImagesToCheck.push(fontBlock4);
    }
    fontImagesToCheck.forEach((image) => {
      if (image) count += 1;
    });
    if (count === fontImagesToCheck.length) {
      return false;
    }
    return true;
  }, [
    familyState.authorized,
    fontBlock1,
    fontBlock2,
    fontBlock3,
    fontBlock4,
    forceLoading,
    getBlock1Text,
    getBlock2Text,
    getBlock3Text,
    getBlock4Text,
    isBot,
  ]);

  const cardUrl = useMemo(() => {
    let url = family.fonts.find((font) => font.idFont === familyState.idFont)?.url ?? family.url;
    if (isExtension && machineId) {
      url += `?${MACHINE_ID}=${machineId}`;
    }
    return url;
  }, [family.fonts, family.url, familyState.idFont, isExtension, machineId]);

  return (
    <Card>
      <CardContainer scaleValue={scaleValue}>
        <Link
          href={cardUrl}
          prefetch={false}
          onClick={handleClickFontLink}
          {...(isExtension && { target: '_blank' })}
        >
          {checkIsLoadingFontImages() && (
            <CardLoader scaleValue={scaleValue}>
              <Spinner
                darkMode={darkMode && displayMode !== 'colored poster'}
                fillTransparent={true}
              />
            </CardLoader>
          )}
          <CardWrapper
            displayMode={displayMode}
            darkMode={darkMode}
            {...(displayMode === 'colored poster' && {
              backgroundColor: posterBackgroundColors[index % posterBackgroundColors.length],
            })}
          >
            <ContentWrapper isLoadingFonts={checkIsLoadingFontImages()}>
              <ContentRenderedWrapper>
                {familyState.authorized && (
                  <RenderIfVisible
                    defaultHeight={0}
                    visibleOffset={400}
                  >
                    <ImagesWrapper>
                      {/* BLOCK FONT 1 */}
                      {getBlock1Text() !== '' && (
                        <Block1ContentWrapper>
                          <Block1Wrapper
                            block1Width={fontBlock1?.width}
                            block1Height={fontBlock1?.height}
                          >
                            {fontBlock1Svg && (
                              <Image
                                src={fontBlock1Svg}
                                alt={getBlock1Text()}
                                fill={true}
                              />
                            )}
                          </Block1Wrapper>
                        </Block1ContentWrapper>
                      )}
                      {/* BLOCK FONT 2 */}
                      {getBlock2Text() !== '' && (
                        <Block2ContentWrapper>
                          <Block2Wrapper
                            block2Width={fontBlock2?.width}
                            block2Height={fontBlock2?.height}
                          >
                            {fontBlock2Svg && (
                              <Image
                                src={fontBlock2Svg}
                                alt={getBlock2Text()}
                                fill={true}
                              />
                            )}
                          </Block2Wrapper>
                        </Block2ContentWrapper>
                      )}
                      {/* BLOCK FONT 3 */}
                      {getBlock3Text() !== '' && (
                        <Block3ContentWrapper>
                          <Block3Wrapper
                            block3Width={fontBlock3?.width}
                            block3Height={fontBlock3?.height}
                          >
                            {fontBlock3Svg && (
                              <Image
                                src={fontBlock3Svg}
                                alt={getBlock3Text()}
                                fill={true}
                              />
                            )}
                          </Block3Wrapper>
                        </Block3ContentWrapper>
                      )}
                      {/* BLOCK FONT 4 */}
                      {getBlock4Text() !== '' && (
                        <Block4ContentWrapper>
                          <Block4Wrapper
                            block4Width={fontBlock4?.width}
                            block4Height={fontBlock4?.height}
                          >
                            {fontBlock4Svg && (
                              <Image
                                src={fontBlock4Svg}
                                alt={getBlock4Text()}
                                fill={true}
                              />
                            )}
                          </Block4Wrapper>
                        </Block4ContentWrapper>
                      )}
                    </ImagesWrapper>
                  </RenderIfVisible>
                )}
                {!familyState.authorized && isBot === 'false' && (
                  <FontUnauthorizedWrapper>
                    <FontUnauthorized
                      darkMode={darkMode && displayMode !== 'colored poster'}
                      width={isMobile ? 66 : 71}
                      height={isMobile ? 66 : 71}
                      shadowSize={4}
                    />
                    <FontUnauthorizedText darkMode={darkMode && displayMode !== 'colored poster'}>
                      {t('card.not-authorized')}
                    </FontUnauthorizedText>
                  </FontUnauthorizedWrapper>
                )}
                <FooterWrapper
                  className="card-footer"
                  isLoadingFonts={checkIsLoadingFontImages()}
                  darkMode={darkMode}
                  displayMode={displayMode}
                  {...(displayMode === 'colored poster' && {
                    backgroundColor: posterBackgroundColors[index % posterBackgroundColors.length],
                  })}
                >
                  <Separator
                    className="separator-font-foundry"
                    darkMode={darkMode && displayMode !== 'colored poster'}
                  />
                  <FontFoundryInfosWrapper>
                    <FontInfos darkMode={darkMode && displayMode !== 'colored poster'}>{family.name}</FontInfos>
                    <FoundryInfos darkMode={darkMode && displayMode !== 'colored poster'}>
                      {family.foundry.name}
                    </FoundryInfos>
                  </FontFoundryInfosWrapper>
                  {family.designers && (
                    <>
                      <Separator
                        className="separator-designers"
                        darkMode={darkMode && displayMode !== 'colored poster'}
                      />
                      <DesignersInfosWrapper>
                        <DesignersLabel darkMode={darkMode && displayMode !== 'colored poster'}>
                          {t('card.designers')}
                        </DesignersLabel>
                        <DesignersInfos darkMode={darkMode && displayMode !== 'colored poster'}>
                          {family.designers}
                        </DesignersInfos>
                      </DesignersInfosWrapper>
                    </>
                  )}
                  <Separator
                    className="separator-pricing"
                    darkMode={darkMode && displayMode !== 'colored poster'}
                  />
                  <StylesPricingsWrapper>
                    <StylesInfos darkMode={darkMode && displayMode !== 'colored poster'}>{`${family.totalFonts} ${
                      family.totalFonts > 1 ? t('card.styles') : t('card.style')
                    }`}</StylesInfos>
                    {(family.price || (family.links && family.links.trials)) && (
                      <PricingInfos darkMode={darkMode && displayMode !== 'colored poster'}>
                        {family.price && t('card.from', { price: family.price.formatedPrice })}
                        {family.price &&
                          family.links &&
                          family.links.trials &&
                          family.links.trials.find((trial) => trial.name === 'website') &&
                          ` / `}
                        {family.links &&
                          family.links.trials &&
                          family.links.trials.find((trial) => trial.name === 'website') &&
                          t('card.trial-available')}
                      </PricingInfos>
                    )}
                  </StylesPricingsWrapper>
                </FooterWrapper>
              </ContentRenderedWrapper>
            </ContentWrapper>
            {!checkIsLoadingFontImages() && family.idFamily && (
              <MarkerContainer>
                <Marker familyId={family.idFamily} />
              </MarkerContainer>
            )}
            {family.variable && <Variable darkMode={darkMode}>{t('card.variable')}</Variable>}
          </CardWrapper>
        </Link>
      </CardContainer>
      {!checkIsLoadingFontImages() && (
        <HoverWrapper
          className="card-hover"
          scaleValue={scaleValue}
        >
          <BookmarkBackground
            className="bookmark-background"
            darkMode={darkMode}
            displayMode={displayMode}
            {...(displayMode === 'colored poster' && {
              backgroundColor: posterBackgroundColors[index % posterBackgroundColors.length],
            })}
          />
          <BookmarkButtonWrapper
            className="bookmark-button-wrapper"
            scaleValue={scaleValue}
            isMobile={isMobile}
          >
            <BookmarkButtonWithSlider
              familyId={family.idFamily}
              fontId={familyState.idFont}
              fonts={family.fonts}
              darkMode={darkMode && displayMode !== 'colored poster'}
              onClickBookmark={handleClickBookmarkButton}
              onChangeFontId={handleChangeFontId}
              onAfterChangeFontId={handleAfterChangeFontId}
            />
          </BookmarkButtonWrapper>
        </HoverWrapper>
      )}
    </Card>
  );
};

export default Poster;
