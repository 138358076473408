import { createContext, PropsWithChildren, ReactElement, useContext } from 'react';
import { SearchContextData, useSearchState } from 'hooks';

const initialData: SearchContextData = {
  openSearch: false,
  setOpenSearch: (_value: boolean) => null,
};

export const SearchContext = createContext(initialData);
export const useSearchContext = () => useContext(SearchContext);

export const SearchContextProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): ReactElement => {
  const state = useSearchState(initialData);

  return <SearchContext.Provider value={state}>{children}</SearchContext.Provider>;
};
