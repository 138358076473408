import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/feedback`;

interface SendTypefacesProps {
  fullName: string;
  foundry?: string;
  email: string;
  file: File;
  recaptchaToken: string | null;
}

// TYPEFACES
export const sendTypefaces = (data: SendTypefacesProps): Promise<API.Feedback.Typefaces> => {
  const formData = new FormData();
  formData.append('fullName', data.fullName);
  if (data.foundry) {
    formData.append('foundry', data.foundry);
  }
  formData.append('email', data.email);
  formData.append('zipfile', data.file);
  if (data.recaptchaToken) {
    formData.append('recaptchaToken', data.recaptchaToken);
  }

  return axios.post(`${baseUrl}/typefaces`, formData).then(({ data }) => {
    return data;
  });
};

// CORRECTIONS
export const sendCorrections = (
  fullName: string,
  email: string,
  message?: string,
  recaptchaToken?: string
): Promise<API.Feedback.Corrections> => {
  const data = {
    fullName,
    email,
    message,
    recaptchaToken,
  };
  return axios.post(`${baseUrl}/corrections`, data).then(({ data }) => {
    return data;
  });
};

// CONTACT US
export const sendContactUs = (
  fullName: string,
  email: string,
  subject: string,
  message?: string,
  recaptchaToken?: string
): Promise<API.Feedback.ContactUs> => {
  const data = {
    fullName,
    email,
    subject,
    message,
    recaptchaToken,
  };
  return axios.post(`${baseUrl}/contact`, data).then(({ data }) => {
    return data;
  });
};
