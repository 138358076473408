export const linear = 'cubic-bezier(0.250, 0.250, 0.750, 0.750)';
export const ease = 'cubic-bezier(0.250, 0.100, 0.250, 1.000)';
export const easeIn = 'cubic-bezier(0.420, 0.000, 1.000, 1.000)';
export const easeOut = 'cubic-bezier(0.000, 0.000, 0.580, 1.000)';
export const easeInOut = 'cubic-bezier(0.420, 0.000, 0.580, 1.000)';

export const easeInQuad = 'cubic-bezier(0.550, 0.085, 0.680, 0.530)';
export const easeInCubic = 'cubic-bezier(0.550, 0.055, 0.675, 0.190)';
export const easeInQuart = 'cubic-bezier(0.895, 0.030, 0.685, 0.220)';
export const easeInQuint = 'cubic-bezier(0.755, 0.050, 0.855, 0.060)';
export const easeInSine = 'cubic-bezier(0.470, 0.000, 0.745, 0.715)';
export const easeInExpo = 'cubic-bezier(0.950, 0.050, 0.795, 0.035)';
export const easeInCirc = 'cubic-bezier(0.600, 0.040, 0.980, 0.335)';
export const easeInBack = 'cubic-bezier(0.600, -0.280, 0.735, 0.045)';

export const easeOutQuad = 'cubic-bezier(0.250, 0.460, 0.450, 0.940)';
export const easeOutCubic = 'cubic-bezier(0.215, 0.610, 0.355, 1.000)';
export const easeOutQuart = 'cubic-bezier(0.165, 0.840, 0.440, 1.000)';
export const easeOutQuint = 'cubic-bezier(0.230, 1.000, 0.320, 1.000)';
export const easeOutSine = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)';
export const easeOutExpo = 'cubic-bezier(0.190, 1.000, 0.220, 1.000)';
export const easeOutCirc = 'cubic-bezier(0.075, 0.820, 0.165, 1.000)';
export const easeOutBack = 'cubic-bezier(0.175, 0.885, 0.320, 1.275)';

export const easeInOutQuad = 'cubic-bezier(0.455, 0.030, 0.515, 0.955)';
export const easeInOutCubic = 'cubic-bezier(0.645, 0.045, 0.355, 1.000)';
export const easeInOutQuart = 'cubic-bezier(0.770, 0.000, 0.175, 1.000)';
export const easeInOutQuint = 'cubic-bezier(0.860, 0.000, 0.070, 1.000)';
export const easeInOutSine = 'cubic-bezier(0.445, 0.050, 0.550, 0.950)';
export const easeInOutExpo = 'cubic-bezier(1.000, 0.000, 0.000, 1.000)';
export const easeInOutCirc = 'cubic-bezier(0.785, 0.135, 0.150, 0.860)';
export const easeInOutBack = 'cubic-bezier(0.680, -0.550, 0.265, 1.550)';
