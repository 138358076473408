import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { trackAccountDeleted } from 'tracking/Mixpanel';
import { useAuthContext, useModalContext, useSettingsContext } from 'context';
import { useUser } from 'hooks/queries/useUser';
import Modal from 'components/Modal';
import {
  ButtonWrapper,
  CloseButtonWrapper,
  ContentWrapper,
  Description,
  GlobalError,
  ModalWrapper,
  TitleWrapper,
} from './DeleteFontsNinjaAccount.styled';

const DeleteFontsNinjaAccount = () => {
  const { t } = useTranslation('common');
  const { logout } = useAuthContext();
  const { darkMode } = useSettingsContext();
  const {
    openModalDeleteFontsNinjaAccount,
    setOpenModalDeleteFontsNinjaAccount,
    setOpenModalDeleteAccountConfirmation,
  } = useModalContext();

  const [startClose, setStartClose] = useState<boolean>(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  const { useDeleteUser } = useUser();
  const deleteUserMutation = useDeleteUser({
    onSuccess: () => {
      // Track Mixpanel Account deleted
      trackAccountDeleted();
      setButtonIsDisabled(false);
      handleClose();
      logout();
      setOpenModalDeleteAccountConfirmation(true);
    },
    onError: (err: any) => {
      setApiError(t('errors.generic'));
    },
  });

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalDeleteFontsNinjaAccount(false);
  }, [setOpenModalDeleteFontsNinjaAccount]);

  const handleDeleteFontsNinjaAccount = useCallback(() => {
    setButtonIsDisabled(true);
    deleteUserMutation.mutate();
  }, [deleteUserMutation]);

  useEffect(() => {
    if (!openModalDeleteFontsNinjaAccount) {
      setButtonIsDisabled(false);
    }
  }, [openModalDeleteFontsNinjaAccount]);

  if (openModalDeleteFontsNinjaAccount) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            <TitleWrapper>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('delete-fonts-ninja-account.title')}
              </H3>
            </TitleWrapper>
            <Description dangerouslySetInnerHTML={{ __html: t('delete-fonts-ninja-account.description') }} />
            <ButtonWrapper darkMode={darkMode}>
              <Button
                disabled={buttonIsDisabled}
                darkMode={darkMode}
                onClick={handleDeleteFontsNinjaAccount}
              >
                {t('delete-fonts-ninja-account.delete-account')}
              </Button>
              {apiError !== '' && <GlobalError>{apiError}</GlobalError>}
            </ButtonWrapper>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default DeleteFontsNinjaAccount;
