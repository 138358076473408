export const isValidUrl = (value: string): boolean => {
  var validUrlRegex = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', // validate fragment locator
    'i'
  );
  return !!validUrlRegex.test(value);
};

export const removeProtocolFromUrl = (value: string): string => {
  // Remove last character `/` of url if exists
  if (value.substring(value.length - 1) === '/') {
    value = value.substring(0, value.length - 1);
  }
  return value.replace(/^https?:\/\//, '');
};

export const cleanInstagramName = (value: string): string => {
  // Remove `@` character if exists
  if (value.substring(0, 1) === '@') {
    value = value.substring(1, value.length);
    return value;
  } else if (isValidUrl(value)) {
    // Clean instagram url if copy from website
    const protocolRegex = new RegExp(/^https?:\/\//);
    if (!protocolRegex.test(value)) {
      value = `https://${value}`;
    }
    let instagramUrl = new URL(value);
    try {
      value = `${instagramUrl.protocol}//${instagramUrl.host}${instagramUrl.pathname}`;
      const protocolRegex = new RegExp(/^https?:\/\//);
      const instagramRegex = new RegExp(/^(?:@|(?:https?:\/\/)?(?:www\.)?instagr(?:\.am|am\.com)\/)?([\w\.]+)\/?$/g);
      let match = instagramRegex.exec(value);
      if (match) {
        return match[1];
      }
      return value;
    } catch {
      return value;
    }
  }
  // Nothing to do
  return value;
};

export const transformStringToUrl = (str: string): string => {
  return str
    .trim()
    .toLowerCase()
    .replace(/ /g, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};

export const generateRandomSeed = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
