import api from 'api';
import { getCookie, setCookie } from 'common/utils';
import { FONTS_NINJA_VISITOR_OTEL, FONTS_NINJA_VISIT_OTEL } from 'common/data/Constants';

export const oTelCountPage = (pagePath: string): void => {
  api.statistics.incrementCounterOpenTelemetry(`page:${pagePath}`);
};

export const oTelCountVisits = (): void => {
  const cookieVisit = getCookie(FONTS_NINJA_VISIT_OTEL);
  if (!cookieVisit) {
    api.statistics.incrementCounterOpenTelemetry('visit');
  }
  const thirtyMinutesAge = 30 * 60; // 30 minutes * 60 seconds
  setCookie(FONTS_NINJA_VISIT_OTEL, 'true', thirtyMinutesAge, 'None');
};

export const oTelCountVisitors = (): void => {
  const cookieVisitor = getCookie(FONTS_NINJA_VISITOR_OTEL);
  if (!cookieVisitor) {
    api.statistics.incrementCounterOpenTelemetry('visitor');
  }
  const oneYearAge = 365 * 24 * 3600; // 365 days * 24 hours * 3600 seconds
  setCookie(FONTS_NINJA_VISITOR_OTEL, 'true', oneYearAge, 'None');
};
