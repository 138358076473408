import styled from '@emotion/styled';
import { GREY_6 } from 'design-system/global/colors';

interface Props {
  darkMode: boolean;
  shadowSize: number;
}

export const IconWrapper = styled.div`
  position: relative;
  filter: ${(props: Props) =>
    props.darkMode ? 'none' : `drop-shadow(${props.shadowSize}px ${props.shadowSize}px 0px ${GREY_6})`};
`;
