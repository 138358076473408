import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { InputText } from 'design-system/atoms/input-text';
import { Icon } from 'design-system/atoms/icon';
import { Switch } from 'design-system/atoms/switch';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useModalContext, useSettingsContext } from 'context';
import { useBoard } from 'hooks/queries/useBoard';
import { QUERY_KEYS } from 'common/data/Constants';
import Modal from 'components/Modal';
import {
  ButtonDeleteWrapper,
  ButtonSaveWrapper,
  ButtonsWrapper,
  CloseButtonWrapper,
  ContentWrapper,
  FormWrapper,
  GlobalError,
  InputTextWrapper,
  ModalWrapper,
  SwitchWrapper,
  TitleWrapper,
} from './EditBoard.styled';

interface InputValue {
  value: string;
  error: string;
}

const EditBoard = () => {
  const { t } = useTranslation('common');

  const { darkMode } = useSettingsContext();
  const { openModalEditBoard, setOpenModalEditBoard, setOpenModalDeleteBoard } = useModalContext();

  const [startClose, setStartClose] = useState<boolean>(false);
  const [boardName, setBoardName] = useState<InputValue>({ value: '', error: '' });
  const [isPrivateBoard, setIsPrivateBoard] = useState<boolean>(false);
  const [buttonSaveDisabled, setButtonSaveDisabled] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { useEditBoard } = useBoard();

  const editBoardMutation = useEditBoard({
    onMutate: () => {
      if (openModalEditBoard) {
        // Cancel any outgoing refetches
        queryClient.cancelQueries([QUERY_KEYS.BOARD, { id: openModalEditBoard.idBoard }]);
        // Snapshot the previous value
        const previousData: { board: API.Board.BoardSimple } | undefined = queryClient.getQueryData([
          QUERY_KEYS.BOARD,
          { id: openModalEditBoard!.idBoard },
        ]);
        if (previousData) {
          // Clone previous data
          const newData: { board: API.Board.BoardSimple } = JSON.parse(JSON.stringify(previousData));
          newData.board.name = boardName.value;
          newData.board.private = isPrivateBoard;
          // Optimistically update to the new value
          queryClient.setQueryData([QUERY_KEYS.BOARD, { id: openModalEditBoard.idBoard }], newData);
          return { previousData };
        }
      }
    },
    onSuccess: () => {
      // Remove board page queries
      queryClient.removeQueries({ queryKey: [QUERY_KEYS.BOARD_COUNT] });
      queryClient.removeQueries({ queryKey: [QUERY_KEYS.BOARD_LIST_DETAILED] });
      queryClient.removeQueries({ queryKey: [QUERY_KEYS.BOARD_LIST_SIMPLE] });

      setButtonSaveDisabled(false);
      handleClose();
    },
    onError: (err, variables, context) => {
      setButtonSaveDisabled(false);
      setGlobalError(true);
      if (openModalEditBoard && context?.previousData) {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData([QUERY_KEYS.BOARD, { id: openModalEditBoard.idBoard }], context.previousData);
      }
    },
  });

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalEditBoard(null);
  }, [setOpenModalEditBoard]);

  const handleChangeBoardName = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setBoardName({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleChangePrivateBoard = useCallback(() => {
    setIsPrivateBoard(!isPrivateBoard);
  }, [isPrivateBoard]);

  const handleSubmitForm = useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      setButtonSaveDisabled(true);
      let nbError = 0;
      if (boardName.value.trim() === '') {
        setBoardName({ value: boardName.value, error: t('edit-board.empty-board-name') });
        setButtonSaveDisabled(false);
        nbError += 1;
      }
      if (nbError > 0) return;

      if (openModalEditBoard) {
        editBoardMutation.mutate({
          idBoard: openModalEditBoard.idBoard,
          name: boardName.value,
          isPrivate: isPrivateBoard,
        });
      }
    },
    [boardName.value, editBoardMutation, isPrivateBoard, openModalEditBoard, t]
  );

  const handleDeleteBoard = useCallback(() => {
    setStartClose(true);
    setOpenModalDeleteBoard({
      idBoard: openModalEditBoard!.idBoard,
      name: openModalEditBoard!.name,
      isPrivate: openModalEditBoard!.isPrivate,
    });
  }, [openModalEditBoard, setOpenModalDeleteBoard]);

  useEffect(() => {
    if (!openModalEditBoard) {
      setBoardName({ value: '', error: '' });
      setIsPrivateBoard(false);
      setButtonSaveDisabled(false);
      setGlobalError(false);
    } else {
      setBoardName({ value: openModalEditBoard.name, error: '' });
      setIsPrivateBoard(openModalEditBoard.isPrivate);
      setButtonSaveDisabled(false);
      setGlobalError(false);
    }
  }, [openModalEditBoard]);

  if (openModalEditBoard) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            <TitleWrapper>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('edit-board.title')}
              </H3>
            </TitleWrapper>
            <FormWrapper onSubmit={handleSubmitForm}>
              <InputTextWrapper isPrivate={isPrivateBoard}>
                <InputText
                  placeholder={t('edit-board.placeholder')}
                  value={boardName.value}
                  error={boardName.error}
                  maxLength={70}
                  darkMode={darkMode}
                  onChange={handleChangeBoardName}
                />
                {isPrivateBoard && (
                  <Icon
                    label="not_visible"
                    color={darkMode ? WHITE : GREY_6}
                  />
                )}
              </InputTextWrapper>
              <SwitchWrapper>
                <Switch
                  checked={isPrivateBoard}
                  darkMode={darkMode}
                  onChange={handleChangePrivateBoard}
                >
                  {t('edit-board.private-board')}
                </Switch>
              </SwitchWrapper>
              <ButtonsWrapper>
                <ButtonSaveWrapper>
                  <Button
                    darkMode={darkMode}
                    disabled={buttonSaveDisabled}
                  >
                    {t('edit-board.save')}
                  </Button>
                </ButtonSaveWrapper>
                <ButtonDeleteWrapper>
                  <Button
                    type="button"
                    render="contrast"
                    darkMode={darkMode}
                    onClick={handleDeleteBoard}
                  >
                    {t('edit-board.delete-board')}
                  </Button>
                </ButtonDeleteWrapper>
              </ButtonsWrapper>
              {globalError && <GlobalError>{t('errors.generic')}</GlobalError>}
            </FormWrapper>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default EditBoard;
