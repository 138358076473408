import { useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useModalContext, useSettingsContext } from 'context';
import Modal from 'components/Modal';
import {
  CloseButtonWrapper,
  ContentWrapper,
  LanguageDetails,
  LanguageName,
  LanguageWrapper,
  ModalWrapper,
  TitleWrapper,
} from './SupportedLanguageSystems.styled';

const SupportedLanguageSystems = () => {
  const { t } = useTranslation('common');
  const { darkMode } = useSettingsContext();
  const { openModalSupportedLanguageSystems, setOpenModalSupportedLanguageSystems } = useModalContext();

  const [startClose, setStartClose] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalSupportedLanguageSystems(null);
  }, [setOpenModalSupportedLanguageSystems]);

  if (openModalSupportedLanguageSystems) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            <TitleWrapper>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('supported-language-systems.title')}
              </H3>
            </TitleWrapper>
            {openModalSupportedLanguageSystems.supportedLanguages.map((language, index) => (
              <LanguageWrapper key={index}>
                <LanguageName darkMode={darkMode}>{language.name}</LanguageName>
                <LanguageDetails darkMode={darkMode}>{language.values.join(', ')}</LanguageDetails>
              </LanguageWrapper>
            ))}
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default SupportedLanguageSystems;
