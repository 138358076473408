import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { CheckboxSelector, CheckboxWrapper, Input, Label } from './checkbox.styled';

export interface CheckboxProps {
  name?: string;
  checked?: boolean;
  darkMode?: boolean;
  color?: string;
  children?: ReactNode;
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
}

export function Checkbox({ name, checked = false, darkMode = false, color, children, onChange }: CheckboxProps) {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setIsChecked(e.currentTarget.checked);
    onChange?.(e);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <Label
      darkMode={darkMode}
      color={color}
      data-testid="checkbox-id"
    >
      <Input
        {...(name && { name })}
        type="checkbox"
        defaultChecked={isChecked}
        onChange={handleChange}
      />
      <CheckboxWrapper
        className="checkbox-wrapper"
        {...(children && { hasMarginRight: true })}
      >
        <CheckboxSelector
          className="checkbox-selector"
          checked={isChecked}
        />
      </CheckboxWrapper>
      {children}
    </Label>
  );
}
