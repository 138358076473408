import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { mq } from 'styles/mediaqueries.styled';
import { BIG_DESKTOP_GUTTER, DESKTOP_GUTTER, MAX_WIDTH, MOBILE_GUTTER, TABLET_GUTTER } from 'common/data/Constants';

interface Props {
  ready: boolean;
  isBot: string;
}

export const MainStyled = styled.main`
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
  visibility: ${(props: Props) => (props.ready ? 'visible' : 'hidden')};
  overflow: hidden;
  z-index: 0;
  /* MOBILE */
  padding: 120px ${MOBILE_GUTTER}px 0;
  /* TABLET */
  ${mq['tablet']} {
    padding: 116px ${TABLET_GUTTER}px 0;
  }
  /* DESKTOP */
  ${mq['desktop']} {
    padding: 56px ${DESKTOP_GUTTER}px 0;
  }
  /* DESKTOP_1200 */
  ${mq['desktop_1200']} {
    padding: 56px ${BIG_DESKTOP_GUTTER}px 0;
  }

  // Bot userAgent
  ${(props: Props) =>
    props.isBot === 'true' &&
    css`
      visibility: visible;
    `}
`;
