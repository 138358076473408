import React, { HTMLAttributes, ReactNode } from 'react';
import { GREY_5 } from 'design-system/global/colors';
import { H6Styled } from './h6.styled';

export interface H6Props extends HTMLAttributes<HTMLHeadingElement | HTMLParagraphElement> {
  display?: 'block' | 'inline-block' | 'inline';
  color?: string;
  italic?: boolean;
  letterSpacing?: 'normal' | 'initial' | 'inherit' | string;
  lineHeight?: 'normal' | 'initial' | 'inherit' | string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  children?: ReactNode;
}

export function H6({
  display = 'block',
  color = GREY_5,
  italic = false,
  lineHeight = '22px',
  as = 'h6',
  children,
  ...props
}: H6Props) {
  return (
    <H6Styled
      display={display}
      color={color}
      italic={italic}
      lineHeight={lineHeight}
      as={as}
      {...props}
    >
      {children}
    </H6Styled>
  );
}
