import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DANGER_RED, GREY_1, GREY_2, GREY_6, WHITE } from 'design-system/global/colors';
import { easeInOutQuart } from 'common/easing/Easing';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
  status?: 'invited' | 'joined' | 'owner';
}

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 5px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 24px 16px 18px;
  /* TABLET */
  ${mq['tablet']} {
    width: 408px;
    padding: 32px 32px 16px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 32px;
`;

export const InputSearchWrapper = styled.div`
  position: relative;
  margin-bottom: 4px;
  z-index: 1;
`;

export const SearchCloseLayout = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  z-index: 1;
`;

export const SearchUsersInvitationWrapper = styled.div`
  position: absolute;
  width: 100%;
  min-height: 40px;
  max-height: 164px;
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  border-radius: 8px;
  margin-top: 4px;
  padding: 4px;
  overflow-y: auto;
  z-index: 1;
`;

export const SearchUserItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
    .avatar-outline {
      background-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
    }
    .invite-button {
      display: flex;
    }
    i,
    span,
    .invite-label {
      color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
    }
  }
`;

export const SearchUserAvatarName = styled.div`
  position: relative;
  display: flex;
`;

export const SearchUserName = styled.p`
  color: ${GREY_1};
  margin-left: 8px;
  font-size: 14px;
  line-height: 22px;

  span {
    color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  }
`;

export const SearchInviteWrapper = styled.div`
  display: none;
  align-items: center;
  height: 24px;
`;

export const SearchInviteLabel = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-right: 4px;
`;

export const EmailInvitationWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 64px;
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  border-radius: 8px;
  margin-top: 4px;
  z-index: 1;
`;

export const EmailInvitationLinkContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 8px);
  border-radius: 4px;
  margin: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
    i,
    p {
      color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
    }
  }
`;

export const EmailIconNameWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export const EmailName = styled.p`
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EmailSendLabel = styled.p`
  padding: 7px 0 0 34px;
  font-size: 12px;
  line-height: 14px;
`;

export const InvitationLinkWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  opacity: 0.5;
  height: 24px;
  margin-bottom: 32px;
  cursor: pointer;
  transition: opacity 0.3s ${easeInOutQuart};
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
`;

export const InvitationLinkText = styled.p`
  font-size: 12px;
  line-height: 16px;
  margin-left: 4px;
`;

export const Separator = styled.div`
  height: 1px;
  background-color: ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  /* MOBILE */
  margin: 0 -24px;
  /* TABLET */
  ${mq['tablet']} {
    margin: 0 -40px;
  }
`;

export const CollaboratorsListWrapper = styled.div`
  overflow-y: auto;
  max-height: 220px;
  /* MOBILE */
  margin: 0 -24px -26px;
  padding: 16px 24px 24px;
  /* TABLET */
  ${mq['tablet']} {
    margin: 0 -40px -24px;
    padding: 16px 40px 24px;
  }
`;

export const CollaboratorsLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const CollaboratorLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CollaboratorItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  &:first-of-type {
    margin-top: 4px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const CollaboratorAvatarName = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const CollaboratorAvatar = styled.div`
  ${(props: Props) =>
    props.status &&
    props.status === 'invited' &&
    css`
      opacity: 0.3;
    `}
`;

export const CollaboratorName = styled.p`
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;
  ${(props: Props) =>
    props.status &&
    props.status === 'invited' &&
    css`
      color: ${GREY_1};
    `}

  ${(props: Props) =>
    props.status !== 'invited' &&
    css`
      @media (hover: hover) {
        &:hover {
          text-decoration: underline;
        }
      }
    `}
`;

export const CollaboratorStatus = styled.div`
  color: ${GREY_1};
  font-size: 12px;
  line-height: 16px;
  padding: 4px 10px;
  border: 1px solid ${GREY_1};
  border-radius: 4px;
`;

export const ButtonConfirmRemoveCollaboratorWrapper = styled.div`
  position: absolute;
  right: 0;

  button {
    background-color: ${DANGER_RED};
    border-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
    color: ${WHITE};
  }
`;
