import styled from '@emotion/styled';
import { GREY_6, WHITE } from 'design-system/global/colors';

const SvgWrapper = styled.svg`
  rect {
    filter: drop-shadow(4px 4px 0px ${GREY_6});
  }
`;

const BookmarkIcon = () => {
  return (
    <SvgWrapper
      width="37"
      height="45"
      viewBox="0 0 37 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="4"
        width="31"
        height="35"
        rx="7"
        fill={WHITE}
        stroke={GREY_6}
        strokeWidth="2"
      />
      <path
        d="M27 14L22.5 10.7692L18 14V5.07692C18 4.79131 18.1185 4.51739 18.3295 4.31542C18.5405 4.11346 18.8266 4 19.125 4H25.875C26.1734 4 26.4595 4.11346 26.6705 4.31542C26.8815 4.51739 27 4.79131 27 5.07692V14Z"
        fill={GREY_6}
        stroke={GREY_6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};

export default BookmarkIcon;
