import React from 'react';
import Select, { components, DropdownIndicatorProps, GroupBase, MultiValueRemoveProps, Props } from 'react-select';
import { Icon } from 'design-system/atoms/icon';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { DropdownStyled } from './dropdown.styled';

export type DropdownProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Props<Option, IsMulti, Group> & {
  width?: string;
  optionsWidth?: string;
  menuMaxHeight?: string;
  darkMode?: boolean;
};

let isDarkMode: boolean;

const DropdownIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        style={{ transform: 'translate(0, -2px)' }}
      >
        <rect
          x="4.26787"
          y="11.6338"
          width="1"
          height="5.03553"
          transform="rotate(-135 4.26787 11.6338)"
          fill={isDarkMode ? WHITE : GREY_6}
        />
        <rect
          x="4.26777"
          y="10.9268"
          width="1"
          height="5.03553"
          transform="rotate(135 4.26777 10.9268)"
          fill={isDarkMode ? WHITE : GREY_6}
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon
        label="close"
        color={isDarkMode ? GREY_6 : WHITE}
        fontSize={'8px'}
      />
    </components.MultiValueRemove>
  );
};

export const Dropdown = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  width = '140px',
  optionsWidth = '326px',
  menuMaxHeight = '300px',
  darkMode = false,
  ...props
}: DropdownProps<Option, IsMulti, Group>) => {
  isDarkMode = darkMode;
  return (
    <DropdownStyled
      width={width}
      optionsWidth={optionsWidth}
      menuMaxHeight={menuMaxHeight}
      darkMode={darkMode}
    >
      <Select
        classNamePrefix="dropdown"
        components={{
          DropdownIndicator,
          MultiValueRemove,
        }}
        {...props}
      />
    </DropdownStyled>
  );
};
