import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DANGER_RED, GREY_6, LIGHT_GREY, WHITE } from 'design-system/global/colors';
import { easeInOutQuart } from 'common/easing/Easing';
import { mq } from 'styles/mediaqueries.styled';

interface ModalWrapperProps {
  modalWrapperHeight?: string;
}

interface TitleWrapperProps {
  displaySeparator: boolean;
}

interface SeparatorProps {
  displaySeparator: boolean;
  darkMode: boolean;
}

interface BoardsWrapperProps {
  displaySeparator: boolean;
}

interface ButtonWrapperProps {
  display: 'flex' | 'none';
}

interface ButtonDeleteWrapperProps {
  darkMode: boolean;
}

export const ModalWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin: 0 -8px;
  padding: 0 8px;
  transition: height 0.3s ${easeInOutQuart};
  /* MOBILE */
  height: ${(props: ModalWrapperProps) => (props.modalWrapperHeight ? 'auto' : '311px')};
  /* TABLET */
  ${mq['tablet']} {
    height: ${(props: ModalWrapperProps) => (props.modalWrapperHeight ? 'auto' : '275px')};
  }
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: 4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 13px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 24px 16px 24px;
  /* TABLET */
  ${mq['tablet']} {
    width: 397px;
    padding: 32px;
  }
`;

export const TitleWrapper = styled.div`
  /* MOBILE */
  margin-bottom: 32px;
  ${(props: TitleWrapperProps) =>
    props.displaySeparator &&
    css`
      margin-top: -8px;
      margin-bottom: 24px;
    `}
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 32px;
  }
`;

export const Separator = styled.div`
  display: ${(props: SeparatorProps) => (props.displaySeparator ? 'block' : 'none')};
  margin: 0 -40px;
  height: 1px;
  background-color: ${(props: SeparatorProps) => (props.darkMode ? WHITE : GREY_6)};
  /* MOBILE */
  margin-bottom: 24px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 32px;
  }
`;

export const BoardsWrapper = styled.div`
  overflow-y: auto;
  /* MOBILE */
  min-height: 112px;
  max-height: 324px;
  margin: 0 -16px 40px;
  padding: 0 16px;
  ${(props: BoardsWrapperProps) =>
    props.displaySeparator &&
    css`
      margin: -24px -16px 0;
      padding: 24px 16px;
    `}
  /* TABLET */
  ${mq['tablet']} {
    min-height: 84px;
    max-height: 396px;
    margin: 0 -32px 32px;
    padding: 0 32px;
    ${(props: BoardsWrapperProps) =>
      props.displaySeparator &&
      css`
        margin: -32px -32px 0;
        padding: 32px;
      `}
  }
`;

export const BoardsLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* MOBILE */
  height: 154px;
  /* TABLET */
  ${mq['tablet']} {
    height: 116px;
  }
`;

export const BoardsEmptyMessage = styled.p`
  /* MOBILE */
  font-size: 16px;
  line-height: 22px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const BoardItemWrapper = styled.div`
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: ${(props: ButtonWrapperProps) => props.display};
  justify-content: space-between;
`;

export const ButtonDeleteWrapper = styled.div`
  button {
    background-color: ${DANGER_RED};
    border-color: ${(props: ButtonDeleteWrapperProps) => (props.darkMode ? WHITE : GREY_6)};
    color: ${WHITE};
  }
`;

export const ButtonSaveWrapper = styled.div`
  button {
    &:disabled {
      color: ${WHITE};
      background-color: ${LIGHT_GREY};
      border-color: ${LIGHT_GREY};
      &:hover {
        border-color: ${LIGHT_GREY};
      }
    }
  }
`;
