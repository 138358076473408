export const setItem = (key: string, value: string) => {
  window.localStorage.setItem(key, value);
};

export const getItem = (key: string): string | null => {
  if (window.localStorage) {
    return window.localStorage.getItem(key);
  }
  return null;
};

export const removeItem = (key: string) => {
  window.localStorage.removeItem(key);
};
