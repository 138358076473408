import styled from '@emotion/styled';
import { GREY_6 } from 'design-system/global/colors';

const SvgWrapper = styled.svg`
  filter: drop-shadow(2px 2px 0px ${GREY_6});
`;

const Icon = () => {
  return (
    <SvgWrapper
      width="39"
      height="34"
      viewBox="0 0 39 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1538 5.92308H17L14.0462 1.98462C13.8169 1.6789 13.5196 1.43077 13.1778 1.25987C12.836 1.08897 12.4591 1 12.0769 1H3.46154C2.8087 1 2.1826 1.25934 1.72097 1.72097C1.25934 2.1826 1 2.8087 1 3.46154V30.5385C1 31.1913 1.25934 31.8174 1.72097 32.279C2.1826 32.7407 2.8087 33 3.46154 33H35.4615C36.1144 33 36.7405 32.7407 37.2021 32.279C37.6637 31.8174 37.9231 31.1913 37.9231 30.5385V8.38462C37.9231 7.73177 37.6637 7.10567 37.2021 6.64404C36.7405 6.18242 36.1144 5.92308 35.4615 5.92308H33"
        fill="#FDBB9F"
      />
      <path
        d="M23.1538 5.92308H17L14.0462 1.98462C13.8169 1.6789 13.5196 1.43077 13.1778 1.25987C12.836 1.08897 12.4591 1 12.0769 1H3.46154C2.8087 1 2.1826 1.25934 1.72097 1.72097C1.25934 2.1826 1 2.8087 1 3.46154V30.5385C1 31.1913 1.25934 31.8174 1.72097 32.279C2.1826 32.7407 2.8087 33 3.46154 33H35.4615C36.1144 33 36.7405 32.7407 37.2021 32.279C37.6637 31.8174 37.9231 31.1913 37.9231 30.5385V8.38462C37.9231 7.73177 37.6637 7.10567 37.2021 6.64404C36.7405 6.18242 36.1144 5.92308 35.4615 5.92308H33"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 19.4648L28.0769 15.7725L23.1538 19.4648V4.69561C23.1538 4.36919 23.2835 4.05614 23.5143 3.82533C23.7451 3.59451 24.0582 3.46484 24.3846 3.46484H31.7692C32.0956 3.46484 32.4087 3.59451 32.6395 3.82533C32.8703 4.05614 33 4.36919 33 4.69561V19.4648Z"
        fill="#CADEEB"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};

export default Icon;
