import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_6, WHITE } from 'design-system/global/colors';

interface AvatarWrapperProps {
  width: number;
  height: number;
}

interface AvatarBackgroundProps {
  darkMode: boolean;
}

interface AvatarBorderProps {
  border: number;
  darkMode: boolean;
  withShadow?: boolean;
}

export const AvatarWrapper = styled.div`
  position: relative;
  width: ${(props: AvatarWrapperProps) => `${props.width}px`};
  min-width: ${(props: AvatarWrapperProps) => `${props.width}px`};
  height: ${(props: AvatarWrapperProps) => `${props.height}px`};
  min-height: ${(props: AvatarWrapperProps) => `${props.height}px`};

  img {
    border-radius: 50%;
    overflow: hidden;
  }
`;

export const AvatarBackground = styled.div`
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 50%;
  background-color: ${(props: AvatarBackgroundProps) => (props.darkMode ? GREY_6 : WHITE)};
`;

export const AvatarBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: ${(props: AvatarBorderProps) => `${props.border}px solid ${props.darkMode ? WHITE : GREY_6}`};
  border-radius: 50%;

  ${(props: AvatarBorderProps) =>
    props.withShadow &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: calc(4px + ${props.border}px);
        left: calc(4px + ${props.border}px);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: ${props.darkMode ? 'transparent' : GREY_6};
        z-index: -1;
      }
    `}
`;
