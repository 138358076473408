import React, { useEffect, useState } from 'react';
import { Icon } from 'design-system/atoms/icon';
import { ToggleButtonStyled, ToggleButtonWrapperStyled } from './toggle-button.styled';

interface Option {
  value: string | number | boolean;
  label?: string;
  icon?: string;
}

type Options = Option[];

export interface ToggleButtonProps {
  options: Options;
  defaultValue?: Option;
  darkMode?: boolean;
  onClick?(option: Option | undefined): void;
}

export function ToggleButton({ options, defaultValue, darkMode = false, onClick }: ToggleButtonProps) {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(defaultValue);

  const handleClick = (option: Option) => {
    if (options.length === 1) {
      const newSelectedOption = option.value !== selectedOption?.value ? option : undefined;
      setSelectedOption(newSelectedOption);
      onClick?.(newSelectedOption);
    } else {
      setSelectedOption(option);
      // We return the value only if current value is not the same than the clicked option
      if (onClick && option.value !== selectedOption?.value) {
        onClick(option);
      }
    }
  };

  useEffect(() => {
    if (JSON.stringify(defaultValue) !== JSON.stringify(selectedOption)) {
      if (options.length === 1) {
        const newSelectedOption = defaultValue?.value !== selectedOption?.value ? defaultValue : undefined;
        setSelectedOption(newSelectedOption);
      } else {
        setSelectedOption(defaultValue);
      }
    }
  }, [defaultValue]);

  return (
    <ToggleButtonWrapperStyled darkMode={darkMode}>
      {options.map((option, index) => (
        <ToggleButtonStyled
          key={`${index}_${option.value}`}
          darkMode={darkMode}
          isSelected={selectedOption ? selectedOption.value === option.value : false}
          onClick={() => handleClick(option)}
        >
          {option.label ? (
            <>
              {option.icon && (
                <Icon
                  label={option.icon}
                  style={{ marginRight: '3px' }}
                />
              )}
              {option.label}
            </>
          ) : (
            <>{option.icon && <Icon label={option.icon} />}</>
          )}
        </ToggleButtonStyled>
      ))}
    </ToggleButtonWrapperStyled>
  );
}
