import ReactSlider from 'react-slider';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_0, GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface ThumbProps {
  darkMode?: boolean;
}

interface TrackProps {
  index: number;
  nbTracks: number;
  darkMode?: boolean;
}

interface MarkProps {
  thumbWidth: number;
  darkMode?: boolean;
}

export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
`;

// @ts-ignore
export const SliderStyled = styled(ReactSlider)`
  width: 100%;
  height: 18px;
`;

export const SliderThumb = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: auto;
  min-width: 16px;
  text-align: center;
  background-color: ${(props: ThumbProps) => (props.darkMode ? WHITE : GREY_6)};
  color: ${(props: ThumbProps) => (props.darkMode ? GREY_6 : WHITE)};
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  padding: 0 3px;
  border-radius: 3px;
  outline: none;
  cursor: default;
  @media (hover: hover) {
    &:hover {
      .slider-tooltip {
        visibility: visible;
      }
    }
  }
  &::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
  }
`;

export const SliderTooltip = styled.div`
  visibility: hidden;
  position: absolute;
  bottom: 26px;
  padding: 9px 13px;
  border-radius: 8px;
  background-color: ${(props: ThumbProps) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: ThumbProps) => (props.darkMode ? GREY_2 : GREY_6)};
  box-shadow: ${(props: ThumbProps) => (props.darkMode ? 'none' : `2px 2px 0px ${GREY_6}`)};
  color: ${(props: ThumbProps) => (props.darkMode ? WHITE : GREY_6)};
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  pointer-events: none;
  min-width: 58px;
  transform: translate(-1px, 0);

  svg {
    position: absolute;
    left: 50%;
    bottom: -7px;
    transform: translate(calc(-50% + 2px), 0);
  }
`;

export const SliderTrack = styled.div`
  top: 50%;
  bottom: calc(50% - 1px);
  // DARK MODE / 1 TRACK
  ${(props: TrackProps) =>
    props.darkMode &&
    props.nbTracks === 1 &&
    props.index === 0 &&
    css`
      background-color: ${WHITE};
    `}
  ${(props: TrackProps) =>
    props.darkMode &&
    props.nbTracks === 1 &&
    props.index === 1 &&
    css`
      background-color: ${GREY_2};
    `}
  // DARK MODE / 2 TRACKS
  ${(props: TrackProps) =>
    props.darkMode &&
    props.nbTracks === 2 &&
    props.index === 0 &&
    css`
      background-color: ${GREY_2};
    `}
  ${(props: TrackProps) =>
    props.darkMode &&
    props.nbTracks === 2 &&
    props.index === 1 &&
    css`
      background-color: ${WHITE};
    `}
  ${(props: TrackProps) =>
    props.darkMode &&
    props.nbTracks === 2 &&
    props.index === 2 &&
    css`
      background-color: ${GREY_2};
    `}
  // LIGHT MODE / 1 TRACK
  ${(props: TrackProps) =>
    !props.darkMode &&
    props.nbTracks === 1 &&
    props.index === 0 &&
    css`
      background-color: ${GREY_6};
    `}
  ${(props: TrackProps) =>
    !props.darkMode &&
    props.nbTracks === 1 &&
    props.index === 1 &&
    css`
      background-color: ${GREY_0};
    `}
  // LIGHT MODE / 2 TRACKS
  ${(props: TrackProps) =>
    !props.darkMode &&
    props.nbTracks === 2 &&
    props.index === 0 &&
    css`
      background-color: ${GREY_0};
    `}
  ${(props: TrackProps) =>
    !props.darkMode &&
    props.nbTracks === 2 &&
    props.index === 1 &&
    css`
      background-color: ${GREY_6};
    `}
  ${(props: TrackProps) =>
    !props.darkMode &&
    props.nbTracks === 2 &&
    props.index === 2 &&
    css`
      background-color: ${GREY_0};
    `}
`;

export const SliderMark = styled.span`
  top: 50%;
  bottom: calc(50% - 1px);
  width: 2px;
  margin: 0 11px;
  background-color: ${(props: MarkProps) => (props.darkMode ? GREY_6 : WHITE)};
  &:first-of-type {
    background-color: transparent;
  }
  &:last-of-type {
    background-color: transparent;
  }
`;
