import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useSettingsContext } from 'context';
import { disableGlobalScroll, enableGlobalScroll } from 'common/utils';
import { EXTENSION } from 'common/data/Constants';
import { Layout, ModalContainer, ModalScrollContainer, ModalStyled } from './Modal.styled';

interface Props extends PropsWithChildren<Record<string, unknown>> {
  darkMode?: boolean;
  layoutClosable?: boolean;
  startClose?: boolean;
  modalContainerOverflowHidden?: boolean;
  onTransitionStartComplete?: () => void;
  onClose?: () => void;
  onCloseComplete?: () => void;
}

let storedScrollY: number;

const Modal = ({
  darkMode,
  layoutClosable,
  startClose,
  modalContainerOverflowHidden,
  onTransitionStartComplete,
  onClose,
  onCloseComplete,
  children,
}: Props) => {
  const { isExtension, extensionScrollY } = useSettingsContext();

  const router = useRouter();

  const isInsideExtension = useMemo(
    () => isExtension || router.query.hasOwnProperty(EXTENSION),
    [isExtension, router.query]
  );

  const modalContainerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalHeight, setModalHeight] = useState<number>(0);

  useEffect(() => {
    if (startClose) {
      setIsOpen(false);

      if (onCloseComplete) {
        modalContainerRef.current?.addEventListener('transitionend', () => {
          enableGlobalScroll();
          onCloseComplete();
        });
      }
    }
  }, [onCloseComplete, startClose]);

  useEffect(() => {
    if (onTransitionStartComplete) {
      enableGlobalScroll();
      modalContainerRef.current?.addEventListener('transitionend', onTransitionStartComplete);
    }
  }, [onTransitionStartComplete]);

  useEffect(() => {
    setIsOpen(true);
    if (modalContainerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        setModalHeight(entries[0].target.getBoundingClientRect().height);
      });
      resizeObserver.observe(modalContainerRef.current);
    }
    setTimeout(() => {
      disableGlobalScroll();
    }, 0);
    return () => {
      enableGlobalScroll();
    };
  }, []);

  return (
    <ModalStyled id="modal">
      <ModalScrollContainer modalHeight={modalHeight}>
        <Layout
          isOpen={isOpen}
          darkMode={darkMode}
          {...(layoutClosable && { onClick: onClose })}
        />
        <ModalContainer
          ref={modalContainerRef}
          darkMode={darkMode}
          isOpen={isOpen}
          startClose={startClose}
          modalContainerOverFlowHidden={modalContainerOverflowHidden}
          {...(isInsideExtension && {
            windowOuterHeight: window.outerHeight,
            extensionScrollY: extensionScrollY,
          })}
        >
          {children}
        </ModalContainer>
      </ModalScrollContainer>
    </ModalStyled>
  );
};

Modal.defaultProps = {
  layoutClosable: true,
};

export default Modal;
