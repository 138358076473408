import {
  BLUE_GREY,
  BLUE_LIGHT,
  LAVENDER,
  NINJA_GREEN,
  ORANGE,
  PINK,
  SALMON,
  YELLOW,
} from 'design-system/global/colors';

export const posterBackgroundColors = [
  YELLOW,
  NINJA_GREEN,
  SALMON,
  BLUE_LIGHT,
  ORANGE,
  PINK,
  NINJA_GREEN,
  LAVENDER,
  SALMON,
  ORANGE,
  BLUE_GREY,
  YELLOW,
  BLUE_LIGHT,
  PINK,
  LAVENDER,
];
