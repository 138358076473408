import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Button } from 'design-system/atoms/button';
import { optInTracking, optOutTracking } from 'tracking/Mixpanel';
import { useBreakpointContext, useSettingsContext } from 'context';
import CookieIcon from 'components/Icons/Cookie';
import { getExtensionGpdrValidation, saveExtensionGdprValidation } from 'bridge/Bridge';
import { getCookie, setCookie } from 'common/utils';
import { EXTENSION, FONTS_NINJA_GDPR_BANNER } from 'common/data/Constants';
import { ButtonsWrapper, GdprBannerContent, GdprBannerWrapper, GdprText, IconTextWrapper } from './GdprBanner.styled';

const GdprBanner = () => {
  const { t } = useTranslation();
  const { darkMode, isExtension, extensionDocumentHeight, extensionScrollY, extensionWindowHeight } =
    useSettingsContext();
  const { isMobile } = useBreakpointContext();

  const router = useRouter();

  const gdprBannerWrapperRef = useRef<HTMLDivElement>(null);

  const isInsideExtension = useMemo(
    () => isExtension || router.query.hasOwnProperty(EXTENSION),
    [isExtension, router.query]
  );

  const [gpdrValidation, setGpdrValidation] = useState<string | undefined>();
  const [showBanner, setShowBanner] = useState<boolean | undefined>(undefined);

  const existsGpdrBanner = useMemo(() => {
    if (gpdrValidation === 'false') {
      return true;
    }
    return false;
  }, [gpdrValidation]);
  const oneYearAge = useMemo(() => 365 * 24 * 3600, []);

  const onGdprBannerClosed = useCallback(() => {
    setGpdrValidation(undefined);
  }, []);

  const handleClickIgnore = useCallback(() => {
    if (!isInsideExtension) {
      // for website set cookie for GDPR banner
      setCookie(FONTS_NINJA_GDPR_BANNER, 'true', oneYearAge, 'None');
    } else {
      // for extension set chrome storage for GDPR banner
      saveExtensionGdprValidation('false');
    }
    optOutTracking();
    setShowBanner(false);
    gdprBannerWrapperRef.current?.addEventListener('transitionend', onGdprBannerClosed);
  }, [isInsideExtension, onGdprBannerClosed, oneYearAge]);

  const handleClickAllow = useCallback(() => {
    if (!isInsideExtension) {
      // for website set cookie for GDPR banner
      setCookie(FONTS_NINJA_GDPR_BANNER, 'true', oneYearAge, 'None');
    } else {
      // for extension set chrome storage for GDPR banner
      saveExtensionGdprValidation('true');
    }
    optInTracking();
    setShowBanner(false);
    gdprBannerWrapperRef.current?.addEventListener('transitionend', onGdprBannerClosed);
  }, [isInsideExtension, onGdprBannerClosed, oneYearAge]);

  const getExtGpdrValidation = useCallback(async () => {
    await getExtensionGpdrValidation().then((data) => {
      const dataGdprExt = data as Record<string, unknown>;
      const gdprValidation = dataGdprExt.gdprValidation as string | undefined;
      const optInMixpanel = dataGdprExt.optInMixpanel as 'true' | 'false' | undefined;
      setGpdrValidation(gdprValidation ?? 'false');
      if (optInMixpanel === 'true') {
        optInTracking();
      }
    });
  }, []);

  useEffect(() => {
    if (!isInsideExtension) {
      // for website check if GDPR cookie is already set or no
      setGpdrValidation(getCookie(FONTS_NINJA_GDPR_BANNER) ?? 'false');
    } else {
      // For extension check if GDPR is set in chrome storage
      getExtGpdrValidation();
    }
  }, [getExtGpdrValidation, isInsideExtension]);

  useEffect(() => {
    // If GDPR cookie not exists we show banner
    if (existsGpdrBanner) {
      setShowBanner(true);
    }
  }, [existsGpdrBanner]);

  if (existsGpdrBanner) {
    return (
      <GdprBannerWrapper
        ref={gdprBannerWrapperRef}
        show={!!showBanner}
        // Position GdprBannerWrapper inside iframe for extension
        {...(isInsideExtension &&
          showBanner !== undefined && {
            style: {
              bottom: `max(
              calc(
                ${extensionDocumentHeight}px - ${extensionWindowHeight}px - ${extensionScrollY}px + 90px + ${
                  isMobile ? 8 : 32
                }px
              ),
              ${isMobile ? 8 : 32}px
            )`,
            },
          })}
      >
        <GdprBannerContent darkMode={darkMode}>
          <IconTextWrapper>
            <CookieIcon darkMode={darkMode} />
            <GdprText
              darkMode={darkMode}
              dangerouslySetInnerHTML={{ __html: t('gdpr-banner.text') }}
            />
          </IconTextWrapper>
          <ButtonsWrapper darkMode={!darkMode}>
            <Button
              darkMode={!darkMode}
              render="contrast"
              size={isMobile ? 'normal' : 'small'}
              onClick={handleClickIgnore}
            >
              {t('gdpr-banner.ignore')}
            </Button>
            <Button
              darkMode={!darkMode}
              size={isMobile ? 'normal' : 'small'}
              onClick={handleClickAllow}
            >
              {t('gdpr-banner.allow')}
            </Button>
          </ButtonsWrapper>
        </GdprBannerContent>
      </GdprBannerWrapper>
    );
  }
  return null;
};

export default GdprBanner;
