import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface Props {
  render?: 'default' | 'contrast';
  darkMode?: boolean;
  size?: 'normal' | 'small';
}

interface IconImgProps {
  size?: 'normal' | 'small';
}

const BREAKPOINT_TABLET = 744;

const easeOutQuart: string = 'cubic-bezier(0.165, 0.840, 0.440, 1.000)';

const buttonCommonStyle = css`
  position: relative;
  display: inline-block;
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration: none;
  margin: 0;
  cursor: pointer;
  transition:
    transform 0.25s ${easeOutQuart},
    border-color 0.25s ${easeOutQuart},
    box-shadow 0.25s ${easeOutQuart};
  -webkit-tap-highlight-color: transparent;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  &:disabled {
    cursor: default;
  }
  @media (hover: hover) {
    &:hover {
      transform: translate(-4px, -4px);
      &:before {
        transform: translate(4px, 4px);
      }
    }
    &:hover:disabled {
      transform: none;
    }
  }
`;

const buttonDefaultStyle = css`
  background-color: ${GREY_6};
  color: ${WHITE};
  border: 1px solid ${GREY_6};
  [class^='icon-'],
  [class*=' icon-'] {
    color: ${WHITE};
  }
  @media (hover: hover) {
    &:hover {
      border-color: ${WHITE};
      box-shadow: 4px 4px 0 ${GREY_6};
    }
    &:hover:disabled {
      border-color: ${GREY_6};
      box-shadow: none;
    }
  }
`;

const buttonDefaultDarkModeStyle = css`
  background-color: ${WHITE};
  color: ${GREY_6};
  border: 1px solid ${WHITE};
  @media (hover: hover) {
    &:hover {
      border-color: ${GREY_6};
      box-shadow: 4px 4px 0 ${WHITE};
    }
    &:hover:disabled {
      border-color: ${WHITE};
      box-shadow: none;
    }
  }
`;

const buttonContrastStyle = css`
  background-color: ${WHITE};
  color: ${GREY_6};
  border: 1px solid ${GREY_6};
  @media (hover: hover) {
    &:hover {
      box-shadow: 4px 4px 0 ${GREY_6};
    }
    &:hover:disabled {
      box-shadow: none;
    }
  }
`;

const buttonContrastDarkModeStyle = css`
  background-color: ${GREY_6};
  color: ${WHITE};
  border: 1px solid ${GREY_2};
  [class^='icon-'],
  [class*=' icon-'] {
    color: ${WHITE};
  }
  @media (hover: hover) {
    &:hover {
      border-color: ${WHITE};
      box-shadow: 4px 4px 0 ${WHITE};
    }
    &:hover:disabled {
      box-shadow: none;
    }
  }
`;

const buttonNormalSizeStyle = css`
  border-radius: 8px;
  /* MOBILE */
  font-size: 16px;
  padding: 12px 15px 14px;
  /* TABLET */
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    font-size: 14px;
    padding: 8px 15px 10px;
  }
  [class^='icon-'],
  [class*=' icon-'] {
    position: relative;
    margin-right: 6px;
    line-height: 12px;
    top: 2px;
  }
`;

const buttonSmallSizeStyle = css`
  border-radius: 4px;
  /* MOBILE */
  font-size: 14px;
  padding: 4px 9px 5px;
  /* TABLET */
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    font-size: 12px;
    padding: 3px 9px;
  }
  [class^='icon-'],
  [class*=' icon-'] {
    position: relative;
    font-size: 12px;
    margin-right: 4px;
    line-height: 12px;
    top: 1px;
  }
`;

export const ButtonStyled = styled.button`
  // COMMON STYLE
  ${buttonCommonStyle}
  // RENDER: DEFAULT / NOT DARK MODE
  ${(props: Props) =>
    props.render === 'default' &&
    !props.darkMode &&
    css`
      ${buttonDefaultStyle}
    `}
  // RENDER: DEFAULT / DARK MODE
  ${(props: Props) =>
    props.render === 'default' &&
    props.darkMode &&
    css`
      ${buttonDefaultDarkModeStyle}
    `}
  // RENDER: CONTRAST / NOT DARK MODE
  ${(props: Props) =>
    props.render === 'contrast' &&
    !props.darkMode &&
    css`
      ${buttonContrastStyle}
    `}
  // RENDER: CONTRAST / DARK MODE
  ${(props: Props) =>
    props.render === 'contrast' &&
    props.darkMode &&
    css`
      ${buttonContrastDarkModeStyle}
    `}
  // SIZE: NORMAL
  ${(props: Props) =>
    props.size === 'normal' &&
    css`
      ${buttonNormalSizeStyle}
    `}
  // SIZE: SMALL
  ${(props: Props) =>
    props.size === 'small' &&
    css`
      ${buttonSmallSizeStyle}
    `}
`;

export const IconImg = styled.img`
  width: ${(props: IconImgProps) => (props.size === 'small' ? '12px' : '16px')};
  height: ${(props: IconImgProps) => (props.size === 'small' ? '12px' : '16px')};
  margin-right: ${(props: IconImgProps) => (props.size === 'small' ? '4px' : '6px')};
  margin-bottom: ${(props: IconImgProps) => (props.size === 'small' ? '-2px' : '-3px')};
`;
