import React, { ButtonHTMLAttributes } from 'react';
import { Icon } from 'design-system/atoms/icon';
import { IconButtonStyled } from './icon-button.styled';

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconLabel: string;
  iconSize?: string;
  color?: string;
  buttonSize?: string;
  type?: 'button' | 'submit';
}

export function IconButton({ iconLabel, iconSize, color, buttonSize, ...props }: IconButtonProps) {
  return (
    <IconButtonStyled
      size={buttonSize}
      {...props}
    >
      <Icon
        label={iconLabel}
        fontSize={iconSize}
        color={color}
      />
    </IconButtonStyled>
  );
}
