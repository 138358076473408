import styled from '@emotion/styled';

interface Props {
  display?: 'block' | 'inline-block' | 'inline';
  color?: string;
  fontSize?: string;
  lineHeight?: string;
}

export const IconStyled = styled.i`
  display: ${(props: Props) => props.display};
  color: ${(props: Props) => props.color};
  font-size: ${(props: Props) => props.fontSize};
  line-height: ${(props: Props) => props.lineHeight};
`;
