import { SmileyWrapper } from './SmileySad.styled';

interface Props {
  darkMode: boolean;
}

const SmileySad = ({ darkMode }: Props) => {
  return (
    <SmileyWrapper darkMode={darkMode}>
      <svg
        width="116"
        height="116"
        viewBox="0 0 116 116"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58 113C88.3757 113 113 88.3757 113 58C113 27.6243 88.3757 3 58 3C27.6243 3 3 27.6243 3 58C3 88.3757 27.6243 113 58 113Z"
          fill="#FDBB9F"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.6676 53.7113L69.001 43.342L83.6676 32.9727"
          stroke="black"
          strokeWidth="6.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.333 32.9727L46.9997 43.342L32.333 53.7113"
          stroke="black"
          strokeWidth="6.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.3333 87.3381C84.6025 81.827 80.3864 77.1885 75.1603 73.9456C69.9342 70.7027 63.906 68.9844 57.7555 68.9844C51.605 68.9844 45.5768 70.7027 40.3507 73.9456C35.1247 77.1885 30.9085 81.827 28.1777 87.3381"
          stroke="black"
          strokeWidth="6.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SmileyWrapper>
  );
};

export default SmileySad;
