import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/otel`;

// ADD 1 TO THE SPECIFIED OPEN TELEMETRY COUNTER
export const incrementCounterOpenTelemetry = (counterValue: string): Promise<API.Statistics.IncrementOpenTelemetry> => {
  return axios.post(`${baseUrl}/increment`, { counter: counterValue }).then(({ data }) => {
    return data;
  });
};
