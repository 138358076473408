type SameSite = 'Strict' | 'Lax' | 'None';

const getDomain = (): string => {
  return document.location.hostname.endsWith('fonts.ninja') ? 'fonts.ninja' : document.location.hostname;
};

export const setCookie = (name: string, value: string, maxAge: number, sameSiteValue: SameSite = 'Lax') => {
  document.cookie = `${name}=${value}; path=/; domain=${getDomain()}; max-age=${maxAge}; samesite=${sameSiteValue}; secure`;
};

export const getCookie = (name: string): string | undefined => {
  const cookieRaw = document.cookie.split('; ').find((row) => row.startsWith(`${name}=`));
  if (cookieRaw) {
    const value = cookieRaw.replace(`${name}=`, '').trim();
    return value;
  }
  return undefined;
};

export const deleteCookie = (name: string) => {
  let expireDate = 'Thu, 01 Jan 1970 00:00:01 GMT';
  document.cookie = `${name}=;domain=${getDomain()};path=/;expires=${expireDate}`;
};
