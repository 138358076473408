import { Fragment, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { H3 } from 'design-system/atoms/h3';
import { IconButton } from 'design-system/atoms/icon-button';
import { Icon } from 'design-system/atoms/icon';
import { InputText } from 'design-system/atoms/input-text';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useModalContext, useNotificationContext, useSettingsContext } from 'context';
import { useDebounce } from 'hooks';
import { useBoardEditors } from 'hooks/queries/useBoardEditors';
import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import Avatar from 'components/Avatar';
import { isValidEmail, transformStringToUrl } from 'common/utils';
import { PAGE_SIZE_FOLLOW, QUERY_KEYS } from 'common/data/Constants';
import {
  ButtonConfirmRemoveCollaboratorWrapper,
  CloseButtonWrapper,
  CollaboratorAvatar,
  CollaboratorAvatarName,
  CollaboratorItem,
  CollaboratorLoading,
  CollaboratorName,
  CollaboratorsListWrapper,
  CollaboratorsLoadingWrapper,
  CollaboratorStatus,
  ContentWrapper,
  EmailIconNameWrapper,
  EmailInvitationLinkContainer,
  EmailInvitationWrapper,
  EmailName,
  EmailSendLabel,
  InputSearchWrapper,
  InvitationLinkText,
  InvitationLinkWrapper,
  ModalWrapper,
  SearchCloseLayout,
  SearchInviteLabel,
  SearchInviteWrapper,
  SearchUserAvatarName,
  SearchUserItem,
  SearchUserName,
  SearchUsersInvitationWrapper,
  Separator,
  TitleWrapper,
} from './BoardCollaboratorsInvite.styled';

const BoardCollaboratorsInvite = () => {
  const { t } = useTranslation('common');
  const { darkMode } = useSettingsContext();
  const { openModalBoardCollaboratorsInvite, setOpenModalBoardCollaboratorsInvite } = useModalContext();
  const { setOpenNotificationBoardInvitationLink, setOpenNotificationEmailInvitationSend } = useNotificationContext();

  const [startClose, setStartClose] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchLayout, setSearchLayout] = useState<'users' | 'email' | null>(null);
  const [confirmRemoveIdUser, setConfirmRemoveIdUser] = useState<number | null>(null);

  const queryClient = useQueryClient();

  const {
    useGetBoardInvitationCode,
    useBoardListEditors,
    useSearchUsersToInvite,
    useInviteEditorByIdUser,
    useInviteEditorByEmail,
    useDeleteBoardEditor,
  } = useBoardEditors();

  const { data: invitationCodeData } = useGetBoardInvitationCode(openModalBoardCollaboratorsInvite?.idBoard ?? 0, {
    enabled: !!openModalBoardCollaboratorsInvite,
  });

  const {
    data: collaboratorsData,
    isLoading: isLoadingCollaborators,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useBoardListEditors(openModalBoardCollaboratorsInvite?.idBoard ?? 0, PAGE_SIZE_FOLLOW, {
    enabled: !!openModalBoardCollaboratorsInvite,
  });

  const debouncedSearchValue = useDebounce(searchValue, 250);
  const { data: searchUsersData } = useSearchUsersToInvite(
    openModalBoardCollaboratorsInvite?.idBoard ?? 0,
    debouncedSearchValue,
    {
      enabled: debouncedSearchValue.trim() !== '',
    }
  );

  const inviteUserByIdUserMutation = useInviteEditorByIdUser({
    onMutate: (variables) => {
      if (collaboratorsData) {
        // Snapshot the previous value
        const previousData = queryClient.getQueryData<InfiniteData<API.BoardEditors.Editors>>([
          QUERY_KEYS.BOARD_EDITORS_LIST,
          { idBoard: openModalBoardCollaboratorsInvite!.idBoard },
        ]);
        // Snapshot search previous value
        const searchPreviousData = queryClient.getQueryData<API.BoardEditors.SearchUsersToInvite>([
          QUERY_KEYS.BOARD_EDITORS_SEARCH_USERS,
          { idBoard: openModalBoardCollaboratorsInvite!.idBoard, searchValue: debouncedSearchValue },
        ]);
        const cloneSearchUsersData: API.BoardEditors.SearchUsersToInvite = JSON.parse(
          JSON.stringify(searchPreviousData)
        );
        const index = cloneSearchUsersData.users.findIndex((user) => user.id === variables.idUser);
        if (index !== -1) {
          cloneSearchUsersData.users.splice(index, 1);
          queryClient.setQueryData(
            [
              QUERY_KEYS.BOARD_EDITORS_SEARCH_USERS,
              { idBoard: openModalBoardCollaboratorsInvite!.idBoard, searchValue: debouncedSearchValue },
            ],
            cloneSearchUsersData
          );
          if (cloneSearchUsersData.users.length === 0) {
            setSearchValue('');
            setSearchLayout(null);
          }
        }
        return { previousData, searchPreviousData };
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.BOARD_EDITORS_SEARCH_USERS,
          { idBoard: openModalBoardCollaboratorsInvite!.idBoard, searchValue: debouncedSearchValue },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BOARD_EDITORS_LIST, { idBoard: openModalBoardCollaboratorsInvite!.idBoard }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BOARD_EDITORS_LIST_SIMPLE, { idBoard: openModalBoardCollaboratorsInvite!.idBoard }],
      });
    },
    onError: (err, variables, context) => {
      if (context?.previousData) {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData(
          [QUERY_KEYS.BOARD_EDITORS_LIST, { idBoard: openModalBoardCollaboratorsInvite!.idBoard }],
          context.previousData
        );
        queryClient.setQueryData(
          [
            QUERY_KEYS.BOARD_EDITORS_SEARCH_USERS,
            { idBoard: openModalBoardCollaboratorsInvite!.idBoard, searchValue: debouncedSearchValue },
          ],
          context.previousData
        );
      }
    },
  });

  const inviteUserByEmailMutation = useInviteEditorByEmail({
    onSuccess: (data, variables) => {
      setOpenNotificationEmailInvitationSend(searchValue);
    },
  });

  const deleteCollaboratorMutation = useDeleteBoardEditor({
    onMutate: (variables) => {
      if (collaboratorsData) {
        // Snapshot the previous value
        const previousData = queryClient.getQueryData<InfiniteData<API.BoardEditors.Editors>>([
          QUERY_KEYS.BOARD_EDITORS_LIST,
          { idBoard: openModalBoardCollaboratorsInvite!.idBoard },
        ]);
        const cloneCollaboratorsData: InfiniteData<API.BoardEditors.Editors> = JSON.parse(JSON.stringify(previousData));
        for (let i = 0; i < cloneCollaboratorsData.pages.length; i++) {
          const currentIndex = cloneCollaboratorsData.pages[i].users.findIndex((user) => user.id === variables.idUser);
          if (currentIndex !== -1) {
            cloneCollaboratorsData.pages[i].users.splice(currentIndex, 1);
            queryClient.setQueryData(
              [QUERY_KEYS.BOARD_EDITORS_LIST, { idBoard: openModalBoardCollaboratorsInvite!.idBoard }],
              cloneCollaboratorsData
            );
          }
        }
        return { previousData };
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BOARD_EDITORS_LIST, { idBoard: openModalBoardCollaboratorsInvite!.idBoard }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BOARD_EDITORS_LIST_SIMPLE, { idBoard: openModalBoardCollaboratorsInvite!.idBoard }],
      });
    },
    onError: (err, variables, context) => {
      if (context?.previousData) {
        // If the mutation fails, use the context returned from onMutate to roll back
        queryClient.setQueryData(
          [QUERY_KEYS.BOARD_EDITORS_LIST, { idBoard: openModalBoardCollaboratorsInvite!.idBoard }],
          context.previousData
        );
      }
    },
  });

  const highlightText = useCallback(
    (text: string) => {
      if (debouncedSearchValue === '') return text;
      const highlightedText = new RegExp(`(${debouncedSearchValue})`, 'gi');
      return text.replace(highlightedText, `<span>$1</span>`);
    },
    [debouncedSearchValue]
  );

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalBoardCollaboratorsInvite(null);
  }, [setOpenModalBoardCollaboratorsInvite]);

  const handleChangeSearchValue = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setSearchValue(value);
    if (value.trim() === '') {
      setSearchLayout(null);
    } else {
      if (isValidEmail(value)) {
        setSearchLayout('email');
      } else {
        setSearchLayout('users');
      }
    }
  }, []);

  const handleCloseSearchLayout = useCallback(() => {
    setSearchLayout(null);
  }, []);

  const handleInviteByIdUser = useCallback(
    (idUser: number) => {
      inviteUserByIdUserMutation.mutate({ idBoard: openModalBoardCollaboratorsInvite!.idBoard, idUser: idUser });
    },
    [inviteUserByIdUserMutation, openModalBoardCollaboratorsInvite]
  );

  const handleInviteByEmail = useCallback(() => {
    setSearchValue('');
    setSearchLayout(null);
    inviteUserByEmailMutation.mutate({ idBoard: openModalBoardCollaboratorsInvite!.idBoard, email: searchValue });
  }, [inviteUserByEmailMutation, openModalBoardCollaboratorsInvite, searchValue]);

  const handleCopyInvitationCode = useCallback(() => {
    if (invitationCodeData && collaboratorsData) {
      const ownerUser = collaboratorsData.pages[0].users.find((user) => user.status === 'owner');
      if (ownerUser) {
        const url = `${window.location.origin}/${transformStringToUrl(ownerUser.name)}-${ownerUser.id}${
          window.location.pathname
        }?invitationCode=${invitationCodeData.invitationCode}`;
        navigator.clipboard.writeText(url);
        setOpenNotificationBoardInvitationLink(true);
      }
    }
  }, [collaboratorsData, invitationCodeData, setOpenNotificationBoardInvitationLink]);

  const handleRemoveCollaborator = useCallback((idUser: number) => {
    setConfirmRemoveIdUser(idUser);
  }, []);

  const handleConfirmRemoveCollaborator = useCallback(
    (idUser: number) => {
      setConfirmRemoveIdUser(null);
      deleteCollaboratorMutation.mutate({ idBoard: openModalBoardCollaboratorsInvite!.idBoard, idUser: idUser });
    },
    [deleteCollaboratorMutation, openModalBoardCollaboratorsInvite]
  );

  useEffect(() => {
    if (openModalBoardCollaboratorsInvite === null) {
      setSearchValue('');
      setSearchLayout(null);
      setConfirmRemoveIdUser(null);
    }
  }, [openModalBoardCollaboratorsInvite]);

  if (openModalBoardCollaboratorsInvite) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            <TitleWrapper>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('board-collaborators-invite.title')}
              </H3>
            </TitleWrapper>
            {((searchLayout === 'users' && searchUsersData && searchUsersData.totalUsers > 0) ||
              searchLayout === 'email') && <SearchCloseLayout onClick={handleCloseSearchLayout} />}
            <InputSearchWrapper>
              <InputText
                type="text"
                placeholder={t('board-collaborators-invite.search-placeholder')}
                value={searchValue}
                darkMode={darkMode}
                onChange={handleChangeSearchValue}
                onFocus={handleChangeSearchValue}
              />
              {searchLayout === 'users' && searchUsersData && searchUsersData.totalUsers > 0 && (
                <SearchUsersInvitationWrapper darkMode={darkMode}>
                  {searchUsersData.users.map((user, index) => (
                    <SearchUserItem
                      key={`${user.id}_${index}`}
                      darkMode={darkMode}
                      onClick={() => handleInviteByIdUser(user.id)}
                    >
                      <SearchUserAvatarName>
                        <Avatar
                          width={24}
                          height={24}
                          border={1}
                          darkMode={darkMode}
                          src={user.avatarUrl}
                          userId={user.id}
                          userName={user.name}
                          withShadow={false}
                        />
                        <SearchUserName
                          dangerouslySetInnerHTML={{ __html: highlightText(user.name) }}
                          darkMode={darkMode}
                        />
                      </SearchUserAvatarName>
                      <SearchInviteWrapper className="invite-button">
                        <SearchInviteLabel className="invite-label">
                          {t('board-collaborators-invite.invite')}
                        </SearchInviteLabel>
                        <Icon
                          label="plus_border"
                          color={darkMode ? WHITE : GREY_6}
                        />
                      </SearchInviteWrapper>
                    </SearchUserItem>
                  ))}
                </SearchUsersInvitationWrapper>
              )}
              {searchLayout === 'email' && (
                <EmailInvitationWrapper darkMode={darkMode}>
                  <EmailInvitationLinkContainer
                    darkMode={darkMode}
                    onClick={handleInviteByEmail}
                  >
                    <EmailIconNameWrapper>
                      <Icon
                        label="email"
                        color={darkMode ? WHITE : GREY_6}
                      />
                      <EmailName>{searchValue}</EmailName>
                    </EmailIconNameWrapper>
                    <EmailSendLabel>{t('board-collaborators-invite.send-invitation-email')}</EmailSendLabel>
                  </EmailInvitationLinkContainer>
                </EmailInvitationWrapper>
              )}
            </InputSearchWrapper>
            <InvitationLinkWrapper onClick={handleCopyInvitationCode}>
              <Icon
                label="copy"
                fontSize="12px"
                color={darkMode ? WHITE : GREY_6}
              />
              <InvitationLinkText>{t('board-collaborators-invite.copy-invitation-link')}</InvitationLinkText>
            </InvitationLinkWrapper>
            <Separator darkMode={darkMode} />
            <CollaboratorsListWrapper>
              {isLoadingCollaborators && (
                <CollaboratorsLoadingWrapper>
                  <Spinner darkMode={darkMode} />
                </CollaboratorsLoadingWrapper>
              )}
              {!isLoadingCollaborators && collaboratorsData && (
                <InfiniteScroll
                  dataLength={collaboratorsData.pages.reduce((acc, page) => acc + page.users.length, 0)}
                  next={() => {
                    if (!isFetchingNextPage) {
                      fetchNextPage();
                    }
                  }}
                  hasMore={!!hasNextPage}
                  loader={
                    <CollaboratorLoading>
                      <Spinner darkMode={darkMode} />
                    </CollaboratorLoading>
                  }
                  scrollableTarget="collaboratorsWrapper"
                >
                  {collaboratorsData.pages.map((page, pageIndex) => (
                    <Fragment key={pageIndex}>
                      {page.users.map((user, index) => (
                        <CollaboratorItem key={`${pageIndex}_${index}`}>
                          <CollaboratorAvatarName>
                            {user.status !== 'invited' ? (
                              <Link
                                href={`/${transformStringToUrl(user.name)}-${user.id}`}
                                onClick={handleClose}
                              >
                                <CollaboratorAvatar status={user.status}>
                                  <Avatar
                                    width={24}
                                    height={24}
                                    border={1}
                                    darkMode={darkMode}
                                    src={user.avatarUrl}
                                    userId={user.id}
                                    userName={user.name}
                                    withShadow={false}
                                  />
                                </CollaboratorAvatar>
                              </Link>
                            ) : (
                              <CollaboratorAvatar status={user.status}>
                                <Avatar
                                  width={24}
                                  height={24}
                                  border={1}
                                  darkMode={darkMode}
                                  src={user.avatarUrl}
                                  userId={user.id}
                                  userName={user.name}
                                  withShadow={false}
                                />
                              </CollaboratorAvatar>
                            )}
                            {user.status !== 'invited' ? (
                              <Link
                                href={`/${transformStringToUrl(user.name)}-${user.id}`}
                                onClick={handleClose}
                              >
                                <CollaboratorName status={user.status}>{user.name}</CollaboratorName>
                              </Link>
                            ) : (
                              <CollaboratorName status={user.status}>{user.name}</CollaboratorName>
                            )}
                          </CollaboratorAvatarName>
                          {user.status && user.status === 'owner' && (
                            <CollaboratorStatus>{t('board-collaborators-invite.owner')}</CollaboratorStatus>
                          )}
                          {user.status && user.status === 'joined' && (
                            <>
                              {confirmRemoveIdUser !== user.id ? (
                                <Button
                                  size="small"
                                  darkMode={darkMode}
                                  onClick={() => handleRemoveCollaborator(user.id)}
                                >
                                  {t('board-collaborators-invite.remove')}
                                </Button>
                              ) : (
                                <ButtonConfirmRemoveCollaboratorWrapper>
                                  <Button
                                    size="small"
                                    darkMode={darkMode}
                                    onClick={() => handleConfirmRemoveCollaborator(user.id)}
                                  >
                                    {t('board-collaborators-invite.remove-confirm')}
                                  </Button>
                                </ButtonConfirmRemoveCollaboratorWrapper>
                              )}
                            </>
                          )}
                          {user.status && user.status === 'invited' && (
                            <CollaboratorStatus>{t('board-collaborators-invite.pending')}</CollaboratorStatus>
                          )}
                        </CollaboratorItem>
                      ))}
                    </Fragment>
                  ))}
                </InfiniteScroll>
              )}
            </CollaboratorsListWrapper>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default BoardCollaboratorsInvite;
