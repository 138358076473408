const DEV_HOST_URL = 'http://localhost:3000';
const PREPROD_HOST_URL = 'https://v2-preprod.fonts.ninja';
const PROD_HOST_URL = 'https://fonts.ninja';

const getHostnameUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return DEV_HOST_URL;
  } else {
    if (process.env.API_ENV === 'preprod') {
      return PREPROD_HOST_URL;
    } else if (process.env.API_ENV === 'production') {
      return PROD_HOST_URL;
    }
  }
  return PROD_HOST_URL;
};

export const HOSTNAME_URL = getHostnameUrl();
