import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_2, GREY_6, WHITE } from 'design-system/global/colors';

interface Props {
  nbFonts?: number;
  darkMode?: boolean;
}

export const BookmarkButtonWithSliderWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  float: right;

  ${(props: Props) =>
    props.nbFonts &&
    props.nbFonts > 1 &&
    css`
      float: none;
      button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    `}
`;

export const SliderWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 0 15px;
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
