export const MAX_WIDTH = 1440;

// MEDIA QUERIES VALUES
export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';
export const DESKTOP_1200 = 'desktop_1200';
export const DESKTOP_1440 = 'desktop_1440';

// MEDIA QUERIES BREAKPOINTS
export const BREAKPOINT_MOBILE = 375;
export const BREAKPOINT_TABLET = 744;
export const BREAKPOINT_DESKTOP = 1020;
export const BREAKPOINT_DESKTOP_1200 = 1200;
export const BREAKPOINT_DESKTOP_1440 = 1440;

// NAV GLOBAL BREAKPOINTS HEIGHT (for scroll top page)
export const NAV_GLOBAL_HEIGHT = 120;

// GUTTER WIDTH
export const MOBILE_GUTTER = 20;
export const TABLET_GUTTER = 48;
export const DESKTOP_GUTTER = 48;
export const BIG_DESKTOP_GUTTER = 56;

// GRIDS BOARDS
export const BOARD_SIZE = 199;
export const BOARD_GAP = 26;

// GRIDS CARDS
export const MOBILE_CARD_WIDTH = 335;
export const TABLET_CARD_WIDTH = 448;
export const DESKTOP_CARD_WIDTH = 422;
export const MOBILE_CARD_GAP = 16;
export const TABLET_CARD_GAP = 28;
export const DESKTOP_CARD_GAP = 31;

// MINIMUM CARDS HEIGHT
export const MOBILE_MIN_CARD_HEIGHT = 172;
export const DESKTOP_MIN_CARD_HEIGHT = 296;

// ROUTES
export const ROUTES = {
  HOME: '/',
  SINGLE_FONT: '/font',
  FOUNDRY: '/foundry',
  BOOKMARKS: '/bookmarks',
  BOARD: '/board',
  RESOURCES: '/resources',
  ABOUT_US: '/about-us',
  TOOLS: '/tools',
  PROFILE: '/profile',
  EXTENSION_DEMO: '/extension-demo',
  OVERVIEW: '/overview',
  PAGE_404: '/404',
};

// PAGE_NAME
export const HOME_PAGE = 'Home';
export const FONT_PAGE = 'Font';
export const FOUNDRY_PAGE = 'Foundry';
export const USER_PAGE = 'User';
export const BOOKMARKS_PAGE = 'Bookmarks';
export const BOARD_PAGE = 'Board';
export const PROFILE_PAGE = 'Profile';
export const ABOUT_US_PAGE = 'About us';
export const TOOLS_PAGE = 'Tools';
export const EXTENSION_DEMO_PAGE = 'Demo';
export const OVERVIEW_PAGE = 'Overview';
export const NOT_FOUND_PAGE = '404';
export const PAGE_NAMES: Record<string, string> = {
  '/': HOME_PAGE,
  '/font/[fontQuery]': FONT_PAGE,
  '/foundry/[foundryQuery]': FOUNDRY_PAGE,
  '/[userQuery]': USER_PAGE,
  '/bookmarks': BOOKMARKS_PAGE,
  '/board/[boardQuery]': BOARD_PAGE,
  '/[userQuery]/board/[boardQuery]': BOARD_PAGE,
  '/profile': PROFILE_PAGE,
  '/about-us': ABOUT_US_PAGE,
  '/tools': TOOLS_PAGE,
  '/extension-demo/[pageQuery]': EXTENSION_DEMO_PAGE,
  '/overview': OVERVIEW_PAGE,
};

// GOOGLE OAUTH CLIENT ID
export const GOOGLE_OAUTH_CLIENT_ID = '825433310744-85dni7nt9lsjgik5pmceiooahg2rpchk.apps.googleusercontent.com';

// API HEADERS
export const X_AUTH_TOKEN = 'x-auth-token';

// QUERY KEYS
export const QUERY_KEYS = {
  FAMILY_SEARCH: 'familySearch',
  FONT_SEARCH: 'fontSearch',
  SUGGESTIONS: 'suggestions',
  FAMILY_DETAILS: 'familyDetails',
  FAMILY_FONT_IN_USE_LIST: 'familyFontInUseList',
  FAMILY_SIBLING_LIST: 'familySiblingList',
  FAMILY_PAIRING_LIST: 'familyPairingList',
  FAMILY_TAG_LIST: 'familyTagList',
  SIMILAR_FONT_LIST: 'similarFontList',
  FONT_TAG_LIST: 'fontTagList',
  BOARD_COUNT: 'boardCount',
  BOARD_LIST_SIMPLE: 'boardListSimple',
  BOARD_LIST_DETAILED: 'boardListDetailed',
  BOARD: 'board',
  BOARD_EDITORS_LIST_SIMPLE: 'boardEditorsListSimple',
  BOARD_EDITORS_LIST: 'boardEditorsList',
  BOARD_EDITORS_INVITATION_CODE: 'boardEditorsInvitationCode',
  BOARD_EDITORS_SEARCH_USERS: 'boardEditorsSearchUsers',
  BOOKMARK_COUNT: 'bookmarkCount',
  BOOKMARK: 'bookmark',
  BOOKMARK_STATUS: 'bookmarkStatus',
  FONT_IMAGE: 'fontImage',
  FOUNDRY_DETAILS: 'foundryDetails',
  COUNT_FOLLOWERS_BY_FOUNDRY: 'countFollowersByFoundry',
  LIST_FOLLOWERS_BY_FOUNDRY: 'listFollowersByFoundry',
  FOLLOW_FOUNDRY_STATUS: 'followFoundryStatus',
  USER_PROFILE: 'userProfile',
  COUNT_FOLLOWERS_BY_USER: 'countFollowersByUser',
  LIST_FOLLOWERS_BY_USER: 'listFollowersByUser',
  COUNT_FOLLOWING_BY_USER: 'countFollowingByUser',
  LIST_FOLLOWING_BY_USER: 'listFollowingByUser',
  LIST_FOLLOWED_FOUNDRIES_BY_USER: 'listFollowedFoundriesByUser',
  FOLLOW_USER_STATUS: 'followUserStatus',
  USER_SOCIAL_SUMMARY: 'userSocialSummary',
  PUBLIC_USER_DATA: 'publicUserData',
  PUBLIC_COUNT_USER_BOARDS: 'publicCountUserBoards',
  PUBLIC_USER_BOARDS: 'publicUserBoards',
  LICENSES_LIST: 'licensesList',
  CURRENCY_LIST: 'currencyList',
};

// RESULTS PER PAGE
export const PAGE_SIZE = 12;
export const PAGE_SIZE_FOLLOW = 15;

// SCROLL THRESHOLD
export const SCROLL_THRESHOLD = 400;

// LOCAL STORAGE KEYS
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const DISPLAY_MODE_OPTION = 'displayModeOption';
export const DISPLAYED_WEIGHT = 'displayedWeight';
export const DARK_MODE = 'darkMode';

// COOKIE KEYS
export const FONTS_NINJA_GDPR_BANNER = 'fontsNinjaGdprBanner';
export const FONTS_NINJA_VISIT_OTEL = 'fontsNinjaVisitOtel';
export const FONTS_NINJA_VISITOR_OTEL = 'fontsNinjaVisitorOtel';

// MIXPANEL
export const MIXPANEL = {
  development: 'b4023fbf389c93654726fe74702a28ef',
  preprod: 'd57dcda7242c0728a21c03342097d022',
  production: '9f6f269f53767c78fe2d9ed82054420c',
};

// GOOGLE RECAPTCHA
export const RECAPTCHA = {
  development: '6LccEnYqAAAAAAr9fl0TFIoqTho9e4EbhAqAHI9W',
  preprod: '6LdW1XUqAAAAAEgxBFAjfPN_j0flieP5cYfTaAqx',
  production: '6Lf31nUqAAAAAAa-hw7aSORZWgHxRrH79TLo3nju',
};

// FONTS-NINJA EXTENSION QUERY PARAM
export const EXTENSION = 'extension';
export const ID_REQUEST = 'idRequest';
export const MACHINE_ID = 'machineId';
export const ID_FONT_VERSION = 'idFontVersion';
export const COLOR = 'color';

// FONTS-NINJA EXTENSIONS URL
export const CHROME_EXTENSION =
  'https://chrome.google.com/webstore/detail/fonts-ninja/eljapbgkmlngdpckoiiibecpemleclhh';
export const FIREFOX_EXTENSION = 'https://addons.mozilla.org/fr/firefox/addon/fonts-ninja/';
export const SAFARI_EXTENSION = 'https://apps.apple.com/us/app/fonts-ninja/id1480227114';

// FONTS-NINJA EXTENSION PING/PONG MESSAGE
export const PING_FONTS_NINJA_EXTENSION = 'ping_from_fonts_ninja_extension';
export const PONG_FONTS_NINJA_EXTENSION = 'pong_from_fonts_ninja_extension';
