import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/family`;

// GET FAMILY DETAILS
export const getFamilyDetails = (idFamily: string, currency: string): Promise<API.Family> => {
  return axios.get(`${baseUrl}/${idFamily}`, { params: { currency } }).then(({ data }) => {
    return data;
  });
};

// GET FAMILY FONT IN USE LIST
export const getFamilyFontInUseList = (
  idFamily: string,
  pageSize: number,
  page: number
): Promise<API.Family.FontInUseList> => {
  return axios.get(`${baseUrl}/${idFamily}/fontInUse/list`, { params: { pageSize, page } }).then(({ data }) => {
    return data;
  });
};

// GET FAMILY SIBLINGS
export const getFamilySiblingList = (
  idFamily: string,
  idFont: number,
  currency: string
): Promise<API.Family.FamilySiblingList> => {
  return axios.get(`${baseUrl}/${idFamily}/font/${idFont}/sibling/list`, { params: { currency } }).then(({ data }) => {
    return data;
  });
};

// GET FAMILY PAIRINGS
export const getFamilyPairingList = (
  idFamily: string,
  pageSize: number,
  page: number
): Promise<API.Family.FamilyPairingList> => {
  return axios.get(`${baseUrl}/${idFamily}/pairing/list`, { params: { pageSize, page } }).then(({ data }) => {
    return data;
  });
};

// GET FAMILY TAG LIST
export const getFamilyTagList = (idFamily: string): Promise<API.Family.FamilyTagList> => {
  return axios.get(`${baseUrl}/${idFamily}/tag/list`).then(({ data }) => {
    return data;
  });
};

// PATCH FAMILY TAG LIST
export const patchFamilyTagList = (idFamily: string, category: string): Promise<API.Family.FamilyTagList> => {
  return axios.patch(`${baseUrl}/${idFamily}/tag`, { category }).then(({ data }) => {
    return data;
  });
};
