import axios from 'axios';
import * as Authentication from './authentication.api';
import * as FamilySearch from './familySearch.api';
import * as Suggestions from './suggestions.api';
import * as Family from './family.api';
import * as Font from './font.api';
import * as Board from './board.api';
import * as BoardEditors from './boardEditors.api';
import * as Bookmark from './bookmark.api';
import * as FontImage from './fontImage.api';
import * as Foundry from './foundry.api';
import * as Social from './social.api';
import * as User from './user.api';
import * as Feedback from './feedback.api';
import * as License from './license.api';
import * as Currency from './currency.api';
import * as Statistics from './statistics.api';

export const injectAccessTokenToHeaders = (accessToken: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const removeAccessTokenFromHeaders = () => {
  delete axios.defaults.headers.common.Authorization;
};

export const injectXNinjaSsrIp = (ip: string) => {
  axios.defaults.headers.common['X-Ninja-SSR-IP'] = ip;
};

export const removeXNinjaSsrIp = () => {
  delete axios.defaults.headers.common['X-Ninja-SSR-IP'];
};

const api = {
  authentication: Authentication,
  familySearch: FamilySearch,
  suggestions: Suggestions,
  family: Family,
  font: Font,
  board: Board,
  boardEditors: BoardEditors,
  bookmark: Bookmark,
  fontImage: FontImage,
  foundry: Foundry,
  social: Social,
  user: User,
  feedback: Feedback,
  license: License,
  currency: Currency,
  statistics: Statistics,
};

export default api;
