import { createContext, PropsWithChildren, ReactElement, useContext } from 'react';
import { NotificationBookmark, NotificationContextData, useNotificationState } from 'hooks';

const initialData: NotificationContextData = {
  // ADD BOOKMARK
  openNotificationBookmark: null,
  setOpenNotificationBookmark: (_value: NotificationBookmark | null) => null,
  // BOARD INVITATION LINK
  openNotificationBoardInvitationLink: false,
  setOpenNotificationBoardInvitationLink: (_value: boolean) => false,
  // EMAIL INVITATION SEND
  openNotificationEmailInvitationSend: null,
  setOpenNotificationEmailInvitationSend: (_value: string | null) => null,
  // BOARD JOINED BY INVITATION LINK
  openNotificationBoardJoinedByInvitationLink: false,
  setOpenNotificationBoardJoinedByInvitationLink: (_value: boolean) => false,
};

export const NotificationContext = createContext(initialData);
export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationContextProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): ReactElement => {
  const state = useNotificationState(initialData);

  return <NotificationContext.Provider value={state}>{children}</NotificationContext.Provider>;
};
