import styled from '@emotion/styled';
import { DANGER_RED, GREY_2, GREY_6, NINJA_GREEN, WHITE } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
}

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 5px;
  }
`;

export const VideoWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% + 16px);
  margin: -2px -8px 0;
  pointer-events: none;
  z-index: -1;
  background-color: #fff;
  /* MOBILE */
  height: 120px;
  /* TABLET */
  ${mq['tablet']} {
    height: 200px;
  }
`;

export const Video = styled.video`
  /*MOBILE */
  width: 422px;
  height: 260px;
  transform: translate(0, -4px);
  /* TABLET */
  ${mq['tablet']} {
    width: 650px;
    height: 400px;
    transform: translate(0, -12px);
  }
  /* Fix border video visible on iPhone */
  mask-image: -webkit-radial-gradient(white, black);
  backface-visibility: hidden;
`;

export const ContentWrapper = styled.div`
  /* MOBILE */
  width: 294px;
  padding: 114px 0 0;
  margin: 0 16px 0;
  /* TABLET */
  ${mq['tablet']} {
    width: 333px;
    padding: 183px 0 0;
    margin: 0 32px 0;
  }
`;

export const GoogleSignInWrapper = styled.div`
  /* MOBILE */
  margin-bottom: 8px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 16px;
  }
  button {
    width: 100%;
    text-align: left;
  }
`;

export const Or = styled.p`
  width: 100%;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 16px;
`;

export const Form = styled.form`
  position: relative;
  /* MOBILE */
  margin-bottom: 40px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 56px;
  }
`;

export const InputTextWrapper = styled.div`
  /* MOBILE */
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 24px;
  }
  /* TABLET */
  ${mq['tablet']} {
    &:last-of-type {
      margin-bottom: 16px;
    }
  }
`;

export const GenericError = styled.p`
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${DANGER_RED};
`;

export const GreenButton = styled.button`
  width: 100%;
  height: 54px;
  margin: 0;
  padding: 0;
  outline: none;
  color: ${GREY_6};
  background-color: ${NINJA_GREEN};
  border: 1px solid ${(props: Props) => (props.darkMode ? NINJA_GREEN : GREY_6)};
  border-radius: 8px;
  cursor: pointer;
`;

// SIGN-IN
export const TitleWrapperSignIn = styled.div`
  margin-bottom: 24px;
`;

export const ButtonsWrapperSignIn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ForgetPasswordLink = styled.a`
  display: inline-block;
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_2)};
  text-align: right;
  /* MOBILE */
  font-size: 14px;
  line-height: 16px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 12px;
    line-height: 14px;
    margin-right: 10px;
  }
  &:after {
    content: '';
    position: relative;
    display: block;
    border-bottom: 1px solid ${(props: Props) => (props.darkMode ? WHITE : GREY_2)};
    /* TABLET */
    ${mq['tablet']} {
      top: -1px;
    }
  }
`;

// SIGN-UP
export const TitleWrapperSignUp = styled.div`
  margin-bottom: 8px;
`;

export const DescriptionWrapperSignUp = styled.div`
  margin-bottom: 24px;
  /* MOBILE */
  padding-right: 4px;
  /* TABLET */
  ${mq['tablet']} {
    padding-right: 10px;
  }
`;
