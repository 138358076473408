import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { InputText } from 'design-system/atoms/input-text';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { useModalContext, useSettingsContext } from 'context';
import { useUser } from 'hooks/queries/useUser';
import Modal from 'components/Modal';
import { isValidPassword } from 'common/utils';
import { QUERY_KEYS } from 'common/data/Constants';
import {
  CloseButtonWrapper,
  ContentWrapper,
  Form,
  GlobalError,
  ModalWrapper,
  PasswordWrapper,
  TitleWrapper,
} from './ChangePassword.styled';

interface InputValue {
  value: string;
  error: string;
}

const ChangePassword = () => {
  const { t } = useTranslation('common');
  const { darkMode } = useSettingsContext();
  const { openModalChangePassword, setOpenModalChangePassword } = useModalContext();

  const [startClose, setStartClose] = useState<boolean>(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(false);
  const [password, setPassword] = useState<InputValue>({ value: '', error: '' });
  const [apiError, setApiError] = useState<string>('');

  const queryClient = useQueryClient();
  const { useUpdatePassword } = useUser();
  const updatePasswordMutation = useUpdatePassword({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_PROFILE] });
      setButtonIsDisabled(false);
      handleClose();
    },
    onError: (err) => {
      if (err.response?.data.code === 40032) {
        setPassword({ value: password.value, error: t('errors.password-invalid') });
      } else {
        setApiError(t('errors.generic'));
      }
    },
  });

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalChangePassword(false);
  }, [setOpenModalChangePassword]);

  const handleChangePassword = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setPassword({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleSubmitForm = useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      setPassword({ value: password.value, error: '' });
      setApiError('');
      setButtonIsDisabled(true);
      let nbError = 0;
      if (!isValidPassword(password.value)) {
        setPassword({ value: password.value, error: t('errors.password-error') });
        nbError += 1;
      }
      if (nbError > 0) {
        setButtonIsDisabled(false);
        return;
      }

      updatePasswordMutation.mutate({ password: password.value });
    },
    [password.value, t, updatePasswordMutation]
  );

  useEffect(() => {
    if (!openModalChangePassword) {
      setButtonIsDisabled(false);
      setPassword({ value: '', error: '' });
    }
  }, [openModalChangePassword]);

  if (openModalChangePassword) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            <TitleWrapper>
              <H3
                as="p"
                color={darkMode ? WHITE : GREY_6}
              >
                {t('change-password.title')}
              </H3>
            </TitleWrapper>
            <Form onSubmit={handleSubmitForm}>
              <PasswordWrapper>
                <InputText
                  type="password"
                  disabled={buttonIsDisabled}
                  placeholder={t('change-password.placeholder')}
                  value={password.value}
                  error={password.error}
                  darkMode={darkMode}
                  onChange={handleChangePassword}
                />
              </PasswordWrapper>
              <Button darkMode={darkMode}>{t('change-password.save')}</Button>
              {apiError && <GlobalError>{apiError}</GlobalError>}
            </Form>
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default ChangePassword;
