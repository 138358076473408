import styled from '@emotion/styled';
import { GREY_6 } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
}

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 5px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 290px;
  padding: 48px 32px 32px;
`;

export const IconWrapper = styled.div`
  position: relative;
  width: 108px;
  height: 93px;
  margin: 0 0 32px 43px;
  filter: ${(props: Props) => (props.darkMode ? 'none' : `drop-shadow(6px 6px 0px ${GREY_6})`)};

  .stamp {
    filter: drop-shadow(4px 4px 0px ${GREY_6});
  }
`;

export const ButtonsWrapper = styled.div`
  position: relative;
  text-align: center;
`;

export const ButtonLinkWrapper = styled.div`
  display: inline-block;
  max-width: 100%;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
