import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { Input, Label, SwitchSelector, SwitchWrapper } from './switch.styled';

export type SwitchProps = {
  name?: string;
  checked?: boolean;
  darkMode?: boolean;
  children?: ReactNode;
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
};

export function Switch({ name, checked = false, darkMode = false, children, onChange }: SwitchProps) {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setIsChecked(e.currentTarget.checked);
    onChange?.(e);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <Label
      checked={isChecked}
      darkMode={darkMode}
      data-testid="switch-id"
    >
      <Input
        {...(name && { name })}
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
      />
      <SwitchWrapper
        checked={isChecked}
        darkMode={darkMode}
        {...(children && { hasMarginRight: true })}
      >
        <SwitchSelector
          checked={isChecked}
          darkMode={darkMode}
        />
      </SwitchWrapper>
      {children}
    </Label>
  );
}
