import styled from '@emotion/styled';
import { GREY_6 } from 'design-system/global/colors';

const SvgWrapper = styled.svg`
  filter: drop-shadow(2px 2px 0px ${GREY_6});
`;

const BoardInvitationLinkIcon = () => {
  return (
    <SvgWrapper
      width="37"
      height="38"
      viewBox="0 0 37 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4178 27.4819C15.6508 27.0003 15.9738 26.5676 16.3696 26.2072C16.7767 25.8367 17.2527 25.55 17.7705 25.3635C18.2883 25.177 18.8378 25.0942 19.3876 25.1201C19.9374 25.1459 20.4767 25.2797 20.9747 25.514C21.4728 25.7482 21.9198 26.0783 22.2903 26.4853C22.6608 26.8923 22.9476 27.3683 23.1341 27.8862C23.3206 28.404 23.4034 28.9535 23.3775 29.5033C23.3517 30.0531 23.2178 30.5924 22.9836 31.0904L22.9798 31.0986C21.9958 33.1665 20.4452 34.9127 18.5082 36.1344C16.5716 37.3559 14.3284 38.0028 12.0388 38.0003C9.56118 38.0009 7.14381 37.236 5.11744 35.8102C3.09162 34.3849 1.55551 32.3686 0.719097 30.0371C0.180842 28.551 -0.0593105 26.9733 0.0123826 25.3942C0.084131 23.814 0.466794 22.2634 1.13847 20.8312L3.53578 15.7314C4.61165 13.4412 6.38152 11.5471 8.5939 10.319C10.8065 9.09079 13.3496 8.59038 15.8625 8.88874C18.3755 9.1871 20.7307 10.2691 22.5942 11.9813C24.4577 13.6934 25.7347 15.9488 26.2443 18.4276L26.2449 18.4304C26.4665 19.5165 26.2477 20.6461 25.6364 21.5708C25.0252 22.4955 24.0717 23.1395 22.9856 23.3611C21.8996 23.5827 20.77 23.3639 19.8453 22.7526C18.9216 22.1421 18.278 21.19 18.0557 20.1054C17.8981 19.347 17.5059 18.6575 16.9346 18.1343C16.3628 17.6106 15.6406 17.2803 14.8705 17.1902C14.1003 17.1001 13.3214 17.2549 12.6441 17.6324C11.9669 18.01 11.4257 18.5912 11.0974 19.2937L11.0956 19.2976L8.72455 24.3425C8.36078 25.2164 8.34563 26.197 8.68303 27.0822C9.02427 27.9775 9.69951 28.7056 10.5667 29.1131C11.4338 29.5207 12.4252 29.576 13.3323 29.2673C14.231 28.9616 14.9775 28.3222 15.4178 27.4819Z"
        fill="black"
      />
      <path
        d="M12.0397 36.0003C9.97373 36.0011 7.95797 35.3634 6.26831 34.1746C4.57865 32.9857 3.29765 31.3038 2.6006 29.3589C2.15109 28.119 1.9505 26.8025 2.01032 25.4849C2.07015 24.1674 2.3892 22.8745 2.94923 21.6804L5.34605 16.5817C6.24331 14.6714 7.71927 13.092 9.56459 12.0677C11.4099 11.0433 13.5309 10.6259 15.6267 10.8748C17.7226 11.1236 19.6869 12.026 21.241 13.454C22.7952 14.882 23.8603 16.763 24.2853 18.8303C24.4009 19.3967 24.2867 19.9857 23.968 20.4679C23.6493 20.9501 23.1521 21.2859 22.5857 21.4015C22.0194 21.5171 21.4303 21.4029 20.9482 21.0842C20.466 20.7655 20.1302 20.2682 20.0146 19.7019C19.772 18.5313 19.167 17.4668 18.2854 16.6594C17.4038 15.852 16.2902 15.3427 15.1028 15.2038C13.9155 15.0649 12.7145 15.3034 11.6703 15.8855C10.6261 16.4677 9.79172 17.3638 9.28555 18.4469L6.89744 23.5281C6.31834 24.8858 6.28848 26.4152 6.81417 27.7945C7.33985 29.1738 8.38008 30.2953 9.71594 30.9232C11.0518 31.551 12.5791 31.6362 13.9765 31.1607C15.3739 30.6853 16.5324 29.6863 17.2081 28.3741C17.3306 28.1137 17.5032 27.8799 17.716 27.6862C17.9287 27.4925 18.1776 27.3427 18.4483 27.2451C18.719 27.1476 19.0063 27.1044 19.2938 27.1179C19.5812 27.1314 19.8632 27.2013 20.1235 27.3238C20.3839 27.4463 20.6176 27.6188 20.8113 27.8316C21.005 28.0444 21.1549 28.2933 21.2525 28.564C21.35 28.8347 21.3932 29.122 21.3797 29.4094C21.3662 29.6969 21.2962 29.9788 21.1738 30.2392C20.3524 31.9654 19.0581 33.423 17.4412 34.4428C15.8244 35.4626 13.9513 36.0027 12.0397 36.0003Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4821 29.2071C20.802 29.2005 19.1421 28.8416 17.6093 28.1536C16.0772 27.4658 14.7062 26.4645 13.5849 25.2141C12.521 24.0314 11.7044 22.6479 11.1829 21.1453C10.9857 20.6356 10.8903 20.0921 10.9024 19.5454C10.9149 18.9788 11.0424 18.4207 11.2773 17.905C11.5122 17.3893 11.8496 16.9268 12.2689 16.5456C12.6881 16.1643 13.1806 15.8723 13.7162 15.6873C14.2518 15.5024 14.8195 15.4283 15.3847 15.4695C15.9499 15.5108 16.5008 15.6666 17.0038 15.9275C17.5069 16.1883 17.9517 16.5487 18.3111 16.9869C18.6555 17.4066 18.9146 17.8892 19.0742 18.4076C19.2377 18.8569 19.4845 19.2712 19.802 19.629C20.2107 20.0771 20.7231 20.4182 21.2944 20.6222C21.8684 20.8273 22.4841 20.8877 23.0871 20.7982C23.6901 20.7086 24.2616 20.4718 24.7513 20.1087C25.2406 19.7458 25.6331 19.2679 25.8939 18.7173L28.287 13.644C28.6984 12.754 28.7425 11.7377 28.4096 10.8154C28.0763 9.89205 27.3922 9.13754 26.5059 8.71574C26.0684 8.50949 25.5945 8.39185 25.1113 8.36963C24.6274 8.34736 24.1438 8.42126 23.6886 8.58704L23.6826 8.58923C22.7562 8.92326 21.9997 9.61042 21.5784 10.5005C21.3436 10.9966 21.0132 11.4418 20.6064 11.8102C20.1996 12.1786 19.7242 12.4633 19.2073 12.648C18.6905 12.8327 18.1424 12.9138 17.5942 12.8867C17.046 12.8595 16.5086 12.7247 16.0125 12.4898C15.0107 12.0155 14.2383 11.1626 13.8653 10.1188C13.4926 9.0759 13.5491 7.92773 14.0223 6.92649C15.3832 4.03325 17.8374 1.79882 20.8453 0.714554C23.8541 -0.370024 27.1705 -0.214941 30.065 1.14569C32.9594 2.50632 35.1948 4.96104 36.2794 7.96983C37.364 10.9786 37.2089 14.295 35.8483 17.1895L35.8465 17.1932L33.441 22.2832C32.4624 24.3529 30.9162 26.1026 28.9825 27.3282C27.0489 28.5539 24.807 29.2054 22.5176 29.2071L22.4821 29.2071Z"
        fill="black"
      />
      <path
        d="M22.49 27.2071C21.0896 27.2016 19.7059 26.9025 18.4284 26.329C17.1508 25.7555 16.0077 24.9204 15.0729 23.8777C14.178 22.8832 13.4932 21.7181 13.0596 20.4525C12.949 20.1784 12.8954 19.8848 12.9019 19.5894C12.9084 19.294 12.9749 19.003 13.0974 18.7341C13.2199 18.4652 13.3958 18.2241 13.6144 18.0253C13.833 17.8265 14.0898 17.6742 14.369 17.5778C14.6483 17.4813 14.9443 17.4427 15.239 17.4642C15.5337 17.4857 15.8209 17.567 16.0832 17.703C16.3456 17.839 16.5775 18.0269 16.7649 18.2554C16.9523 18.4838 17.0913 18.748 17.1734 19.0318C17.4241 19.7451 17.812 20.4024 18.3151 20.9667C18.9458 21.6614 19.738 22.19 20.6215 22.5057C21.5051 22.8213 22.4529 22.9143 23.381 22.7764C24.3091 22.6386 25.1889 22.2741 25.9426 21.7152C26.6962 21.1563 27.3005 20.4202 27.702 19.5722L30.0988 14.4909C30.7343 13.1215 30.8033 11.5565 30.2908 10.1364C29.7783 8.71635 28.7257 7.55615 27.3621 6.90825C26.685 6.5885 25.9512 6.40615 25.2032 6.37174C24.4552 6.33733 23.7078 6.45155 23.0042 6.70779C21.5806 7.22111 20.418 8.27711 19.7707 9.64499C19.6482 9.90366 19.476 10.1357 19.2638 10.3278C19.0517 10.5199 18.8038 10.6684 18.5343 10.7647C18.2648 10.861 17.979 10.9033 17.6931 10.8891C17.4073 10.8749 17.127 10.8046 16.8683 10.6822C16.3459 10.4348 15.9432 9.99009 15.7487 9.4458C15.5542 8.9015 15.5838 8.30224 15.8312 7.77982C16.9661 5.36541 19.0137 3.50075 21.5235 2.59605C24.0333 1.69134 26.7997 1.82071 29.2141 2.95568C31.6286 4.09065 33.4932 6.13826 34.3979 8.64805C35.3026 11.1578 35.1733 13.9242 34.0383 16.3387L31.6328 21.4286C30.816 23.156 29.5257 24.616 27.9118 25.639C26.2979 26.6619 24.4269 27.2057 22.5161 27.2071H22.49Z"
        fill="white"
      />
      <path
        d="M20.5025 10.3276C18.5 8 15.1665 8.00121 13.9998 8.50121C12.3998 10.1005 15.4998 10.6665 16.9998 11.5005C19.6665 11.8335 23.085 13.3294 20.5025 10.3276Z"
        fill="black"
      />
      <path
        d="M20 12.5014C16.8 10.5014 13.8333 10.6667 13 11L14.5 14.0009C17.6667 14.3344 23.2 14.5014 20 12.5014Z"
        fill="white"
      />
    </SvgWrapper>
  );
};

export default BoardInvitationLinkIcon;
