import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { easeInOutQuart } from 'common/easing/Easing';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  show?: boolean;
  darkMode?: boolean;
}

export const GdprBannerWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100%;
  transition: transform 0.4s ${easeInOutQuart} ${(props: Props) => (props.show ? '0.2s' : '0s')};
  /* MOBILE */
  bottom: 8px;
  transform: translateY(calc(100% + 16px));
  ${(props: Props) =>
    props.show &&
    css`
      transform: translateY(0);
    `}
  /* TABLET */
  ${mq['tablet']} {
    bottom: 32px;
    transform: translateY(calc(100% + 64px));
    ${(props: Props) =>
      props.show &&
      css`
        transform: translateY(0);
      `}
  }
`;

export const GdprBannerContent = styled.div`
  background-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  border-radius: 8px;
  /* MOBILE */
  margin: 0 8px;
  padding: 16px 16px 24px;
  /* TABLET */
  ${mq['tablet']} {
    display: flex;
    margin: 0 auto;
    padding: 16px 24px;
    width: 695px;
  }
`;

export const IconTextWrapper = styled.div`
  display: flex;
  /* MOBILE */
  align-items: flex-start;
  margin-bottom: 16px;
  /* TABLET */
  ${mq['tablet']} {
    flex: 1;
    align-items: center;
    margin-bottom: 0;
    margin-right: 16px;
  }
`;

export const GdprText = styled.p`
  color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  /* MOBILE */
  font-size: 14px;
  line-height: 22px;
  margin-left: 8px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 12px;
    line-height: normal;
    margin-left: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  /* MOBILE */
  margin-left: 32px;
  /* TABLET */
  ${mq['tablet']} {
    justify-content: flex-end;
    margin-left: 0;
  }

  button {
    margin-right: 8px;
    &:last-of-type {
      margin-right: 0;
    }
    &:first-of-type {
      ${(props: Props) =>
        props.darkMode &&
        css`
          border-color: ${WHITE};
        `}
    }
  }
`;
