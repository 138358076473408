import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

interface Price {
  min: number;
  max?: number;
  currency: string;
}

interface Filter {
  type: 'family' | 'minStyle' | 'tag' | 'price' | 'license' | 'foundry' | 'board' | 'user';
  value: string | number | Price;
}
export type Filters = Array<Filter>;

export interface Display {
  bestWeight?: number;
  bestStyle?: string;
}

export type Sorting =
  | 'latest_bookmark'
  | 'community'
  | 'alphabetical'
  | 'foundries'
  | 'release_date'
  | 'popularity_websites'
  | 'popularity_bookmarks'
  | 'random';

const baseUrl = `${API_BASE_URL}/family/search`;

export const initFamilySearch = (
  pageSize: number,
  page: number,
  filters?: Filters,
  display?: Display,
  sorting?: Sorting,
  seed?: string
): Promise<API.FamilySearch.ResultFamilySearch> => {
  if (display && display.bestWeight === 0) {
    display = undefined;
  } else if (display && display.bestWeight === 1000) {
    display.bestWeight = 950;
  }
  if (filters) {
    const cloneFilters: Filters = JSON.parse(JSON.stringify(filters));
    const priceFilters = cloneFilters.find((filter) => filter.type === 'price');
    if (priceFilters && (priceFilters.value as Price).max === 125) {
      priceFilters.value = {
        min: (priceFilters.value as Price).min,
        currency: (priceFilters.value as Price).currency,
      };
      filters = cloneFilters;
    }
  }
  return axios.post(`${baseUrl}`, { pageSize, page, filters, display, sorting, seed }).then(({ data }) => {
    return data;
  });
};

export const continueFamilySearch = (
  idSearch: string,
  pageSize: number,
  page: number,
  display?: Display
): Promise<API.FamilySearch.ResultFamilySearch> => {
  if (display && display.bestWeight === 0) {
    display = undefined;
  } else if (display && display.bestWeight === 1000) {
    display.bestWeight = 950;
  }
  return axios.get(`${baseUrl}/${idSearch}`, { params: { pageSize, page, display } }).then(({ data }) => {
    return data;
  });
};
