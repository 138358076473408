import { Filters } from 'api/familySearch.api';
import { contrastIcons, endingIcons, widthIcons } from 'common/data/Datas';
import {
  initialContrast,
  initialCurrency,
  initialEnding,
  initialFontStyles,
  initialMinStyles,
  initialMonospacedFonts,
  initialPriceRange,
  initialSearchValue,
  initialTrialFonts,
  initialWidth,
} from 'common/data/Settings';
import { isTouchDevice } from './device';
import { isServerSideRendering } from './next';

interface PageFilter {
  type: 'foundry' | 'board' | 'user';
  value: string | number;
}

export const getCurrentFilters = (
  searchValue: string,
  minStyles: number,
  contrast: number,
  width: number,
  ending: number,
  fontStyles: Record<string, boolean>,
  trialFonts: boolean,
  monospacedFonts: boolean,
  priceRange: Array<number>,
  currency: string,
  page?: PageFilter
): Filters => {
  // Disable Filters by search value for Touch Devices (to avoid options bars jump during results search)
  if (!isServerSideRendering() && isTouchDevice()) {
    searchValue = '';
  }
  const filters: Filters = [
    // family
    { type: 'family', value: searchValue },
    // minStyles
    { type: 'minStyle', value: minStyles },
    // price
    { type: 'price', value: { min: priceRange[0], max: priceRange[1], currency: currency } },
  ];
  // tag -> category
  if (fontStyles) {
    // serif
    if (fontStyles['serif'] === true) {
      filters.push({ type: 'tag', value: 'category:serif:normal' });
    }
    // triangle-serif
    if (fontStyles['triangle-serif'] === true) {
      filters.push({ type: 'tag', value: 'category:serif:triangle' });
    }
    // slab-serif
    if (fontStyles['slab-serif'] === true) {
      filters.push({ type: 'tag', value: 'category:serif:squared' });
    }
    // sans-serif
    if (fontStyles['sans-serif'] === true) {
      filters.push({ type: 'tag', value: 'category:sans:normal' });
    }
    // hexagonal-sans
    if (fontStyles['hexagonal-sans'] === true) {
      filters.push({ type: 'tag', value: 'category:sans:hexagon' });
    }
    // other
    if (fontStyles['other'] === true) {
      filters.push({ type: 'tag', value: 'category:other' });
    }
  }
  // tag -> contrast
  if (contrast >= 0) {
    const contrastData = contrastIcons.find((o) => o.value === contrast);
    if (contrastData) {
      switch (contrastData.label) {
        case 'NONE':
          filters.push({ type: 'tag', value: 'contrast:none' });
          break;
        case 'LOW':
          filters.push({ type: 'tag', value: 'contrast:low' });
          break;
        case 'MEDIUM':
          filters.push({ type: 'tag', value: 'contrast:medium' });
          break;
        case 'HIGH':
          filters.push({ type: 'tag', value: 'contrast:high' });
          break;
        case 'EXTREME':
          filters.push({ type: 'tag', value: 'contrast:extreme' });
          break;
      }
    }
  }
  // tag -> width
  if (width >= 0) {
    const widthData = widthIcons.find((o) => o.value === width);
    if (widthData) {
      switch (widthData.label) {
        case 'COMPRESSED':
          filters.push({ type: 'tag', value: 'width:compressed' });
          break;
        case 'CONDENSED':
          filters.push({ type: 'tag', value: 'width:condensed' });
          break;
        case 'NORMAL':
          filters.push({ type: 'tag', value: 'width:normal' });
          break;
        case 'WIDE':
          filters.push({ type: 'tag', value: 'width:wide' });
          break;
        case 'EXTENDED':
          filters.push({ type: 'tag', value: 'width:extended' });
          break;
      }
    }
  }
  // tag -> ending
  if (ending > 0) {
    const endingData = endingIcons.find((o) => o.value === ending);
    if (endingData && endingData.label === 'SQUARE') {
      filters.push({ type: 'tag', value: 'ending:square' });
    } else if (endingData && endingData.label === 'ROUNDED') {
      filters.push({ type: 'tag', value: 'ending:rounded' });
    }
  }
  // tag -> feature:trials
  if (trialFonts) {
    filters.push({ type: 'tag', value: 'feature:trials' });
  }
  // tag -> feature:monospace
  if (monospacedFonts) {
    filters.push({ type: 'tag', value: 'feature:monospace' });
  }
  // tag -> page
  if (page) {
    filters.push({ type: page.type, value: page.value });
  }
  return filters;
};

export const getInitialFilters = (page?: PageFilter, withFontStyles: boolean = true): Filters => {
  const filters: Filters = [
    // family
    { type: 'family', value: initialSearchValue },
    // minStyles
    { type: 'minStyle', value: initialMinStyles },
    // price
    {
      type: 'price',
      value: {
        min: initialPriceRange[0],
        max: initialPriceRange[1],
        currency: initialCurrency,
      },
    },
  ];
  // tag -> category
  if (initialFontStyles && withFontStyles) {
    // serif
    if (initialFontStyles['serif'] === true) {
      filters.push({ type: 'tag', value: 'category:serif:normal' });
    }
    // triangle-serif
    if (initialFontStyles['triangle-serif'] === true) {
      filters.push({ type: 'tag', value: 'category:serif:triangle' });
    }
    // slab-serif
    if (initialFontStyles['slab-serif'] === true) {
      filters.push({ type: 'tag', value: 'category:serif:squared' });
    }
    // sans-serif
    if (initialFontStyles['sans-serif'] === true) {
      filters.push({ type: 'tag', value: 'category:sans:normal' });
    }
    // hexagonal-sans
    if (initialFontStyles['hexagonal-sans'] === true) {
      filters.push({ type: 'tag', value: 'category:sans:hexagon' });
    }
    // other
    if (initialFontStyles['other'] === true) {
      filters.push({ type: 'tag', value: 'category:other' });
    }
  }
  // tag -> contrast
  if (initialContrast >= 0) {
    const contrastData = contrastIcons.find((o) => o.value === initialContrast);
    if (contrastData) {
      switch (contrastData.label) {
        case 'NONE':
          filters.push({ type: 'tag', value: 'contrast:none' });
          break;
        case 'LOW':
          filters.push({ type: 'tag', value: 'contrast:low' });
          break;
        case 'MEDIUM':
          filters.push({ type: 'tag', value: 'contrast:medium' });
          break;
        case 'HIGH':
          filters.push({ type: 'tag', value: 'contrast:high' });
          break;
        case 'EXTREME':
          filters.push({ type: 'tag', value: 'contrast:extreme' });
          break;
      }
    }
  }
  // tag -> width
  if (initialWidth >= 0) {
    const widthData = widthIcons.find((o) => o.value === initialWidth);
    if (widthData) {
      switch (widthData.label) {
        case 'COMPRESSED':
          filters.push({ type: 'tag', value: 'width:compressed' });
          break;
        case 'CONDENSED':
          filters.push({ type: 'tag', value: 'width:condensed' });
          break;
        case 'NORMAL':
          filters.push({ type: 'tag', value: 'width:normal' });
          break;
        case 'WIDE':
          filters.push({ type: 'tag', value: 'width:wide' });
          break;
        case 'EXTENDED':
          filters.push({ type: 'tag', value: 'width:extended' });
          break;
      }
    }
  }
  // tag -> ending
  if (initialEnding > 0) {
    const endingData = endingIcons.find((o) => o.value === initialEnding);
    if (endingData && endingData.label === 'SQUARE') {
      filters.push({ type: 'tag', value: 'ending:square' });
    } else if (endingData && endingData.label === 'ROUNDED') {
      filters.push({ type: 'tag', value: 'ending:rounded' });
    }
  }
  // tag -> feature:trials
  if (initialTrialFonts) {
    filters.push({ type: 'tag', value: 'feature:trials' });
  }
  // tag -> feature:monospace
  if (initialMonospacedFonts) {
    filters.push({ type: 'tag', value: 'feature:monospace' });
  }
  // tag -> page
  if (page) {
    filters.push({ type: page.type, value: page.value });
  }
  return filters;
};
