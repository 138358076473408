import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/board`;

// COUNT PERSONAL BOARDS
export const boardCount = (): Promise<API.Board.TotalBoards> => {
  return axios.get(`${baseUrl}/count`).then(({ data }) => data);
};

// GET SIMPLE LIST PERSONAL BOARDS
export const boardListSimple = (): Promise<API.Board.BoardListSimple> => {
  return axios.get(`${baseUrl}/list/simple`).then(({ data }) => {
    return data;
  });
};

// GET DETAILED LIST PERSONAL BOARDS
export const boardListDetailed = (): Promise<API.Board.BoardListDetailed> => {
  return axios.get(`${baseUrl}/list/detailed`).then(({ data }) => {
    return data;
  });
};

// CREATE NEW BOARD
export const addNewBoard = (name: string, isPrivate: boolean): Promise<API.Board.BoardSimple> => {
  return axios.post(`${baseUrl}`, { name: name, private: isPrivate }).then(({ data }) => {
    return data;
  });
};

// GET INFOS BOARD
export const getBoard = (idBoard: number): Promise<{ board: API.Board.BoardDetailed }> => {
  return axios.get(`${baseUrl}/${idBoard}`).then(({ data }) => {
    return data;
  });
};

// EDIT BOARD
export const editBoard = (
  idBoard: number,
  name: string,
  isPrivate: boolean
): Promise<{ board: API.Board.BoardSimple }> => {
  return axios.patch(`${baseUrl}/${idBoard}`, { name: name, private: isPrivate }).then(({ data }) => {
    return data;
  });
};

// DELETE BOARD
export const deleteBoard = (idBoard: number): Promise<API.Board.DeleteBoard> => {
  return axios.delete(`${baseUrl}/${idBoard}`).then(({ data }) => {
    return data;
  });
};

// COUNT PUBLIC BOARDS OF A SPECIFIC USER
export const countPublicBoards = (idUser: number): Promise<API.Board.TotalBoards> => {
  return axios.get(`${baseUrl}/count/by-user/${idUser}`).then(({ data }) => {
    return data;
  });
};

// GET DETAILED LIST PUBLIC BOARDS OF A SPECIFIC USER
export const getPublicBoards = (idUser: number): Promise<API.Board.BoardListDetailed> => {
  return axios.get(`${baseUrl}/search/by-user/${idUser}`).then(({ data }) => {
    return data;
  });
};

// UPDATE CURRENT FONT WEIGHT ON A BOOKMARK IN A BOARD
export const updateCurrentFontWeightBookmarkInBoard = (
  idBoard: number,
  idFamily: string,
  idFont: number
): Promise<API.Board.UpdateFontWeightBookmarkInBoard> => {
  return axios.patch(`${baseUrl}/${idBoard}/bookmark/${idFamily}`, { idFont }).then(({ data }) => {
    return data;
  });
};
