import { AddBookmarkToBoardOptions, AuthContextData, FollowNotLoggedIn, useAuthState } from 'hooks';
import { createContext, PropsWithChildren, ReactElement, useContext } from 'react';

const initialData: AuthContextData = {
  authReady: false,
  isLoggedIn: false,
  accessToken: null,
  setAccessToken: (accessToken: string) => null,
  refreshToken: null,
  setRefreshToken: (refreshToken: string) => null,
  userId: null,
  userName: null,
  email: null,
  avatar: null,
  loginType: null,
  preferredCurrency: null,
  superAdmin: false,
  bookmarkNotLoggedCacheData: null,
  setBookmarkNotLoggedCacheData: (data: AddBookmarkToBoardOptions | null) => null,
  followFoundryIdNotLogged: null,
  setFollowFoundryIdNotLogged: (idFoundry: string | null) => null,
  followUserIdNotLogged: null,
  setFollowUserIdNotLogged: (idUser: number | null) => null,
  openModalFollowNotLogged: null,
  setOpenModalFollowNotLogged: (data: FollowNotLoggedIn | null) => null,
  setTokens: (data: API.Auth.Tokens) => null,
  setAuthToken: (authToken: string) => null,
  checkAccessToken: (accessToken: string) => null,
  logout: () => null,
};

export const AuthContext = createContext(initialData);
export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }: PropsWithChildren<Record<string, unknown>>): ReactElement => {
  const state = useAuthState(initialData);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
