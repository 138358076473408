import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/license`;

// GET LICENSES LIST
export const licensesList = (): Promise<API.Licenses.LicensesList> => {
  return axios.get(`${baseUrl}/list`).then(({ data }) => {
    return data;
  });
};
