import { UAParser } from 'ua-parser-js';
import { CHROME_EXTENSION, FIREFOX_EXTENSION, SAFARI_EXTENSION } from 'common/data/Constants';

const getRealOSName = () => {
  const { os } = UAParser();
  // Workaround for iPadOS which is currently not detectable via UA
  if (os.name === 'Mac OS') {
    if (window.orientation !== undefined) {
      return 'iPadOS';
    } else {
      return os.name;
    }
  }
  return os.name;
};

// TODO: update this method when we'll release web extension for iPhone and iPad (and Android ?)
export const isExtensionAvailable = () => {
  const { device, browser } = UAParser();
  // `device.type = undefined` means desktop
  // Now extension is only available for desktop, later for iphone and ipad
  if (device.type === undefined) {
    if (getRealOSName() === 'iPadOS') {
      return false;
    }
    const browserName = browser.name;
    if (
      browserName &&
      (browserName.indexOf('Chrome') > -1 || browserName.indexOf('Safari') > -1 || browserName.indexOf('Firefox') > -1)
    ) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const getExtensionPath = (value?: string) => {
  let browserName;
  if (value) {
    browserName = value;
  } else {
    const { browser } = UAParser();
    browserName = browser.name;
  }
  if (browserName) {
    if (browserName.indexOf('Safari') > -1) {
      return SAFARI_EXTENSION;
    } else if (browserName.indexOf('Firefox') > -1) {
      return FIREFOX_EXTENSION;
    }
    return CHROME_EXTENSION;
  }
  return CHROME_EXTENSION;
};
