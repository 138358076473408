import styled from '@emotion/styled';
import { DANGER_RED, GREY_1, GREY_6, LIGHT_GREY, WHITE } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

interface Props {
  darkMode?: boolean;
}

export const Form = styled.form`
  position: relative;
`;

export const InputTextWrapper = styled.div`
  margin-bottom: 15px;
`;

export const InputItem = styled.div`
  margin-bottom: 16px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 181px;
  padding: 8px 14px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  border: 1px solid ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  outline: none;
  resize: none;
  /* MOBILE */
  font-size: 16px;
  line-height: 18px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 14px;
    line-height: 16px;
  }
  &::placeholder {
    color: ${GREY_1};
    opacity: 1;
  }
`;

export const ButtonSendWrapper = styled.div`
  button {
    &:disabled {
      color: ${WHITE};
      background-color: ${LIGHT_GREY};
      border-color: ${LIGHT_GREY};
      &:hover {
        border-color: ${LIGHT_GREY};
      }
    }
  }
`;

export const ResponseError = styled.p`
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${DANGER_RED};
`;
