import React, { ReactNode, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Icon } from 'design-system/atoms/icon';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { Label, SelectBoardStyled, IconBookmark } from './select-board.styled';

export type SelectBoardProps = {
  active: boolean;
  privateBoard: boolean;
  width?: string;
  darkMode?: boolean;
  children?: ReactNode;
  onClick?: (active: boolean) => void;
};

const BOOKMARKED: string = 'bookmarked';
const ADD_BOOKMARK: string = 'add_bookmark';
const REMOVE_BOOKMARK: string = 'remove_bookmark';

export function SelectBoard({
  active = false,
  privateBoard = false,
  width,
  darkMode = false,
  children,
  onClick,
}: SelectBoardProps) {
  const [isActive, setIsActive] = useState<boolean>(active);
  const [bookmarkIconState, setBookmarkIconState] = useState<string>(BOOKMARKED);
  const [showIconBookmark, setShowIconBookmark] = useState<boolean>(active);

  useEffect(() => {
    setIsActive(active);
    setShowIconBookmark(active);
  }, [active, setIsActive, setShowIconBookmark]);

  const handleMouseOver = useCallback(() => {
    setBookmarkIconState(isActive ? REMOVE_BOOKMARK : ADD_BOOKMARK);
    setShowIconBookmark(true);
  }, [isActive, setBookmarkIconState, setShowIconBookmark]);

  const handleMouseOut = useCallback(() => {
    setBookmarkIconState(BOOKMARKED);
    setShowIconBookmark(isActive ? true : false);
  }, [isActive, setBookmarkIconState, setShowIconBookmark]);

  const handleClick = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      const newIsActive = !isActive;
      setIsActive(newIsActive);
      setShowIconBookmark(newIsActive);
      setBookmarkIconState(BOOKMARKED);
      onClick?.(newIsActive);
    },
    [isActive, setIsActive, setShowIconBookmark, setBookmarkIconState, onClick]
  );

  const getIconColor = useCallback(() => {
    if (isActive) {
      return darkMode ? GREY_6 : WHITE;
    } else {
      return darkMode ? WHITE : GREY_6;
    }
  }, [isActive, darkMode]);

  return (
    <SelectBoardStyled
      active={isActive}
      width={width}
      darkMode={darkMode}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
    >
      <Label
        active={isActive}
        darkMode={darkMode}
      >
        {children}
      </Label>
      {privateBoard && (
        <Icon
          label="not_visible"
          color={getIconColor()}
        />
      )}
      <IconBookmark
        className="bookmark-state"
        show={showIconBookmark}
      >
        <Icon
          label={bookmarkIconState}
          color={getIconColor()}
        />
      </IconBookmark>
    </SelectBoardStyled>
  );
}
