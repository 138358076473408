import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

let storedScrollY: number;

export const disableGlobalScroll = () => {
  storedScrollY = window.scrollY;
  disableBodyScroll(document.body, { allowTouchMove: () => true, reserveScrollBarGap: true });
  document.body.style.setProperty('top', `${window.scrollY * -1}px`);
};

export const enableGlobalScroll = () => {
  enableBodyScroll(document.body);
  document.body.style.setProperty('top', '');
  document.body.scrollTo(0, storedScrollY);
};
