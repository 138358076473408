import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import { useNotificationContext, useSettingsContext } from 'context';
import Notification from 'components/Notification';
import BoardInvitationLinkIcon from './BoardInvitationLinkIcon';
import { ContentWrapper, IconWrapper, Text } from './BoardInvitationLink.styled';

const BoardInvitationLink = () => {
  const { t } = useTranslation('common');
  const { darkMode } = useSettingsContext();
  const { openNotificationBoardInvitationLink, setOpenNotificationBoardInvitationLink } = useNotificationContext();

  const handleCloseComplete = useCallback(() => {
    setOpenNotificationBoardInvitationLink(false);
  }, [setOpenNotificationBoardInvitationLink]);

  if (openNotificationBoardInvitationLink) {
    return (
      <Notification
        darkMode={darkMode}
        onCloseComplete={handleCloseComplete}
      >
        <ContentWrapper>
          <IconWrapper>
            <BoardInvitationLinkIcon />
          </IconWrapper>
          <Text>{t('notification-board-invitation-link.label')}</Text>
        </ContentWrapper>
      </Notification>
    );
  }
  return null;
};

export default BoardInvitationLink;
