import React, { InputHTMLAttributes, ReactNode, useCallback, useState } from 'react';
import { Icon } from 'design-system/atoms/icon';
import { IconButton } from 'design-system/atoms/icon-button';
import { DANGER_RED, GREY_6, WHITE } from 'design-system/global/colors';
import { Error, IconWrapper, Input, Label, ShowHidePasswordWrapper } from './input-text.styled';

export interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'email' | 'password';
  error?: string;
  width?: string;
  iconLabel?: string;
  darkMode?: boolean;
  children?: ReactNode;
}

export function InputText({
  type = 'text',
  darkMode = false,
  error,
  width,
  iconLabel,
  children,
  ...props
}: InputTextProps) {
  const [currenType, setCurrentType] = useState<'text' | 'email' | 'password'>(type);
  const [iconEye, setIconEye] = useState<'visible' | 'not_visible'>('visible');

  const togglePassword = useCallback(() => {
    if (currenType === 'password') {
      setCurrentType('text');
      setIconEye('not_visible');
    } else {
      setCurrentType('password');
      setIconEye('visible');
    }
  }, [currenType]);

  return (
    <>
      <Label
        data-testid="inputText-id"
        width={width}
        darkMode={darkMode}
        error={error && error.trim() !== '' ? true : false}
      >
        {children}
        <Input
          defaultType={type}
          type={currenType}
          width={width}
          marginLeft={!!children}
          darkMode={darkMode}
          iconLabel={iconLabel}
          error={error && error.trim() !== '' ? true : false}
          autoComplete="off"
          {...props}
        />
        {iconLabel && (
          <IconWrapper>
            <Icon
              label={iconLabel}
              color={error && error.trim() !== '' ? DANGER_RED : darkMode ? WHITE : GREY_6}
            />
          </IconWrapper>
        )}
        {type === 'password' && (
          <ShowHidePasswordWrapper>
            <IconButton
              type="button"
              iconLabel={iconEye}
              color={error && error.trim() !== '' ? DANGER_RED : darkMode ? WHITE : GREY_6}
              buttonSize="36px"
              onClick={togglePassword}
            />
          </ShowHidePasswordWrapper>
        )}
      </Label>
      {error && error.trim() !== '' && <Error>{error}</Error>}
    </>
  );
}
