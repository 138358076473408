import { css } from "@emotion/react";
// @ts-ignore
import AeonikBold from './assets/fonts/Aeonik/Aeonik-Bold.woff2';
// @ts-ignore
import AeonikRegular from './assets/fonts/Aeonik/Aeonik-Regular.woff2';

export const InjectFontsStyled = css`
  @font-face {
    font-family: 'FontsNinja-Aeonik';
    src: url(${AeonikBold}) format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'FontsNinja-Aeonik';
    src: url(${AeonikRegular}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;
