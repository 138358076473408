import { ReactElement, useCallback, useState } from 'react';
import Image from 'next/image';
import { getRandomInt } from 'common/utils';
import { AvatarBackground, AvatarBorder, AvatarWrapper } from './Avatar.styled';

interface Props {
  width: number;
  height: number;
  border: number;
  darkMode: boolean;
  src?: string | null;
  userId?: number | null;
  userName?: string | null;
  withShadow?: boolean;
}

const Avatar = ({ width, height, border, darkMode, src, userId, userName, withShadow }: Props): ReactElement => {
  const getAvatarUrl = useCallback(() => {
    if (!src) {
      const num = userId ? userId % 20 : getRandomInt(1, 20);
      return `/images/avatars/avatar-${num}.svg`;
    }
    return src;
  }, [src, userId]);

  const getBorder = useCallback(() => {
    if (!src) {
      return border;
    }
    return 1;
  }, [border, src]);

  return (
    <AvatarWrapper
      width={width}
      height={height}
    >
      <AvatarBackground
        className="avatar-outline"
        darkMode={darkMode}
      />
      <Image
        src={getAvatarUrl()}
        alt={userName ?? ''}
        fill={true}
      />
      <AvatarBorder
        className="avatar-border"
        border={getBorder()}
        withShadow={withShadow}
        darkMode={darkMode}
      />
    </AvatarWrapper>
  );
};

export default Avatar;
