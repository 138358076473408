import styled from '@emotion/styled';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { easeOutQuart } from 'common/easing/Easing';

interface Props {
  darkMode?: boolean;
}

export const ButtonWrapper = styled.div`
  button {
    transition:
      transform 0.25s ${easeOutQuart},
      box-shadow 0.25s ${easeOutQuart},
      background-color 0.25s ${easeOutQuart},
      border-color 0.25s ${easeOutQuart},
      color 0.25s ${easeOutQuart};
    [class^='icon-'],
    [class*=' icon-'] {
      transition: color 0.25s ${easeOutQuart};
    }

    @media (hover: hover) {
      &:hover {
        background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
        border-color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
        color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
        [class^='icon-'],
        [class*=' icon-'] {
          color: ${(props: Props) => (props.darkMode ? WHITE : GREY_6)};
        }
      }
    }
  }
`;
