import styled from '@emotion/styled';

interface Props {
  display?: 'block' | 'inline-block' | 'inline';
  color?: string;
  italic?: boolean;
  letterSpacing?: 'normal' | 'initial' | 'inherit' | string;
  lineHeight?: 'normal' | 'initial' | 'inherit' | string;
}

export const H6Styled = styled.h6`
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  display: ${(props: Props) => props.display};
  color: ${(props: Props) => props.color};
  font-style: ${(props: Props) => (props.italic ? 'italic' : 'normal')};
  letter-spacing: ${(props: Props) => props.letterSpacing};
  line-height: ${(props: Props) => props.lineHeight};
`;
