import { useQuery } from '@tanstack/react-query';
import api from 'api';
import { QUERY_KEYS } from 'common/data/Constants';

interface Options {
  enabled: boolean;
}

export const useSuggestions = (text: string, options: Options = { enabled: true }) => {
  return useQuery([QUERY_KEYS.SUGGESTIONS, { text }], () => api.suggestions.getSuggestionsSearch(text), options);
};
