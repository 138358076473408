import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { BLACK, GREY_2, GREY_6, WHITE } from 'design-system/global/colors';
import { easeInOutQuart } from 'common/easing/Easing';

interface Props {
  darkMode?: boolean;
  isOpen?: boolean;
  startClose?: boolean;
  modalHeight?: number;
  modalContainerOverFlowHidden?: boolean;
  windowOuterHeight?: number;
  extensionScrollY?: number;
}

const translateOpen: string = 'calc(-50% + 40px)';
const translateClose: string = '-50%';

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ModalScrollContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: ${(props: Props) =>
    props.modalHeight && props.modalHeight !== 0 ? `${props.modalHeight + 112}px` : 'auto'};
`;

export const Layout = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props: Props) => (props.darkMode ? BLACK : GREY_6)};
  opacity: ${(props: Props) => (props.isOpen ? 0.85 : 0)};
  transition: opacity 0.3s ${easeInOutQuart};
`;

export const ModalContainer = styled.div`
  position: absolute;
  overflow: ${(props: Props) => (props.modalContainerOverFlowHidden === false ? 'visible' : 'hidden')};
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    ${(props: Props) => (props.isOpen ? '-50%' : props.startClose ? translateClose : translateOpen)}
  );
  padding: 8px;
  border-radius: 8px;
  background-color: ${(props: Props) => (props.darkMode ? GREY_6 : WHITE)};
  border: 1px solid ${(props: Props) => (props.darkMode ? GREY_2 : GREY_6)};
  box-shadow: ${(props: Props) => (props.darkMode ? 'none' : `8px 8px 0px ${GREY_6}`)};
  opacity: ${(props: Props) => (props.isOpen ? 1 : 0)};
  transition:
    opacity 0.3s ${easeInOutQuart},
    transform 0.3s ${easeInOutQuart};

  // Position ModalContainer inside iframe for extension
  ${(props: Props) =>
    props.windowOuterHeight !== undefined &&
    props.extensionScrollY !== undefined &&
    css`
      /* 130px = margin-top: 90px + margin-bottom: 40px inside iframe */
      top: calc(0.5 * ${props.windowOuterHeight}px + ${props.extensionScrollY}px - 130px);
    `}
`;
