import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/social`;

/**
 * FOUNDRY
 */
export const getCountFollowersByFoundry = (idFoundry: string): Promise<API.Social.CountFollowersFoundry> => {
  return axios.get(`${baseUrl}/by-foundry/${idFoundry}/follower/count`).then(({ data }) => {
    return data;
  });
};

export const getListFollowersByFoundry = (
  idFoundry: string,
  pageSize: number,
  page: number
): Promise<API.Social.ListFollowingFoundry> => {
  return axios
    .get(`${baseUrl}/by-foundry/${idFoundry}/follower/list`, { params: { pageSize, page } })
    .then(({ data }) => {
      return data;
    });
};

export const getFollowFoundryStatus = (idFoundry: string): Promise<API.Social.FollowFoundryStatus> => {
  return axios.get(`${baseUrl}/by-foundry/${idFoundry}/isFollowed`).then(({ data }) => {
    return data;
  });
};

export const followFoundry = (idFoundry: string): Promise<API.Social.FollowFoundry> => {
  return axios.post(`${baseUrl}/by-foundry/${idFoundry}/follow`).then(({ data }) => {
    return data;
  });
};

export const unfollowFoundry = (idFoundry: string): Promise<API.Social.UnfollowFoundry> => {
  return axios.delete(`${baseUrl}/by-foundry/${idFoundry}/follow`).then(({ data }) => {
    return data;
  });
};

/**
 * USER
 */
export const getCountFollowersByUser = (idUser: number): Promise<API.Social.CountFollowersUser> => {
  return axios.get(`${baseUrl}/by-user/${idUser}/follower/count`).then(({ data }) => {
    return data;
  });
};

export const getListFollowersByUser = (
  idUser: number,
  pageSize: number,
  page: number
): Promise<API.Social.ListFollowersUser> => {
  return axios.get(`${baseUrl}/by-user/${idUser}/follower/list`, { params: { pageSize, page } }).then(({ data }) => {
    return data;
  });
};

export const getCountFollowingByUser = (idUser: number): Promise<API.Social.CountFollowingUser> => {
  return axios.get(`${baseUrl}/by-user/${idUser}/following/count`).then(({ data }) => {
    return data;
  });
};

export const getListFollowingByUser = (
  idUser: number,
  pageSize: number,
  page: number
): Promise<API.Social.ListFollowingUser> => {
  return axios.get(`${baseUrl}/by-user/${idUser}/following/list`, { params: { pageSize, page } }).then(({ data }) => {
    return data;
  });
};

export const getListFollowedFoundriesByUser = (
  idUser: number,
  pageSize: number,
  page: number
): Promise<API.Social.ListFollowedFoundriesUser> => {
  return axios
    .get(`${baseUrl}/by-user/${idUser}/following-foundry/list`, { params: { pageSize, page } })
    .then(({ data }) => {
      return data;
    });
};

export const getFollowUserStatus = (idUser: number): Promise<API.Social.FollowUserStatus> => {
  return axios.get(`${baseUrl}/by-user/${idUser}/isFollowed`).then(({ data }) => {
    return data;
  });
};

export const followUser = (idUser: number): Promise<API.Social.FollowUser> => {
  return axios.post(`${baseUrl}/by-user/${idUser}/follow`).then(({ data }) => {
    return data;
  });
};

export const unfollowUser = (idUser: number): Promise<API.Social.FollowUser> => {
  return axios.delete(`${baseUrl}/by-user/${idUser}/follow`).then(({ data }) => {
    return data;
  });
};

/**
 * PROFILE
 */
export const getSocialSummaryForCurrentUser = (): Promise<API.Social.UserSocialSummary> => {
  return axios.get(`${baseUrl}/summary`).then(({ data }) => {
    return data;
  });
};
