import axios from 'axios';
import { API_BASE_URL } from 'common/data/Api';

const baseUrl = `${API_BASE_URL}/board`;

// LIST BOARD EDITORS
export const getBoardListEditors = (
  idBoard: number,
  pageSize: number,
  page: number
): Promise<API.BoardEditors.Editors> => {
  return axios.get(`${baseUrl}/${idBoard}/editor/list`, { params: { pageSize, page } }).then(({ data }) => {
    return data;
  });
};

// INVITE BY EMAIL
export const inviteByEmail = (idBoard: number, email: string): Promise<API.BoardEditors.InviteByEmail> => {
  return axios.post(`${baseUrl}/${idBoard}/editor/invite/by-email`, { email }).then(({ data }) => {
    return data;
  });
};

// INVITE BY ID USER
export const inviteByIdUser = (idBoard: number, idUser: number): Promise<API.BoardEditors.InviteByIdUser> => {
  return axios.post(`${baseUrl}/${idBoard}/editor/invite/by-user`, { idUser }).then(({ data }) => {
    return data;
  });
};

// REMOVE A BOARD EDITOR
export const deleteBoardEditor = (idBoard: number, idUser: number): Promise<API.BoardEditors.DeleteBoardEditor> => {
  return axios.delete(`${baseUrl}/${idBoard}/editor/${idUser}`).then(({ data }) => {
    return data;
  });
};

// GET BOARD INVITATION CODE
export const getBoardInvitationCode = (idBoard: number): Promise<API.BoardEditors.BoardInvitationCode> => {
  return axios.get(`${baseUrl}/${idBoard}/invitation/code`).then(({ data }) => {
    return data;
  });
};

// VALIDATE BOARD INVITATION CODE
export const validateBoardInvitationCode = (
  invitationCode: string
): Promise<API.BoardEditors.ValidateInvitationCode> => {
  return axios.post(`${baseUrl}/invitation/${invitationCode}`).then(({ data }) => {
    return data;
  });
};

// SEARCH USERS TO INVITE
export const searchUsersToInvite = (
  idBoard: number,
  searchValue: string
): Promise<API.BoardEditors.SearchUsersToInvite> => {
  return axios.get(`${baseUrl}/${idBoard}/user-search/${searchValue}`).then(({ data }) => {
    return data;
  });
};
