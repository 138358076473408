import styled from '@emotion/styled';
import { easeOutExpo } from 'common/easing/Easing';

interface Props {
  visible: boolean;
}

export const MarkerContainer = styled.div`
  transform: translateY(${(props: Props) => (props.visible ? '0%' : '-100%')});
  transition: transform 0.25s ${easeOutExpo};
`;

export const MarkerWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 28px;
`;

export const MarkerShadow = styled.div`
  position: absolute;
  left: 2px;
  width: 20px;
  height: 29px;
`;
