export const initialDisplayNavPageAppearanceTooltip: boolean = false;

export const initialDisplayOptionsBarFilters: boolean = false;

export const initialSearchValue: string = '';

export const initialFontStyles = {
  serif: false,
  'triangle-serif': false,
  'slab-serif': false,
  'sans-serif': false,
  'hexagonal-sans': false,
  other: false,
};

export const initialMinStyles: number = 1;

export const initialContrast: number = -25;

export const initialWidth: number = -50;

export const initialEnding: number = 1;

export const initialTrialFonts: boolean = false;

export const initialMonospacedFonts: boolean = false;

export const initialPriceRange: Array<number> = [0, 125];

export const initialCurrency: string = 'USD';

export const initialDisplayMode: string = 'poster';

export const initialDisplayedWeight: number = 0;

export const initialDarkMode: boolean = false;

export const initialSingleFontAppearance = 'pangram';

export const initialSingleFontTextTransform = 'lowercase';

export const initialSingleFontItalic = undefined;
