import styled from '@emotion/styled';
import { DANGER_RED } from 'design-system/global/colors';
import { mq } from 'styles/mediaqueries.styled';

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 5px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 32px 16px;
  /* TABLET */
  ${mq['tablet']} {
    width: 407px;
    padding: 32px 40px 24px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Form = styled.form`
  position: relative;
`;

export const PasswordWrapper = styled.div`
  /* MOBILE */
  margin-bottom: 24px;
  /* TABLET */
  ${mq['tablet']} {
    margin-bottom: 32px;
  }
`;

export const GlobalError = styled.p`
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
  color: ${DANGER_RED};
`;
