import styled from '@emotion/styled';
import { mq } from 'styles/mediaqueries.styled';

export const ModalWrapper = styled.div`
  position: relative;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 1;
  /* MOBILE */
  top: -4px;
  right: -4px;
  /* TABLET */
  ${mq['tablet']} {
    top: 5px;
    right: 5px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  /* MOBILE */
  width: 329px;
  padding: 24px 16px 32px;
  /* TABLET */
  ${mq['tablet']} {
    width: 402px;
    padding: 24px 32px 32px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin: 0 0 22px -27px;
  /* MOBILE */
  width: 342px;
  height: 110px;
  /* TABLET */
  ${mq['tablet']} {
    width: 392px;
    height: 138px;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Description = styled.p`
  /* MOBILE */
  font-size: 16px;
  line-height: 18px;
  /* TABLET */
  ${mq['tablet']} {
    font-size: 14px;
    line-height: 16px;
  }
`;
