import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { AxiosError } from 'axios';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { H6 } from 'design-system/atoms/h6';
import { InputText } from 'design-system/atoms/input-text';
import { Button } from 'design-system/atoms/button';
import { GREY_6, WHITE } from 'design-system/global/colors';
import Modal from 'components/Modal';
import api from 'api';
import { useAuthContext, useModalContext, useSettingsContext } from 'context';
import { isValidEmail, isValidPassword } from 'common/utils';
import {
  CloseButtonWrapper,
  ContentWrapper,
  DescriptionWrapper,
  Form,
  InputCode,
  InputCodeWrapper,
  InputTextWrapper,
  ModalWrapper,
  TitleWrapper,
} from './PasswordRetrieval.styled';

interface InputValue {
  value: string;
  error: string;
}

const PasswordRetrieval = () => {
  const { t } = useTranslation('common');
  const { setTokens } = useAuthContext();
  const { darkMode } = useSettingsContext();
  const { openModalPasswordRetrieval, setOpenModalPasswordRetrieval } = useModalContext();

  const inputCode1Ref = useRef<HTMLInputElement>(null);
  const inputCode2Ref = useRef<HTMLInputElement>(null);
  const inputCode3Ref = useRef<HTMLInputElement>(null);
  const inputCode4Ref = useRef<HTMLInputElement>(null);
  const inputsCodesRef = useMemo(() => [inputCode1Ref, inputCode2Ref, inputCode3Ref, inputCode4Ref], []);

  const [startClose, setStartClose] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [email, setEmail] = useState<InputValue>({ value: '', error: '' });
  const [code, setCode] = useState<Array<number | ''>>(['', '', '', '']);
  const [codeError, setCodeError] = useState<boolean>(false);
  const [password, setPassword] = useState<InputValue>({ value: '', error: '' });

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalPasswordRetrieval(false);
  }, [setOpenModalPasswordRetrieval]);

  const callCheckRecoveryPassword = useCallback(
    (code: Array<number | ''>) => {
      // CALL API CHECK CODE RECOVERY PASSWORD
      api.authentication
        .checkRecoveryPassword(email.value, code.join(''))
        .then((response) => {
          if (response.data.success === true) {
            setStep(3);
          }
        })
        .catch((error: AxiosError<API.Error>) => {
          setCodeError(true);
        });
    },
    [email.value]
  );

  const handleChangeEmail = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setEmail({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleSubmitStep1 = useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (isValidEmail(email.value)) {
        setDisabled(true);
        setEmail({ value: email.value, error: '' });
        // CALL API INIT RECOVERY PASSWORD
        api.authentication
          .initRecoveryPassword(email.value)
          .then((response) => {
            if (response.data.success === true) {
              setStep(2);
              setDisabled(false);
            }
          })
          .catch((error: AxiosError<API.Error>) => {
            const cloneEmail = { ...email };
            if (error.response?.data.code === 40031) {
              cloneEmail.error = t('errors.email-error');
            } else if (error.response?.data.code === 40400) {
              cloneEmail.error = t('errors.email-not-exist');
            } else {
              cloneEmail.error = t('errors.generic');
            }
            setEmail(cloneEmail);
            setDisabled(false);
          });
      } else {
        setEmail({ value: email.value, error: t('errors.email-error') });
      }
    },
    [email, t]
  );

  const handleKeyUpCode = useCallback(
    (event: KeyboardEvent, index: number) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        const cloneCode = [...code];
        if (cloneCode[index] === '') {
          let prevIndex = index - 1;
          if (prevIndex < 0) prevIndex = 0;
          inputsCodesRef[prevIndex].current?.focus();
          cloneCode[prevIndex] = '';
        } else {
          cloneCode[index] = '';
        }
        setCode(cloneCode);
        setCodeError(false);
      } else if (event.key === 'ArrowLeft') {
        let prevIndex = index - 1;
        if (prevIndex < 0) prevIndex = 0;
        inputsCodesRef[prevIndex].current?.focus();
      } else if (event.key === 'ArrowRight') {
        let nextIndex = index + 1;
        if (nextIndex > code.length - 1) nextIndex = code.length - 1;
        inputsCodesRef[nextIndex].current?.focus();
      } else if (isFinite(event.key as any)) {
        if (code[index] !== '') {
          const cloneCode = [...code];
          cloneCode[index] = parseInt(event.key);
          setCode(cloneCode);
        }
      }
    },
    [code, inputsCodesRef]
  );

  const handleChangeCode = useCallback(
    (event: SyntheticEvent<HTMLInputElement>, index: number) => {
      const digit = event.currentTarget.value.replace(/[^\d]/, '');
      if (digit === '') return;

      setCodeError(false);

      const cloneCode = [...code];
      cloneCode[index] = parseInt(digit);
      setCode(cloneCode);

      const nextIndex = index + 1;
      if (nextIndex < code.length) {
        inputsCodesRef[nextIndex].current?.focus();
      }

      let nbDigitFilled = 0;
      cloneCode.forEach((c) => {
        if (c !== '') {
          nbDigitFilled += 1;
        }
      });
      if (nbDigitFilled === cloneCode.length) {
        callCheckRecoveryPassword(cloneCode);
      }
    },
    [callCheckRecoveryPassword, code, inputsCodesRef]
  );

  const handlePasteCode = useCallback(
    (e: ClipboardEvent) => {
      if (e.clipboardData) {
        const clipboardData = e.clipboardData.getData('Text');
        let isFocus: boolean = false;
        inputsCodesRef.forEach((codeRef, index) => {
          if (codeRef.current === document.activeElement) {
            isFocus = true;
          }
        });
        if (isFocus) {
          const cloneCode = [...code];
          cloneCode.forEach((c, index) => {
            const code = parseInt(clipboardData.split('')[index]);
            if (isNaN(code)) {
              cloneCode[index] = '';
            } else {
              cloneCode[index] = code;
            }
          });
          setTimeout(() => {
            setCode(cloneCode);
            setCodeError(false);
            let nbDigit = 0;
            cloneCode.forEach((c) => {
              if (typeof c === 'number') nbDigit += 1;
            });
            if (nbDigit === cloneCode.length) {
              callCheckRecoveryPassword(cloneCode);
            }
          }, 0);
        }
      }
    },
    [callCheckRecoveryPassword, code, inputsCodesRef]
  );

  const handleChangePassword = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setPassword({ value: event.currentTarget.value, error: '' });
  }, []);

  const handleSubmitStep3 = useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (isValidPassword(password.value)) {
        setDisabled(true);
        setPassword({ value: password.value, error: '' });
        // CALL API UPDATE PASSWORD RECOVERY PASSWORD
        api.authentication
          .updateRecoveryPassword(email.value, code.join(''), password.value)
          .then((response) => {
            setTokens(response.data);
            setStartClose(true);
            setDisabled(false);
          })
          .catch((error: AxiosError<API.Error>) => {
            const clonePassword = { ...password };
            if (error.response?.data.code === 40031) {
              clonePassword.error = t('errors.email-error');
            } else {
              clonePassword.error = t('errors.generic');
            }
            setPassword(clonePassword);
            setDisabled(false);
          });
      } else {
        setPassword({ value: password.value, error: t('errors.password-error') });
      }
    },
    [code, email.value, password, setTokens, t]
  );

  useEffect(() => {
    if (!openModalPasswordRetrieval) {
      setDisabled(false);
      setStep(1);
      setEmail({ value: '', error: '' });
      setCode(['', '', '', '']);
      setCodeError(false);
      setPassword({ value: '', error: '' });
    }
  }, [openModalPasswordRetrieval]);

  useEffect(() => {
    if (step === 2) {
      document.addEventListener('paste', handlePasteCode);
    }

    return () => {
      if (step === 2) {
        document.removeEventListener('paste', handlePasteCode);
      }
    };
  }, [handlePasteCode, step]);

  const renderStep1 = () => {
    return (
      <>
        <TitleWrapper>
          <H3
            as="p"
            color={darkMode ? WHITE : GREY_6}
          >
            {t('password-retrieval.step-1.title')}
          </H3>
        </TitleWrapper>
        <DescriptionWrapper>
          <H6
            as="p"
            color={darkMode ? WHITE : GREY_6}
          >
            {t('password-retrieval.step-1.description')}
          </H6>
        </DescriptionWrapper>
        <Form onSubmit={handleSubmitStep1}>
          <InputTextWrapper>
            <InputText
              type="email"
              placeholder={t('password-retrieval.step-1.email')}
              value={email.value}
              error={email.error}
              darkMode={darkMode}
              onChange={handleChangeEmail}
            />
          </InputTextWrapper>
          <Button
            disabled={disabled}
            darkMode={darkMode}
          >
            {t('password-retrieval.step-1.reset-password')}
          </Button>
        </Form>
      </>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <TitleWrapper>
          <H3
            as="p"
            color={darkMode ? WHITE : GREY_6}
          >
            {t('password-retrieval.step-2.title')}
          </H3>
        </TitleWrapper>
        <DescriptionWrapper>
          <H6
            as="p"
            color={darkMode ? WHITE : GREY_6}
          >
            {t('password-retrieval.step-2.description')}
          </H6>
        </DescriptionWrapper>
        <InputCodeWrapper
          codeError={codeError}
          darkMode={darkMode}
        >
          {code.map((c, index) => (
            <InputCode
              key={index}
              ref={inputsCodesRef[index]}
              type="text"
              placeholder="0"
              value={code[index]}
              maxLength={1}
              pattern="\d*"
              darkMode={darkMode}
              {...(index === 0 && { autoFocus: true })}
              onKeyUp={(event) => handleKeyUpCode(event as unknown as KeyboardEvent, index)}
              onChange={(event) => handleChangeCode(event, index)}
            />
          ))}
        </InputCodeWrapper>
      </>
    );
  };

  const renderStep3 = () => {
    return (
      <>
        <TitleWrapper>
          <H3
            as="p"
            color={darkMode ? WHITE : GREY_6}
          >
            {t('password-retrieval.step-3.title')}
          </H3>
        </TitleWrapper>
        <DescriptionWrapper>
          <H6
            as="p"
            color={darkMode ? WHITE : GREY_6}
          >
            {t('password-retrieval.step-3.description')}
          </H6>
        </DescriptionWrapper>
        <Form onSubmit={handleSubmitStep3}>
          <InputTextWrapper>
            <InputText
              type="password"
              placeholder={t('sign-in.password')}
              value={password.value}
              error={password.error}
              darkMode={darkMode}
              autoFocus
              onChange={handleChangePassword}
            />
          </InputTextWrapper>
          <Button
            disabled={disabled}
            darkMode={darkMode}
          >
            {t('password-retrieval.step-3.create-new-password')}
          </Button>
        </Form>
      </>
    );
  };

  if (openModalPasswordRetrieval) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <ContentWrapper>
            {step === 1 && renderStep1()}
            {step === 2 && renderStep2()}
            {step === 3 && renderStep3()}
          </ContentWrapper>
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default PasswordRetrieval;
