import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { trackFontClicked } from 'tracking/Mixpanel';
import { useAuthContext, useModalContext, useSettingsContext } from 'context';
import { AddBookmarkToBoardOptions } from 'hooks';
import { useBoard } from 'hooks/queries/useBoard';
import BareBone from './BareBone';
import FontInUse from './FontInUse';
import Poster from './Poster';

interface Props {
  index: number;
  family: API.Family;
  page?: number;
  scaleValue?: number;
  forceLoading?: boolean;
  source?: string;
  onCardHeight?: (cardHeight: number) => void;
}

const Card = ({ index, family, page, scaleValue, forceLoading, source, onCardHeight }: Props) => {
  const router = useRouter();
  const { isLoggedIn, setBookmarkNotLoggedCacheData } = useAuthContext();
  const { displayMode } = useSettingsContext();
  const { setOpenModalAddBookmarkToBoard, setOpenModalSignInUp } = useModalContext();

  // Do a copy of router.prefetch in order to disable/enable prefetch
  const saveRouterPrefetch = useMemo(() => router.prefetch, [router.prefetch]);

  const cardRef = useRef<HTMLDivElement>(null);

  const { useUpdateCurrentFontWeightBookmarkInBoard } = useBoard();
  const updateCurrentFontWeightBookmarkInBoardMutation = useUpdateCurrentFontWeightBookmarkInBoard();

  const handleClickBookmarkButton = useCallback(
    (idFont: number) => {
      const bookmarkOptions: AddBookmarkToBoardOptions = {
        idFamily: family.idFamily,
        idFont: idFont,
        familyName: family.name,
        page: page,
        source: source,
      };
      const currentFont = family.fonts.find((font) => font.idFont === idFont);
      const fontWeight = currentFont?.weight;
      if (fontWeight) {
        bookmarkOptions.fontWeight = fontWeight;
      }
      if (isLoggedIn) {
        setOpenModalAddBookmarkToBoard(bookmarkOptions);
      } else {
        setBookmarkNotLoggedCacheData(bookmarkOptions);
        setOpenModalSignInUp('signUp');
      }
    },
    [
      family.fonts,
      family.idFamily,
      family.name,
      isLoggedIn,
      page,
      setBookmarkNotLoggedCacheData,
      setOpenModalAddBookmarkToBoard,
      setOpenModalSignInUp,
      source,
    ]
  );

  const handleClickFontLink = useCallback(
    (idFont: number) => {
      const currentFont = family.fonts.find((font) => font.idFont === idFont);
      const fontWeight = currentFont?.weight;
      // Track Mixpanel Font clicked
      trackFontClicked(source ?? '', family.name, fontWeight ?? '');
    },
    [family.fonts, family.name, source]
  );

  const disablePrefetch = useCallback(() => {
    router.prefetch = async () => {};
  }, [router]);

  const enablePrefetch = useCallback(() => {
    router.prefetch = saveRouterPrefetch;
  }, [router, saveRouterPrefetch]);

  // Disable prefetch when user roll-over card
  const handleMouseEnterCard = useCallback(() => {
    disablePrefetch();
  }, [disablePrefetch]);

  // Enable back prefetch when user roll-out card
  const handleMouseLeaveCard = useCallback(() => {
    enablePrefetch();
  }, [enablePrefetch]);

  const handleSaveCurrentFontWeightInBoard = useCallback(
    (idBoard: number, idFamily: string, idFont: number) => {
      if (isLoggedIn) {
        updateCurrentFontWeightBookmarkInBoardMutation.mutate({ idBoard, idFamily, idFont });
      }
    },
    [isLoggedIn, updateCurrentFontWeightBookmarkInBoardMutation]
  );

  const getCardHeight = useCallback(() => {
    if (index === 0 && onCardHeight && cardRef.current) {
      onCardHeight(cardRef.current.getBoundingClientRect().height >> 1);
    }
  }, [index, onCardHeight]);

  useEffect(() => {
    getCardHeight();
  }, [displayMode, getCardHeight]);

  useEffect(() => {
    getCardHeight();
    window.addEventListener('resize', getCardHeight);
    return () => {
      window.removeEventListener('resize', getCardHeight);
    };
  }, [getCardHeight]);

  return (
    <div
      ref={cardRef}
      onMouseEnter={handleMouseEnterCard}
      onMouseLeave={handleMouseLeaveCard}
    >
      {(displayMode === 'typeface name' || displayMode === 'pangram' || displayMode === 'pangram debug') && (
        <BareBone
          index={index}
          family={family}
          scaleValue={scaleValue}
          forceLoading={forceLoading}
          onClickBookmarkButton={handleClickBookmarkButton}
          onClickFontLink={handleClickFontLink}
          onSaveCurrentFontWeightInBoard={handleSaveCurrentFontWeightInBoard}
        />
      )}
      {displayMode === 'font in use' && (
        <FontInUse
          index={index}
          family={family}
          scaleValue={scaleValue}
          forceLoading={forceLoading}
          onClickBookmarkButton={handleClickBookmarkButton}
          onClickFontLink={handleClickFontLink}
          onSaveCurrentFontWeightInBoard={handleSaveCurrentFontWeightInBoard}
        />
      )}
      {(displayMode === 'poster' || displayMode === 'colored poster') && (
        <Poster
          index={index}
          family={family}
          scaleValue={scaleValue}
          forceLoading={forceLoading}
          onClickBookmarkButton={handleClickBookmarkButton}
          onClickFontLink={handleClickFontLink}
          onSaveCurrentFontWeightInBoard={handleSaveCurrentFontWeightInBoard}
        />
      )}
    </div>
  );
};

export default Card;
