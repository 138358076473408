import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import { AxiosError } from 'axios';
import jwt from 'jsonwebtoken';
import { IconButton } from 'design-system/atoms/icon-button';
import { H3 } from 'design-system/atoms/h3';
import { H6 } from 'design-system/atoms/h6';
import { Button } from 'design-system/atoms/button';
import { InputText } from 'design-system/atoms/input-text';
import { GREY_6, WHITE } from 'design-system/global/colors';
import {
  ConnectionType,
  trackLoginCompleted,
  trackLoginStarted,
  trackSignupCompleted,
  trackSignupStarted,
} from 'tracking/Mixpanel';
import { AccessTokenData } from 'hooks';
import Modal from 'components/Modal';
import { useAuthContext, useModalContext, useSettingsContext } from 'context';
import api from 'api';
import { getPageNameWithRoutePathname, isValidEmail, isValidPassword } from 'common/utils';
import {
  ButtonsWrapperSignIn,
  CloseButtonWrapper,
  ContentWrapper,
  DescriptionWrapperSignUp,
  ForgetPasswordLink,
  Form,
  GenericError,
  GoogleSignInWrapper,
  GreenButton,
  InputTextWrapper,
  ModalWrapper,
  Or,
  TitleWrapperSignIn,
  TitleWrapperSignUp,
  Video,
  VideoWrapper,
} from './SignInUp.styled';

interface InputValue {
  value: string;
  error: string;
}

const SignInUp = () => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const {
    setTokens,
    bookmarkNotLoggedCacheData,
    setBookmarkNotLoggedCacheData,
    setFollowFoundryIdNotLogged,
    setFollowUserIdNotLogged,
    openModalFollowNotLogged,
    setOpenModalFollowNotLogged,
  } = useAuthContext();
  const { darkMode, machineId, setMachineId } = useSettingsContext();
  const {
    openModalSignInUp,
    setOpenModalSignInUp,
    setOpenModalPasswordRetrieval,
    setOpenModalAddBookmarkToBoard,
    setOpenModalFollowers,
    setOpenModalFollowing,
    setOpenModalFollowingFoundry,
  } = useModalContext();

  const themeMode = useMemo(() => (darkMode ? 'dark' : 'light'), [darkMode]);

  const trackSignUp = useCallback((connectionType: ConnectionType, token: string) => {
    const date = new Date();
    const createdAt = `${date.toISOString().slice(0, 10)} ${date.toISOString().slice(11, 19)}`;
    const tokenData = jwt.decode(token) as unknown as AccessTokenData;
    // Track Mixpanel sign-up completed
    trackSignupCompleted(connectionType, tokenData.email, createdAt, tokenData.id);
  }, []);

  const trackSignIn = useCallback((connectionType: ConnectionType) => {
    // Track Mixpanel login completed
    trackLoginCompleted(connectionType);
  }, []);

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (tokenResponse) => {
      setEmail({ value: email.value, error: '' });
      setPassword({ value: password.value, error: '' });
      setGenericError('');
      api.authentication
        .googleAuth(tokenResponse.code, machineId)
        .then((response) => {
          if (openModalSignInUp === 'signUp') {
            trackSignUp('google_connect', response.data.accessToken);
          } else if (openModalSignInUp === 'signIn') {
            trackSignIn('google_connect');
          }
          setTokens(response.data);
          setStartClose(true);
          // Empty machineId if exists
          if (machineId) {
            setMachineId(undefined);
          }
          // Check bookmark cache data
          if (bookmarkNotLoggedCacheData) {
            setOpenModalAddBookmarkToBoard(bookmarkNotLoggedCacheData);
          }
        })
        .catch((error: AxiosError<API.Error>) => {
          if (error.response?.data.code === 40033) {
            setGenericError(t('errors.account-exists'));
          } else {
            setGenericError(t('errors.generic'));
          }
        });
    },
    onError: () => {
      setGenericError(t('errors.generic'));
    },
  });

  const [startClose, setStartClose] = useState<boolean>(false);
  const [email, setEmail] = useState<InputValue>({ value: '', error: '' });
  const [password, setPassword] = useState<InputValue>({ value: '', error: '' });
  const [disabled, setDisabled] = useState<boolean>(false);
  const [genericError, setGenericError] = useState<string>('');

  const handleClose = useCallback(() => {
    setStartClose(true);
  }, []);

  const handleCloseComplete = useCallback(() => {
    setStartClose(false);
    setOpenModalSignInUp(null);
  }, [setOpenModalSignInUp]);

  const handleChangeEmail = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setEmail({ value: event.currentTarget.value, error: '' });
    setGenericError('');
  }, []);

  const handleChangePassword = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    setPassword({ value: event.currentTarget.value, error: '' });
    setGenericError('');
  }, []);

  const isEmailValid = useCallback((): boolean => {
    let nbError = 0;
    if (!isValidEmail(email.value)) {
      setEmail({ value: email.value, error: t('errors.email-error') });
      nbError += 1;
    } else {
      setEmail({ value: email.value, error: '' });
    }
    return nbError > 0 ? false : true;
  }, [email.value, t]);

  const isEmailPasswordValid = useCallback((): boolean => {
    let nbError = 0;
    if (!isValidEmail(email.value)) {
      setEmail({ value: email.value, error: t('errors.email-error') });
      nbError += 1;
    } else {
      setEmail({ value: email.value, error: '' });
    }
    if (!isValidPassword(password.value)) {
      setPassword({ value: password.value, error: t('errors.password-error') });
      nbError += 1;
    } else {
      setPassword({ value: password.value, error: '' });
    }
    return nbError > 0 ? false : true;
  }, [email.value, password.value, t]);

  const openFollowModalWhenNotLoggedIn = useCallback(() => {
    if (openModalFollowNotLogged) {
      switch (openModalFollowNotLogged.type) {
        case 'followers':
          setOpenModalFollowers({
            idUser: openModalFollowNotLogged.id as number,
            count: openModalFollowNotLogged.count,
          });
          break;
        case 'following':
          setOpenModalFollowing({
            idUser: openModalFollowNotLogged.id as number,
            count: openModalFollowNotLogged.count,
          });
          break;
        case 'FollowingFoundry':
          setOpenModalFollowingFoundry({
            idFoundry: openModalFollowNotLogged.id as string,
            count: openModalFollowNotLogged.count,
          });
          break;
      }
    }
  }, [openModalFollowNotLogged, setOpenModalFollowers, setOpenModalFollowing, setOpenModalFollowingFoundry]);

  const handleSubmitSignIn = useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      setGenericError('');
      if (isEmailValid()) {
        setDisabled(true);
        // CALL API SIGNIN
        api.authentication
          .signIn(email.value, password.value, machineId)
          .then((response) => {
            trackSignIn('email');
            setTokens(response.data);
            setStartClose(true);
            // Empty machineId if exists
            if (machineId) {
              setMachineId(undefined);
            }
            // Check bookmark cache data
            if (bookmarkNotLoggedCacheData) {
              setOpenModalAddBookmarkToBoard(bookmarkNotLoggedCacheData);
            }
            // Check if need to open a Follow modal
            if (openModalFollowNotLogged) {
              openFollowModalWhenNotLoggedIn();
            }
            // Safari fix, to propose credentials window before changing page
            router.replace(window.location.pathname);
          })
          .catch((error: AxiosError<API.Error>) => {
            const cloneEmail = { ...email };
            const clonePassword = { ...password };
            if (error.response?.data.code === 40031) {
              cloneEmail.error = t('errors.email-error');
              setEmail(cloneEmail);
            } else if (error.response?.data.code === 40032) {
              clonePassword.error = t('errors.password-error');
              setPassword(clonePassword);
            } else if (error.response?.data.code === 40131) {
              clonePassword.error = t('errors.bad-credentials');
              setPassword(clonePassword);
            } else if (error.response?.data.code === 40100 || error.response?.data.code === 40400) {
              setGenericError(t('errors.bad-credentials'));
            } else {
              setGenericError(t('errors.generic'));
            }
            setDisabled(false);
          });
      }
    },
    [
      bookmarkNotLoggedCacheData,
      email,
      isEmailValid,
      machineId,
      openFollowModalWhenNotLoggedIn,
      openModalFollowNotLogged,
      password,
      router,
      setMachineId,
      setOpenModalAddBookmarkToBoard,
      setTokens,
      t,
      trackSignIn,
    ]
  );

  const handleSubmitSignUp = useCallback(
    (event: SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      setGenericError('');
      if (isEmailPasswordValid()) {
        setDisabled(true);
        // CALL API SIGNUP
        api.authentication
          .signUp(email.value, password.value, machineId)
          .then((response) => {
            trackSignUp('email', response.data.accessToken);
            setTokens(response.data);
            setStartClose(true);
            // Empty machineId if exists
            if (machineId) {
              setMachineId(undefined);
            }
            // Check bookmark cache data
            if (bookmarkNotLoggedCacheData) {
              setOpenModalAddBookmarkToBoard(bookmarkNotLoggedCacheData);
            }
            // Check if need to open a Follow modal
            if (openModalFollowNotLogged) {
              openFollowModalWhenNotLoggedIn();
            }
            // Safari fix, to propose credentials window before changing page
            router.replace(window.location.pathname);
          })
          .catch((error: AxiosError<API.Error>) => {
            const cloneEmail = { ...email };
            const clonePassword = { ...password };
            if (error.response?.data.code === 40031) {
              cloneEmail.error = t('errors.email-error');
              setEmail(cloneEmail);
            } else if (error.response?.data.code === 40032) {
              clonePassword.error = t('errors.password-error');
              setPassword(clonePassword);
            } else if (error.response?.data.code === 40033) {
              cloneEmail.error = t('errors.account-exists');
              setEmail(cloneEmail);
            } else {
              setGenericError(t('errors.generic'));
            }
            setDisabled(false);
          });
      }
    },
    [
      bookmarkNotLoggedCacheData,
      email,
      isEmailPasswordValid,
      machineId,
      openFollowModalWhenNotLoggedIn,
      openModalFollowNotLogged,
      password,
      router,
      setMachineId,
      setOpenModalAddBookmarkToBoard,
      setTokens,
      t,
      trackSignUp,
    ]
  );

  const goToSignIn = useCallback(() => {
    setEmail({ value: '', error: '' });
    setPassword({ value: '', error: '' });
    setDisabled(false);
    setGenericError('');
    setOpenModalSignInUp('signIn');
  }, [setOpenModalSignInUp]);

  const goToSignUp = useCallback(() => {
    setEmail({ value: '', error: '' });
    setPassword({ value: '', error: '' });
    setDisabled(false);
    setGenericError('');
    setOpenModalSignInUp('signUp');
  }, [setOpenModalSignInUp]);

  const goToForgetPassword = useCallback(
    (event: SyntheticEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      handleClose();
      setOpenModalPasswordRetrieval(true);
    },
    [handleClose, setOpenModalPasswordRetrieval]
  );

  useEffect(() => {
    if (openModalSignInUp === 'signIn') {
      // Track Mixpanel Login started
      trackLoginStarted(getPageNameWithRoutePathname(router.pathname));
    } else if (openModalSignInUp === 'signUp') {
      // Track Mixpanel sign-up started
      trackSignupStarted(getPageNameWithRoutePathname(router.pathname));
    }
  }, [openModalSignInUp, router.pathname, router.query]);

  useEffect(() => {
    if (!openModalSignInUp) {
      setEmail({ value: '', error: '' });
      setPassword({ value: '', error: '' });
      setDisabled(false);
      setGenericError('');
      setBookmarkNotLoggedCacheData(null);
      setFollowFoundryIdNotLogged(null);
      setFollowUserIdNotLogged(null);
      setOpenModalFollowNotLogged(null);
    }
  }, [
    openModalSignInUp,
    setBookmarkNotLoggedCacheData,
    setFollowFoundryIdNotLogged,
    setFollowUserIdNotLogged,
    setOpenModalFollowNotLogged,
  ]);

  const renderSignIn = () => {
    return (
      <>
        <ContentWrapper>
          <TitleWrapperSignIn>
            <H3
              as="p"
              color={darkMode ? WHITE : GREY_6}
            >
              {t('sign-in.title')}
            </H3>
          </TitleWrapperSignIn>
          <GoogleSignInWrapper>
            <Button
              iconLabel="google"
              darkMode={darkMode}
              onClick={() => login()}
            >
              {t('sign-in.sign-in-google')}
            </Button>
          </GoogleSignInWrapper>
          <Or>{t('sign-in.or')}</Or>
          <Form onSubmit={handleSubmitSignIn}>
            <InputTextWrapper>
              <InputText
                type="email"
                placeholder={t('sign-in.email')}
                value={email.value}
                error={email.error}
                darkMode={darkMode}
                onChange={handleChangeEmail}
              />
            </InputTextWrapper>
            <InputTextWrapper>
              <InputText
                type="password"
                placeholder={t('sign-in.password')}
                value={password.value}
                error={password.error}
                darkMode={darkMode}
                onChange={handleChangePassword}
              />
              {genericError && <GenericError>{genericError}</GenericError>}
            </InputTextWrapper>
            <ButtonsWrapperSignIn>
              <Button
                disabled={disabled}
                darkMode={darkMode}
              >
                {t('sign-in.sign-in')}
              </Button>
              <ForgetPasswordLink
                href="#"
                darkMode={darkMode}
                onClick={goToForgetPassword}
              >
                {t('sign-in.forget-password')}
              </ForgetPasswordLink>
            </ButtonsWrapperSignIn>
          </Form>
        </ContentWrapper>
        <GreenButton
          darkMode={darkMode}
          onClick={goToSignUp}
        >
          {t('sign-in.no-account')}
        </GreenButton>
      </>
    );
  };

  const renderSignUp = () => {
    return (
      <>
        <ContentWrapper>
          <TitleWrapperSignUp>
            <H3
              as="p"
              color={darkMode ? WHITE : GREY_6}
            >
              {!machineId ? t('sign-up.title') : t('sign-up.title-with-machine-id')}
            </H3>
          </TitleWrapperSignUp>
          <DescriptionWrapperSignUp>
            <H6
              as="p"
              color={darkMode ? WHITE : GREY_6}
              dangerouslySetInnerHTML={{
                __html: !machineId ? t('sign-up.description') : t('sign-up.description-with-machine-id'),
              }}
            />
          </DescriptionWrapperSignUp>
          <GoogleSignInWrapper>
            <Button
              iconLabel="google"
              darkMode={darkMode}
              onClick={() => login()}
            >
              {t('sign-up.sign-in-google')}
            </Button>
          </GoogleSignInWrapper>
          <Or>{t('sign-up.or')}</Or>
          <Form onSubmit={handleSubmitSignUp}>
            <InputTextWrapper>
              <InputText
                type="email"
                placeholder={t('sign-in.email')}
                value={email.value}
                error={email.error}
                darkMode={darkMode}
                onChange={handleChangeEmail}
              />
            </InputTextWrapper>
            <InputTextWrapper>
              <InputText
                type="password"
                placeholder={t('sign-in.password')}
                value={password.value}
                error={password.error}
                darkMode={darkMode}
                onChange={handleChangePassword}
              />
              {genericError && <GenericError>{genericError}</GenericError>}
            </InputTextWrapper>
            <Button
              disabled={disabled}
              darkMode={darkMode}
            >
              {t('sign-up.sign-up')}
            </Button>
          </Form>
        </ContentWrapper>
        <GreenButton
          darkMode={darkMode}
          onClick={goToSignIn}
        >
          {t('sign-up.have-account')}
        </GreenButton>
      </>
    );
  };

  if (openModalSignInUp) {
    return (
      <Modal
        darkMode={darkMode}
        layoutClosable={true}
        startClose={startClose}
        onClose={handleClose}
        onCloseComplete={handleCloseComplete}
      >
        <ModalWrapper>
          <CloseButtonWrapper>
            <IconButton
              iconLabel="close_big"
              iconSize="14px"
              buttonSize="40px"
              color={darkMode ? WHITE : GREY_6}
              onClick={handleClose}
            />
          </CloseButtonWrapper>
          <VideoWrapper>
            <Video
              src={`/videos/bookmark-${themeMode}.mp4`}
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline
            />
          </VideoWrapper>
          {openModalSignInUp === 'signIn' && renderSignIn()}
          {openModalSignInUp === 'signUp' && renderSignUp()}
        </ModalWrapper>
      </Modal>
    );
  }
  return null;
};

export default SignInUp;
