import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GREY_5, WHITE } from 'design-system/global/colors';

interface LabelProps {
  darkMode?: boolean;
  color?: string;
}

interface CheckboxWrapperProps {
  hasMarginRight?: boolean;
}

interface CheckboxSelectorProps {
  checked: boolean;
}

const BREAKPOINT_TABLET = 744;

const normalModeStyle = css`
  color: ${GREY_5};
  > .checkbox-wrapper {
    border: 1px solid ${GREY_5};
    > .checkbox-selector {
      background-color: ${GREY_5};
    }
  }
`;

const darkModeStyle = css`
  color: ${WHITE};
  > .checkbox-wrapper {
    border: 1px solid ${WHITE};
    > .checkbox-selector {
      background-color: ${WHITE};
    }
  }
`;

const colorModeStyle = (props: LabelProps) => css`
  color: ${props.color};
  > .checkbox-wrapper {
    border: 1px solid ${props.color};
    > .checkbox-selector {
      background-color: ${props.color};
    }
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  font-family: 'FontsNinja-Aeonik', Arial, sans-serif;
  /* MOBILE */
  font-size: 16px;
  line-height: 18px;
  /* TABLET */
  @media (min-width: ${BREAKPOINT_TABLET}px) {
    font-size: 14px;
    line-height: 16px;
  }
  cursor: pointer;
  // NOT DARK MODE
  ${(props: LabelProps) =>
    !props.darkMode &&
    !props.color &&
    css`
      ${normalModeStyle}
    `}
  // DARK MODE
  ${(props: LabelProps) =>
    props.darkMode &&
    !props.color &&
    css`
      ${darkModeStyle}
    `}
  // COLOR MODE
  ${(props: LabelProps) =>
    props.color &&
    css`
      ${colorModeStyle(props)}
    `}
`;

export const CheckboxWrapper = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  box-sizing: border-box;
  ${(props: CheckboxWrapperProps) =>
    props.hasMarginRight &&
    css`
      margin-right: 6px;
    `}
`;

export const CheckboxSelector = styled.div`
  display: ${(props: CheckboxSelectorProps) => (props.checked ? 'block' : 'none')};
  position: relative;
  width: 8px;
  height: 8px;
  top: 2px;
  left: 2px;
  border-radius: 1px;
`;

export const Input = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
