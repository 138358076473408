import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Logo } from 'design-system/atoms/logo';
import { Button } from 'design-system/atoms/button';
import { Icon } from 'design-system/atoms/icon';
import { GREY_6, WHITE } from 'design-system/global/colors';
import { trackLoggedOut } from 'tracking/Mixpanel';
import { useAuthContext, useBreakpointContext, useModalContext, useSettingsContext } from 'context';
import NavSearchPageAppearance from 'containers/NavSearchPageAppearance';
import NavProfileDesktop from 'containers/NavProfileDesktop';
import { transformStringToUrl } from 'common/utils';
import { PAGE_NAMES, ROUTES, USER_PAGE } from 'common/data/Constants';
import {
  Beta,
  BurgerButton,
  BurgerButtonBar,
  Layout,
  LI,
  LoggedInWrapper,
  LogoWrapper,
  NavLogo,
  navPaddingTop,
  navStickyPaddingTop,
  NavStyled,
  NavWrapper,
  // NotifDesktopWrapper,
  Separator,
  SignInUpWrapper,
  UL,
} from './Nav.styled';

const Nav = () => {
  const { t } = useTranslation('nav');
  const { authReady, isLoggedIn, userId, userName, logout } = useAuthContext();
  const { breakpointsReady, isMobile, isTablet } = useBreakpointContext();
  const { darkMode } = useSettingsContext();
  const { setOpenModalSignInUp } = useModalContext();

  const router = useRouter();
  const { isBot } = router.query;
  const isBotStr = isBot === undefined ? 'true' : (isBot as string);

  const routeUserId = useMemo(() => {
    const userQuery = typeof router.query?.userQuery === 'string' ? router.query.userQuery : '';
    const userQuerySplit = userQuery.split('-');
    const userId = userQuerySplit[userQuerySplit.length - 1] as unknown as number;
    return userId;
  }, [router.query.userQuery]);

  const [burgerMenuOpen, setBurgerMenuOpen] = useState<boolean>(false);
  const [stickyNavBar, setStickyNavBar] = useState<boolean>(false);

  const toggleBurgerMenu = useCallback(() => {
    setBurgerMenuOpen(!burgerMenuOpen);
  }, [burgerMenuOpen]);

  const handleScroll = useCallback(
    (event: Event) => {
      const target = event.target as Document;
      if (isMobile || isTablet) {
        if (!stickyNavBar && target.scrollingElement && target.scrollingElement.scrollTop > 0) {
          setStickyNavBar(true);
        } else if (stickyNavBar && target.scrollingElement && target.scrollingElement.scrollTop <= 0) {
          setStickyNavBar(false);
        }
      } else {
        if (
          !stickyNavBar &&
          target.scrollingElement &&
          target.scrollingElement.scrollTop > navPaddingTop - navStickyPaddingTop
        ) {
          setStickyNavBar(true);
        } else if (
          stickyNavBar &&
          target.scrollingElement &&
          target.scrollingElement.scrollTop <= navPaddingTop - navStickyPaddingTop
        ) {
          setStickyNavBar(false);
        }
      }
    },
    [isMobile, isTablet, stickyNavBar]
  );

  const handleClickLink = useCallback(() => {
    setBurgerMenuOpen(false);
  }, []);

  const handleLogout = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      logout();
      handleClickLink();
      // Track Mixpanel Logout
      trackLoggedOut();
    },
    [handleClickLink, logout]
  );

  const openModalSignIn = useCallback(() => {
    setOpenModalSignInUp('signIn');
  }, [setOpenModalSignInUp]);

  const openModalSignUp = useCallback(() => {
    setOpenModalSignInUp('signUp');
  }, [setOpenModalSignInUp]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <NavStyled
      ready={authReady && breakpointsReady}
      isBot={isBotStr}
      darkMode={darkMode}
      sticky={stickyNavBar}
    >
      <NavWrapper sticky={stickyNavBar}>
        <NavLogo>
          {(isMobile || isTablet) && (
            <>
              <Layout
                burgerMenuOpen={burgerMenuOpen}
                onClick={toggleBurgerMenu}
              />
              <BurgerButton
                burgerMenuOpen={burgerMenuOpen}
                onClick={toggleBurgerMenu}
              >
                <BurgerButtonBar darkMode={darkMode} />
                <BurgerButtonBar darkMode={darkMode} />
                <BurgerButtonBar darkMode={darkMode} />
              </BurgerButton>
            </>
          )}
          <Link href={ROUTES.HOME}>
            <LogoWrapper sticky={stickyNavBar}>
              <Logo darkMode={darkMode} />
              <Beta>BETA</Beta>
            </LogoWrapper>
          </Link>
        </NavLogo>
        {(isMobile || isTablet) && <NavSearchPageAppearance burgerMenuOpen={burgerMenuOpen} />}
        <UL
          darkMode={darkMode}
          burgerMenuOpen={burgerMenuOpen}
          sticky={stickyNavBar}
        >
          <LI
            active={router.pathname === ROUTES.HOME}
            darkMode={darkMode}
          >
            <Link
              href={ROUTES.HOME}
              title={t('typefaces')}
              onClick={handleClickLink}
            >
              {t('typefaces')}
            </Link>
          </LI>
          <LI
            active={
              router.pathname === ROUTES.BOOKMARKS ||
              (PAGE_NAMES[router.pathname] === USER_PAGE && userId?.toString() === routeUserId.toString())
            }
            darkMode={darkMode}
          >
            <Link
              href={!isLoggedIn ? ROUTES.BOOKMARKS : `/${transformStringToUrl(userName ?? '')}-${userId}`}
              title={t('bookmarks')}
              onClick={handleClickLink}
            >
              {t('bookmarks')}
            </Link>
          </LI>
          <LI
            active={router.pathname === ROUTES.TOOLS}
            darkMode={darkMode}
          >
            <Link
              href={ROUTES.TOOLS}
              title={t('tools')}
              onClick={handleClickLink}
            >
              {t('tools')}
            </Link>
          </LI>
          {/* DISABLED FOR BETA */}
          {/* <LI
            active={router.pathname === ROUTES.RESOURCES}
            darkMode={darkMode}
          >
            <Link
              href={ROUTES.RESOURCES}
              title={t('resources')}
              onClick={handleClickLink}
            >
              {t('resources')}
            </Link>
          </LI>
          */}
          <LI
            active={router.pathname === ROUTES.ABOUT_US}
            darkMode={darkMode}
          >
            <Link
              href={ROUTES.ABOUT_US}
              title={t('about-us')}
              onClick={handleClickLink}
            >
              {t('about-us')}
            </Link>
          </LI>
          {(isMobile || isTablet) && (
            <LI>
              <Separator darkMode={darkMode} />
            </LI>
          )}
          {!isMobile && !isTablet && (
            <LI>
              <NavSearchPageAppearance />
            </LI>
          )}
          {!isLoggedIn ? (
            <LI>
              <SignInUpWrapper>
                <Button
                  render="contrast"
                  darkMode={darkMode}
                  onClick={openModalSignIn}
                >
                  {t('sign-in')}
                </Button>
                <Button
                  darkMode={darkMode}
                  onClick={openModalSignUp}
                >
                  {t('sign-up')}
                </Button>
              </SignInUpWrapper>
            </LI>
          ) : (
            <>
              {isMobile || isTablet ? (
                <>
                  {/* DEACTIVATED FOR V1 */}
                  {/* <LI>
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        handleClickLink();
                      }}
                    >
                      <span>
                        <Icon
                          label="notif_filled"
                          color={darkMode ? WHITE : GREY_6}
                        />
                        {t('notifications')}
                      </span>
                    </a>
                  </LI> */}
                  <LI
                    active={router.pathname === ROUTES.PROFILE}
                    darkMode={darkMode}
                  >
                    <Link
                      href={ROUTES.PROFILE}
                      title={t('profile')}
                      onClick={handleClickLink}
                    >
                      <span>
                        <Icon
                          label="profile"
                          color={darkMode ? WHITE : GREY_6}
                        />
                        {t('profile')}
                      </span>
                    </Link>
                  </LI>
                  <LI darkMode={darkMode}>
                    <a
                      href="#"
                      onClick={handleLogout}
                    >
                      <span>
                        <Icon
                          label="logout"
                          color={darkMode ? WHITE : GREY_6}
                        />
                        {t('log-out')}
                      </span>
                    </a>
                  </LI>
                </>
              ) : (
                <LI>
                  <LoggedInWrapper>
                    <NavProfileDesktop />
                    {/* DEACTIVATED FOR V1 */}
                    {/* <NotifDesktopWrapper>
                      <a
                        href="#"
                        onClick={(event) => {
                          event.preventDefault();
                          handleClickLink();
                        }}
                      >
                        <Icon
                          label="notif_true"
                          color={darkMode ? WHITE : GREY_6}
                        />
                      </a>
                    </NotifDesktopWrapper> */}
                  </LoggedInWrapper>
                </LI>
              )}
            </>
          )}
        </UL>
      </NavWrapper>
    </NavStyled>
  );
};

export default Nav;
