import { isServerSideRendering } from 'common/utils';

export const convertSvgStringToDataUrl = (svgString: string): string => {
  if (isServerSideRendering()) {
    const base64Data = Buffer.from(svgString).toString('base64');
    const dataUrl = `data:image/svg+xml;base64,${base64Data}`;
    return dataUrl;
  } else {
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  }
};
